{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2023                                      }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCListBox;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF FMXLIB}
{$DEFINE FMXWEBLIB}
{$ENDIF}
{$IFDEF WEBLIB}
{$DEFINE FMXWEBLIB}
{$DEFINE CMNWEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

uses
  Classes, WEBLib.TMSFNCTreeView, WEBLib.TMSFNCStyles, WEBLib.TMSFNCPersistence, WEBLib.TMSFNCTreeViewData, WEBLib.TMSFNCCustomControl,
  WEBLib.TMSFNCTypes, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCCustomTreeView, WEBLib.TMSFNCBitmapContainer, TypInfo,
  WEBLib.TMSFNCGraphicsTypes, WEBLib.TMSFNCControlPicker
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  ,UITypes, Types
  {$ENDIF}
  {$ENDIF}
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 4; // Release nr.
  BLD_VER = 1; // Build nr.
  CLP_FMT = '#LISTBOX#';

  // version history
  // v1.0.0.0 : first release
  // v1.0.0.1 : Clear selection with ItemIndex := -1;
  // v1.0.0.2 : Fixed : Issue with OnClick and OnDblClick
  // v1.0.0.3 : Fixed : Issue with assignment of data properties
  // v1.0.1.0 : Improved : Exposed ShowFocus property
  // v1.0.1.1 : Improved : Exposed SortMode property
  // v1.0.2.0 : Improved : OnHeaderAnchorClick event added
  // v1.0.3.0 : New : Interface for TMSFNCControlPicker implemented
  // v1.0.3.1 : Fixed : Issue with saving and setting ItemIndex when clearing items
  // v1.0.4.0 : Improved : GlobalFont interface implemented
  // v1.0.4.1 : Fixed : High DPI issue with TMSFNCListBox on TFrame

type
  TTMSFNCCustomListBox = class;
  TTMSFNCListBoxInteraction = class;

  TTMSFNCListBoxItem = class(TCollectionItem)
  private
    FTag: NativeInt;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FListBox: TTMSFNCCustomListBox;
    FText: String;
    FDBKey: String;
    FChecked: Boolean;
    FDataBoolean: Boolean;
    FWordWrapping: Boolean;
    FTrimming: TTMSFNCGraphicsTextTrimming;
    FTextAlign: TTMSFNCGraphicsTextAlign;
    FSelectedTextColor: TTMSFNCGraphicsColor;
    FTextColor: TTMSFNCGraphicsColor;
    FDisabledTextColor: TTMSFNCGraphicsColor;
    FEnabled: Boolean;
    FBitmapName: string;
    FBitmap: TTMSFNCBitmap;
    FHeight: Double;
    FDataPointer: Pointer;
    FBitmapHeight: Double;
    FBitmapWidth: Double;
    procedure SetText(const Value: String);
    procedure SetHeight(const Value: Double);
    procedure SetTrimming(const Value: TTMSFNCGraphicsTextTrimming);
    procedure SetWordWrapping(const Value: Boolean);
    procedure SetTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetSelectedTextColor(const Value: TTMSFNCGraphicsColor);
    procedure SetTextColor(const Value: TTMSFNCGraphicsColor);
    procedure SetDisabledTextColor(const Value: TTMSFNCGraphicsColor);
    procedure SetEnabled(const Value: Boolean);
    procedure SetBitmapName(const Value: string);
    procedure SetBitmap(const Value: TTMSFNCBitmap);
    procedure SetChecked(const Value: Boolean);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    function GetStrippedHTMLText: string;
    function IsHeightStored: Boolean;
    function IsBitmapHeightStored: Boolean;
    function IsBitmapWidthStored: Boolean;
    procedure SetBitmapHeight(const Value: Double);
    procedure SetBitmapWidth(const Value: Double);
  protected
    procedure UpdateItem;
    procedure Changed(Sender: TObject);
    procedure BitmapChanged(Sender: TObject);
  public
    function ListBox: TTMSFNCCustomListBox;
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    function SaveToString(ATextOnly: Boolean = True): String; virtual;
    function IsSelected: Boolean; virtual;
    procedure LoadFromString(AString: String); virtual;
    procedure Assign(Source: TPersistent); override;
    procedure AssignData(Source: TPersistent); virtual;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property DBKey: String read FDBKey write FDBKey;
    property Checked: Boolean read FChecked write SetChecked default False;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer;
    property StrippedHTMLText: String read GetStrippedHTMLText;
  published
    property Text: String read FText write SetText;
    property Bitmap: TTMSFNCBitmap read FBitmap write SetBitmap;
    property BitmapWidth: Double read FBitmapWidth write SetBitmapWidth stored IsBitmapWidthStored nodefault;
    property BitmapHeight: Double read FBitmapHeight write SetBitmapHeight stored IsBitmapHeightStored nodefault;
    property BitmapName: string read FBitmapName write SetBitmapName;
    property WordWrapping: Boolean read FWordWrapping write SetWordWrapping default False;
    property Trimming: TTMSFNCGraphicsTextTrimming read FTrimming write SetTrimming default gttNone;
    property TextAlign: TTMSFNCGraphicsTextAlign read FTextAlign write SetTextAlign default gtaLeading;
    property TextColor: TTMSFNCGraphicsColor read FTextColor write SetTextColor default gcBlack;
    property SelectedTextColor: TTMSFNCGraphicsColor read FSelectedTextColor write SetSelectedTextColor default gcWhite;
    property DisabledTextColor: TTMSFNCGraphicsColor read FDisabledTextColor write SetDisabledTextColor default gcSilver;
    property Enabled: Boolean read FEnabled write SetEnabled default True;
    property Height: Double read FHeight write SetHeight stored IsHeightStored nodefault;
    property Tag: NativeInt read FTag write FTag default 0;
  end;

  TTMSFNCListBoxItemsSortMode = (ismAscending, ismDescending);
  TTMSFNCListBoxItemsSortKind = (iskNone, iskAscending, iskDescending);

  {$IFDEF WEBLIB}
  TTMSFNCListBoxItems = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCListBoxItems = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCListBoxItem>)
  {$ENDIF}
  private
    FListBox: TTMSFNCCustomListBox;
    function GetItem(Index: Integer): TTMSFNCListBoxItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCListBoxItem);
  protected
    function GetItemClass: TCollectionItemClass; virtual;
    function Compare(AItem1, AItem2: TTMSFNCListBoxItem; ACaseSensitive: Boolean = True; ASortingMode: TTMSFNCListBoxItemsSortMode = ismAscending): Integer; virtual;
    procedure QuickSort(L, R: Integer; ACaseSensitive: Boolean = True; ASortingMode: TTMSFNCListBoxItemsSortMode = ismAscending); virtual;
  public
    function ListBox: TTMSFNCCustomListBox;
    constructor Create(AListBox: TTMSFNCCustomListBox);
    function Add: TTMSFNCListBoxItem; virtual;
    function Insert(Index: Integer): TTMSFNCListBoxItem;
    procedure Clear; virtual;
    procedure Sort(ACaseSensitive: Boolean = True; ASortingMode: TTMSFNCListBoxItemsSortMode = ismAscending);
    property Items[Index: Integer]: TTMSFNCListBoxItem read GetItem write SetItem; default;
  end;

  TTMSFNCListBoxLookup = class(TPersistent)
  private
    FOwner: TTMSFNCListBoxInteraction;
    function GetAutoSelect: Boolean;
    function GetCaseSensitive: Boolean;
    function GetEnabled: Boolean;
    function GetIncremental: Boolean;
    procedure SetAutoSelect(const Value: Boolean);
    procedure SetCaseSensitive(const Value: Boolean);
    procedure SetEnabled(const Value: Boolean);
    procedure SetIncremental(const Value: Boolean);
  public
    constructor Create(AOwner: TTMSFNCListBoxInteraction);
  published
    property CaseSensitive: Boolean read GetCaseSensitive write SetCaseSensitive default False;
    property Enabled: Boolean read GetEnabled write SetEnabled default True;
    property Incremental: Boolean read GetIncremental write SetIncremental default True;
    property AutoSelect: Boolean read GetAutoSelect write SetAutoSelect default True;
  end;

  TTMSFNCListBoxFiltering = class(TPersistent)
  private
    FOwner: TTMSFNCListBoxInteraction;
    function GetDropDownHeight: integer;
    function GetDropDownWidth: integer;
    function GetEnabled: Boolean;
    procedure SetDropDownHeight(const Value: integer);
    procedure SetDropDownWidth(const Value: integer);
    procedure SetEnabled(const Value: Boolean);
  public
    constructor Create(AOwner: TTMSFNCListBoxInteraction);
  published
    property Enabled: Boolean read GetEnabled write SetEnabled default False;
    property DropDownWidth: integer read GetDropDownWidth write SetDropDownWidth default 100;
    property DropDownHeight: integer read GetDropDownHeight write SetDropDownHeight default 120;
  end;

  TTMSFNCListBoxMouseEditMode = (lmemDoubleClick, lmemSingleClick, lmemSingleClickOnSelectedItem);
  TTMSFNCListBoxClipboardMode = (lcmNone, lcmTextOnly, lcmFull);
  TTMSFNCListBoxDragDropMode = (ldmNone, ldmMove, ldmCopy);
  TTMSFNCListBoxSorting = (lcsNone, lcsNormal, lcsNormalCaseSensitive);

  TTMSFNCListBoxInteraction = class(TPersistent)
  private
    FListBox: TTMSFNCCustomListBox;
    FLookup: TTMSFNCListBoxLookup;
    FFiltering: TTMSFNCListBoxFiltering;
    function GetMultiSelect: Boolean;
    procedure SetMultiSelect(const Value: Boolean);
    function GetClipboardMode: TTMSFNCListBoxClipboardMode;
    function GetDragDropMode: TTMSFNCListBoxDragDropMode;
    function GetReorder: Boolean;
    function GetTouchScrolling: Boolean;
    procedure SetDragDropMode(const Value: TTMSFNCListBoxDragDropMode);
    procedure SetFiltering(const Value: TTMSFNCListBoxFiltering);
    procedure SetLookup(const Value: TTMSFNCListBoxLookup);
    procedure SetReorder(const Value: Boolean);
    procedure SetTouchScrolling(const Value: Boolean);
    procedure SetClipboardMode(const Value: TTMSFNCListBoxClipboardMode);
    function GetSorting: TTMSFNCListBoxSorting;
    procedure SetSorting(const Value: TTMSFNCListBoxSorting);
  public
    constructor Create(AListBox: TTMSFNCCustomListBox);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property MultiSelect: Boolean read GetMultiSelect write SetMultiSelect default False;
    property TouchScrolling: Boolean read GetTouchScrolling write SetTouchScrolling default True;
    property ClipboardMode: TTMSFNCListBoxClipboardMode read GetClipboardMode write SetClipboardMode default lcmNone;
    property Reorder: Boolean read GetReorder write SetReorder default False;
    property DragDropMode: TTMSFNCListBoxDragDropMode read GetDragDropMode write SetDragDropMode default ldmNone;
    property Lookup: TTMSFNCListBoxLookup read FLookup write SetLookup;
    property Filtering: TTMSFNCListBoxFiltering read FFiltering write SetFiltering;
    property Sorting: TTMSFNCListBoxSorting read GetSorting write SetSorting default lcsNone;
  end;

  TTMSFNCListBoxItemHeightMode = (lihmFixed, lihmVariable);

  TTMSFNCListBoxItemsAppearance = class(TPersistent)
  private
    FListBox: TTMSFNCCustomListBox;
    function GetFont: TTMSFNCGraphicsFont;
    function GetSelectedFill: TTMSFNCGraphicsFill;
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetSelectedFill(const Value: TTMSFNCGraphicsFill);
    function GetSelectedStroke: TTMSFNCGraphicsStroke;
    procedure SetSelectedStroke(const Value: TTMSFNCGraphicsStroke);
    function GetDisabledFill: TTMSFNCGraphicsFill;
    function GetDisabledStroke: TTMSFNCGraphicsStroke;
    function GetFill: TTMSFNCGraphicsFill;
    function GetStroke: TTMSFNCGraphicsStroke;
    procedure SetDisabledFill(const Value: TTMSFNCGraphicsFill);
    procedure SetDisabledStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    function GetHeightMode: TTMSFNCListBoxItemHeightMode;
    procedure SetHeightMode(const Value: TTMSFNCListBoxItemHeightMode);
    function GetFixedHeight: Double;
    procedure SetFixedHeight(const Value: Double);
    function GetShowFocus: Boolean;
    procedure SetShowFocus(const Value: Boolean);
  public
    constructor Create(AListBox: TTMSFNCCustomListBox);
  published
    property Font: TTMSFNCGraphicsFont read GetFont write SetFont;
    property Fill: TTMSFNCGraphicsFill read GetFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read GetStroke write SetStroke;
    property SelectedFill: TTMSFNCGraphicsFill read GetSelectedFill write SetSelectedFill;
    property SelectedStroke: TTMSFNCGraphicsStroke read GetSelectedStroke write SetSelectedStroke;
    property DisabledFill: TTMSFNCGraphicsFill read GetDisabledFill write SetDisabledFill;
    property DisabledStroke: TTMSFNCGraphicsStroke read GetDisabledStroke write SetDisabledStroke;
    property HeightMode: TTMSFNCListBoxItemHeightMode read GetHeightMode write SetHeightMode default lihmFixed;
    property FixedHeight: Double read GetFixedHeight write SetFixedHeight;
    property ShowFocus: Boolean read GetShowFocus write SetShowFocus default True;
  end;

  TTMSFNCListBoxItemArray = array of Integer;

  TTMSFNCListBoxHeader = class(TPersistent)
  private
    FListBox: TTMSFNCCustomListBox;
    function GetVisible: Boolean;
    procedure SetVisible(const Value: Boolean);
    function GetText: String;
    procedure SetText(const Value: String);
    function GetFill: TTMSFNCGraphicsFill;
    function GetHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    function GetSize: Single;
    function GetStroke: TTMSFNCGraphicsStroke;
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetHorizontalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetSize(const Value: Single);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    function GetVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    procedure SetVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    function GetFont: TTMSFNCGraphicsFont;
    function GetTrimming: TTMSFNCGraphicsTextTrimming;
    function GetWordWrapping: Boolean;
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetTrimming(const Value: TTMSFNCGraphicsTextTrimming);
    procedure SetWordWrapping(const Value: Boolean);
    function GetSortIndicatorColor: TTMSFNCGraphicsColor;
    procedure SetSortIndicatorColor(const Value: TTMSFNCGraphicsColor);
  public
    constructor Create(AListBox: TTMSFNCCustomListBox); virtual;
  published
    property Visible: Boolean read GetVisible write SetVisible default False;
    property Text: String read GetText write SetText;
    property Fill: TTMSFNCGraphicsFill read GetFill write SetFill;
    property Font: TTMSFNCGraphicsFont read GetFont write SetFont;
    property Stroke: TTMSFNCGraphicsStroke read GetStroke write SetStroke;
    property HorizontalTextAlign: TTMSFNCGraphicsTextAlign read GetHorizontalTextAlign write SetHorizontalTextAlign default gtaCenter;
    property VerticalTextAlign: TTMSFNCGraphicsTextAlign read GetVerticalTextAlign write SetVerticalTextAlign default gtaCenter;
    property WordWrapping: Boolean read GetWordWrapping write SetWordWrapping default False;
    property Trimming: TTMSFNCGraphicsTextTrimming read GetTrimming write SetTrimming default gttNone;
    property Size: Single read GetSize write SetSize;
    property SortIndicatorColor: TTMSFNCGraphicsColor read GetSortIndicatorColor write SetSortIndicatorColor default TTMSFNCTreeViewColorSelection;
  end;

  TTMSFNCListBoxCopyItems = class(TTMSFNCListBoxItems);

  TTMSFNCListBoxBeforeDrawSortIndicatorEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ASortIndex: Integer; ASortKind: TTMSFNCListBoxItemsSortKind; var ADefaultDraw: Boolean) of object;
  TTMSFNCListBoxAfterDrawSortIndicatorEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ASortIndex: Integer; ASortKind: TTMSFNCListBoxItemsSortKind) of object;
  TTMSFNCListBoxNeedFilterDropDownDataEvent = procedure(Sender: TObject; AValues: TStrings) of object;
  TTMSFNCListBoxItemAnchorClickEvent = procedure(Sender: TObject; AItem: TTMSFNCListBoxItem; AAnchor: String) of object;
  TTMSFNCListBoxHeaderAnchorClickEvent = procedure(Sender: TObject; AAnchor: String) of object;
  TTMSFNCListBoxBeforeReorderItemEvent = procedure(Sender: TObject; AFromItem, AToItem: TTMSFNCListBoxItem; var ACanReorder: Boolean) of object;
  TTMSFNCListBoxAfterReorderItemEvent = procedure(Sender: TObject; AFromItem, AToItem: TTMSFNCListBoxItem) of object;
  TTMSFNCListBoxBeforeDropItemEvent = procedure(Sender: TObject; AFromItem, AToItem: TTMSFNCListBoxItem; var ACanDrop: Boolean) of object;
  TTMSFNCListBoxAfterDropItemEvent = procedure(Sender: TObject; AFromItem, AToItem: TTMSFNCListBoxItem) of object;
  TTMSFNCListBoxBeforeCopyToClipboardEvent = procedure(Sender: TObject; var ACanCopy: Boolean) of object;
  TTMSFNCListBoxBeforeCutToClipboardEvent = procedure(Sender: TObject; var ACanCut: Boolean) of object;
  TTMSFNCListBoxBeforePasteFromClipboardEvent = procedure(Sender: TObject; var ACanPaste: Boolean) of object;
  TTMSFNCListBoxAfterCopyToClipboardEvent = procedure(Sender: TObject) of object;
  TTMSFNCListBoxAfterCutToClipboardEvent = procedure(Sender: TObject) of object;
  TTMSFNCListBoxAfterPasteFromClipboardEvent = procedure(Sender: TObject) of object;
  TTMSFNCListBoxBeforeDrawItemTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCListBoxItem; AText: String; var AAllow: Boolean) of object;
  TTMSFNCListBoxAfterDrawItemTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCListBoxItem; AText: String) of object;
  TTMSFNCListBoxItemSelectedEvent = procedure(Sender: TObject; AItem: TTMSFNCListBoxItem) of object;
  TTMSFNCListBoxItemClickEvent = procedure(Sender: TObject; AItem: TTMSFNCListBoxItem) of object;
  TTMSFNCListBoxItemMouseEnterEvent = procedure(Sender: TObject; AItem: TTMSFNCListBoxItem) of object;
  TTMSFNCListBoxItemMouseLeaveEvent = procedure(Sender: TObject; AItem: TTMSFNCListBoxItem) of object;
  TTMSFNCListBoxScrollEvent = procedure(Sender: TObject; APosition: Single) of object;
  TTMSFNCListBoxGetItemHeightEvent = procedure(Sender: TObject; AItem: TTMSFNCListBoxItem; var AHeight: Double) of object;
  TTMSFNCListBoxBeforeDrawItemIconEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCListBoxItem; AIcon:TTMSFNCBitmap; var AAllow: Boolean) of object;
  TTMSFNCListBoxAfterDrawItemIconEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCListBoxItem; AIcon:TTMSFNCBitmap) of object;
  TTMSFNCListBoxBeforeDrawItemCheckEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCListBoxItem; ACheck: TTMSFNCBitmap; var AAllow: Boolean) of object;
  TTMSFNCListBoxAfterDrawItemCheckEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCListBoxItem; ACheck: TTMSFNCBitmap) of object;
  TTMSFNCListBoxBeforeDrawItemEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCListBoxItem; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCListBoxAfterDrawItemEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItem: TTMSFNCListBoxItem) of object;
  TTMSFNCListBoxItemCompareEvent = procedure(Sender: TObject; Item1, Item2: TTMSFNCListBoxItem; var ACompareResult: Integer) of object;
  TTMSFNCListBoxFilterSelectEvent = procedure(Sender: TObject; var ACondition: string) of object;

  TTMSFNCListBoxFilterOperation = (lfoSHORT, lfoNONE, lfoAND, lfoXOR, lfoOR);

  TTMSFNCListBoxFilterData = class(TCollectionItem)
  private
    FCondition: string;
    FCaseSensitive: Boolean;
    FSuffix: string;
    FPrefix: string;
    FOperation: TTMSFNCListBoxFilterOperation;
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
  published
    property Condition:string read FCondition write FCondition;
    property CaseSensitive: Boolean read FCaseSensitive write FCaseSensitive default True;
    property Prefix: string read FPrefix write FPrefix;
    property Suffix: string read FSuffix write FSuffix;
    property Operation: TTMSFNCListBoxFilterOperation read FOperation write FOperation;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCListBoxFilter = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCListBoxFilter = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCListBoxFilterData>)
  {$ENDIF}
  private
    FOwner: TTMSFNCCustomListBox;
    function GetItem(Index: Integer): TTMSFNCListBoxFilterData;
    procedure SetItem(Index: Integer; const Value: TTMSFNCListBoxFilterData);
  public
    constructor Create(AOwner: TTMSFNCCustomListBox);
    function Add: TTMSFNCListBoxFilterData;
    function Insert(index: Integer): TTMSFNCListBoxFilterData;
    property Items[Index: Integer]: TTMSFNCListBoxFilterData read GetItem write SetItem; default;
  end;

  TTMSFNCListBoxFilteredItems = array of TTMSFNCListBoxItem;

  TTMSFNCCustomListBox = class(TTMSFNCCustomControl, ITMSFNCStylesManager, ITMSFNCBitmapContainer, ITMSFNCControlPickerFull, ITMSFNCAppearanceGlobalFont)
  private
    FUpdateCount: Integer;
    FSaveSelectedItemIndex: Integer;
    FCopyItems: TTMSFNCListBoxCopyItems;
    FAccepted: Boolean;
    FItems: TTMSFNCListBoxItems;
    FTreeView: TTMSFNCTreeView;
    FDefaultItem: TTMSFNCListBoxItem;
    FItemsAppearance: TTMSFNCListBoxItemsAppearance;
    FOnItemSelected: TTMSFNCListBoxItemSelectedEvent;
    FInteraction: TTMSFNCListBoxInteraction;
    FHeader: TTMSFNCListBoxHeader;
    FOnItemAnchorClick: TTMSFNCListBoxItemAnchorClickEvent;
    FOnAfterCutToClipboard: TTMSFNCListBoxAfterCutToClipboardEvent;
    FOnBeforePasteFromClipboard: TTMSFNCListBoxBeforePasteFromClipboardEvent;
    FOnAfterReorderItem: TTMSFNCListBoxAfterReorderItemEvent;
    FOnBeforeDropItem: TTMSFNCListBoxBeforeDropItemEvent;
    FOnBeforeDrawItemText: TTMSFNCListBoxBeforeDrawItemTextEvent;
    FOnVScroll: TTMSFNCListBoxScrollEvent;
    FOnBeforeCopyToClipboard: TTMSFNCListBoxBeforeCopyToClipboardEvent;
    FOnItemDblClick: TTMSFNCListBoxItemClickEvent;
    FOnBeforeDrawItemCheck: TTMSFNCListBoxBeforeDrawItemCheckEvent;
    FOnAfterDrawItemIcon: TTMSFNCListBoxAfterDrawItemIconEvent;
    FOnBeforeDrawItem: TTMSFNCListBoxBeforeDrawItemEvent;
    FOnAfterPasteFromClipboard: TTMSFNCListBoxAfterPasteFromClipboardEvent;
    FOnItemClick: TTMSFNCListBoxItemClickEvent;
    FOnAfterDropItem: TTMSFNCListBoxAfterDropItemEvent;
    FOnAfterDrawItemText: TTMSFNCListBoxAfterDrawItemTextEvent;
    FOnItemCompare: TTMSFNCListBoxItemCompareEvent;
    FOnBeforeCutToClipboard: TTMSFNCListBoxBeforeCutToClipboardEvent;
    FOnBeforeReorderItem: TTMSFNCListBoxBeforeReorderItemEvent;
    FOnNeedFilterDropDownData: TTMSFNCListBoxNeedFilterDropDownDataEvent;
    FOnAfterCopyToClipboard: TTMSFNCListBoxAfterCopyToClipboardEvent;
    FOnFilterSelect: TTMSFNCListBoxFilterSelectEvent;
    FOnAfterDrawItemCheck: TTMSFNCListBoxAfterDrawItemCheckEvent;
    FOnAfterDrawItem: TTMSFNCListBoxAfterDrawItemEvent;
    FOnBeforeDrawItemIcon: TTMSFNCListBoxBeforeDrawItemIconEvent;
    FFilter: TTMSFNCListBoxFilter;
    FOnBeforeDrawSortIndicator: TTMSFNCListBoxBeforeDrawSortIndicatorEvent;
    FOnAfterDrawSortIndicator: TTMSFNCListBoxAfterDrawSortIndicatorEvent;
    FOnItemMouseLeave: TTMSFNCListBoxItemMouseLeaveEvent;
    FOnItemMouseEnter: TTMSFNCListBoxItemMouseEnterEvent;
    FSortMode: TTMSFNCListBoxItemsSortMode;
    FOnHeaderAnchorClick: TTMSFNCListBoxHeaderAnchorClickEvent;
    FGlobalFont: TTMSFNCAppearanceGlobalFont;
    procedure SetItems(const Value: TTMSFNCListBoxItems);
    procedure SetDefaultItem(const Value: TTMSFNCListBoxItem);
    procedure SetItemsAppearance(const Value: TTMSFNCListBoxItemsAppearance);
    function GetItemIndex: Integer;
    procedure SetItemIndex(const Value: Integer);
    procedure SetInteraction(const Value: TTMSFNCListBoxInteraction);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
    procedure SetHeader(const Value: TTMSFNCListBoxHeader);
    function GetSelectedItem: TTMSFNCListBoxItem;
    procedure SetSelectedItem(const Value: TTMSFNCListBoxItem);
    function GetSelItem(AIndex: Integer): TTMSFNCListBoxItem;
    function GetVerticalScrollBarVisible: Boolean;
    procedure SetVerticalScrollBarVisible(const Value: Boolean);
    function GetFill: TTMSFNCGraphicsFill;
    function GetStroke: TTMSFNCGraphicsStroke;
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    function GetFilteredItems: TTMSFNCListBoxFilteredItems;
    procedure SetGlobalFont(const Value: TTMSFNCAppearanceGlobalFont);
  protected
    function GetDocURL: string; override;
    function GetSubComponentArray: TTMSFNCStylesManagerComponentArray;
    function IsAppearanceProperty({%H-}AObject: TObject; {%H-}APropertyName: string; {%H-}APropertyType: TTypeKind): Boolean; override;
    function GetVersion: string; override;
    function CreateItems: TTMSFNCListBoxItems; virtual;
    function GetItemIndexForNode(ANode: TTMSFNCTreeViewVirtualNode): Integer; virtual;
    function GetItemForNode(ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCListBoxItem; virtual;
    function GetNodeForItemIndex(AItemIndex: Integer): TTMSFNCTreeViewVirtualNode; virtual;
    procedure Loaded; override;
    procedure RegisterRuntimeClasses; override;
    procedure ApplyStyle; override;
    procedure ResetToDefaultStyle; override;
    procedure SetAdaptToStyle(const Value: Boolean); override;
    procedure UpdateItems; virtual;
    procedure DoGetNodeHeight(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var AHeight: Double); virtual;
    procedure DoGetNodeIcon(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; {%H-}ALarge: Boolean; var AIcon: TTMSFNCBitmap); virtual;
    procedure DoGetNodeIconSize(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; {%H-}ALarge: Boolean; AIcon: TTMSFNCBitmap; var AIconWidth: Double; var AIconHeight: Double); virtual;
    procedure DoGetNumberOfNodes(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; var ANumberOfNodes: Integer); virtual;
    procedure DoGetNodeText(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; {%H-}AMode: TTMSFNCTreeViewNodeTextMode; var AText: String); virtual;
    procedure DoGetNodeTrimming(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var ATrimming: TTMSFNCGraphicsTextTrimming); virtual;
    procedure DoGetNodeWordWrapping(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var AWordWrapping: Boolean); virtual;
    procedure DoGetNodeHorizontalTextAlign(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoGetNodeVerticalTextAlign(Sender: TObject; {%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var AVerticalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoGetNodeTextColor(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var ATextColor: TTMSFNCGraphicsColor); virtual;
    procedure DoGetNodeSelectedTextColor(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var ASelectedTextColor: TTMSFNCGraphicsColor); virtual;
    procedure DoGetNodeDisabledTextColor(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var ADisabledTextColor: TTMSFNCGraphicsColor); virtual;
    procedure DoIsNodeEnabled(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; var AEnabled: Boolean); virtual;
    procedure DoAfterSelectNode(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode); virtual;
    procedure DoNeedFilterDropDownData(Sender: TObject; {%H-}AColumn: Integer; AValues: TStrings); virtual;
    procedure DoFilterSelect(Sender: TObject; {%H-}AColumn: integer; var ACondition: string); virtual;
    procedure DoBeforeDrawSortIndicator(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; {%H-}AColumn: Integer; ASortIndex: Integer; ASortKind: TTMSFNCTreeViewNodesSortKind; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSortIndicator(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; {%H-}AColumn: Integer; ASortIndex: Integer; ASortKind: TTMSFNCTreeViewNodesSortKind); virtual;
    procedure DoBeforeDrawNode(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ANode: TTMSFNCTreeViewVirtualNode; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawNode(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ANode: TTMSFNCTreeViewVirtualNode); virtual;
    procedure DoItemCompare(AItem1, AItem2: TTMSFNCListBoxItem; var ACompareResult: Integer); virtual;
    procedure DoBeforeDrawNodeText(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; {%H-}AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; AText: String; var AAllow: Boolean); virtual;
    procedure DoAfterDrawNodeText(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; {%H-}AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; AText: String); virtual;
    procedure DoClick(Sender: TObject);
    procedure DoDblClick(Sender: TObject);
    procedure DoBeforeDrawNodeIcon(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; {%H-}AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; AIcon: TTMSFNCBitmap; var AAllow: Boolean); virtual;
    procedure DoAfterDrawNodeIcon(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; {%H-}AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; AIcon: TTMSFNCBitmap); virtual;
    procedure DoBeforeDrawNodeCheck(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; {%H-}AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; ACheck: TTMSFNCBitmap; var AAllow: Boolean); virtual;
    procedure DoAfterDrawNodeCheck(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; {%H-}AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; ACheck: TTMSFNCBitmap); virtual;
    procedure DoNodeAnchorClick(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; AAnchor: String); virtual;
    procedure DoColumnAnchorClick(Sender: TObject; {%H-}AColumn: Integer; AAnchor: String); virtual;
    procedure DoNodeClick(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode); virtual;
    procedure DoNodeMouseEnter(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode); virtual;
    procedure DoNodeMouseLeave(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode); virtual;
    procedure DoNodeDblClick(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode); virtual;
    procedure DoVScroll(Sender: TObject; APosition: Single); virtual;
    procedure DoBeforeCutToClipboard(Sender: TObject; var ACanCut: Boolean); virtual;
    procedure DoBeforeCopyToClipboard(Sender: TObject; var ACanCopy: Boolean); virtual;
    procedure DoBeforePasteFromClipboard(Sender: TObject; var ACanPaste: Boolean); virtual;
    procedure DoAfterCutToClipboard(Sender: TObject); virtual;
    procedure DoAfterCopyToClipboard(Sender: TObject); virtual;
    procedure DoAfterPasteFromClipboard(Sender: TObject); virtual;
    procedure DoBeforeReorderItem(AFromItem, AToItem: TTMSFNCListBoxItem; var ACanReorder: Boolean); virtual;
    procedure DoAfterReorderItem(AFromItem, AToItem: TTMSFNCListBoxItem); virtual;
    procedure DoBeforeDropItem(AFromItem, AToItem: TTMSFNCListBoxItem; var ACanDrop: Boolean); virtual;
    procedure DoAfterDropItem(AFromItem, AToItem: TTMSFNCListBoxItem); virtual;
    procedure CustomizeTreeView({%H-}ATreeView: TTMSFNCTreeView); virtual;
    procedure DoCustomDragOver(Sender: TObject; Source: TObject; {%H-}Point: TPointF; var Accept: Boolean); virtual;
    procedure DoCustomDragDrop(Sender: TObject; Source: TObject; {%H-}Point: TPointF); virtual;
    procedure DoCustomReorder(Sender: TObject; AFromNode, AToNode: TTMSFNCTreeViewVirtualNode); virtual;
    procedure DoCopyToClipboard(Sender: TObject); virtual;
    procedure DoCutToClipboard(Sender: TObject); virtual;
    procedure DoPasteFromClipboard(Sender: TObject); virtual;
    procedure DoColumnSort(Sender: TObject; {%H-}AColumn: Integer; ASortMode: TTMSFNCTreeViewNodesSortMode); virtual;
    procedure DoLookup(Sender: TObject; ALookupString: String); virtual;
    function PickerGetContent: String; virtual;
    procedure PickerSelectItem(AItemIndex: Integer); virtual;
    function PickerGetSelectedItem: Integer; virtual;
    function PickerGetVisibleItemCount: Integer; virtual;
    function PickerGetItemCount: Integer; virtual;
    function PickerGetItemHeight: Single; virtual;
    procedure PickerSetItemHeight(AValue: Single); virtual;
    function PickerGetItemWidth: Single; virtual;
    procedure PickerSetItemWidth(AValue: Single); virtual;
    function PickerGetNextSelectableItem(AItemIndex: Integer): Integer; virtual;
    function PickerGetPreviousSelectableItem(AItemIndex: Integer): Integer; virtual;
    function PickerGetFirstSelectableItem: Integer; virtual;
    function PickerGetLastSelectableItem: Integer; virtual;
    procedure PickerResetFilter; virtual;
    procedure PickerApplyFilter(ACondition: string; ACaseSensitive: Boolean); virtual;
    function PickerLookupItem(ALookupString: String; ACaseSensitive: Boolean): TTMSFNCControlPickerFilterItem; virtual;
    procedure SetFonts(ASetType: TTMSFNCAppearanceGlobalFontType); virtual;
    property GlobalFont: TTMSFNCAppearanceGlobalFont read FGlobalFont write SetGlobalFont;
    property Fill: TTMSFNCGraphicsFill read GetFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read GetStroke write SetStroke;
    property Version: String read GetVersion;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer write SetBitmapContainer;
    property Items: TTMSFNCListBoxItems read FItems write SetItems;
    property DefaultItem: TTMSFNCListBoxItem read FDefaultItem write SetDefaultItem;
    property ItemsAppearance: TTMSFNCListBoxItemsAppearance read FItemsAppearance write SetItemsAppearance;
    property Interaction: TTMSFNCListBoxInteraction read FInteraction write SetInteraction;
    property ItemIndex: Integer read GetItemIndex write SetItemIndex default -1;
    property OnItemSelected: TTMSFNCListBoxItemSelectedEvent read FOnItemSelected write FOnItemSelected;
    property Header: TTMSFNCListBoxHeader read FHeader write SetHeader;
    property VerticalScrollBarVisible: Boolean read GetVerticalScrollBarVisible write SetVerticalScrollBarVisible default True;
    property OnNeedFilterDropDownData: TTMSFNCListBoxNeedFilterDropDownDataEvent read FOnNeedFilterDropDownData write FOnNeedFilterDropDownData;
    property OnFilterSelect: TTMSFNCListBoxFilterSelectEvent read FOnFilterSelect write FOnFilterSelect;
    property OnBeforeDrawItem: TTMSFNCListBoxBeforeDrawItemEvent read FOnBeforeDrawItem write FOnBeforeDrawItem;
    property OnAfterDrawItem: TTMSFNCListBoxAfterDrawItemEvent read FOnAfterDrawItem write FOnAfterDrawItem;
    property OnItemCompare: TTMSFNCListBoxItemCompareEvent read FOnItemCompare write FOnItemCompare;
    property OnAfterDrawItemIcon: TTMSFNCListBoxAfterDrawItemIconEvent read FOnAfterDrawItemIcon write FOnAfterDrawItemIcon;
    property OnBeforeDrawItemIcon: TTMSFNCListBoxBeforeDrawItemIconEvent read FOnBeforeDrawItemIcon write FOnBeforeDrawItemIcon;
    property OnAfterDrawItemCheck: TTMSFNCListBoxAfterDrawItemCheckEvent read FOnAfterDrawItemCheck write FOnAfterDrawItemCheck;
    property OnBeforeDrawItemCheck: TTMSFNCListBoxBeforeDrawItemCheckEvent read FOnBeforeDrawItemCheck write FOnBeforeDrawItemCheck;
    property OnBeforeDrawItemText: TTMSFNCListBoxBeforeDrawItemTextEvent read FOnBeforeDrawItemText write FOnBeforeDrawItemText;
    property OnAfterDrawItemText: TTMSFNCListBoxAfterDrawItemTextEvent read FOnAfterDrawItemText write FOnAfterDrawItemText;
    property OnAfterDrawSortIndicator: TTMSFNCListBoxAfterDrawSortIndicatorEvent read FOnAfterDrawSortIndicator write FOnAfterDrawSortIndicator;
    property OnBeforeDrawSortIndicator: TTMSFNCListBoxBeforeDrawSortIndicatorEvent read FOnBeforeDrawSortIndicator write FOnBeforeDrawSortIndicator;
    property OnItemAnchorClick: TTMSFNCListBoxItemAnchorClickEvent read FOnItemAnchorClick write FOnItemAnchorClick;
    property OnItemClick: TTMSFNCListBoxItemClickEvent read FOnItemClick write FOnItemClick;
    property OnItemMouseEnter: TTMSFNCListBoxItemMouseEnterEvent read FOnItemMouseEnter write FOnItemMouseEnter;
    property OnItemMouseLeave: TTMSFNCListBoxItemMouseLeaveEvent read FOnItemMouseLeave write FOnItemMouseLeave;
    property OnItemDblClick: TTMSFNCListBoxItemClickEvent read FOnItemDblClick write FOnItemDblClick;
    property OnVScroll: TTMSFNCListBoxScrollEvent read FOnVScroll write FOnVScroll;
    property OnBeforeReorderItem: TTMSFNCListBoxBeforeReorderItemEvent read FOnBeforeReorderItem write FOnBeforeReorderItem;
    property OnAfterReorderItem: TTMSFNCListBoxAfterReorderItemEvent read FOnAfterReorderItem write FOnAfterReorderItem;
    property OnBeforeDropItem: TTMSFNCListBoxBeforeDropItemEvent read FOnBeforeDropItem write FOnBeforeDropItem;
    property OnAfterDropItem: TTMSFNCListBoxAfterDropItemEvent read FOnAfterDropItem write FOnAfterDropItem;
    property OnBeforeCutToClipboard: TTMSFNCListBoxBeforeCutToClipboardEvent read FOnBeforeCutToClipboard write FOnBeforeCutToClipboard;
    property OnBeforeCopyToClipboard: TTMSFNCListBoxBeforeCopyToClipboardEvent read FOnBeforeCopyToClipboard write FOnBeforeCopyToClipboard;
    property OnBeforePasteFromClipboard: TTMSFNCListBoxBeforePasteFromClipboardEvent read FOnBeforePasteFromClipboard write FOnBeforePasteFromClipboard;
    property OnAfterCutToClipboard: TTMSFNCListBoxAfterCutToClipboardEvent read FOnAfterCutToClipboard write FOnAfterCutToClipboard;
    property OnAfterCopyToClipboard: TTMSFNCListBoxAfterCopyToClipboardEvent read FOnAfterCopyToClipboard write FOnAfterCopyToClipboard;
    property OnAfterPasteFromClipboard: TTMSFNCListBoxAfterPasteFromClipboardEvent read FOnAfterPasteFromClipboard write FOnAfterPasteFromClipboard;
    property OnHeaderAnchorClick: TTMSFNCListBoxHeaderAnchorClickEvent read FOnHeaderAnchorClick write FOnHeaderAnchorClick;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function AddItem(AText: string = ''): TTMSFNCListBoxItem; virtual;
    function XYToItem(X, Y: Single): TTMSFNCListBoxItem; virtual;
    function XYToItemIndex(X, Y: Single): Integer; virtual;
    function SelectedItemCount: Integer; virtual;
    function GetItemsFromClipboard: TTMSFNCListBoxCopyItems; virtual;
    function LookupItem(ALookupString: String; ACaseSensitive: Boolean = False; AAutoSelect: Boolean = False): TTMSFNCListBoxItem; virtual;
    function IsItemSelectable(AItem: TTMSFNCListBoxItem): Boolean; virtual;
    procedure RemoveItem(AItem: TTMSFNCListBoxItem); virtual;
    procedure DoEnter; override;
    procedure BeginUpdate; override;
    procedure EndUpdate; override;
    procedure InitSample; virtual;
    procedure UnSelectAllItems; virtual;
    procedure LoadSettingsFromFile(AFileName: string); override;
    procedure LoadSettingsFromStream(AStream: TStreamEx); override;
    procedure ScrollToItem(AItemIndex: Integer); virtual;
    procedure SelectItem(AItemIndex: Integer); virtual;
    procedure SelectItems(AItemIndexes: TTMSFNCListBoxItemArray); virtual;
    procedure LoadFromStrings(AStrings: TStrings); virtual;
    procedure SaveToStrings(AStrings: TStrings); virtual;
    {$IFNDEF WEBLIB}
    procedure LoadFromFile(AFileName: string); virtual;
    procedure SaveToFile(AFileName: string; ATextOnly: Boolean = True); virtual;
    procedure LoadFromStream(AStream: TStream); virtual;
    procedure SaveToStream(AStream: TStream; ATextOnly: Boolean = True); virtual;
    {$ENDIF}
    procedure CutToClipboard(ATextOnly: Boolean = False); virtual;
    procedure CopyToClipboard(ATextOnly: Boolean = False); virtual;
    procedure PasteFromClipboard; virtual;
    procedure Sort(ACaseSensitive: Boolean = True; ASortingMode: TTMSFNCListBoxItemsSortMode = ismAscending); {$IFDEF FMXLIB}reintroduce;{$ENDIF} virtual;
    procedure ClearSorting; virtual;
    procedure ApplyFilter; virtual;
    procedure RemoveFilter; virtual;
    procedure RemoveFilters; virtual;
    property FilteredItems: TTMSFNCListBoxFilteredItems read GetFilteredItems;
    property Filter: TTMSFNCListBoxFilter read FFilter;
    property SelectedItem: TTMSFNCListBoxItem read GetSelectedItem write SetSelectedItem;
    property SelectedItems[AIndex: Integer]: TTMSFNCListBoxItem read GetSelItem;
    property TreeView: TTMSFNCTreeView read FTreeView;
    property SortMode: TTMSFNCListBoxItemsSortMode read FSortMode;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCListBox = class(TTMSFNCCustomListBox)
  protected
    procedure RegisterRuntimeClasses; override;
  published
    property Fill;
    property Stroke;
    property BitmapContainer;
    property ItemsAppearance;
    property DefaultItem;
    property Items;
    property Header;
    property ItemIndex;
    property Interaction;
    property OnItemSelected;
    property Version;
    property VerticalScrollBarVisible;
    property GlobalFont;
    property OnNeedFilterDropDownData;
    property OnFilterSelect;
    property OnBeforeDrawItem;
    property OnAfterDrawItem;
    property OnItemCompare;
    property OnAfterDrawItemIcon;
    property OnBeforeDrawItemIcon;
    property OnBeforeDrawItemText;
    property OnAfterDrawItemText;
    property OnAfterDrawSortIndicator;
    property OnBeforeDrawSortIndicator;
    property OnItemAnchorClick;
    property OnItemClick;
    property OnItemMouseLeave;
    property OnItemMouseEnter;
    property OnItemDblClick;
    property OnVScroll;
    property OnBeforeReorderItem;
    property OnAfterReorderItem;
    property OnBeforeDropItem;
    property OnAfterDropItem;
    property OnBeforeCutToClipboard;
    property OnBeforeCopyToClipboard;
    property OnBeforePasteFromClipboard;
    property OnAfterCutToClipboard;
    property OnAfterCopyToClipboard;
    property OnAfterPasteFromClipboard;
  end;

implementation

uses
  WEBLib.TMSFNCUtils, WEBLib.Controls, SysUtils, WEBLib.Graphics, WEBLib.TMSFNCCustomSelector
  {$IFDEF FMXLIB}
  ,FMX.Types
  {$ENDIF}
  {$IFDEF VCLLIB}
  ,VCL.Forms
  {$ENDIF}
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  ,Generics.Collections
  {$ENDIF}
  {$ENDIF}
  ;

type
  TTMSFNCTreeViewOpen = class(TTMSFNCTreeView);
  TTMSFNCCustomTreeViewOpen = class(TTMSFNCCustomTreeView);
  TTMSFNCCustomListBoxOpen = class(TTMSFNCCustomListBox);

function TTMSFNCCustomListBox.AddItem(AText: string = ''): TTMSFNCListBoxItem;
begin
  Result := Items.Add;
  Result.Text := AText;
end;

procedure TTMSFNCCustomListBox.ApplyFilter;
var
  I: Integer;
  ft: TTMSFNCTreeViewFilterData;
  o: Integer;
begin
  if Assigned(FTreeView) then
  begin
    FTreeView.Filter.Clear;
    for I := 0 to Filter.Count - 1 do
    begin
      ft := FTreeView.Filter.Add;
      ft.Column := 0;
      ft.Condition := Filter[I].Condition;
      ft.CaseSensitive := Filter[I].CaseSensitive;
      ft.Prefix := Filter[I].Prefix;
      ft.Suffix := Filter[I].Suffix;
      o := Integer(Filter[I].Operation);
      ft.Operation := TTMSFNCTreeViewFilterOperation(o);
    end;
    FTreeView.ApplyFilter;
  end;
end;

procedure TTMSFNCCustomListBox.ApplyStyle;
var
  I: Integer;
  c: TTMSFNCGraphicsColor;
begin
  inherited;
  c := gcNull;
  if TTMSFNCStyles.GetStyleTextFontColor(c) then
  begin
    DefaultItem.TextColor := c;
    for I := 0 to Items.Count - 1 do
    begin
      Items[I].TextColor := c;
    end;
  end;
end;

procedure TTMSFNCCustomListBox.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCCustomListBox) then
    FTreeView.Assign((Source as TTMSFNCCustomListBox).TreeView);
end;

procedure TTMSFNCCustomListBox.BeginUpdate;
begin
  inherited;
  if Assigned(FTreeView) then
  begin
    if FUpdateCount = 0 then
      FSaveSelectedItemIndex := ItemIndex;

    Inc(FUpdateCount);
    FTreeView.BeginUpdate;
    FTreeView.ClearNodeList;
  end;
end;

procedure TTMSFNCCustomListBox.ClearSorting;
begin
  if Assigned(FTreeView) then
    FTreeView.ClearSorting;
end;

procedure TTMSFNCCustomListBox.CopyToClipboard(ATextOnly: Boolean);
var
  s: String;
  I: Integer;
  it: TTMSFNCListBoxItem;
  cl: TTMSFNCTreeViewIntegerList;
begin
  cl := TTMSFNCTreeViewIntegerList.Create;
  try
    s := '';
    for I := 0 to SelectedItemCount - 1 do
    begin
      it := SelectedItems[I];
      if Assigned(it) and (cl.IndexOf(it.Index) = -1) then
      begin
        s := s + it.SaveToString(ATextOnly) + ENDOFLINE;
        cl.Add(it.Index);
      end;
    end;

    if s <> '' then
      TTMSFNCClipBoard.SetText(CLP_FMT + s);
  finally
    cl.Free;
  end;
end;

constructor TTMSFNCCustomListBox.Create(AOwner: TComponent);
var
  c: TTMSFNCTreeViewColumn;
begin
  inherited;
  FFilter := TTMSFNCListBoxFilter.Create(Self);
  FHeader := TTMSFNCListBoxHeader.Create(Self);
  FItemsAppearance := TTMSFNCListBoxItemsAppearance.Create(Self);
  FDefaultItem := TTMSFNCListBoxItem.Create(nil);
  FItems := CreateItems;
  FInteraction := TTMSFNCListBoxInteraction.Create(Self);
  FCopyItems := TTMSFNCListBoxCopyItems.Create(nil);
  FTreeView := TTMSFNCTreeView.Create(Self);
  FTreeView.Stored := False;
  FTreeView.Parent := Self;
  FTreeView.Columns.Clear;
  c := FTreeView.Columns.Add;
  c.Text := 'Header';
  c.HorizontalTextAlign := gtaCenter;
  FTreeView.ClearNodes;
  FTreeView.ColumnsAppearance.Layouts := [];
  FTreeView.StretchScrollBars := True;
  FTreeView.NodesAppearance.ExpandColumn := -1;
  FTreeView.NodesAppearance.LevelIndent := 0;
  FTreeView.NodesAppearance.ShowLines := False;
  FTreeView.NodesAppearance.ExpandWidth := 0;
  FTreeView.NodesAppearance.ExpandHeight := 0;
  FTreeView.NodesAppearance.SelectionArea := tsaFull;
  FTreeView.OnGetNumberOfNodes := DoGetNumberOfNodes;
  FTreeView.OnGetNodeHeight := DoGetNodeHeight;
  FTreeView.OnGetNodeIcon := DoGetNodeIcon;
  FTreeView.OnGetNodeIconSize := DoGetNodeIconSize;
  FTreeView.OnGetNodeTrimming := DoGetNodeTrimming;
  FTreeView.OnGetNodeWordWrapping := DoGetNodeWordWrapping;
  FTreeView.OnGetNodeTextColor := DoGetNodeTextColor;
  FTreeView.OnGetNodeSelectedTextColor := DoGetNodeSelectedTextColor;
  FTreeView.OnGetNodeDisabledTextColor := DoGetNodeDisabledTextColor;
  FTreeView.OnGetNodeText := DoGetNodeText;
  FTreeView.OnGetNodeHorizontalTextAlign := DoGetNodeHorizontalTextAlign;
  FTreeView.OnGetNodeVerticalTextAlign := DoGetNodeVerticalTextAlign;
  FTreeView.OnIsNodeEnabled := DoIsNodeEnabled;
  FTreeView.OnAfterSelectNode := DoAfterSelectNode;
  FTreeView.OnNeedFilterDropDownData := DoNeedFilterDropDownData;
  FTreeView.OnFilterSelect := DoFilterSelect;
  FTreeView.OnBeforeDrawNode := DoBeforeDrawNode;
  FTreeView.OnAfterDrawNode := DoAfterDrawNode;
  FTreeView.OnAfterDrawNodeIcon := DoAfterDrawNodeIcon;
  FTreeView.OnBeforeDrawNodeIcon := DoBeforeDrawNodeIcon;
  FTreeView.OnAfterDrawNodeCheck := DoAfterDrawNodeCheck;
  FTreeView.OnBeforeDrawNodeCheck := DoBeforeDrawNodeCheck;
  FTreeView.OnBeforeDrawNodeText := DoBeforeDrawNodeText;
  FTreeView.OnAfterDrawNodeText := DoAfterDrawNodeText;
  FTreeView.OnNodeAnchorClick := DoNodeAnchorClick;
  FTreeView.OnBeforeDrawSortIndicator := DoBeforeDrawSortIndicator;
  FTreeView.OnAfterDrawSortIndicator := DoAfterDrawSortIndicator;
  FTreeView.OnNodeClick := DoNodeClick;
  FTreeView.OnNodeMouseLeave := DoNodeMouseLeave;
  FTreeView.OnNodeMouseEnter := DoNodeMouseEnter;
  FTreeView.OnNodeDblClick := DoNodeDblClick;
  FTreeView.OnVScroll := DoVScroll;
  FTreeView.OnBeforeCutToClipboard := DoBeforeCutToClipboard;
  FTreeView.OnBeforeCopyToClipboard := DoBeforeCopyToClipboard;
  FTreeView.OnBeforePasteFromClipboard := DoBeforePasteFromClipboard;
  FTreeView.OnAfterCutToClipboard := DoAfterCutToClipboard;
  FTreeView.OnAfterCopyToClipboard := DoAfterCopyToClipboard;
  FTreeView.OnAfterPasteFromClipboard := DoAfterPasteFromClipboard;
  FTreeView.OnColumnAnchorClick := @DoColumnAnchorClick;
  TTMSFNCTreeViewOpen(FTreeView).OnCustomReorder := DoCustomReorder;
  TTMSFNCTreeViewOpen(FTreeView).OnCustomDragOver := DoCustomDragOver;
  TTMSFNCTreeViewOpen(FTreeView).OnCustomDragDrop := DoCustomDragDrop;
  TTMSFNCTreeViewOpen(FTreeView).OnCustomCopyToClipboard := DoCopyToClipboard;
  TTMSFNCTreeViewOpen(FTreeView).OnCustomCutToClipboard := DoCutToClipboard;
  TTMSFNCTreeViewOpen(FTreeView).OnCustomPasteFromClipboard := DoPasteFromClipboard;
  TTMSFNCTreeViewOpen(FTreeView).OnCustomColumnSort := DoColumnSort;
  TTMSFNCTreeViewOpen(FTreeView).OnCustomLookup := DoLookup;
  FTreeView.OnClick := DoClick;
  FTreeView.OnDblClick := DoDblClick;

  CustomizeTreeView(FTreeView);
  {$IFDEF FMXLIB}
  FTreeView.Align := TAlignLayout.Client;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  FTreeView.Align := alClient;
  {$ENDIF}

  FGlobalFont := TTMSFNCAppearanceGlobalFont.Create(Self);

  Width := 200;
  Height := 220;
  if IsDesignTime then
    InitSample;
end;

function TTMSFNCCustomListBox.CreateItems: TTMSFNCListBoxItems;
begin
  Result := TTMSFNCListBoxItems.Create(Self);
end;

procedure TTMSFNCCustomListBox.CustomizeTreeView(
  ATreeView: TTMSFNCTreeView);
begin

end;

procedure TTMSFNCCustomListBox.CutToClipboard(ATextOnly: Boolean);
var
  I: Integer;
begin
  BeginUpdate;
  CopyToClipboard(ATextOnly);
  for I := SelectedItemCount - 1 downto 0 do
    RemoveItem(SelectedItems[I]);
  EndUpdate;
end;

destructor TTMSFNCCustomListBox.Destroy;
begin
  FGlobalFont.Free;
  FFilter.Free;
  FCopyItems.Free;
  FHeader.Free;
  FDefaultItem.Free;
  FInteraction.Free;
  FItems.Free;
  FTreeView.Free;
  FItemsAppearance.Free;
  inherited;
end;

procedure TTMSFNCCustomListBox.DoAfterCopyToClipboard(Sender: TObject);
begin
  if Assigned(OnAfterCopyToClipboard) then
    OnAfterCopyToClipboard(Self);
end;

procedure TTMSFNCCustomListBox.DoAfterCutToClipboard(Sender: TObject);
begin
  if Assigned(OnAfterCutToClipboard) then
    OnAfterCutToClipboard(Self);
end;

procedure TTMSFNCCustomListBox.DoAfterDrawNode(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; ANode: TTMSFNCTreeViewVirtualNode);
begin
  if Assigned(OnAfterDrawItem) then
    OnAfterDrawItem(Self, AGraphics, ARect, GetItemForNode(ANode));
end;

procedure TTMSFNCCustomListBox.DoAfterDrawNodeCheck(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer;
  ANode: TTMSFNCTreeViewVirtualNode; ACheck: TTMSFNCBitmap);
begin
  if Assigned(OnAfterDrawItemCheck) then
    OnAfterDrawItemCheck(Self, AGraphics, ARect, GetItemForNode(ANode), ACheck);
end;

procedure TTMSFNCCustomListBox.DoAfterDrawNodeIcon(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer;
  ANode: TTMSFNCTreeViewVirtualNode; AIcon: TTMSFNCBitmap);
begin
  if Assigned(OnAfterDrawItemIcon) then
    OnAfterDrawItemIcon(Self, AGraphics, ARect, GetItemForNode(ANode), AIcon);
end;

procedure TTMSFNCCustomListBox.DoAfterDrawNodeText(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer;
  ANode: TTMSFNCTreeViewVirtualNode; AText: String);
begin
  if Assigned(OnAfterDrawItemText) then
    OnAfterDrawItemText(Self, AGraphics, ARect, GetItemForNode(ANode), AText);
end;

procedure TTMSFNCCustomListBox.DoAfterDrawSortIndicator(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn, ASortIndex: Integer;
  ASortKind: TTMSFNCTreeViewNodesSortKind);
var
  i: Integer;
  sk: TTMSFNCListBoxItemsSortKind;
begin
  if Assigned(OnAfterDrawSortIndicator) then
  begin
    i := Integer(ASortKind);
    sk := TTMSFNCListBoxItemsSortKind(i);
    OnAfterDrawSortIndicator(Self, AGraphics, ARect, ASortIndex, sk);
  end;
end;

procedure TTMSFNCCustomListBox.DoAfterDropItem(AFromItem: TTMSFNCListBoxItem; AToItem: TTMSFNCListBoxItem);
begin
  if Assigned(OnAfterDropItem) then
    OnAfterDropItem(Self, AFromItem, AToItem);
end;

procedure TTMSFNCCustomListBox.DoAfterPasteFromClipboard(Sender: TObject);
begin
  if Assigned(OnAfterPasteFromClipboard) then
    OnAfterPasteFromClipboard(Self);
end;

procedure TTMSFNCCustomListBox.DoAfterReorderItem(AFromItem, AToItem: TTMSFNCListBoxItem);
begin
  if Assigned(OnAfterReorderItem) then
    OnAfterReorderItem(Self, AFromItem, AToItem);
end;

procedure TTMSFNCCustomListBox.DoAfterSelectNode(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode);
begin
  if Assigned(OnItemSelected) then
    OnItemSelected(Self, GetItemForNode(ANode));

  if Assigned(Parent) and (Parent is TTMSFNCCustomSelector) and Assigned(Parent.Owner) and (Parent.Owner is TTMSFNCControlPicker)then
  begin
//    Update ControlPicker if assigned as control
    (Parent.Owner as TTMSFNCControlPicker).UpdateDropDown;
  end;
end;

procedure TTMSFNCCustomListBox.DoBeforeCopyToClipboard(Sender: TObject;
  var ACanCopy: Boolean);
begin
  if Assigned(OnBeforeCopyToClipboard) then
    OnBeforeCopyToClipboard(Self, ACanCopy);
end;

procedure TTMSFNCCustomListBox.DoBeforeCutToClipboard(Sender: TObject;
  var ACanCut: Boolean);
begin
  if Assigned(OnBeforeCutToClipboard) then
    OnBeforeCutToClipboard(Self, ACanCut);
end;

procedure TTMSFNCCustomListBox.DoBeforeDrawNode(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; ANode: TTMSFNCTreeViewVirtualNode;
  var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawItem) then
    OnBeforeDrawItem(Self, AGraphics, ARect, GetItemForNode(ANode), AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomListBox.DoBeforeDrawNodeCheck(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer;
  ANode: TTMSFNCTreeViewVirtualNode; ACheck: TTMSFNCBitmap; var AAllow: Boolean);
begin
  if Assigned(OnBeforeDrawItemCheck) then
    OnBeforeDrawItemCheck(Self, AGraphics, ARect, GetItemForNode(ANode), ACheck, AAllow);
end;

procedure TTMSFNCCustomListBox.DoBeforeDrawNodeIcon(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer;
  ANode: TTMSFNCTreeViewVirtualNode; AIcon: TTMSFNCBitmap; var AAllow: Boolean);
begin
  if Assigned(OnBeforeDrawItemIcon) then
    OnBeforeDrawItemIcon(Self, AGraphics, ARect, GetItemForNode(ANode), AIcon, AAllow);
end;

procedure TTMSFNCCustomListBox.DoBeforeDrawNodeText(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer;
  ANode: TTMSFNCTreeViewVirtualNode; AText: String; var AAllow: Boolean);
begin
  if Assigned(OnBeforeDrawItemText) then
    OnBeforeDrawItemText(Self, AGraphics, ARect, GetItemForNode(ANode), AText, AAllow);
end;

procedure TTMSFNCCustomListBox.DoBeforeDrawSortIndicator(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn, ASortIndex: Integer;
  ASortKind: TTMSFNCTreeViewNodesSortKind; var ADefaultDraw: Boolean);
var
  i: Integer;
  sk: TTMSFNCListBoxItemsSortKind;
begin
  if Assigned(OnBeforeDrawSortIndicator) then
  begin
    i := Integer(ASortKind);
    sk := TTMSFNCListBoxItemsSortKind(i);
    OnBeforeDrawSortIndicator(Self, AGraphics, ARect, ASortIndex, sk, ADefaultDraw);
  end;
end;

procedure TTMSFNCCustomListBox.DoBeforeDropItem(AFromItem: TTMSFNCListBoxItem; AToItem: TTMSFNCListBoxItem; var ACanDrop: Boolean);
begin
  if Assigned(OnBeforeDropItem) then
    OnBeforeDropItem(Self, AFromItem, AToItem, ACanDrop);
end;

procedure TTMSFNCCustomListBox.DoBeforePasteFromClipboard(Sender: TObject;
  var ACanPaste: Boolean);
begin
  if Assigned(OnBeforePasteFromClipboard) then
    OnBeforePasteFromClipboard(Self, ACanPaste);
end;

procedure TTMSFNCCustomListBox.DoBeforeReorderItem(AFromItem, AToItem: TTMSFNCListBoxItem; var ACanReorder: Boolean);
begin
  if Assigned(OnBeforeReorderItem) then
    OnBeforeReorderItem(Self, AFromItem, AToItem, ACanReorder);
end;

procedure TTMSFNCCustomListBox.DoClick(Sender: TObject);
begin
  Click;
end;

procedure TTMSFNCCustomListBox.DoColumnAnchorClick(Sender: TObject; AColumn: Integer; AAnchor: String);
begin
  if Assigned(OnHeaderAnchorClick) then
    OnHeaderAnchorClick(Self, AAnchor)
  else
    TTMSFNCUtils.OpenURL(AAnchor);
end;

procedure TTMSFNCCustomListBox.DoColumnSort(Sender: TObject; AColumn: Integer;
  ASortMode: TTMSFNCTreeViewNodesSortMode);
var
  i: Integer;
begin
  i := Integer(ASortMode);
  Items.Sort(Interaction.Sorting in [lcsNormalCaseSensitive], TTMSFNCListBoxItemsSortMode(i));
end;

procedure TTMSFNCCustomListBox.DoCopyToClipboard(Sender: TObject);
begin
  CopyToClipboard(Interaction.ClipboardMode = lcmTextOnly);
end;

procedure TTMSFNCCustomListBox.DoCustomDragDrop(Sender, Source: TObject;
  Point: TPointF);
var
  di, dragi, li: TTMSFNCListBoxItem;
  dragn: TTMSFNCTreeViewVirtualNode;
  b: Boolean;
begin
  if FAccepted and Assigned(Source) and (Source is TTMSFNCCustomTreeView) and ((Source as TTMSFNCCustomTreeView).Parent is TTMSFNCCustomListBox)
    and (TTMSFNCCustomTreeViewOpen(Source).Interaction.DragDropMode <> tdmNone) then
  begin
    dragn := (Source as TTMSFNCCustomTreeView).DragNode;
    if Assigned(dragn) then
    begin
      dragi := TTMSFNCCustomListBoxOpen((Source as TTMSFNCCustomTreeView).Parent).GetItemForNode(dragn);
      di := XYToItem(Point.X, Point.Y);
      if (di <> dragi) and Assigned(dragi) then
      begin
        b := True;
        DoBeforeDropItem(dragi, di, b);
        if b then
        begin
          BeginUpdate;
          TTMSFNCCustomListBoxOpen((Source as TTMSFNCCustomTreeView).Parent).BeginUpdate;
          if Assigned(di) then
          begin
            case TTMSFNCCustomListBoxOpen((Source as TTMSFNCCustomTreeView).Parent).Interaction.DragDropMode of
              ldmMove:
              begin
                if (Source as TTMSFNCCustomTreeView).Parent = Self then
                begin
                  dragi.Index := di.Index;
                end
                else
                begin
                  li := Items.Add;
                  li.Assign(dragi);
                  li.Index := di.Index;
                  TTMSFNCCustomListBoxOpen((Source as TTMSFNCCustomTreeView).Parent).Items.Delete(dragi.Index);
                end;
              end;
              ldmCopy:
              begin
                li := Items.Add;
                li.Assign(dragi);
                li.Index := di.Index;
              end;
            end;
          end
          else
          begin
            li := Items.Add;
            li.Assign(dragi);
            case TTMSFNCCustomListBoxOpen((Source as TTMSFNCCustomTreeView).Parent).Interaction.DragDropMode of
              ldmMove: TTMSFNCCustomListBoxOpen((Source as TTMSFNCCustomTreeView).Parent).Items.Delete(dragi.Index);
            end;
          end;
          (Source as TTMSFNCCustomTreeView).DragNode := nil;
          TTMSFNCCustomListBoxOpen((Source as TTMSFNCCustomTreeView).Parent).EndUpdate;
          EndUpdate;
          DoAfterDropItem(dragi, di);
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomListBox.DoCustomDragOver(Sender, Source: TObject;
  Point: TPointF; var Accept: Boolean);
begin
  Accept := (Interaction.DragDropMode <> ldmNone) and (Source is TTMSFNCCustomTreeView) and Assigned((Source as TTMSFNCCustomTreeView).DragNode);
  FAccepted := Accept;
end;

procedure TTMSFNCCustomListBox.DoCustomReorder(Sender: TObject; AFromNode,
  AToNode: TTMSFNCTreeViewVirtualNode);
var
  it, itt: TTMSFNCListBoxItem;
  b: Boolean;
begin
  if not Assigned(AFromNode) or not Assigned(AToNode) then
    Exit;

  b := True;
  it := GetItemForNode(AFromNode);
  itt := GetItemForNode(AToNode);
  DoBeforeReorderItem(it, itt, b);
  if b then
  begin
    BeginUpdate;
    it.Index := itt.Index;
    EndUpdate;
    DoAfterReorderItem(it, itt);
  end;
end;

procedure TTMSFNCCustomListBox.DoCutToClipboard(Sender: TObject);
begin
  CutToClipboard(Interaction.ClipboardMode = lcmTextOnly);
end;

procedure TTMSFNCCustomListBox.DoDblClick(Sender: TObject);
begin
  DblClick;
end;

procedure TTMSFNCCustomListBox.DoEnter;
begin
  inherited;
  if Assigned(FTreeView) then
  begin
    if FTreeView.AllowFocus then
    begin
      {$IFDEF FMXLIB}
      TThread.CreateAnonymousThread(
      procedure
      begin
        TThread.Synchronize( nil,
        procedure
        begin
      {$ENDIF}
          FTreeView.SetFocus;
      {$IFDEF FMXLIB}
        end
      );
      end
      ).Start;
      {$ENDIF}
    end;

    FTreeView.Invalidate;
  end;
end;

procedure TTMSFNCCustomListBox.DoFilterSelect(Sender: TObject; AColumn: integer;
  var ACondition: string);
begin
  if Assigned(OnFilterSelect) then
    OnFilterSelect(Self, ACondition);
end;

procedure TTMSFNCCustomListBox.DoGetNodeDisabledTextColor(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ADisabledTextColor: TTMSFNCGraphicsColor);
begin
  if (ANode.Row >= 0) and (ANode.Row <= Items.Count - 1) then
    ADisabledTextColor := Items[ANode.Row].DisabledTextColor;
end;

procedure TTMSFNCCustomListBox.DoGetNodeHeight(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AHeight: Double);
begin
  if (ANode.Row >= 0) and (ANode.Row <= Items.Count - 1) then
  begin
    if Items[ANode.Row].Height > -1 then
      AHeight := Items[ANode.Row].Height;
  end;
end;

procedure TTMSFNCCustomListBox.DoGetNodeHorizontalTextAlign(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  if (ANode.Row >= 0) and (ANode.Row <= Items.Count - 1) then
    AHorizontalTextAlign := Items[ANode.Row].TextAlign;
end;

procedure TTMSFNCCustomListBox.DoGetNodeVerticalTextAlign(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AVerticalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  AVerticalTextAlign := gtaCenter;
end;

procedure TTMSFNCCustomListBox.DoGetNodeIcon(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ALarge: Boolean;
  var AIcon: TTMSFNCBitmap);
begin
  if (ANode.Row >= 0) and (ANode.Row <= Items.Count - 1) then
  begin
    if Assigned(BitmapContainer) and (Items[ANode.Row].BitmapName <> '') then
      AIcon := BitmapContainer.FindBitmap(Items[ANode.Row].BitmapName);

    if not Assigned(AIcon) then
      AIcon := Items[ANode.Row].Bitmap;
  end;
end;

procedure TTMSFNCCustomListBox.DoGetNodeIconSize(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ALarge: Boolean;
  AIcon: TTMSFNCBitmap; var AIconWidth, AIconHeight: Double);
var
  w, h: Single;
begin
  if (ANode.Row >= 0) and (ANode.Row <= Items.Count - 1) then
  begin
    w := Items[ANode.Row].BitmapWidth;
    if w <> -1 then
      AIconWidth := w;

    h := Items[ANode.Row].BitmapHeight;
    if h <> -1 then
      AIconHeight := h;
  end;
end;

procedure TTMSFNCCustomListBox.DoGetNodeSelectedTextColor(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ASelectedTextColor: TTMSFNCGraphicsColor);
begin
  if (ANode.Row >= 0) and (ANode.Row <= Items.Count - 1) then
    ASelectedTextColor := Items[ANode.Row].SelectedTextColor;
end;

procedure TTMSFNCCustomListBox.DoGetNodeText(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; AMode: TTMSFNCTreeViewNodeTextMode; var AText: String);
begin
  if (ANode.Row >= 0) and (ANode.Row <= Items.Count - 1) then
    AText := Items[ANode.Row].Text;
end;

procedure TTMSFNCCustomListBox.DoGetNodeTextColor(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ATextColor: TTMSFNCGraphicsColor);
begin
  if (ANode.Row >= 0) and (ANode.Row <= Items.Count - 1) then
    ATextColor := Items[ANode.Row].TextColor;
end;

procedure TTMSFNCCustomListBox.DoGetNodeTrimming(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ATrimming: TTMSFNCGraphicsTextTrimming);
begin
  if (ANode.Row >= 0) and (ANode.Row <= Items.Count - 1) then
    ATrimming := Items[ANode.Row].Trimming;
end;

procedure TTMSFNCCustomListBox.DoGetNodeWordWrapping(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AWordWrapping: Boolean);
begin
  if (ANode.Row >= 0) and (ANode.Row <= Items.Count - 1) then
    AWordWrapping := Items[ANode.Row].WordWrapping;
end;

procedure TTMSFNCCustomListBox.DoGetNumberOfNodes(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; var ANumberOfNodes: Integer);
begin
  if ANode.Level = -1 then
    ANumberOfNodes := Items.Count;
end;

procedure TTMSFNCCustomListBox.DoIsNodeEnabled(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; var AEnabled: Boolean);
begin
  if (ANode.Row >= 0) and (ANode.Row <= Items.Count - 1) then
    AEnabled := Items[ANode.Row].Enabled;
end;

procedure TTMSFNCCustomListBox.DoLookup(Sender: TObject; ALookupString: String);
begin
  LookupItem(ALookupString, Interaction.Lookup.CaseSensitive, Interaction.Lookup.AutoSelect)
end;

procedure TTMSFNCCustomListBox.DoNeedFilterDropDownData(Sender: TObject;
  AColumn: Integer; AValues: TStrings);
begin
  if Assigned(OnNeedFilterDropDownData) then
    OnNeedFilterDropDownData(Self, AValues);
end;

procedure TTMSFNCCustomListBox.DoNodeAnchorClick(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; AAnchor: String);
begin
  if Assigned(OnItemAnchorClick) then
    OnItemAnchorClick(Self, GetItemForNode(ANode), AAnchor)
  else
    TTMSFNCUtils.OpenURL(AAnchor);
end;

procedure TTMSFNCCustomListBox.DoNodeClick(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode);
begin
  if Assigned(OnItemClick) then
    OnItemClick(Self, GetItemForNode(ANode));
end;

procedure TTMSFNCCustomListBox.DoNodeMouseLeave(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode);
begin
  if Assigned(OnItemMouseLeave) then
    OnItemMouseLeave(Self, GetItemForNode(ANode));
end;

procedure TTMSFNCCustomListBox.DoNodeMouseEnter(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode);
begin
  if Assigned(OnItemMouseEnter) then
    OnItemMouseEnter(Self, GetItemForNode(ANode));
end;

procedure TTMSFNCCustomListBox.DoItemCompare(AItem1: TTMSFNCListBoxItem; AItem2: TTMSFNCListBoxItem; var ACompareResult: Integer);
begin
  if Assigned(OnItemCompare) then
    OnItemCompare(Self, AItem1, AItem2, ACompareResult);
end;

procedure TTMSFNCCustomListBox.DoNodeDblClick(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode);
begin
  if Assigned(OnItemDblClick) then
    OnItemDblClick(Self, GetItemForNode(ANode));
end;

procedure TTMSFNCCustomListBox.DoPasteFromClipboard(Sender: TObject);
begin
  PasteFromClipboard;
end;

procedure TTMSFNCCustomListBox.DoVScroll(Sender: TObject; APosition: Single);
begin
  if Assigned(OnVScroll) then
    OnVScroll(Self, APosition);
end;

procedure TTMSFNCCustomListBox.EndUpdate;
begin
  inherited;
  if Assigned(FTreeView) then
  begin
    Dec(FUpdateCount);
    FTreeView.EndUpdate;
    if FUpdateCount = 0 then
      ItemIndex := FSaveSelectedItemIndex
  end;
end;

function TTMSFNCCustomListBox.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := FTreeView.BitmapContainer;
end;

function TTMSFNCCustomListBox.GetDocURL: string;
begin
  Result := TTMSFNCBaseDocURL + 'tmsfncuipack/components/' + LowerCase(ClassName);
end;

function TTMSFNCCustomListBox.GetFill: TTMSFNCGraphicsFill;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := TTMSFNCTreeViewOpen(FTreeView).Fill;
end;

function TTMSFNCCustomListBox.GetFilteredItems: TTMSFNCListBoxFilteredItems;
var
  I: Integer;
  li: TTMSFNCListBoxItem;
begin
  for I := 0 to TTMSFNCTreeViewOpen(FTreeView).VisibleNodes.Count - 1 do
  begin
    SetLength(Result, Length(Result) + 1);
    li := GetItemForNode(TTMSFNCTreeViewOpen(FTreeView).VisibleNodes[I]);
    Result[Length(Result) - 1] := li;
  end;
end;

function TTMSFNCCustomListBox.GetItemForNode(
  ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCListBoxItem;
var
  i: Integer;
begin
  Result := nil;
  i := GetItemIndexForNode(ANode);
  if (i >= 0) and (i <= Items.Count - 1) then
    Result := Items[i];
end;

function TTMSFNCCustomListBox.GetItemIndex: Integer;
begin
  Result := -1;
  if Assigned(FTreeView) and (FTreeView.SelectedVirtualNodeCount > 0) and Assigned(FTreeView.SelectedVirtualNodes[0]) then
    Result := FTreeView.SelectedVirtualNodes[0].Row;
end;

function TTMSFNCCustomListBox.GetItemIndexForNode(
  ANode: TTMSFNCTreeViewVirtualNode): Integer;
begin
  Result := -1;
  if Assigned(ANode) then
    Result := ANode.Row;
end;

function TTMSFNCCustomListBox.GetItemsFromClipboard: TTMSFNCListBoxCopyItems;
var
  s: String;
  a: TStringList;
  AItem: TTMSFNCListBoxItem;
  I: Integer;
  CurrStr: String;
  templ: TTMSFNCCustomListBox;
begin
  if TTMSFNCClipBoard.HasFormat(TTMSFNCClipBoardFormat.cfText) then
  begin
    s := TTMSFNCClipBoard.GetText;
    if (Pos(CLP_FMT, s) > 0) then
    begin
      templ := TTMSFNCCustomListBox.Create(nil);
      a := TStringList.Create;
      try
        s := StringReplace(s, CLP_FMT, '', [rfReplaceAll]);
        a.Text := s;
        try
          for I := 0 to a.Count - 1 do
          begin
            CurrStr := a[i];
            AItem := templ.AddItem;
            AItem.LoadFromString(CurrStr);
          end;
        finally
        end;
      finally
        FCopyItems.Assign(templ.Items);
        a.Free;
        templ.Free;
      end;
    end;
  end;

  Result := FCopyItems;
end;

function TTMSFNCCustomListBox.GetNodeForItemIndex(
  AItemIndex: Integer): TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := TTMSFNCTreeViewOpen(FTreeView).GetNodeForRow(AItemIndex);
end;

function TTMSFNCCustomListBox.GetSelectedItem: TTMSFNCListBoxItem;
begin
  Result := nil;
  if (ItemIndex >= 0) and (ItemIndex <= Items.Count - 1) then
    Result := Items[ItemIndex];
end;

function TTMSFNCCustomListBox.GetSelItem(AIndex: Integer): TTMSFNCListBoxItem;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := GetItemForNode(FTreeView.SelectedVirtualNodes[AIndex]);
end;

function TTMSFNCCustomListBox.GetStroke: TTMSFNCGraphicsStroke;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := TTMSFNCTreeViewOpen(FTreeView).Stroke;
end;

function TTMSFNCCustomListBox.GetSubComponentArray: TTMSFNCStylesManagerComponentArray;
begin
  SetLength(Result, 1);
  Result[0] := FTreeView;
end;

function TTMSFNCCustomListBox.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

function TTMSFNCCustomListBox.GetVerticalScrollBarVisible: Boolean;
begin
  Result := True;
  if Assigned(FTreeView) then
    Result := FTreeView.VerticalScrollBarVisible;
end;

procedure TTMSFNCCustomListBox.InitSample;
begin
  BeginUpdate;
  Items.Clear;

  ResetToDefaultStyle;

  AddItem('Bugfix 376');
  AddItem('Bugfix 378');
  AddItem('Customer Update');
  AddItem('Install New server');
  AddItem('Meeting: Next Phase');
  AddItem('Release Planning');

  EndUpdate;
end;

function TTMSFNCCustomListBox.IsAppearanceProperty(AObject: TObject;
  APropertyName: string; APropertyType: TTypeKind): Boolean;
begin
  Result := inherited IsAppearanceProperty(AObject, APropertyName, APropertyType);
  Result := Result or (APropertyName = 'Header');
end;

function TTMSFNCCustomListBox.IsItemSelectable(AItem: TTMSFNCListBoxItem): Boolean;
begin
  Result := True;
  if Assigned(AItem) then
  begin
    Result := AItem.Enabled;
    if not (TTMSFNCTreeViewOpen(FTreeView).VisibleNodes.IndexOf(GetNodeForItemIndex(AItem.Index)) > -1) then
      Result := False;
  end
end;

{$IFDEF VCLLIB}
function GetParentFrame(AControl: TControl): TFrame;
var
  c: TControl;
begin
  Result := nil;
  c := AControl;
  if not Assigned(c) then
    Exit;

  if c.Parent is TFrame then
    Result := c.Parent as TFrame
  else
    Result := GetParentFrame(c.Parent);
end;
{$ENDIF}

procedure TTMSFNCCustomListBox.Loaded;
{$IFDEF VCLLIB}
{$HINTS OFF}
{$IF COMPILERVERSION >= 33}
var
  fr: TFrame;
{$IFEND}
{$HINTS ON}
{$ENDIF}
begin
  inherited;
  {$IFDEF VCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION >= 33}
  fr := GetParentFrame(Self);
  if not Assigned(fr) then
  begin
    TTMSFNCTreeViewOpen(FTreeView).ChangeDPIScale(96, DesigntimeFormPixelsPerInch);
  end
  else
  begin
    TTMSFNCTreeViewOpen(FTreeView).ChangeDPIScale(96, CurrentPPI);
  end;
  FTreeView.ScaleForPPI(CurrentPPI);
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCCustomListBox.LoadFromFile(AFileName: string);
var
  Stream: TStream;
begin
  Stream := TFileStream.Create(AFileName, fmOpenRead);
  try
    LoadFromStream(Stream);
  finally
    Stream.Free;
  end;
end;

procedure TTMSFNCCustomListBox.LoadFromStream(AStream: TStream);
var
  List: TStringList;
  AItem: TTMSFNCListBoxItem;
  i: Integer;
  CurrStr: string;
begin
  List := TStringList.Create;
  BeginUpdate;
  try
    try
      Items.Clear;
      List.LoadFromStream(AStream);
      for i := 0 to List.Count - 1 do
      begin
        CurrStr := List[i];
        AItem := AddItem;
        if Assigned(AItem) then
          AItem.LoadFromString(CurrStr);
      end;
    finally
      EndUpdate;
      List.Free;
    end;
  except
  end;
end;
{$ENDIF}

procedure TTMSFNCCustomListBox.LoadFromStrings(AStrings: TStrings);
var
  I: Integer;
begin
  BeginUpdate;
  Items.Clear;
  for I := 0 to AStrings.Count - 1 do
    Items.Add.Text := AStrings[I];
  EndUpdate;
end;

procedure TTMSFNCCustomListBox.LoadSettingsFromFile(AFileName: string);
var
  c: TTMSFNCGraphicsColor;
  I: Integer;
begin
  c := DefaultItem.TextColor;
  inherited;
  if (c = DefaultItem.TextColor) and (c <> ItemsAppearance.Font.Color) then
  begin
    DefaultItem.TextColor := ItemsAppearance.Font.Color;
    for I := 0 to Items.Count - 1 do
      Items[I].TextColor := DefaultItem.TextColor;
  end;
end;

procedure TTMSFNCCustomListBox.LoadSettingsFromStream(AStream: TStreamEx);
var
  c: TTMSFNCGraphicsColor;
  I: Integer;
begin
  c := DefaultItem.TextColor;
  inherited;
  if (c = DefaultItem.TextColor) and (c <> ItemsAppearance.Font.Color) then
  begin
    DefaultItem.TextColor := ItemsAppearance.Font.Color;
    for I := 0 to Items.Count - 1 do
      Items[I].TextColor := DefaultItem.TextColor;
  end;
end;

function TTMSFNCCustomListBox.LookupItem(ALookupString: String; ACaseSensitive,
  AAutoSelect: Boolean): TTMSFNCListBoxItem;
var
  v: String;
  it: TTMSFNCListBoxItem;
  i: Integer;
  s: String;
begin
  Result := nil;
  v := ALookupString;
  if not ACaseSensitive then
    v := Uppercase(v);

  for I := 0 to Items.Count - 1 do
  begin
    it := Items[I];
    s := it.StrippedHTMLText;

    if not ACaseSensitive then
      s := UpperCase(s);

    if Pos(v, s) = 1 then
    begin
      Result := it;
      Break;
    end;
  end;

  if Assigned(Result) and IsItemSelectable(Result) and AAutoSelect then
  begin
    SelectItem(Result.Index);
    ScrollToItem(Result.Index);
  end;
end;

procedure TTMSFNCCustomListBox.PasteFromClipboard;
var
  s: String;
  a: TStringList;
  AItem: TTMSFNCListBoxItem;
  I: Integer;
  CurrStr: String;
begin
  if TTMSFNCClipBoard.HasFormat(TTMSFNCClipBoardFormat.cfText) then
  begin
    s := TTMSFNCClipBoard.GetText;
    if (Pos(CLP_FMT, s) > 0) then
    begin
      a := TStringList.Create;
      try
        s := StringReplace(s, CLP_FMT, '', [rfReplaceAll]);
        a.Text := s;
        try
          for I := 0 to a.Count - 1 do
          begin
            CurrStr := a[i];
            AItem := AddItem;
            AItem.LoadFromString(CurrStr);
          end;
        finally
        end;
      finally
        a.Free;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomListBox.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCCustomListBox);
end;

procedure TTMSFNCCustomListBox.RemoveFilter;
begin
  if Assigned(FTreeView) then
    FTreeView.RemoveFilter;
end;

procedure TTMSFNCCustomListBox.RemoveFilters;
begin
  if Assigned(FTreeView) then
    FTreeView.RemoveFilters;
end;

procedure TTMSFNCCustomListBox.RemoveItem(AItem: TTMSFNCListBoxItem);
begin
  if Assigned(AItem) then
    Items.Delete(AItem.Index);
end;

procedure TTMSFNCCustomListBox.ResetToDefaultStyle;
var
  I: Integer;
begin
  inherited;

  {$IFDEF FMXLIB}
  DefaultItem.TextColor := $FF454545;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  DefaultItem.TextColor := $454545;
  {$ENDIF}
  DefaultItem.SelectedTextColor := DefaultItem.TextColor;

  for I := 0 to Items.Count - 1 do
  begin
    {$IFDEF FMXLIB}
    Items[I].TextColor := $FF454545;
    {$ENDIF}
    {$IFNDEF FMXLIB}
    Items[I].TextColor := $454545;
    {$ENDIF}
    Items[I].SelectedTextColor := DefaultItem.TextColor;
  end;
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCCustomListBox.SaveToFile(AFileName: string; ATextOnly: Boolean = True);
var
  Stream: TStream;
begin
  Stream := TFileStream.Create(AFileName, fmCreate);
  try
    SaveToStream(Stream, ATextOnly);
  finally
    Stream.Free;
  end;
end;

procedure TTMSFNCCustomListBox.SaveToStream(AStream: TStream; ATextOnly: Boolean = True);
var
  AItem: TTMSFNCListBoxItem;
  ItemStr: string;
  Buffer: TBytes;
  K: Integer;
  {$IFDEF LCLLIB}
  I: Integer;
  {$ENDIF}
begin
  for K := 0 to Items.Count - 1 do
  begin
    AItem := Items[K];
    ItemStr := AItem.SaveToString(ATextOnly);
    ItemStr := ItemStr + EndOfLine;
    {$IFNDEF LCLLIB}
    Buffer := TEncoding.Default.GetBytes(ItemStr);
    {$ENDIF}
    {$IFDEF LCLLIB}
    SetLength(Buffer, Length(ItemStr));
    for I := 1 to Length(ItemStr) do
      Buffer[I - 1] := Ord(ItemStr[I]);
    {$ENDIF}

    {$IFDEF CMNLIB}
    AStream.Write(Buffer[0], Length(Buffer));
    {$ELSE}
    AStream.Write(Buffer, Length(Buffer));
    {$ENDIF}
  end;
end;
{$ENDIF}

procedure TTMSFNCCustomListBox.SaveToStrings(AStrings: TStrings);
var
  I: Integer;
begin
  for I := 0 to Items.Count - 1 do
    AStrings.Add(Items[I].Text);
end;

procedure TTMSFNCCustomListBox.ScrollToItem(AItemIndex: Integer);
var
  t: TTMSFNCTreeViewOpen;
begin
  if Assigned(FTreeView) then
  begin
    if (AItemIndex >= 0) and (AItemIndex <= Items.Count - 1) then
    begin
      t := TTMSFNCTreeViewOpen(FTreeView);
      t.ScrollToVirtualNode(t.GetNodeForRow(AItemIndex), True, tvnspTop);
    end;
  end;
end;

function TTMSFNCCustomListBox.SelectedItemCount: Integer;
begin
  Result := 0;
  if Assigned(FTreeView) then
    Result := FTreeView.SelectedVirtualNodeCount;
end;

procedure TTMSFNCCustomListBox.SelectItem(AItemIndex: Integer);
var
  t: TTMSFNCTreeViewOpen;
begin
  if Assigned(FTreeView) then
  begin
    t := TTMSFNCTreeViewOpen(FTreeView);
    if (AItemIndex >= 0) and (AItemIndex <= Items.Count - 1) then
      t.SelectVirtualNode(t.GetNodeForRow(AItemIndex))
    else
      t.SelectVirtualNode(nil);
  end;
end;

procedure TTMSFNCCustomListBox.SelectItems(
  AItemIndexes: TTMSFNCListBoxItemArray);
var
  t: TTMSFNCTreeViewOpen;
  I: Integer;
  na: TTMSFNCTreeViewVirtualNodeArray;
begin
  if Assigned(FTreeView) then
  begin
    t := TTMSFNCTreeViewOpen(FTreeView);
    SetLength(na, Length(AItemIndexes));
    for I := 0 to Length(AItemIndexes) - 1 do
      na[I] := t.GetNodeForRow(AItemIndexes[I]);

    t.SelectVirtualNodes(na);
  end;
end;

procedure TTMSFNCCustomListBox.SetItemsAppearance(
  const Value: TTMSFNCListBoxItemsAppearance);
begin
  FItemsAppearance.Assign(Value);
end;

procedure TTMSFNCCustomListBox.SetSelectedItem(const Value: TTMSFNCListBoxItem);
begin
  if Assigned(Value) then
    ItemIndex := Value.Index;
end;

procedure TTMSFNCCustomListBox.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if Assigned(FTreeView) then
    TTMSFNCTreeViewOpen(FTreeView).Stroke.Assign(Value);
end;

procedure TTMSFNCCustomListBox.SetVerticalScrollBarVisible(
  const Value: Boolean);
begin
  if Assigned(FTreeView) then
    FTreeView.VerticalScrollBarVisible := Value;
end;

procedure TTMSFNCCustomListBox.Sort(ACaseSensitive: Boolean;
  ASortingMode: TTMSFNCListBoxItemsSortMode);
begin
  Items.Sort(ACaseSensitive, ASortingMode);
end;

procedure TTMSFNCCustomListBox.SetAdaptToStyle(const Value: Boolean);
begin
  inherited;
  if Assigned(FTreeView) then
    FTreeView.AdaptToStyle := AdaptToStyle;
end;

procedure TTMSFNCCustomListBox.SetBitmapContainer(
  const Value: TTMSFNCBitmapContainer);
begin
  FTreeView.BitmapContainer := Value;
end;

procedure TTMSFNCCustomListBox.SetDefaultItem(const Value: TTMSFNCListBoxItem);
begin
  FDefaultItem.Assign(Value);
end;

procedure TTMSFNCCustomListBox.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  if Assigned(FTreeView) then
    TTMSFNCTreeViewOpen(FTreeView).Fill.Assign(Value);
end;

procedure TTMSFNCCustomListBox.SetFonts(ASetType: TTMSFNCAppearanceGlobalFontType);
var
  I: Integer;
begin
  BeginUpdate;

  GlobalFont.ApplyChange(ItemsAppearance.Font, ASetType);
  GlobalFont.ApplyChange(Header.Font, ASetType);

  if ASetType = aftColor then
  begin
    for I := 0 to Items.Count - 1 do
    begin
      Items[I].TextColor := GlobalFont.Color;
      Items[I].SelectedTextColor := GlobalFont.Color;
    end;
  end;

  EndUpdate;
end;

procedure TTMSFNCCustomListBox.SetGlobalFont(
  const Value: TTMSFNCAppearanceGlobalFont);
begin
  FGlobalFont.Assign(Value);
end;

procedure TTMSFNCCustomListBox.SetHeader(const Value: TTMSFNCListBoxHeader);
begin
  FHeader.Assign(Value);
end;

procedure TTMSFNCCustomListBox.SetInteraction(
  const Value: TTMSFNCListBoxInteraction);
begin
  FInteraction.Assign(Value);
end;

procedure TTMSFNCCustomListBox.SetItemIndex(const Value: Integer);
begin
  SelectItem(Value);
end;

procedure TTMSFNCCustomListBox.SetItems(const Value: TTMSFNCListBoxItems);
begin
  FItems.Assign(Value);
end;

procedure TTMSFNCCustomListBox.UnSelectAllItems;
begin
  if Assigned(FTreeView) then
    FTreeView.UnSelectAllVirtualNodes;
end;

procedure TTMSFNCCustomListBox.UpdateItems;
var
  sel: TTMSFNCListBoxItem;
begin
  if IsDestroying then
    Exit;

  if Assigned(FTreeView) and (FUpdateCount = 0) then
  begin
    sel := SelectedItem;
    FTreeView.BeginUpdate;
    FTreeView.ClearNodeList;
    FTreeView.EndUpdate;
    SelectedItem := sel;
  end;
end;

function TTMSFNCCustomListBox.XYToItem(X, Y: Single): TTMSFNCListBoxItem;
var
  n: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(FTreeView) then
  begin
    n := FTreeView.XYToNode(X, Y);
    if Assigned(n) and (n.Row >= 0) and (n.Row <= Items.Count - 1) then
      Result := Items[n.Row];
  end;
end;

function TTMSFNCCustomListBox.XYToItemIndex(X, Y: Single): Integer;
var
  it: TTMSFNCListBoxItem;
begin
  Result := - 1;
  it := XYToItem(X, Y);
  if Assigned(it) then
    Result := it.Index;
end;

{ TTMSFNCCustomListBox picker implementation }

procedure TTMSFNCCustomListBox.PickerApplyFilter(ACondition: string; ACaseSensitive: Boolean);
begin
  ApplyFilter;
end;

function TTMSFNCCustomListBox.PickerGetContent: String;
begin
  Result := '';
  if ItemIndex >= 0 then
    Result := Items[ItemIndex].Text;
end;

function TTMSFNCCustomListBox.PickerGetFirstSelectableItem: Integer;
begin
  if Items.Count > 0 then
    Result := 0
  else
    Result := -1;
end;

function TTMSFNCCustomListBox.PickerGetItemCount: Integer;
begin
  Result := Items.Count;
end;

function TTMSFNCCustomListBox.PickerGetItemHeight: Single;
begin
  Result := ItemsAppearance.FixedHeight;
end;

function TTMSFNCCustomListBox.PickerGetItemWidth: Single;
begin
  Result := Width;
end;

function TTMSFNCCustomListBox.PickerGetLastSelectableItem: Integer;
begin
  if Items.Count > 0 then
    Result := Items.Count - 1
  else
    Result := -1;
end;

function TTMSFNCCustomListBox.PickerGetNextSelectableItem(AItemIndex: Integer): Integer;
begin
  if AItemIndex < Items.Count - 1 then
    Result := AItemIndex + 1
  else
    Result := AItemIndex;
end;

function TTMSFNCCustomListBox.PickerGetPreviousSelectableItem(AItemIndex: Integer): Integer;
begin
  if AItemIndex > 0 then
    Result := AItemIndex - 1
  else
    Result := AItemIndex;
end;

function TTMSFNCCustomListBox.PickerGetSelectedItem: Integer;
begin
  Result := ItemIndex;
end;

function TTMSFNCCustomListBox.PickerGetVisibleItemCount: Integer;
begin
  if Assigned(FTreeView) then
    Result := TTMSFNCTreeViewOpen(FTreeView).VisibleNodes.Count
  else
    Result := 0;
end;

function TTMSFNCCustomListBox.PickerLookupItem(ALookupString: String; ACaseSensitive: Boolean): TTMSFNCControlPickerFilterItem;
var
  it: TTMSFNCListBoxItem;
begin
  it := LookupItem(ALookupString, ACaseSensitive);
  Result.ItemIndex := it.Index;
  Result.ItemText := it.Text;
end;

procedure TTMSFNCCustomListBox.PickerResetFilter;
begin
  RemoveFilter;
end;

procedure TTMSFNCCustomListBox.PickerSelectItem(AItemIndex: Integer);
begin
  if (AItemIndex >= 0) and (AItemIndex <= Items.Count - 1) then
    ItemIndex := AItemIndex;
end;

procedure TTMSFNCCustomListBox.PickerSetItemHeight(AValue: Single);
begin
  ItemsAppearance.FixedHeight := AValue;
end;

procedure TTMSFNCCustomListBox.PickerSetItemWidth(AValue: Single);
begin
  //
end;

{ TTMSFNCListBoxItems }

function TTMSFNCListBoxItems.Add: TTMSFNCListBoxItem;
begin
  Result := TTMSFNCListBoxItem(inherited Add);
end;

procedure TTMSFNCListBoxItems.Clear;
var
  l: TTMSFNCCustomListBox;
begin
  l := ListBox;
  if Assigned(l) then
    l.BeginUpdate;
  inherited Clear;
  if Assigned(l) then
    l.EndUpdate;
end;

function TTMSFNCListBoxItems.Compare(AItem1, AItem2: TTMSFNCListBoxItem;
  ACaseSensitive: Boolean; ASortingMode: TTMSFNCListBoxItemsSortMode): Integer;
var
  l: TTMSFNCCustomListBox;
begin
  Result := 0;

  l := ListBox;
  if not Assigned(l) then
    Exit;

  if not ACaseSensitive then
    Result := AnsiCompareStr(UpperCase(AItem1.StrippedHTMLText), UpperCase(AItem2.StrippedHTMLText))
  else
    Result := AnsiCompareStr(AItem1.StrippedHTMLText, AItem2.StrippedHTMLText);

  case ASortingMode of
    ismDescending: Result := Result * -1;
  end;

  l.DoItemCompare(AItem1, AItem2, Result);
end;

constructor TTMSFNCListBoxItems.Create(AListBox: TTMSFNCCustomListBox);
begin
  inherited Create(AListBox, GetItemClass);
  FListBox := AListBox;
end;

function TTMSFNCListBoxItems.GetItem(Index: Integer): TTMSFNCListBoxItem;
begin
  Result := TTMSFNCListBoxItem(inherited Items[Index]);
end;

function TTMSFNCListBoxItems.GetItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCListBoxItem;
end;

function TTMSFNCListBoxItems.Insert(Index: Integer): TTMSFNCListBoxItem;
begin
  Result := TTMSFNCListBoxItem(inherited Insert(Index));
end;

function TTMSFNCListBoxItems.ListBox: TTMSFNCCustomListBox;
begin
  Result := FListBox;
end;

type
  {$HINTS OFF}
  TShadowedCollection = class(TPersistent)
  private
    {%H-}FItemClass: TCollectionItemClass;
    {$IFDEF LCLWEBLIB}
    FItems: TFPList;
    {$ENDIF}
    {$IFNDEF LCLWEBLIB}
    FItems: TList<TCollectionItem>;
    {$ENDIF}
  end;
  {$HINTS ON}

procedure TTMSFNCListBoxItems.QuickSort(L, R: Integer; ACaseSensitive: Boolean;
  ASortingMode: TTMSFNCListBoxItemsSortMode);
var
  I, J, p: Integer;
  {$IFDEF LCLWEBLIB}
  SortList: TFPList;

  procedure ExchangeItems(Index1, Index2: Integer);
  var
    {$IFDEF WEBLIB}
    Save: JSValue;
    {$ENDIF}
    {$IFNDEF WEBLIB}
    Save: Pointer;
    {$ENDIF}
  begin
    Save := SortList.Items[Index1];
    SortList.Items[Index1] := SortList.Items[Index2];
    SortList.Items[Index2] := Save;
  end;
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  SortList: TList<TCollectionItem>;

  procedure ExchangeItems(Index1, Index2: Integer);
  var
    Save: TCollectionItem;
  begin
    Save := SortList[Index1];
    SortList[Index1] := SortList[Index2];
    SortList[Index2] := Save;
  end;
  {$ENDIF}
begin
  //This cast allows us to get at the private elements in the base class
  {$IFNDEF WEBLIB}
  SortList := {%H-}TShadowedCollection(Self).FItems;
  {$ENDIF}
  {$IFDEF WEBLIB}
  SortList := Self.FPList;
  {$ENDIF}

  repeat
    I := L;
    J := R;
    P := (L + R) shr 1;
    repeat
      while Compare(Items[I], Items[P], ACaseSensitive, ASortingMode) < 0 do
        Inc(I);
      while Compare(Items[J], Items[P], ACaseSensitive, ASortingMode) > 0 do
        Dec(J);
      if I <= J then
      begin
        ExchangeItems(I, J);
        if P = I then
          P := J
        else if P = J then
          P := I;
        Inc(I);
        Dec(J);
      end;
    until I > J;
    if L < J then
      QuickSort(L, J, ACaseSensitive, ASortingMode);
    L := I;
  until I >= R;
end;

procedure TTMSFNCListBoxItems.SetItem(Index: Integer;
  const Value: TTMSFNCListBoxItem);
begin
  inherited Items[Index] := Value;
end;

procedure TTMSFNCListBoxItems.Sort(ACaseSensitive: Boolean;
  ASortingMode: TTMSFNCListBoxItemsSortMode);
var
  l: TTMSFNCCustomListBox;
begin
  l := ListBox;
  if Assigned(l) then
  begin
    l.FSortMode := ASortingMode;
    l.BeginUpdate;
    BeginUpdate;
    if Count > 1 then
      QuickSort(0, Pred(Count), ACaseSensitive, ASortingMode);
    EndUpdate;
    l.EndUpdate;
  end;
end;

{ TTMSFNCListBoxItem }

procedure TTMSFNCListBoxItem.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCListBoxItem then
  begin
    FTag := (Source as TTMSFNCListBoxItem).Tag;
    FText := (Source as TTMSFNCListBoxItem).Text;
    FTrimming := (Source as TTMSFNCListBoxItem).Trimming;
    FWordWrapping := (Source as TTMSFNCListBoxItem).WordWrapping;
    FTextAlign := (Source as TTMSFNCListBoxItem).TextAlign;
    FTextColor := (Source as TTMSFNCListBoxItem).TextColor;
    FSelectedTextColor := (Source as TTMSFNCListBoxItem).SelectedTextColor;
    FDisabledTextColor := (Source as TTMSFNCListBoxItem).DisabledTextColor;
    FEnabled := (Source as TTMSFNCListBoxItem).Enabled;
    FBitmapName := (Source as TTMSFNCListBoxItem).BitmapName;
    FBitmap.Assign((Source as TTMSFNCListBoxItem).Bitmap);
    FHeight := (Source as TTMSFNCListBoxItem).Height;
    FBitmapHeight := (Source as TTMSFNCListBoxItem).BitmapHeight;
    FBitmapWidth := (Source as TTMSFNCListBoxItem).BitmapWidth;
    FDataPointer := (Source as TTMSFNCListBoxItem).DataPointer;
    FDataBoolean := (Source as TTMSFNCListBoxItem).DataBoolean;
    FDataObject := (Source as TTMSFNCListBoxItem).DataObject;
    FDataString := (Source as TTMSFNCListBoxItem).DataString;
    FDataInteger := (Source as TTMSFNCListBoxItem).DataInteger;
    AssignData(Source);
  end;
end;

procedure TTMSFNCListBoxItem.AssignData(Source: TPersistent);
begin
  if Source is TTMSFNCListBoxItem then
  begin
    FDataString := (Source as TTMSFNCListBoxItem).DataString;
    FDataBoolean := (Source as TTMSFNCListBoxItem).DataBoolean;
    FDataObject := (Source as TTMSFNCListBoxItem).DataObject;
    FDataInteger := (Source as TTMSFNCListBoxItem).DataInteger;
    FDataPointer := (Source as TTMSFNCListBoxItem).DataPointer;
    FDBKey := (Source as TTMSFNCListBoxItem).DBKey;
  end;
end;

procedure TTMSFNCListBoxItem.BitmapChanged(Sender: TObject);
begin
  UpdateItem;
end;

procedure TTMSFNCListBoxItem.Changed(Sender: TObject);
begin
  UpdateItem;
end;

constructor TTMSFNCListBoxItem.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(Collection) then
    FListBox := (Collection as TTMSFNCListBoxItems).ListBox;

  FTextColor := gcBlack;
  FHeight := -1;
  FBitmapWidth := -1;
  FBitmapHeight := -1;
  FEnabled := True;
  FSelectedTextColor := gcWhite;
  FDisabledTextColor := gcSilver;
  FWordWrapping := False;
  FTextAlign := gtaLeading;
  FTrimming := gttNone;
  FBitmap := TTMSFNCBitmap.Create;
  FBitmap.OnChange := @BitmapChanged;
  if Assigned(FListBox) then
    Self.Assign(FListBox.DefaultItem);
  UpdateItem;
end;

destructor TTMSFNCListBoxItem.Destroy;
begin
  FBitmap.Free;
  inherited;
  UpdateItem;
end;

procedure TTMSFNCListBoxItem.SetHeight(const Value: Double);
begin
  if FHeight <> Value then
  begin
    FHeight := Value;
    UpdateItem;
  end;
end;

function TTMSFNCListBoxItem.GetBitmapContainer: TTMSFNCBitmapContainer;
var
  l: TTMSFNCCustomListBox;
begin
  Result := nil;
  l := ListBox;
  if Assigned(l) then
    Result := l.BitmapContainer;
end;

function TTMSFNCListBoxItem.ListBox: TTMSFNCCustomListBox;
begin
  Result := FListBox;
end;

procedure TTMSFNCListBoxItem.LoadFromString(AString: String);
var
  k: Integer;
  a: TStringList;
  s: TStringList;
  y: Integer;
  st: string;
  ps: Integer;
  subst: string;
begin
  a := TStringList.Create;
  try
    st := AString;
    while st <> '' do
    begin
      ps := Pos('{0}', st);
      if ps = 0 then
      begin
        a.Add(st);
        Break;
      end;
      subst := Copy(st, 1, ps - 1);
      a.Add(subst);
      Delete(st, 1, ps - 1 + Length('{0}'));
    end;

    for K := 0 to a.Count - 1 do
    begin
      if a[K] <> '' then
      begin
        if K = 0 then
          Text := a[K]
        else if K = 1 then
        begin
          s := TStringList.Create;
          try
            TTMSFNCUtils.Split(';', a[K], s);
            for y := 0 to s.Count - 1 do
            begin
              if s[y] <> '' then
              begin
                if y = 0 then
                  Checked := StrToBool(s[y])
                else if y = 1 then
                  BitmapName := s[y]
                else if y = 2 then
                  TTMSFNCUtils.LoadBitmapFromHexStr(s[y], Bitmap);
              end;
            end;
          finally
            s.Free;
          end;
        end
        else if K = 2 then
          Enabled := StrToBool(a[K]);
      end;
    end;
  finally
    a.Free;
  end;
end;

function TTMSFNCListBoxItem.GetStrippedHTMLText: String;
begin
  Result := TTMSFNCUtils.HTMLStrip(Text);
end;

function TTMSFNCListBoxItem.IsBitmapHeightStored: Boolean;
begin
  Result := BitmapHeight <> -1;
end;

function TTMSFNCListBoxItem.IsBitmapWidthStored: Boolean;
begin
  Result := BitmapWidth <> -1;
end;

function TTMSFNCListBoxItem.IsHeightStored: Boolean;
begin
  Result := Height <> -1;
end;

function TTMSFNCListBoxItem.IsSelected: Boolean;
var
  i: Integer;
begin
  Result := False;
  if Assigned(FListBox) then
  begin
    for I := 0 to FListBox.SelectedItemCount - 1 do
    begin
      if FListBox.SelectedItems[I] = Self then
      begin
        Result := True;
        Break;
      end;
    end;
  end;
end;

function TTMSFNCListBoxItem.SaveToString(ATextOnly: Boolean): String;
var
  s: String;
begin
  if not Assigned(FListBox) then
    Exit;

  Result := '';
  Result := Result + Text;

  if not ATextOnly then
    Result := Result + '{0}';

  if not ATextOnly then
  begin
    Result := Result + BoolToStr(Checked) + ';';
    Result := Result + BitmapName + ';';
    Result := Result + TTMSFNCUtils.SaveBitmapToHexStr(Bitmap) + ';';
    Result := Result + s;
  end;

  if not ATextOnly then
  begin
    Result := Result + '{0}';
    Result := Result + BoolToStr(Enabled);
  end;
end;

procedure TTMSFNCListBoxItem.SetChecked(const Value: Boolean);
var
  l: TTMSFNCCustomListBox;
  n: TTMSFNCTreeViewVirtualNode;
begin
  if FChecked <> Value then
  begin
    FChecked := Value;
    l := ListBox;
    if Assigned(l) then
    begin
      n := TTMSFNCCustomListBoxOpen(l).GetNodeForItemIndex(Index);
      if Assigned(n) and (n.CheckStates[0] <> FChecked) then
      begin
        if FChecked then
          TTMSFNCCustomListBoxOpen(l).TreeView.CheckVirtualNode(n, 0)
        else
          TTMSFNCCustomListBoxOpen(l).TreeView.UnCheckVirtualNode(n, 0);
      end;

      if Assigned(l.TreeView) then
        l.TreeView.Invalidate;
    end;
  end;
end;

procedure TTMSFNCListBoxItem.SetBitmap(const Value: TTMSFNCBitmap);
begin
  if FBitmap <> Value then
  begin
    FBitmap.Assign(Value);
  end;
end;

procedure TTMSFNCListBoxItem.SetBitmapHeight(const Value: Double);
begin
  FBitmapHeight := Value;
end;

procedure TTMSFNCListBoxItem.SetBitmapName(const Value: string);
begin
  if FBitmapName <> Value then
  begin
    FBitmapName := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCListBoxItem.SetBitmapWidth(const Value: Double);
begin
  FBitmapWidth := Value;
end;

procedure TTMSFNCListBoxItem.SetDisabledTextColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FDisabledTextColor <> Value then
  begin
    FDisabledTextColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCListBoxItem.SetEnabled(const Value: Boolean);
begin
  if FEnabled <> Value then
  begin
    FEnabled := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCListBoxItem.SetSelectedTextColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FSelectedTextColor <> Value then
  begin
    FSelectedTextColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCListBoxItem.SetText(const Value: String);
begin
  if FText <> Value then
  begin
    FText := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCListBoxItem.SetTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTextAlign <> Value then
  begin
    FTextAlign := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCListBoxItem.SetTextColor(const Value: TTMSFNCGraphicsColor);
begin
  if FTextColor <> Value then
  begin
    FTextColor := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCListBoxItem.SetTrimming(
  const Value: TTMSFNCGraphicsTextTrimming);
begin
  if FTrimming <> Value then
  begin
    FTrimming := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCListBoxItem.SetWordWrapping(const Value: Boolean);
begin
  if FWordWrapping <> Value then
  begin
    FWordWrapping := Value;
    UpdateItem;
  end;
end;

procedure TTMSFNCListBoxItem.UpdateItem;
var
  l: TTMSFNCCustomListBox;
begin
  l := ListBox;
  if Assigned(l) then
    l.UpdateItems;
end;

{ TTMSFNCListBoxItemsAppearance }

constructor TTMSFNCListBoxItemsAppearance.Create(
  AListBox: TTMSFNCCustomListBox);
begin
  FListBox := AListBox;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) and FListBox.IsDesignTime then
  begin
    FListBox.FTreeView.NodesAppearance.DisabledFill.Kind := gfkNone;
    FListBox.FTreeView.NodesAppearance.DisabledStroke.Kind := gskNone;
  end;
end;

function TTMSFNCListBoxItemsAppearance.GetDisabledFill: TTMSFNCGraphicsFill;
begin
  Result := nil;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    Result := FListBox.FTreeView.NodesAppearance.DisabledFill;
end;

function TTMSFNCListBoxItemsAppearance.GetDisabledStroke: TTMSFNCGraphicsStroke;
begin
  Result := nil;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    Result := FListBox.FTreeView.NodesAppearance.DisabledStroke;
end;

function TTMSFNCListBoxItemsAppearance.GetFill: TTMSFNCGraphicsFill;
begin
  Result := nil;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    Result := FListBox.FTreeView.NodesAppearance.Fill;
end;

function TTMSFNCListBoxItemsAppearance.GetFixedHeight: Double;
begin
  Result := 0;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    Result := FListBox.FTreeView.NodesAppearance.FixedHeight;
end;

function TTMSFNCListBoxItemsAppearance.GetFont: TTMSFNCGraphicsFont;
begin
  Result := nil;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    Result := FListBox.FTreeView.NodesAppearance.Font;
end;

function TTMSFNCListBoxItemsAppearance.GetHeightMode: TTMSFNCListBoxItemHeightMode;
var
  i: Integer;
begin
  Result := lihmFixed;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
  begin
    i := Integer(FListBox.FTreeView.NodesAppearance.HeightMode);
    Result := TTMSFNCListBoxItemHeightMode(i);
  end;
end;

function TTMSFNCListBoxItemsAppearance.GetSelectedFill: TTMSFNCGraphicsFill;
begin
  Result := nil;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    Result := FListBox.FTreeView.NodesAppearance.SelectedFill;
end;

function TTMSFNCListBoxItemsAppearance.GetSelectedStroke: TTMSFNCGraphicsStroke;
begin
  Result := nil;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    Result := FListBox.FTreeView.NodesAppearance.SelectedStroke;
end;

function TTMSFNCListBoxItemsAppearance.GetShowFocus: Boolean;
begin
  Result := True;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    Result := FListBox.FTreeView.NodesAppearance.ShowFocus;
end;

function TTMSFNCListBoxItemsAppearance.GetStroke: TTMSFNCGraphicsStroke;
begin
  Result := nil;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    Result := FListBox.FTreeView.NodesAppearance.Stroke;
end;

procedure TTMSFNCListBoxItemsAppearance.SetDisabledFill(
  const Value: TTMSFNCGraphicsFill);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    FListBox.FTreeView.NodesAppearance.DisabledFill.Assign(Value);
end;

procedure TTMSFNCListBoxItemsAppearance.SetDisabledStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    FListBox.FTreeView.NodesAppearance.DisabledStroke.Assign(Value);
end;

procedure TTMSFNCListBoxItemsAppearance.SetFill(
  const Value: TTMSFNCGraphicsFill);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    FListBox.FTreeView.NodesAppearance.Fill.Assign(Value);
end;

procedure TTMSFNCListBoxItemsAppearance.SetFixedHeight(const Value: Double);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    FListBox.FTreeView.NodesAppearance.FixedHeight := Value;
end;

procedure TTMSFNCListBoxItemsAppearance.SetFont(
  const Value: TTMSFNCGraphicsFont);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    FListBox.FTreeView.NodesAppearance.Font.Assign(Value);
end;

procedure TTMSFNCListBoxItemsAppearance.SetHeightMode(
  const Value: TTMSFNCListBoxItemHeightMode);
var
  i: Integer;
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
  begin
    i := Integer(Value);
    FListBox.FTreeView.NodesAppearance.HeightMode := TTMSFNCTreeViewNodeHeightMode(i);
  end;
end;

procedure TTMSFNCListBoxItemsAppearance.SetSelectedFill(
  const Value: TTMSFNCGraphicsFill);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    FListBox.FTreeView.NodesAppearance.SelectedFill.Assign(Value);
end;

procedure TTMSFNCListBoxItemsAppearance.SetSelectedStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    FListBox.FTreeView.NodesAppearance.SelectedStroke.Assign(Value);
end;

procedure TTMSFNCListBoxItemsAppearance.SetShowFocus(const Value: Boolean);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    FListBox.FTreeView.NodesAppearance.ShowFocus := Value;
end;

procedure TTMSFNCListBoxItemsAppearance.SetStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    FListBox.FTreeView.NodesAppearance.Stroke.Assign(Value);
end;

{ TTMSFNCListBoxInteraction }

procedure TTMSFNCListBoxInteraction.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCListBoxInteraction) then
  begin
    FLookup.Assign((Source as TTMSFNCListBoxInteraction).Lookup);
    Filtering.Assign((Source as TTMSFNCListBoxInteraction).Filtering);
  end;
end;

constructor TTMSFNCListBoxInteraction.Create(AListBox: TTMSFNCCustomListBox);
begin
  FListBox := AListBox;
  FLookup := TTMSFNCListBoxLookup.Create(Self);
  FFiltering := TTMSFNCListBoxFiltering.Create(Self);
end;

destructor TTMSFNCListBoxInteraction.Destroy;
begin
  FLookup.Free;
  FFiltering.Free;
  inherited;
end;

function TTMSFNCListBoxInteraction.GetClipboardMode: TTMSFNCListBoxClipboardMode;
var
  i: Integer;
begin
  Result := lcmNone;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
  begin
    i := Integer(FListBox.FTreeView.Interaction.ClipboardMode);
    Result := TTMSFNCListBoxClipboardMode(i);
  end;
end;

function TTMSFNCListBoxInteraction.GetDragDropMode: TTMSFNCListBoxDragDropMode;
var
  i: Integer;
begin
  Result := ldmNone;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
  begin
    i := Integer(FListBox.FTreeView.Interaction.DragDropMode);
    Result := TTMSFNCListBoxDragDropMode(i);
  end;
end;

function TTMSFNCListBoxInteraction.GetMultiSelect: Boolean;
begin
  Result := False;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    Result := FListBox.FTreeView.Interaction.MultiSelect;
end;

function TTMSFNCListBoxInteraction.GetReorder: Boolean;
begin
  Result := False;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    Result := FListBox.FTreeView.Interaction.Reorder;
end;

function TTMSFNCListBoxInteraction.GetSorting: TTMSFNCListBoxSorting;
begin
  Result := lcsNone;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) and (FListBox.FTreeView.Columns.Count > 0) then
  begin
    case FListBox.FTreeView.Columns[0].Sorting of
      tcsNone: Result := lcsNone;
      tcsNormal: Result := lcsNormal;
      tcsNormalCaseSensitive: Result := lcsNormalCaseSensitive;
    end;
  end;
end;

function TTMSFNCListBoxInteraction.GetTouchScrolling: Boolean;
begin
  Result := True;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    Result := FListBox.FTreeView.Interaction.TouchScrolling;
end;

procedure TTMSFNCListBoxInteraction.SetClipboardMode(
  const Value: TTMSFNCListBoxClipboardMode);
var
  i: Integer;
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
  begin
    i := Integer(Value);
    FListBox.FTreeView.Interaction.ClipboardMode := TTMSFNCTreeViewClipboardMode(i);
  end;
end;

procedure TTMSFNCListBoxInteraction.SetDragDropMode(
  const Value: TTMSFNCListBoxDragDropMode);
var
  i: Integer;
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
  begin
    i := Integer(Value);
    FListBox.FTreeView.Interaction.DragDropMode := TTMSFNCTreeViewDragDropMode(i);
  end;
end;

procedure TTMSFNCListBoxInteraction.SetFiltering(
  const Value: TTMSFNCListBoxFiltering);
begin
  FFiltering.Assign(Value);
end;

procedure TTMSFNCListBoxInteraction.SetLookup(
  const Value: TTMSFNCListBoxLookup);
begin
  FLookup.Assign(Value);
end;

procedure TTMSFNCListBoxInteraction.SetMultiSelect(const Value: Boolean);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    FListBox.FTreeView.Interaction.MultiSelect := Value;
end;

procedure TTMSFNCListBoxInteraction.SetReorder(const Value: Boolean);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    FListBox.FTreeView.Interaction.Reorder := Value;
end;

procedure TTMSFNCListBoxInteraction.SetSorting(
  const Value: TTMSFNCListBoxSorting);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) and (FListBox.FTreeView.Columns.Count > 0) then
  begin
    case Value of
      lcsNone: FListBox.FTreeView.Columns[0].Sorting := tcsNone;
      lcsNormal: FListBox.FTreeView.Columns[0].Sorting := tcsNormal;
      lcsNormalCaseSensitive: FListBox.FTreeView.Columns[0].Sorting := tcsNormalCaseSensitive;
    end;
  end;
end;

procedure TTMSFNCListBoxInteraction.SetTouchScrolling(const Value: Boolean);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    FListBox.FTreeView.Interaction.TouchScrolling := Value;
end;

{ TTMSFNCListBoxLookup }

constructor TTMSFNCListBoxLookup.Create(AOwner: TTMSFNCListBoxInteraction);
begin
  FOwner := AOwner;
end;

function TTMSFNCListBoxLookup.GetAutoSelect: Boolean;
begin
  Result := False;
  if Assigned(FOwner) and Assigned(FOwner.FListBox) and Assigned(FOwner.FListBox.FTreeView) then
    Result := FOwner.FListBox.FTreeView.Interaction.Lookup.AutoSelect;
end;

function TTMSFNCListBoxLookup.GetCaseSensitive: Boolean;
begin
  Result := False;
  if Assigned(FOwner) and Assigned(FOwner.FListBox) and Assigned(FOwner.FListBox.FTreeView) then
    Result := FOwner.FListBox.FTreeView.Interaction.Lookup.CaseSensitive;
end;

function TTMSFNCListBoxLookup.GetEnabled: Boolean;
begin
  Result := False;
  if Assigned(FOwner) and Assigned(FOwner.FListBox) and Assigned(FOwner.FListBox.FTreeView) then
    Result := FOwner.FListBox.FTreeView.Interaction.Lookup.Enabled;
end;

function TTMSFNCListBoxLookup.GetIncremental: Boolean;
begin
  Result := False;
  if Assigned(FOwner) and Assigned(FOwner.FListBox) and Assigned(FOwner.FListBox.FTreeView) then
    Result := FOwner.FListBox.FTreeView.Interaction.Lookup.Incremental;
end;

procedure TTMSFNCListBoxLookup.SetAutoSelect(const Value: Boolean);
begin
  if Assigned(FOwner) and Assigned(FOwner.FListBox) and Assigned(FOwner.FListBox.FTreeView) then
    FOwner.FListBox.FTreeView.Interaction.Lookup.AutoSelect := Value;
end;

procedure TTMSFNCListBoxLookup.SetCaseSensitive(const Value: Boolean);
begin
  if Assigned(FOwner) and Assigned(FOwner.FListBox) and Assigned(FOwner.FListBox.FTreeView) then
    FOwner.FListBox.FTreeView.Interaction.Lookup.CaseSensitive := Value;
end;

procedure TTMSFNCListBoxLookup.SetEnabled(const Value: Boolean);
begin
  if Assigned(FOwner) and Assigned(FOwner.FListBox) and Assigned(FOwner.FListBox.FTreeView) then
    FOwner.FListBox.FTreeView.Interaction.Lookup.Enabled := Value;
end;

procedure TTMSFNCListBoxLookup.SetIncremental(const Value: Boolean);
begin
  if Assigned(FOwner) and Assigned(FOwner.FListBox) and Assigned(FOwner.FListBox.FTreeView) then
    FOwner.FListBox.FTreeView.Interaction.Lookup.Incremental := Value;
end;

{ TTMSFNCListBoxFiltering }

constructor TTMSFNCListBoxFiltering.Create(
  AOwner: TTMSFNCListBoxInteraction);
begin
  FOwner := AOwner;
end;

function TTMSFNCListBoxFiltering.GetDropDownHeight: integer;
begin
  Result := 120;
  if Assigned(FOwner) and Assigned(FOwner.FListBox) and Assigned(FOwner.FListBox.FTreeView) and (FOwner.FListBox.FTreeView.Columns.Count > 0) then
    Result := FOwner.FListBox.FTreeView.Columns[0].Filtering.DropDownHeight;
end;

function TTMSFNCListBoxFiltering.GetDropDownWidth: integer;
begin
  Result := 100;
  if Assigned(FOwner) and Assigned(FOwner.FListBox) and Assigned(FOwner.FListBox.FTreeView) and (FOwner.FListBox.FTreeView.Columns.Count > 0) then
    Result := FOwner.FListBox.FTreeView.Columns[0].Filtering.DropDownWidth;
end;

function TTMSFNCListBoxFiltering.GetEnabled: Boolean;
begin
  Result := False;
  if Assigned(FOwner) and Assigned(FOwner.FListBox) and Assigned(FOwner.FListBox.FTreeView) and (FOwner.FListBox.FTreeView.Columns.Count > 0) then
    Result := FOwner.FListBox.FTreeView.Columns[0].Filtering.Enabled;
end;

procedure TTMSFNCListBoxFiltering.SetDropDownHeight(const Value: integer);
begin
  if Assigned(FOwner) and Assigned(FOwner.FListBox) and Assigned(FOwner.FListBox.FTreeView) and (FOwner.FListBox.FTreeView.Columns.Count > 0) then
    FOwner.FListBox.FTreeView.Columns[0].Filtering.DropDownHeight := Value;
end;

procedure TTMSFNCListBoxFiltering.SetDropDownWidth(const Value: integer);
begin
  if Assigned(FOwner) and Assigned(FOwner.FListBox) and Assigned(FOwner.FListBox.FTreeView) and (FOwner.FListBox.FTreeView.Columns.Count > 0) then
    FOwner.FListBox.FTreeView.Columns[0].Filtering.DropDownWidth := Value;
end;

procedure TTMSFNCListBoxFiltering.SetEnabled(const Value: Boolean);
begin
  if Assigned(FOwner) and Assigned(FOwner.FListBox) and Assigned(FOwner.FListBox.FTreeView) and (FOwner.FListBox.FTreeView.Columns.Count > 0) then
    FOwner.FListBox.FTreeView.Columns[0].Filtering.Enabled := Value;
end;

{ TTMSFNCListBoxHeader }

constructor TTMSFNCListBoxHeader.Create(AListBox: TTMSFNCCustomListBox);
begin
  FListBox := AListBox;
end;

function TTMSFNCListBoxHeader.GetFill: TTMSFNCGraphicsFill;
begin
  Result := nil;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    Result := FListBox.FTreeView.ColumnsAppearance.TopFill;
end;

function TTMSFNCListBoxHeader.GetFont: TTMSFNCGraphicsFont;
begin
  Result := nil;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    Result := FListBox.FTreeView.ColumnsAppearance.TopFont;
end;

function TTMSFNCListBoxHeader.GetHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
begin
  Result := gtaCenter;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) and (FListBox.FTreeView.Columns.Count > 0) then
    Result := FListBox.FTreeView.Columns[0].HorizontalTextAlign;
end;

function TTMSFNCListBoxHeader.GetSize: Single;
begin
  Result := 25;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    Result := FListBox.FTreeView.ColumnsAppearance.TopSize;
end;

function TTMSFNCListBoxHeader.GetSortIndicatorColor: TTMSFNCGraphicsColor;
begin
  Result := TTMSFNCTreeViewColorSelection;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    Result := FListBox.FTreeView.ColumnsAppearance.SortIndicatorColor;
end;

function TTMSFNCListBoxHeader.GetStroke: TTMSFNCGraphicsStroke;
begin
  Result := nil;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    Result := FListBox.FTreeView.ColumnsAppearance.TopStroke;
end;

function TTMSFNCListBoxHeader.GetText: String;
begin
  Result := '';
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) and (FListBox.FTreeView.Columns.Count > 0) then
    Result := FListBox.FTreeView.Columns[0].Text
end;

function TTMSFNCListBoxHeader.GetTrimming: TTMSFNCGraphicsTextTrimming;
begin
  Result := gttNone;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) and (FListBox.FTreeView.Columns.Count > 0) then
    Result := FListBox.FTreeView.Columns[0].Trimming
end;

function TTMSFNCListBoxHeader.GetVerticalTextAlign: TTMSFNCGraphicsTextAlign;
begin
  Result := gtaCenter;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) and (FListBox.FTreeView.Columns.Count > 0) then
    Result := FListBox.FTreeView.Columns[0].VerticalTextAlign;
end;

function TTMSFNCListBoxHeader.GetVisible: Boolean;
begin
  Result := False;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    Result := tclTop in FListBox.FTreeView.ColumnsAppearance.Layouts;
end;

function TTMSFNCListBoxHeader.GetWordWrapping: Boolean;
begin
  Result := False;
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) and (FListBox.FTreeView.Columns.Count > 0) then
    Result := FListBox.FTreeView.Columns[0].WordWrapping
end;

procedure TTMSFNCListBoxHeader.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    FListBox.FTreeView.ColumnsAppearance.TopFill.Assign(Value);
end;

procedure TTMSFNCListBoxHeader.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    FListBox.FTreeView.ColumnsAppearance.TopFont.Assign(Value);
end;

procedure TTMSFNCListBoxHeader.SetHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) and (FListBox.FTreeView.Columns.Count > 0) then
    FListBox.FTreeView.Columns[0].HorizontalTextAlign := Value;
end;

procedure TTMSFNCListBoxHeader.SetSize(const Value: Single);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    FListBox.FTreeView.ColumnsAppearance.TopSize := Value;
end;

procedure TTMSFNCListBoxHeader.SetSortIndicatorColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    FListBox.FTreeView.ColumnsAppearance.SortIndicatorColor := Value;
end;

procedure TTMSFNCListBoxHeader.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
    FListBox.FTreeView.ColumnsAppearance.TopStroke.Assign(Value);
end;

procedure TTMSFNCListBoxHeader.SetText(const Value: String);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) and (FListBox.FTreeView.Columns.Count > 0) then
    FListBox.FTreeView.Columns[0].Text := Value;
end;

procedure TTMSFNCListBoxHeader.SetTrimming(
  const Value: TTMSFNCGraphicsTextTrimming);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) and (FListBox.FTreeView.Columns.Count > 0) then
    FListBox.FTreeView.Columns[0].Trimming := Value;
end;

procedure TTMSFNCListBoxHeader.SetVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) and (FListBox.FTreeView.Columns.Count > 0) then
    FListBox.FTreeView.Columns[0].VerticalTextAlign := Value;
end;

procedure TTMSFNCListBoxHeader.SetVisible(const Value: Boolean);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) then
  begin
    if Value then
      FListBox.FTreeView.ColumnsAppearance.Layouts := [tclTop]
    else
      FListBox.FTreeView.ColumnsAppearance.Layouts := [];
  end;
end;

procedure TTMSFNCListBoxHeader.SetWordWrapping(const Value: Boolean);
begin
  if Assigned(FListBox) and Assigned(FListBox.FTreeView) and (FListBox.FTreeView.Columns.Count > 0) then
    FListBox.FTreeView.Columns[0].WordWrapping := Value;
end;

{ TTMSFNCListBoxFilterData }

procedure TTMSFNCListBoxFilterData.Assign(Source: TPersistent);
var
  ASrcFilterData: TTMSFNCListBoxFilterData;
begin
  ASrcFilterData := Source as TTMSFNCListBoxFilterData;
  if Assigned(ASrcFilterData) then
  begin
    FCondition := ASrcFilterData.Condition;
    FCaseSensitive := ASrcFilterData.CaseSensitive;
    FPrefix := ASrcFilterData.Prefix;
    FSuffix := ASrcFilterData.Suffix;
    FOperation := ASrcFilterData.Operation;
  end;
end;

constructor TTMSFNCListBoxFilterData.Create(ACollection: TCollection);
begin
  inherited;
  FCaseSensitive := True;
end;

{ TTMSFNCListBoxFilter }

function TTMSFNCListBoxFilter.Add: TTMSFNCListBoxFilterData;
begin
  Result := TTMSFNCListBoxFilterData(inherited Add);
  if Count = 1 then
    Result.Operation := lfoNone
  else
    Result.Operation := lfoAND;
end;

constructor TTMSFNCListBoxFilter.Create(AOwner: TTMSFNCCustomListBox);
begin
  inherited Create(AOwner, TTMSFNCListBoxFilterData);
  FOwner := AOwner;
end;

function TTMSFNCListBoxFilter.GetItem(Index: Integer): TTMSFNCListBoxFilterData;
begin
  Result := TTMSFNCListBoxFilterData(inherited GetItem(Index));
end;

function TTMSFNCListBoxFilter.Insert(index: Integer): TTMSFNCListBoxFilterData;
begin
  Result := TTMSFNCListBoxFilterData(inherited Insert(Index));
end;

procedure TTMSFNCListBoxFilter.SetItem(Index: Integer; const Value: TTMSFNCListBoxFilterData);
begin
  inherited SetItem(Index, Value);
end;


{ TTMSFNCListBox }

procedure TTMSFNCListBox.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClasses([TTMSFNCListBox, TTMSFNCListBoxItem]);
end;

end.
