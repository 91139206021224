{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2022                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCTabSet;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

uses
  Classes, Types, WEBLib.TMSFNCPersistence, WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCBitmapContainer, WEBLib.TMSFNCPopup,
  WEBLib.TMSFNCGraphics, WEBLib.TMSFNCTypes, WEBLib.Controls, WEBLib.ExtCtrls, WEBLib.StdCtrls,
  WEBLib.TMSFNCGraphicsTypes
  {$IFDEF FNCLIB}
  {$IFNDEF WEBLIB}
  ,WEBLib.TMSFNCHint
  {$ENDIF}
  {$ENDIF}
  {$IFDEF FMXLIB}
  ,FMX.Types, FMX.ListBox, FMX.Edit
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  ,Generics.Collections, UITypes
  {$ENDIF}
  {$ENDIF}
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 1; // Release nr.
  BLD_VER = 1; // Build nr.

  // version history
  // v1.0.0.0 : First Release
  // v1.0.0.1 : Fixed: Issue with setting margins to 0
  // v1.0.0.2 : Improved: Added virtual keyword to some methods
  // v1.0.0.3 : Fixed: Exception when changing focus while inplace editor is active
  // v1.0.0.4 : Fixed: Issue with tab free under iOS/Android
  // v1.0.0.5 : Fixed: Issue with updating height while changing tabs
  // v1.0.0.6 : Fixed: Issue with handling navigations button when overlapping with tabs
  // v1.0.0.7 : Fixed: Regression: hovering tabs, close button and hints not working after previous fix
  // v1.0.0.8 : Fixed : Issue with Anchor if font was changed
  // v1.0.0.9 : Fixed : Issue in Delphi 11 with begin and end scene for CreateBitmapCanvas
  // v1.0.0.10: Fixed : Issue with tab drawing when height or width = 0
  // v1.0.1.0 : New : Support for high dpi added
  // v1.0.2.0 : New : GlobalFont interface implemented
  //          : New : Updated initial look

  {$IFDEF WEBLIB}
  TMSFNCTABSETLISTSVG = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRw'
    +'Oi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb'
    +'2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJtMTcgMTAtNSA1LTUtNSIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==';
  TMSFNCTABSETCLOSESVG = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRw'
    +'Oi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbD0ibm9uZSI+PHBhdGggc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHN0cm9rZS1saW5lY2Fw'
    +'PSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJNMTIgMTIgNyA3bTUgNSA1IDVtLTUtNSA1'
    +'LTVtLTUgNS01IDUiLz48L3N2Zz4=';
  TMSFNCTABSETADDSVG = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53'
    +'My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjRweCIgaGVpZ2h0'
    +'PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSI+CjxnIGlkPSJzdXJmYWNlMSI+CjxwYXRoIHN0eWxlPSIgc3Ry'
    +'b2tlOm5vbmU7ZmlsbC1ydWxlOm5vbnplcm87ZmlsbDpyZ2IoMCUsMCUsMCUpO2ZpbGwtb3BhY2l0eToxOyIgZD0iTSAxMiA2IEMgMTIu'
    +'NDE0MDYyIDYgMTIuNzUgNi4zMzU5MzggMTIuNzUgNi43NSBMIDEyLjc1IDExLjI1IEwgMTcuMjUgMTEuMjUgQyAxNy42NjQwNjIgMTEu'
    +'MjUgMTggMTEuNTg1OTM4IDE4IDEyIEMgMTggMTIuNDE0MDYyIDE3LjY2NDA2MiAxMi43NSAxNy4yNSAxMi43NSBMIDEyLjc1IDEyLjc1'
    +'IEwgMTIuNzUgMTcuMjUgQyAxMi43NSAxNy42NjQwNjIgMTIuNDE0MDYyIDE4IDEyIDE4IEMgMTEuNTg1OTM4IDE4IDExLjI1IDE3LjY2'
    +'NDA2MiAxMS4yNSAxNy4yNSBMIDExLjI1IDEyLjc1IEwgNi43NSAxMi43NSBDIDYuMzM1OTM4IDEyLjc1IDYgMTIuNDE0MDYyIDYgMTIg'
    +'QyA2IDExLjU4NTkzOCA2LjMzNTkzOCAxMS4yNSA2Ljc1IDExLjI1IEwgMTEuMjUgMTEuMjUgTCAxMS4yNSA2Ljc1IEMgMTEuMjUgNi4z'
    +'MzU5MzggMTEuNTg1OTM4IDYgMTIgNiBaIE0gMTIgNiAiLz4KPC9nPgo8L3N2Zz4K';
  TMSFNCTABSETNEXTSVG = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRw'
    +'Oi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHN0cm9rZS1saW5lY2Fw'
    +'PSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJtMTAgNyA1IDUtNSA1Ii8+PC9zdmc+';
  TMSFNCTABSETPREVIOUSSVG = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRw'
    +'Oi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHN0cm9rZS1saW5lY2Fw'
    +'PSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJtMTQgNy01IDUgNSA1Ii8+PC9zdmc+';
  {$ENDIF}

  {$IFDEF FMXLIB}
  TTMSFNCTabSetColorProgress = $FF1BADF8;
  TTMSFNCTabSetColor = $FFEDF7F8;
  TTMSFNCTabSetDownColor = $FFD9E3EE;
  TTMSFNCTabSetHoverColor = $FFE3EDF8;
  {$ELSE}
  TTMSFNCTabSetColorProgress = $F8AD1B;
  TTMSFNCTabSetColor = $F8F7ED;
  TTMSFNCTabSetDownColor = $EEE3D9;
  TTMSFNCTabSetHoverColor = $F8EDE3;
  {$ENDIF}

type
  TTMSFNCCustomTabSet = class;

  TTMSFNCTabSetTab = class;

  TTMSFNCTabSetTabShape = (tsRectangle, tsPyramidLeft, tsPyramidRight, tsPyramid, tsRoundLeft, tsRoundRight, tsRound);

  TTMSFNCTabSetTabKind = (ttkNormal, ttkInsert);

  TTMSFNCTabSetTabProgressMode = (tpmNormal, tpmMarquee);

  TTMSFNCTabSetTabProgressKind = (tpkNone, tpkCircular, tpkRectangular);

  TTMSFNCTabSetTabSizeMode = (tsmAutoSize, tsmFixedSize, tsmFixedSizeAutoShrink, tsmAutoTabSize);

  TTMSFNCTabSetLayoutPosition = (tlpLeft, tlpTop, tlpBottom, tlpRight);

  TTMSFNCTabSetLayoutMultiline = (tlmNone, tlmEnabled, tlmEnabledActiveTab);

  TTMSFNCTabSetTabCloseAction = (ttcaNone, ttcaFree, ttcaHide);

  TTMSFNCTabSetDisplayTab = record
    Tab: TTMSFNCTabSetTab;
    TabKind: TTMSFNCTabSetTabKind;
    First, Last: Boolean;
    ContentRect: TRectF;
    ProgressRect: TRectF;
    CloseButtonRect: TRectF;
    Rect: TRectF;
    BitmapRect: TRectF;
    Index: Integer;
    Row: Integer;
    TextRect: TRectF;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCTabSetDisplayTab) b : Boolean;
    {$ENDIF}
  end;

  {$IFDEF WEBLIB}
  TTMSFNCTabSetDisplayList = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCTabSetDisplayTab;
    procedure SetItem(Index: Integer; const Value: TTMSFNCTabSetDisplayTab);
  public
    property Items[Index: Integer]: TTMSFNCTabSetDisplayTab read GetItem write SetItem; default;
  end;
  TTMSFNCTabSetInvisibleTabList = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCTabSetTab;
    procedure SetItem(Index: Integer; const Value: TTMSFNCTabSetTab);
  public
    property Items[Index: Integer]: TTMSFNCTabSetTab read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCTabSetDisplayList = class(TList<TTMSFNCTabSetDisplayTab>);
  TTMSFNCTabSetInvisibleTabList = class(TList<TTMSFNCTabSetTab>);
  {$ENDIF}

  TTMSFNCTabSetTab = class(TCollectionItem)
  private
    FAnimMarqueeStartAngle, FAnimMarqueeSweepAngle: Single;
    FAnimMarqueeSwitch: Boolean;
    FTag: NativeInt;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FTabSet: TTMSFNCCustomTabSet;
    FText: String;
    FDBKey: String;
    FDataBoolean: Boolean;
    FWordWrapping: Boolean;
    FTrimming: TTMSFNCGraphicsTextTrimming;
    FTextAlign: TTMSFNCGraphicsTextAlign;
    FActiveTextColor: TTMSFNCGraphicsColor;
    FTextColor: TTMSFNCGraphicsColor;
    FDisabledTextColor: TTMSFNCGraphicsColor;
    FEnabled: Boolean;
    FBitmaps: TTMSFNCScaledBitmaps;
    FDisabledBitmaps: TTMSFNCScaledBitmaps;
    FVisible: Boolean;
    FUseDefaultAppearance: Boolean;
    FWidth: Single;
    FHeight: Single;
    FDisabledColor: TTMSFNCGraphicsColor;
    FActiveColor: TTMSFNCGraphicsColor;
    FHoverTextColor: TTMSFNCGraphicsColor;
    FColor: TTMSFNCGraphicsColor;
    FHoverColor: TTMSFNCGraphicsColor;
    FDownTextColor: TTMSFNCGraphicsColor;
    FDownColor: TTMSFNCGraphicsColor;
    FBitmapVisible: Boolean;
    FBitmapSize: Single;
    FTextVisible: Boolean;
    FStretchText: Boolean;
    FShape: TTMSFNCTabSetTabShape;
    FProgressColor: TTMSFNCGraphicsColor;
    FProgress: Single;
    FProgressMax: Single;
    FProgressMode: TTMSFNCTabSetTabProgressMode;
    FCloseButton: Boolean;
    FProgressKind: TTMSFNCTabSetTabProgressKind;
    FBadge: string;
    FBadgeColor: TTMSFNCGraphicsColor;
    FBadgeTextColor: TTMSFNCGraphicsColor;
    FHint: string;
    FDataPointer: Pointer;
    FShortCutHint: string;
    procedure SetTrimming(const Value: TTMSFNCGraphicsTextTrimming);
    procedure SetWordWrapping(const Value: Boolean);
    procedure SetTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetActiveTextColor(const Value: TTMSFNCGraphicsColor);
    procedure SetTextColor(const Value: TTMSFNCGraphicsColor);
    procedure SetDisabledTextColor(const Value: TTMSFNCGraphicsColor);
    procedure SetEnabled(const Value: Boolean);
    procedure SetBitmaps(const Value: TTMSFNCScaledBitmaps);
    procedure SetDisabledBitmaps(const Value: TTMSFNCScaledBitmaps);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    procedure SetVisible(const Value: Boolean);
    procedure SetUseDefaultAppearance(const Value: Boolean);
    function IsWidthStored: Boolean;
    procedure SetWidth(const Value: Single);
    procedure SetColor(const Value: TTMSFNCGraphicsColor);
    procedure SetDisabledColor(const Value: TTMSFNCGraphicsColor);
    procedure SetDownColor(const Value: TTMSFNCGraphicsColor);
    procedure SetDownTextColor(const Value: TTMSFNCGraphicsColor);
    procedure SetHoverColor(const Value: TTMSFNCGraphicsColor);
    procedure SetHoverTextColor(const Value: TTMSFNCGraphicsColor);
    procedure SetActiveColor(const Value: TTMSFNCGraphicsColor);
    procedure SetBitmapVisible(const Value: Boolean);
    function IsBitmapSizeStored: Boolean;
    procedure SetBitmapSize(const Value: Single);
    procedure SetTextVisible(const Value: Boolean);
    procedure SetStretchText(const Value: Boolean);
    procedure SetShape(const Value: TTMSFNCTabSetTabShape);
    procedure SetProgressColor(const Value: TTMSFNCGraphicsColor);
    function IsProgressStored: Boolean;
    procedure SetProgress(const Value: Single);
    function IsProgressMaxStored: Boolean;
    procedure SetProgressMax(const Value: Single);
    procedure SetProgressMode(const Value: TTMSFNCTabSetTabProgressMode);
    procedure SetCloseButton(const Value: Boolean);
    procedure SetProgressKind(const Value: TTMSFNCTabSetTabProgressKind);
    procedure SetBadge(const Value: string);
    procedure SetBadgeColor(const Value: TTMSFNCGraphicsColor);
    procedure SetBadgeTextColor(const Value: TTMSFNCGraphicsColor);
    procedure SetHint(const Value: string);
  protected
    procedure SetText(const Value: String); virtual;
    procedure UpdateTab;
    procedure Changed(Sender: TObject);
    procedure BitmapsChanged(Sender: TObject);
    procedure Draw(AGraphics: TTMSFNCGraphics; ATab: TTMSFNCTabSetDisplayTab); virtual;
    procedure DrawBackground(AGraphics: TTMSFNCGraphics; ATab: TTMSFNCTabSetDisplayTab); virtual;
    procedure DrawText(AGraphics: TTMSFNCGraphics; ATab: TTMSFNCTabSetDisplayTab); virtual;
    procedure DrawFocus(AGraphics: TTMSFNCGraphics; ATab: TTMSFNCTabSetDisplayTab); virtual;
    procedure DrawProgress(AGraphics: TTMSFNCGraphics; ATab: TTMSFNCTabSetDisplayTab); virtual;
    procedure DrawCloseButton(AGraphics: TTMSFNCGraphics; ATab: TTMSFNCTabSetDisplayTab); virtual;
    procedure DrawBitmap(AGraphics: TTMSFNCGraphics; ATab: TTMSFNCTabSetDisplayTab); virtual;
    procedure DrawBadge(AGraphics: TTMSFNCGraphics; ATab: TTMSFNCTabSetDisplayTab); virtual;
    function GetInsertShapePath(ARect: TRectF): TTMSFNCGraphicsPath; virtual;
    function GetShapePath(ARect: TRectF): TTMSFNCGraphicsPath; virtual;
    function GetBitmapRect(ARect: TRectF): TRectF; virtual;
    function GetTextRect(ARect: TRectF): TRectF; virtual;
    function GetProgressRect(ARect: TRectF): TRectF; virtual;
    function GetCloseButtonRect(ARect: TRectF): TRectF; virtual;
    function GetFriendlyName: String; virtual;
    function GetStrippedHTMLText: String; virtual;
    function IsHTML: Boolean; virtual;
    function GetFixedRect(ARect: TRectF; ATab: TTMSFNCTabSetDisplayTab): TRectF; virtual;
    property ShortCutHint: string read FShortCutHint write FShortCutHint;
  public
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    function TabSet: TTMSFNCCustomTabSet;
    function GetShape: TTMSFNCTabSetTabShape;
    function GetAutoSize: Single; virtual;
    function GetBitmap: TTMSFNCBitmap; virtual;
    procedure Assign(Source: TPersistent); override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property DBKey: String read FDBKey write FDBKey;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer;
  published
    property Text: String read FText write SetText;
    property Badge: string read FBadge write SetBadge;
    property Hint: string read FHint write SetHint;
    property StretchText: Boolean read FStretchText write SetStretchText default False;
    property TextVisible: Boolean read FTextVisible write SetTextVisible default True;
    property TabVisible: Boolean read FVisible write SetVisible default True;
    property Bitmaps: TTMSFNCScaledBitmaps read FBitmaps write SetBitmaps;
    property DisabledBitmaps: TTMSFNCScaledBitmaps read FDisabledBitmaps write SetDisabledBitmaps;
    property BitmapVisible: Boolean read FBitmapVisible write SetBitmapVisible default False;
    property BitmapSize: Single read FBitmapSize write SetBitmapSize stored IsBitmapSizeStored nodefault;
    property WordWrapping: Boolean read FWordWrapping write SetWordWrapping default False;
    property Trimming: TTMSFNCGraphicsTextTrimming read FTrimming write SetTrimming default gttCharacter;
    property TextAlign: TTMSFNCGraphicsTextAlign read FTextAlign write SetTextAlign default gtaCenter;
    property TextColor: TTMSFNCGraphicsColor read FTextColor write SetTextColor default gcBlack;
    property ActiveTextColor: TTMSFNCGraphicsColor read FActiveTextColor write SetActiveTextColor default gcBlack;
    property HoverTextColor: TTMSFNCGraphicsColor read FHoverTextColor write SetHoverTextColor default gcBlack;
    property DownTextColor: TTMSFNCGraphicsColor read FDownTextColor write SetDownTextColor default gcBlack;
    property DisabledTextColor: TTMSFNCGraphicsColor read FDisabledTextColor write SetDisabledTextColor default gcBlack;
    property Color: TTMSFNCGraphicsColor read FColor write SetColor default TTMSFNCTabSetColor;
    property ProgressColor: TTMSFNCGraphicsColor read FProgressColor write SetProgressColor default TTMSFNCTabSetColorProgress;
    property ActiveColor: TTMSFNCGraphicsColor read FActiveColor write SetActiveColor default gcWhite;
    property HoverColor: TTMSFNCGraphicsColor read FHoverColor write SetHoverColor default TTMSFNCTabSetHoverColor;
    property DownColor: TTMSFNCGraphicsColor read FDownColor write SetDownColor default TTMSFNCTabSetDownColor;
    property DisabledColor: TTMSFNCGraphicsColor read FDisabledColor write SetDisabledColor default gcLightgray;
    property Enabled: Boolean read FEnabled write SetEnabled default True;
    property Visible: Boolean read FVisible write SetVisible default True;
    property UseDefaultAppearance: Boolean read FUseDefaultAppearance write SetUseDefaultAppearance default False;
    property Width: Single read FWidth write SetWidth stored IsWidthStored nodefault;
    property Shape: TTMSFNCTabSetTabShape read FShape write SetShape default tsPyramid;
    property Tag: NativeInt read FTag write FTag default 0;
    property Progress: Single read FProgress write SetProgress stored IsProgressStored nodefault;
    property ProgressMax: Single read FProgressMax write SetProgressMax stored IsProgressMaxStored nodefault;
    property ProgressMode: TTMSFNCTabSetTabProgressMode read FProgressMode write SetProgressMode default tpmNormal;
    property ProgressKind: TTMSFNCTabSetTabProgressKind read FProgressKind write SetProgressKind default tpkNone;
    property CloseButton: Boolean read FCloseButton write SetCloseButton default True;
    property BadgeTextColor: TTMSFNCGraphicsColor read FBadgeTextColor write SetBadgeTextColor default gcDarkred;
    property BadgeColor: TTMSFNCGraphicsColor read FBadgeColor write SetBadgeColor default gcOrange;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCTabSetTabs = class(TTMSFNCOwnedCollection, ITMSFNCBaseListIO, ITMSFNCBasePersistenceIO)
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCTabSetTabs = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCTabSetTab>, ITMSFNCBaseListIO, ITMSFNCBasePersistenceIO)
  {$ENDIF}
  private
    FOwnerInterface: IInterface;
    FTabSet: TTMSFNCCustomTabSet;
    function GetTab(Index: Integer): TTMSFNCTabSetTab;
    procedure SetTab(Index: Integer; const Value: TTMSFNCTabSetTab);
  protected
    function ITMSFNCBaseListIO.GetItemClass = GetInterfaceItemClass;
    function CreateObject(const AClassName: string; const ABaseClass: TClass): TObject; virtual;
    function GetInterfaceItemClass: TClass; virtual;
    {$IFDEF LCLLIB}
    function _AddRef : longint;{$IFNDEF WINDOWS}cdecl{$ELSE}stdcall{$ENDIF};
    function _Release : longint;{$IFNDEF WINDOWS}cdecl{$ELSE}stdcall{$ENDIF};
    {$ELSE}
    function _AddRef: Integer; {$IFNDEF WEBLIB}stdcall;{$ENDIF}
    function _Release: Integer; {$IFNDEF WEBLIB}stdcall;{$ENDIF}
    {$ENDIF}
    function GetTabClass: TCollectionItemClass; virtual;
  public
    {$IFDEF LCLLIB}
    function QueryInterface({$IFDEF FPC_HAS_CONSTREF}constref{$ELSE}const{$ENDIF} iid : tguid;out obj) : longint;{$IFNDEF WINDOWS}cdecl{$ELSE}stdcall{$ENDIF};
    {$ELSE}
    function QueryInterface(const IID: TGUID; out Obj): HResult; virtual; {$IFNDEF WEBLIB}stdcall;{$ENDIF}
    {$ENDIF}
    function TabSet: TTMSFNCCustomTabSet;
    constructor Create(ATabSet: TTMSFNCCustomTabSet); virtual;
    function Add: TTMSFNCTabSetTab;
    function Insert(Index: Integer): TTMSFNCTabSetTab;
    procedure Clear; virtual;
    property Tabs[Index: Integer]: TTMSFNCTabSetTab read GetTab write SetTab; default;
  end;

  TTMSFNCTabSetTabSize = class(TPersistent)
  private
    FTabSet: TTMSFNCCustomTabSet;
    FMode: TTMSFNCTabSetTabSizeMode;
    FWidth: Single;
    FHeight: Single;
    FSpacing: Single;
    FMargins: TTMSFNCMargins;
    FOnChange: TNotifyEvent;
    function IsHeightStored: Boolean;
    function IsWidthStored: Boolean;
    procedure SetHeight(const Value: Single);
    procedure SetMode(const Value: TTMSFNCTabSetTabSizeMode);
    procedure SetWidth(const Value: Single);
    function IsSpacingStored: Boolean;
    procedure SetMargins(const Value: TTMSFNCMargins);
    procedure SetSpacing(const Value: Single);
  protected
    procedure ChangeDPIScale(M, D: Integer);
    procedure UpdateTabs;
    procedure MarginsChanged(Sender: TObject);
  public
    constructor Create(ATabSet: TTMSFNCCustomTabSet);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  published
    property Mode: TTMSFNCTabSetTabSizeMode read FMode write SetMode default tsmAutoTabSize;
    property Width: Single read FWidth write SetWidth stored IsWidthStored nodefault;
    property Height: Single read FHeight write SetHeight stored IsHeightStored nodefault;
    property Margins: TTMSFNCMargins read FMargins write SetMargins;
    property Spacing: Single read FSpacing write SetSpacing stored IsSpacingStored nodefault;
  end;

  TTMSFNCTabSetCloseMode = (tcmNone, tcmTab, tcmMenu);
  TTMSFNCTabSetInsertMode = (timNone, timTab, timMenu);

  TTMSFNCTabSetButtonAppearance = class(TPersistent)
  private
    FTabSet: TTMSFNCCustomTabSet;
    FSize: Single;
    FHoverFill: TTMSFNCGraphicsFill;
    FStroke: TTMSFNCGraphicsStroke;
    FHoverStroke: TTMSFNCGraphicsStroke;
    FDownFill: TTMSFNCGraphicsFill;
    FDownStroke: TTMSFNCGraphicsStroke;
    FDisabledFill: TTMSFNCGraphicsFill;
    FDisabledStroke: TTMSFNCGraphicsStroke;
    FFill: TTMSFNCGraphicsFill;
    FCloseIcon: TTMSFNCBitmap;
    FScrollPreviousIcon: TTMSFNCBitmap;
    FInsertIcon: TTMSFNCBitmap;
    FScrollNextIcon: TTMSFNCBitmap;
    FTabListIcon: TTMSFNCBitmap;
    FRounding: Single;
    function IsSizedStored: Boolean;
    procedure SetSize(const Value: Single);
    procedure SetDisabledFill(const Value: TTMSFNCGraphicsFill);
    procedure SetDisabledStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetDownFill(const Value: TTMSFNCGraphicsFill);
    procedure SetDownStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetHoverFill(const Value: TTMSFNCGraphicsFill);
    procedure SetHoverStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetCloseIcon(const Value: TTMSFNCBitmap);
    procedure SetInsertIcon(const Value: TTMSFNCBitmap);
    procedure SetScrollNextIcon(const Value: TTMSFNCBitmap);
    procedure SetScrollPreviousIcon(const Value: TTMSFNCBitmap);
    procedure SetTabListIcon(const Value: TTMSFNCBitmap);
    function IsRoundingStored: Boolean;
    procedure SetRounding(const Value: Single);
  protected
    procedure UpdateTabs;
    procedure FillChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
    procedure BitmapChanged(Sender: TObject);
  public
    constructor Create(ATabSet: TTMSFNCCustomTabSet);
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
  published
    property Rounding: Single read FRounding write SetRounding stored IsRoundingStored nodefault;
    property Size: Single read FSize write SetSize stored IsSizedStored nodefault;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property DownFill: TTMSFNCGraphicsFill read FDownFill write SetDownFill;
    property DownStroke: TTMSFNCGraphicsStroke read FDownStroke write SetDownStroke;
    property DisabledFill: TTMSFNCGraphicsFill read FDisabledFill write SetDisabledFill;
    property DisabledStroke: TTMSFNCGraphicsStroke read FDisabledStroke write SetDisabledStroke;
    property HoverFill: TTMSFNCGraphicsFill read FHoverFill write SetHoverFill;
    property HoverStroke: TTMSFNCGraphicsStroke read FHoverStroke write SetHoverStroke;
    property CloseIcon: TTMSFNCBitmap read FCloseIcon write SetCloseIcon;
    property InsertIcon: TTMSFNCBitmap read FInsertIcon write SetInsertIcon;
    property TabListIcon: TTMSFNCBitmap read FTabListIcon write SetTabListIcon;
    property ScrollNextIcon: TTMSFNCBitmap read FScrollNextIcon write SetScrollNextIcon;
    property ScrollPreviousIcon: TTMSFNCBitmap read FScrollPreviousIcon write SetScrollPreviousIcon;
  end;

  TTMSFNCTabSetTabAppearance = class(TPersistent)
  private
    FTabSet: TTMSFNCCustomTabSet;
    FHoverStroke: TTMSFNCGraphicsStroke;
    FDownFill: TTMSFNCGraphicsFill;
    FDownStroke: TTMSFNCGraphicsStroke;
    FDisabledFill: TTMSFNCGraphicsFill;
    FFont: TTMSFNCGraphicsFont;
    FActiveFill: TTMSFNCGraphicsFill;
    FDisabledStroke: TTMSFNCGraphicsStroke;
    FActiveStroke: TTMSFNCGraphicsStroke;
    FFill: TTMSFNCGraphicsFill;
    FHoverFill: TTMSFNCGraphicsFill;
    FStroke: TTMSFNCGraphicsStroke;
    FWordWrapping: Boolean;
    FTrimming: TTMSFNCGraphicsTextTrimming;
    FTextAlign: TTMSFNCGraphicsTextAlign;
    FShape: TTMSFNCTabSetTabShape;
    FShapeOverlap: Single;
    FShapeSlope: Single;
    FTextColor: TTMSFNCGraphicsColor;
    FHoverTextColor: TTMSFNCGraphicsColor;
    FDownTextColor: TTMSFNCGraphicsColor;
    FDisabledTextColor: TTMSFNCGraphicsColor;
    FActiveTextColor: TTMSFNCGraphicsColor;
    FShowFocus: Boolean;
    FFocusBorderColor: TTMSFNCGraphicsColor;
    FProgressFill: TTMSFNCGraphicsFill;
    FShapeRounding: Single;
    FProgressStroke: TTMSFNCGraphicsStroke;
    FCloseSize: Single;
    FProgressCircularSize: Single;
    FCloseHoverStroke: TTMSFNCGraphicsStroke;
    FCloseDownFill: TTMSFNCGraphicsFill;
    FCloseDownStroke: TTMSFNCGraphicsStroke;
    FCloseFill: TTMSFNCGraphicsFill;
    FCloseHoverFill: TTMSFNCGraphicsFill;
    FCloseStroke: TTMSFNCGraphicsStroke;
    FInsertSize: Single;
    FBadgeFont: TTMSFNCGraphicsFont;
    FBadgeFill: TTMSFNCGraphicsFill;
    FBadgeStroke: TTMSFNCGraphicsStroke;
    FTextSpacing: Single;
    procedure SetDisabledFill(const Value: TTMSFNCGraphicsFill);
    procedure SetDisabledStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetDownFill(const Value: TTMSFNCGraphicsFill);
    procedure SetDownStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetHoverFill(const Value: TTMSFNCGraphicsFill);
    procedure SetHoverStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetActiveFill(const Value: TTMSFNCGraphicsFill);
    procedure SetActiveStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetTrimming(const Value: TTMSFNCGraphicsTextTrimming);
    procedure SetWordWrapping(const Value: Boolean);
    procedure SetShape(const Value: TTMSFNCTabSetTabShape);
    procedure SetShapeOverlap(const Value: Single);
    function IsShapeOverlapStored: Boolean;
    function IsShapeSlopeStored: Boolean;
    procedure SetShapeSlope(const Value: Single);
    procedure SetActiveTextColor(const Value: TTMSFNCGraphicsColor);
    procedure SetDisabledTextColor(const Value: TTMSFNCGraphicsColor);
    procedure SetDownTextColor(const Value: TTMSFNCGraphicsColor);
    procedure SetHoverTextColor(const Value: TTMSFNCGraphicsColor);
    procedure SetTextColor(const Value: TTMSFNCGraphicsColor);
    procedure SetFocusBorderColor(const Value: TTMSFNCGraphicsColor);
    procedure SetShowFocus(const Value: Boolean);
    procedure SetProgressFill(const Value: TTMSFNCGraphicsFill);
    function IsShapeRoundingStored: Boolean;
    procedure SetShapeRounding(const Value: Single);
    procedure SetProgressStroke(const Value: TTMSFNCGraphicsStroke);
    function IsCloseSizeStored: Boolean;
    procedure SetCloseSize(const Value: Single);
    function IsProgressCircularSizeStored: Boolean;
    procedure SetProgressCircularSize(const Value: Single);
    procedure SetCloseDownFill(const Value: TTMSFNCGraphicsFill);
    procedure SetCloseDownStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetCloseFill(const Value: TTMSFNCGraphicsFill);
    procedure SetCloseHoverFill(const Value: TTMSFNCGraphicsFill);
    procedure SetCloseHoverStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetCloseStroke(const Value: TTMSFNCGraphicsStroke);
    function IsInsertSizeStored: Boolean;
    procedure SetInsertSize(const Value: Single);
    procedure SetBadgeFill(const Value: TTMSFNCGraphicsFill);
    procedure SetBadgeFont(const Value: TTMSFNCGraphicsFont);
    procedure SetBadgeStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetTextSpacing(const Value: Single);
  protected
    procedure ChangeDPIScale(M, D: Integer);
    procedure UpdateTabs;
    procedure FillChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
    procedure FontChanged(Sender: TObject);
  public
    constructor Create(ATabSet: TTMSFNCCustomTabSet);
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
  published
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property ProgressFill: TTMSFNCGraphicsFill read FProgressFill write SetProgressFill;
    property ProgressStroke: TTMSFNCGraphicsStroke read FProgressStroke write SetProgressStroke;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property ActiveFill: TTMSFNCGraphicsFill read FActiveFill write SetActiveFill;
    property ActiveStroke: TTMSFNCGraphicsStroke read FActiveStroke write SetActiveStroke;
    property DisabledFill: TTMSFNCGraphicsFill read FDisabledFill write SetDisabledFill;
    property DisabledStroke: TTMSFNCGraphicsStroke read FDisabledStroke write SetDisabledStroke;
    property DownFill: TTMSFNCGraphicsFill read FDownFill write SetDownFill;
    property DownStroke: TTMSFNCGraphicsStroke read FDownStroke write SetDownStroke;
    property HoverFill: TTMSFNCGraphicsFill read FHoverFill write SetHoverFill;
    property HoverStroke: TTMSFNCGraphicsStroke read FHoverStroke write SetHoverStroke;
    property WordWrapping: Boolean read FWordWrapping write SetWordWrapping default False;
    property Trimming: TTMSFNCGraphicsTextTrimming read FTrimming write SetTrimming default gttCharacter;
    property TextAlign: TTMSFNCGraphicsTextAlign read FTextAlign write SetTextAlign default gtaCenter;
    property TextSpacing: Single read FTextSpacing write SetTextSpacing nodefault;
    property Shape: TTMSFNCTabSetTabShape read FShape write SetShape default tsPyramid;
    property ShapeOverlap: Single read FShapeOverlap write SetShapeOverlap stored IsShapeOverlapStored nodefault;
    property ShapeSlope: Single read FShapeSlope write SetShapeSlope stored IsShapeSlopeStored nodefault;
    property ShapeRounding: Single read FShapeRounding write SetShapeRounding stored IsShapeRoundingStored nodefault;
    property TextColor: TTMSFNCGraphicsColor read FTextColor write SetTextColor default gcBlack;
    property ActiveTextColor: TTMSFNCGraphicsColor read FActiveTextColor write SetActiveTextColor default gcBlack;
    property HoverTextColor: TTMSFNCGraphicsColor read FHoverTextColor write SetHoverTextColor default gcBlack;
    property DownTextColor: TTMSFNCGraphicsColor read FDownTextColor write SetDownTextColor default gcBlack;
    property DisabledTextColor: TTMSFNCGraphicsColor read FDisabledTextColor write SetDisabledTextColor default gcBlack;
    property ShowFocus: Boolean read FShowFocus write SetShowFocus default True;
    property FocusBorderColor: TTMSFNCGraphicsColor read FFocusBorderColor write SetFocusBorderColor default gcBlack;
    property ProgressCircularSize: Single read FProgressCircularSize write SetProgressCircularSize stored IsProgressCircularSizeStored nodefault;
    property InsertSize: Single read FInsertSize write SetInsertSize stored IsInsertSizeStored nodefault;
    property CloseSize: Single read FCloseSize write SetCloseSize stored IsCloseSizeStored nodefault;
    property CloseFill: TTMSFNCGraphicsFill read FCloseFill write SetCloseFill;
    property CloseStroke: TTMSFNCGraphicsStroke read FCloseStroke write SetCloseStroke;
    property CloseDownFill: TTMSFNCGraphicsFill read FCloseDownFill write SetCloseDownFill;
    property CloseDownStroke: TTMSFNCGraphicsStroke read FCloseDownStroke write SetCloseDownStroke;
    property CloseHoverFill: TTMSFNCGraphicsFill read FCloseHoverFill write SetCloseHoverFill;
    property CloseHoverStroke: TTMSFNCGraphicsStroke read FCloseHoverStroke write SetCloseHoverStroke;
    property BadgeFill: TTMSFNCGraphicsFill read FBadgeFill write SetBadgeFill;
    property BadgeStroke: TTMSFNCGraphicsStroke read FBadgeStroke write SetBadgeStroke;
    property BadgeFont: TTMSFNCGraphicsFont read FBadgeFont write SetBadgeFont;
  end;

  TTMSFNCTabSetLayout = class(TPersistent)
  private
    FTabSet: TTMSFNCCustomTabSet;
    FMultiline: TTMSFNCTabSetLayoutMultiline;
    FPosition: TTMSFNCTabSetLayoutPosition;
    FOnChange: TNotifyEvent;
    procedure SetMultiline(const Value: TTMSFNCTabSetLayoutMultiline);
    procedure SetPosition(const Value: TTMSFNCTabSetLayoutPosition);
  protected
    procedure UpdateTabs;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(ATabSet: TTMSFNCCustomTabSet);
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  published
    property Position: TTMSFNCTabSetLayoutPosition read FPosition write SetPosition default tlpTop;
    property Multiline: TTMSFNCTabSetLayoutMultiline read FMultiline write SetMultiline default tlmNone;
  end;

  TTMSFNCTabSetInteraction = class(TPersistent)
  private
    FTabSet: TTMSFNCCustomTabSet;
    FSelectTabOnFocus: Boolean;
    FEditing: Boolean;
    FSelectTabOnInsert: Boolean;
    FSelectTabOnScroll: Boolean;
    FInsertTabWithKeyboard: Boolean;
    FCloseTabWithKeyboard: Boolean;
    FAutoOpenURL: Boolean;
    FReorder: Boolean;
  protected
    procedure UpdateTabs;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(ATabSet: TTMSFNCCustomTabSet);
  published
    property SelectTabOnFocus: Boolean read FSelectTabOnFocus write FSelectTabOnFocus default True;
    property SelectTabOnInsert: Boolean read FSelectTabOnInsert write FSelectTabOnInsert default True;
    property SelectTabOnScroll: Boolean read FSelectTabOnScroll write FSelectTabOnScroll default True;
    property Editing: Boolean read FEditing write FEditing default False;
    property CloseTabWithKeyboard: Boolean read FCloseTabWithKeyboard write FCloseTabWithKeyboard default True;
    property InsertTabWithKeyboard: Boolean read FInsertTabWithKeyboard write FInsertTabWithKeyboard default True;
    property AutoOpenURL: Boolean read FAutoOpenURL write FAutoOpenURL default True;
    property Reorder: Boolean read FReorder write FReorder default False;
  end;

  TTMSFNCTabSetOptions = class(TPersistent)
  private
    FTabSet: TTMSFNCCustomTabSet;
    FCloseMode: TTMSFNCTabSetCloseMode;
    FInsertMode: TTMSFNCTabSetInsertMode;
    FTabListButton: Boolean;
    FCloseAction: TTMSFNCTabSetTabCloseAction;
    procedure SetCloseMode(const Value: TTMSFNCTabSetCloseMode);
    procedure SetInsertMode(const Value: TTMSFNCTabSetInsertMode);
    procedure SetTabListButton(const Value: Boolean);
  protected
    procedure UpdateTabs;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(ATabSet: TTMSFNCCustomTabSet);
  published
    property CloseMode: TTMSFNCTabSetCloseMode read FCloseMode write SetCloseMode default tcmNone;
    property CloseAction: TTMSFNCTabSetTabCloseAction read FCloseAction write FCloseAction default ttcaHide;
    property InsertMode: TTMSFNCTabSetInsertMode read FInsertMode write SetInsertMode default timNone;
    property TabListButton: Boolean read FTabListButton write SetTabListButton default False;
  end;

  TTMSFNCTabSetButtonState = (tbsNormal, tbsHover, tbsDown, tbsDisabled);

  TTMSFNCTabSetTabState = (ttsNormal, ttsHover, ttsDown, ttsActive, ttsDisabled);

  {$IFDEF FMXLIB}
  TTMSFNCTabSetInplaceEditor = TControl;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  TTMSFNCTabSetInplaceEditor = TWinControl;
  {$ENDIF}

  TTMSFNCTabSetMenuButton = (tmbClose, tmbInsert, tmbTabList, tmbScrollPrevious, tmbScrollNext);

  TTMSFNCTabSetBeforeReorderTabEvent = procedure(Sender: TObject; ACurrentTabIndex: Integer; ANewTabIndex: Integer; var ACanReorder: Boolean) of object;
  TTMSFNCTabSetBeforeChangeTabEvent = procedure(Sender: TObject; ACurrentTabIndex: Integer; ANewTabIndex: Integer; var ACanChange: Boolean) of object;
  TTMSFNCTabSetChangeTabEvent = procedure(Sender: TObject; APreviousTabIndex: Integer; ACurrentTabIndex: Integer) of object;
  TTMSFNCTabSetReorderTabEvent = procedure(Sender: TObject; APreviousTabIndex: Integer; ACurrentTabIndex: Integer) of object;
  TTMSFNCTabSetBeforeCloseTabEvent = procedure(Sender: TObject; ATabIndex: Integer; var ACloseAction: TTMSFNCTabSetTabCloseAction) of object;
  TTMSFNCTabSetCloseTabEvent = procedure(Sender: TObject; ATabIndex: Integer; ACloseAction: TTMSFNCTabSetTabCloseAction) of object;
  TTMSFNCTabSetBeforeInsertTabEvent = procedure(Sender: TObject; var ANewTabIndex: Integer; var ACanInsert: Boolean) of object;
  TTMSFNCTabSetInsertTabEvent = procedure(Sender: TObject; ANewTabIndex: Integer) of object;
  TTMSFNCTabSetInplaceEditorClass = class of TTMSFNCTabSetInplaceEditor;
  TTMSFNCTabSetCustomizeInplaceEditorEvent = procedure(Sender: TObject; ATabIndex: Integer; AInplaceEditor: TTMSFNCTabSetInplaceEditor) of object;
  TTMSFNCTabSetGetInplaceEditorRectEvent = procedure(Sender: TObject; ATabIndex: Integer; AInplaceEditor: TTMSFNCTabSetInplaceEditor; var AInplaceEditorRect: TRectF) of object;
  TTMSFNCTabSetGetInplaceEditorEvent = procedure(Sender: TObject; ATabIndex: Integer; var AInplaceEditorClass: TTMSFNCTabSetInplaceEditorClass) of object;
  TTMSFNCTabSetBeforeOpenInplaceEditorEvent = procedure(Sender: TObject; ATabIndex: Integer; var ACanOpen: Boolean) of object;
  TTMSFNCTabSetOpenInplaceEditorEvent = procedure(Sender: TObject; ATabIndex: Integer; AInplaceEditor: TTMSFNCTabSetInplaceEditor; AInplaceEditorRect: TRectF) of object;
  TTMSFNCTabSetCloseInplaceEditorEvent = procedure(Sender: TObject; ATabIndex: Integer; AInplaceEditor: TTMSFNCTabSetInplaceEditor; ACancelled: Boolean; var ACanClose: Boolean) of object;
  TTMSFNCTabSetBeforeUpdateTabEvent = procedure(Sender: TObject; ATabIndex: Integer; var AText: String; var ACanUpdate: Boolean) of object;
  TTMSFNCTabSetUpdateTabEvent = procedure(Sender: TObject; ATabIndex: Integer) of object;
  TTMSFNCTabSetAnchorTabClickEvent = procedure(Sender: TObject; ATabIndex: Integer; AAnchor: String) of object;
  TTMSFNCTabSetBeforeDrawTabBackgroundEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; AState: TTMSFNCTabSetTabState; var ADefaultDraw: Boolean) of object;
  TTMSFNCTabSetBeforeDrawTabProgressEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; AProgressMode: TTMSFNCTabSetTabProgressMode; AProgressKind: TTMSFNCTabSetTabProgressKind; AAnimationStartAngle, AAnimationSweepAngle, AProgress, AProgressMax: Single; var ADefaultDraw: Boolean) of object;
  TTMSFNCTabSetBeforeDrawTabBadgeEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; AText: String; var ADefaultDraw: Boolean) of object;
  TTMSFNCTabSetBeforeDrawTabTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; AText: String; var ADefaultDraw: Boolean) of object;
  TTMSFNCTabSetBeforeDrawTabCloseButtonEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; AState: TTMSFNCTabSetButtonState; var ADefaultDraw: Boolean) of object;
  TTMSFNCTabSetBeforeDrawTabBitmapEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; var ADefaultDraw: Boolean) of object;
  TTMSFNCTabSetAfterDrawTabBackgroundEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; AState: TTMSFNCTabSetTabState) of object;
  TTMSFNCTabSetAfterDrawTabProgressEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; AProgressMode: TTMSFNCTabSetTabProgressMode; AProgressKind: TTMSFNCTabSetTabProgressKind; AAnimationStartAngle, AAnimationSweepAngle, AProgress, AProgressMax: Single) of object;
  TTMSFNCTabSetAfterDrawTabBadgeEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; AText: String) of object;
  TTMSFNCTabSetAfterDrawTabTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; AText: String) of object;
  TTMSFNCTabSetAfterDrawTabCloseButtonEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; AState: TTMSFNCTabSetButtonState) of object;
  TTMSFNCTabSetAfterDrawTabBitmapEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF) of object;
  TTMSFNCTabSetBeforeDrawMenuButton = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AButton: TTMSFNCTabSetMenuButton; AState: TTMSFNCTabSetButtonState; var ADefaultDraw: Boolean) of object;
  TTMSFNCTabSetAfterDrawMenuButton = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AButton: TTMSFNCTabSetMenuButton; AState: TTMSFNCTabSetButtonState) of object;

  TTMSFNCTabSetEdit = class(TEdit)
  private
    FTabSet: TTMSFNCCustomTabSet;
  protected
  {$IFDEF FMXLIB}
    function GetDefaultStyleLookupName: string; override;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
    procedure KeyDown(var Key: Word; Shift: TShiftState); override;
  {$ENDIF}
    procedure DoExit; override;
  public
    constructor Create(AOwner: TComponent); override;
  end;

  TTMSFNCCustomTabSet = class(TTMSFNCCustomControl, ITMSFNCBitmapContainer, ITMSFNCAppearanceGlobalFont, ITMSFNCBaseListIO, ITMSFNCBasePersistenceIO)
  private
    FPersistence: Boolean;
    {$IFDEF FMXLIB}
    FInplaceEditorTimer: TTimer;
    {$ENDIF}
    FTotalTabHeight: Single;
    FDownX, FDownY: Single;
    FDragDownX, FDragDownY: Single;
    FClosing: Boolean;
    FTabAnchor: String;
    FDblClicked: Boolean;
    FInplaceEditorClosed, FCloseWithDialogKey: Boolean;
    FUpdateTab: TTMSFNCTabSetDisplayTab;
    FInplaceEditorClass: TTMSFNCTabSetInplaceEditorClass;
    FInplaceEditor: TTMSFNCTabSetInplaceEditor;
    FInplaceEditorActive: Boolean;
    FMenuSize: Single;
    FInvisibleTabList: TTMSFNCTabSetInvisibleTabList;
    FTabListPopup: TTMSFNCPopup;
    FTabList: TListBox;
    FFirstTabIndex, FLastTabIndex: Integer;
    FTabListButtonState: TTMSFNCTabSetButtonState;
    FCloseButtonState: TTMSFNCTabSetButtonState;
    FInsertButtonState: TTMSFNCTabSetButtonState;
    FScrollButtonPreviousState: TTMSFNCTabSetButtonState;
    FScrollButtonNextState: TTMSFNCTabSetButtonState;
    FPrevTabListButtonState: TTMSFNCTabSetButtonState;
    FPrevCloseButtonState: TTMSFNCTabSetButtonState;
    FPrevInsertButtonState: TTMSFNCTabSetButtonState;
    FPrevScrollButtonPreviousState: TTMSFNCTabSetButtonState;
    FPrevScrollButtonNextState: TTMSFNCTabSetButtonState;
    FScrollButtonNextDisabled: Boolean;
    FScrollButtonPreviousDisabled: Boolean;
    FShowMenu, FShowScrollButtons, FAllowScrolling, FShowCloseButton,
    FShowTabListButton, FShowInsertButton: Boolean;
    FInsertTab, FDragTab: TTMSFNCTabSetTab;
    FActiveTabIndex: Integer;
    FDownCount: Integer;
    FAnimTimer, FDownTimer: TTimer;
    FActiveTab, FFocusedTab, FPrevTab, FDownTab, FPrevHoverTab, FHoverTab: TTMSFNCTabSetTab;
    FTabChanging: Boolean;
    FDownCloseTab, FHoverCloseTab, FPrevHoverCloseTab: TTMSFNCTabSetTab;
    FDragTabDisplay: TTMSFNCTabSetDisplayTab;
    FOrigRect: TRectF;
    FStartTab, FScrollCount: Integer;
    FUpdateCount: Integer;
    FDisplayList: TTMSFNCTabSetDisplayList;
    FBitmapContainer: TTMSFNCBitmapContainer;
    FTabs: TTMSFNCTabSetTabs;
    FTabSize: TTMSFNCTabSetTabSize;
    FLayout: TTMSFNCTabSetLayout;
    FTabAppearance: TTMSFNCTabSetTabAppearance;
    FInteraction: TTMSFNCTabSetInteraction;
    FOnChangeTab: TTMSFNCTabSetChangeTabEvent;
    FOnCloseTab: TTMSFNCTabSetCloseTabEvent;
    FOnBeforeChangeTab: TTMSFNCTabSetBeforeChangeTabEvent;
    FOnBeforeCloseTab: TTMSFNCTabSetBeforeCloseTabEvent;
    FOptions: TTMSFNCTabSetOptions;
    FOnInsertTab: TTMSFNCTabSetInsertTabEvent;
    FOnBeforeInsertTab: TTMSFNCTabSetBeforeInsertTabEvent;
    FButtonAppearance: TTMSFNCTabSetButtonAppearance;
    FOnGetInplaceEditorRect: TTMSFNCTabSetGetInplaceEditorRectEvent;
    FOnCustomizeInplaceEditor: TTMSFNCTabSetCustomizeInplaceEditorEvent;
    FOnOpenInplaceEditor: TTMSFNCTabSetOpenInplaceEditorEvent;
    FOnGetInplaceEditor: TTMSFNCTabSetGetInplaceEditorEvent;
    FOnCloseInplaceEditor: TTMSFNCTabSetCloseInplaceEditorEvent;
    FOnBeforeOpenInplaceEditor: TTMSFNCTabSetBeforeOpenInplaceEditorEvent;
    FOnUpdateTab: TTMSFNCTabSetUpdateTabEvent;
    FOnBeforeUpdateTab: TTMSFNCTabSetBeforeUpdateTabEvent;
    FOnAnchorTabClick: TTMSFNCTabSetAnchorTabClickEvent;
    FOnReorderTab: TTMSFNCTabSetReorderTabEvent;
    FOnBeforeReorderTab: TTMSFNCTabSetBeforeReorderTabEvent;
    FOnBeforeDrawTabBadge: TTMSFNCTabSetBeforeDrawTabBadgeEvent;
    FOnBeforeDrawTabText: TTMSFNCTabSetBeforeDrawTabTextEvent;
    FOnAfterDrawTabBackground: TTMSFNCTabSetAfterDrawTabBackgroundEvent;
    FOnAfterDrawTabBitmap: TTMSFNCTabSetAfterDrawTabBitmapEvent;
    FOnBeforeDrawTabCloseButton: TTMSFNCTabSetBeforeDrawTabCloseButtonEvent;
    FOnAfterDrawTabProgress: TTMSFNCTabSetAfterDrawTabProgressEvent;
    FOnAfterDrawTabBadge: TTMSFNCTabSetAfterDrawTabBadgeEvent;
    FOnAfterDrawTabText: TTMSFNCTabSetAfterDrawTabTextEvent;
    FOnAfterDrawTabCloseButton: TTMSFNCTabSetAfterDrawTabCloseButtonEvent;
    FOnBeforeDrawTabBackground: TTMSFNCTabSetBeforeDrawTabBackgroundEvent;
    FOnBeforeDrawTabBitmap: TTMSFNCTabSetBeforeDrawTabBitmapEvent;
    FOnBeforeDrawTabProgress: TTMSFNCTabSetBeforeDrawTabProgressEvent;
    FOnAfterDrawMenuButton: TTMSFNCTabSetAfterDrawMenuButton;
    FOnBeforeDrawMenuButton: TTMSFNCTabSetBeforeDrawMenuButton;
    FGlobalFont: TTMSFNCAppearanceGlobalFont;
    procedure SetTabs(const Value: TTMSFNCTabSetTabs);
    procedure SetTabSize(const Value: TTMSFNCTabSetTabSize);
    procedure SetLayout(const Value: TTMSFNCTabSetLayout);
    procedure SetTabAppearance(const Value: TTMSFNCTabSetTabAppearance);
    function GetActiveTab: TTMSFNCTabSetTab;
    function GetActiveTabIndex: Integer;
    procedure SetActiveTab(const Value: TTMSFNCTabSetTab);
    procedure SetActiveTabIndex(const Value: Integer);
    procedure SetInteraction(const Value: TTMSFNCTabSetInteraction);
    procedure SetOptions(const Value: TTMSFNCTabSetOptions);
    procedure SetButtonAppearance(const Value: TTMSFNCTabSetButtonAppearance);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    procedure SetAllowScrolling(const Value: Boolean);
    procedure SetGlobalFont(const Value: TTMSFNCAppearanceGlobalFont);
  protected
    function GetItemClass: TClass; virtual;
    function CreateObject(const AClassName: string; const ABaseClass: TClass): TObject; virtual;
    function GetTabClass: TCollectionItemClass; virtual;

    function GetDocURL: string; override;
    function HandleDesignHitTest(const APoint: TPoint): Boolean; override;
    function GetHintString: string; override;
    function HasHint: Boolean; override;
    function CreateTabs: TTMSFNCTabSetTabs; virtual;
    function GetTotalTabHeight: Single; virtual;
    function GetVersion: string; override;
    function GetInnerTabRect: TRectF; virtual;
    function GetTabSize(ATabIndex: Integer): Single; virtual;
    function GetTotalTabCount: Integer; virtual;
    function GetTabRect: TRectF; virtual;
    function GetMenuRect: TRectF; virtual;
    function GetVisibleTabCount: Integer; virtual;
    function GetInvisibleTabCount: Integer; virtual;
    function GetInvisibleTabCountForScrolling: Integer; virtual;
    function GetLastScrollableTabIndex: Integer; virtual;
    function GetFirstScrollableTabIndex: Integer; virtual;
    function GetDisplayCount: Integer; virtual;
    function GetNormalDisplayCount: Integer; virtual;
    function GetAdditionalTabSpacing: Single; virtual;
    function GetButtonShapePath(ARect: TRectF): TTMSFNCGraphicsPath; virtual;
    function GetTabListButtonRect: TRectF; virtual;
    function GetCloseButtonRect: TRectF; virtual;
    function GetInsertButtonRect: TRectF; virtual;
    function GetScrollButtonNextRect: TRectF; virtual;
    function GetScrollButtonPreviousRect: TRectF; virtual;
    function IsButtonStateDown: Boolean; virtual;
    function DragTabDisplay: TTMSFNCTabSetDisplayTab; virtual;
    function IsButtonStateChanged: Boolean; virtual;
    function GetNextTab(ATabIndex: Integer): Integer; virtual;
    function GetPreviousTab(ATabIndex: Integer): Integer; virtual;
    function GetDisplayTab(ATabIndex: Integer): TTMSFNCTabSetDisplayTab; virtual;
    function HandleInsertTab(var AChanged: Boolean): TTMSFNCTabSetTab; virtual;
    function GetInplaceEditorRect(ATab: TTMSFNCTabSetDisplayTab): TRectF; virtual;
    function XYToAnchor(ATab: TTMSFNCTabSetDisplayTab; X, Y: Single): string; virtual;
    {$IFDEF FNCLIB}
    {$IFNDEF WEBLIB}
    function FindDisplayTabWithShortCutHint(AShortCutHint: string): TTMSFNCTabSetDisplayTab; virtual;
    function ExecuteShortCutMethod(AShortCut: string): Boolean; override;
    procedure GetShortCutHints(AShortCutHints: TStringList); override;
    procedure CustomizeShortCut(AShortCutWindow: TTMSFNCHint; AShortCut: string; AShortCutRect: TRectF; var AShortCutPosition: TPointF); override;
    {$ENDIF}
    {$ENDIF}
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer); virtual;
    procedure ChangeDPIScale(M, D: Integer); override;
    {$IFDEF FMXLIB}
    procedure PopupListSelected(const Sender: TCustomListBox; const Item: TListBoxItem);
    procedure ApplyInplaceEditorStyleLookup(Sender: TObject);
    procedure DoInplaceEditorTimer(Sender: TObject);
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    procedure PopupListSelected(Sender: TObject);
    {$ENDIF}
    procedure ApplyStyle; override;
    procedure ResetToDefaultStyle; override;
    procedure DoInvalidate; virtual;
    procedure InitializeInvisibleTabs; virtual;
    procedure InitializeScrollButtonState; virtual;
    procedure FillTabList; virtual;
    procedure SaveButtonState; virtual;
    procedure ClearButtonState; virtual;
    procedure HandleButtonDownState(X, Y: Single); virtual;
    procedure HandleButtonHoverState(X, Y: Single); virtual;
    procedure InitializeMenu; virtual;
    procedure StartProgressAnimationTimer; virtual;
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure CalculateRects(var ATab: TTMSFNCTabSetDisplayTab); virtual;
    procedure FixStartTab; virtual;
    procedure UpdateTabs(ACalculateScrolling: Boolean = False; AForceUpdate: Boolean = False); virtual;
    procedure DoBeforeDrawMenuButton(AGraphics: TTMSFNCGraphics; ARect: TRectF; AButton: TTMSFNCTabSetMenuButton; AState: TTMSFNCTabSetButtonState; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawMenuButton(AGraphics: TTMSFNCGraphics; ARect: TRectF; AButton: TTMSFNCTabSetMenuButton; AState: TTMSFNCTabSetButtonState); virtual;
    procedure DoBeforeDrawTabBackground(AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; AState: TTMSFNCTabSetTabState; var ADefaultDraw: Boolean); virtual;
    procedure DoBeforeDrawTabProgress(AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; AProgressMode: TTMSFNCTabSetTabProgressMode; AProgressKind: TTMSFNCTabSetTabProgressKind; AAnimationStartAngle, AAnimationSweepAngle, AProgress, AProgressMax: Single; var ADefaultDraw: Boolean); virtual;
    procedure DoBeforeDrawTabBadge(AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; AText: String; var ADefaultDraw: Boolean); virtual;
    procedure DoBeforeDrawTabText(AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; AText: String; var ADefaultDraw: Boolean); virtual;
    procedure DoBeforeDrawTabCloseButton(AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; AState: TTMSFNCTabSetButtonState; var ADefaultDraw: Boolean); virtual;
    procedure DoBeforeDrawTabBitmap(AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawTabBackground(AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; AState: TTMSFNCTabSetTabState); virtual;
    procedure DoAfterDrawTabProgress(AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; AProgressMode: TTMSFNCTabSetTabProgressMode; AProgressKind: TTMSFNCTabSetTabProgressKind; AAnimationStartAngle, AAnimationSweepAngle, AProgress, AProgressMax: Single); virtual;
    procedure DoAfterDrawTabBadge(AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; AText: String); virtual;
    procedure DoAfterDrawTabText(AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; AText: String); virtual;
    procedure DoAfterDrawTabCloseButton(AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; AState: TTMSFNCTabSetButtonState); virtual;
    procedure DoAfterDrawTabBitmap(AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF); virtual;
    procedure DoBeforeChangeTab(ACurrentTabIndex: Integer; ANewTabIndex: Integer; var ACanChange: Boolean); virtual;
    procedure DoChangeTab(APreviousTabIndex: Integer; ACurrentTabIndex: Integer); virtual;
    procedure DoBeforeReorderTab(ACurrentTabIndex: Integer; ANewTabIndex: Integer; var ACanReorder: Boolean); virtual;
    procedure DoReorderTab(APreviousTabIndex: Integer; ACurrentTabIndex: Integer); virtual;
    procedure DoBeforeCloseTab(ATabIndex: Integer; var ACloseAction: TTMSFNCTabSetTabCloseAction); virtual;
    procedure DoCloseTab(ATabIndex: Integer; ACloseAction: TTMSFNCTabSetTabCloseAction); virtual;
    procedure DoBeforeInsertTab(var ANewTabIndex: Integer; var ACanInsert: Boolean); virtual;
    procedure DoInsertTab(ANewTabIndex: Integer); virtual;
    procedure DoAnchorTabClick(ATabIndex: Integer; AAnchor: String); virtual;
    procedure Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    procedure DrawTabs(AGraphics: TTMSFNCGraphics); virtual;
    procedure DrawCustom({%H-}AGraphics: TTMSFNCGraphics); virtual;
    procedure DrawBadges(AGraphics: TTMSFNCGraphics); virtual;
    procedure DrawMenu(AGraphics: TTMSFNCGraphics); virtual;
    procedure HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseMove(Shift: TShiftState; X, Y: Single); override;
    procedure HandleDblClick(X, Y: Single); override;
    procedure HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseEnter; override;
    procedure HandleMouseLeave; override;
    procedure DoExit; override;
    procedure DoEnter; override;
    procedure UpdateControlAfterResize; override;
    procedure StartDownTimer; virtual;
    procedure StopDownTimer; virtual;
    procedure HandleKeyDown(var Key: Word; Shift: TShiftState); override;
    procedure HandleDialogKey(var Key: Word; Shift: TShiftState); override;
    procedure HandleKeyUp(var Key: Word; Shift: TShiftState); override;
    procedure HandleMouseWheel(Shift: TShiftState; WheelDelta: Integer; var Handled: Boolean); override;
    procedure HandleCloseTab(ATab: TTMSFNCTabSetTab; var AChanged: Boolean); virtual;
    procedure HandleDragTab(ATab: TTMSFNCTabSetTab; ADropTab: TTMSFNCTabSetTab); virtual;
    procedure HandleChangeTab(ATab: TTMSFNCTabSetTab); virtual;
    procedure HandleTabEditing(ATab: TTMSFNCTabSetDisplayTab); virtual;
    procedure CustomizeInplaceEditor(AInplaceEditor: TTMSFNCTabSetInplaceEditor; ATab: TTMSFNCTabSetDisplayTab); virtual;
    procedure SelectNextSequentialTab; virtual;
    procedure AnimateTimerChanged(Sender: TObject);
    procedure DownTimerChanged(Sender: TObject);
    procedure HandleScrollNext(var AChanged: Boolean; ASelect: Boolean = True); virtual;
    procedure HandleScrollPrevious(var AChanged: Boolean; ASelect: Boolean = True); virtual;
    procedure DoBeforeOpenInplaceEditor(ATabIndex: Integer; var ACanOpen: Boolean); virtual;
    procedure DoOpenInplaceEditor(ATabIndex: Integer; AInplaceEditor: TTMSFNCTabSetInplaceEditor; AInplaceEditorRect: TRectF); virtual;
    procedure DoBeforeUpdateTab(ATabIndex: Integer; var AText: String; var ACanUpdate: Boolean); virtual;
    procedure DoUpdateTab(ATabIndex: Integer); virtual;
    procedure DoCloseInplaceEditor(ATabIndex: Integer; AInplaceEditor: TTMSFNCTabSetInplaceEditor; ACancelled: Boolean; var ACanClose: Boolean); virtual;
    procedure UpdateInplaceEditorPosition; virtual;
    procedure DoGetInplaceEditor(ATabIndex: Integer; var AInplaceEditorClass: TTMSFNCTabSetInplaceEditorClass); virtual;
    procedure SetFonts(ASetType: TTMSFNCAppearanceGlobalFontType); virtual;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer write SetBitmapContainer;
    property AllowScrolling: Boolean read FAllowScrolling write SetAllowScrolling default True;
    property Tabs: TTMSFNCTabSetTabs read FTabs write SetTabs;
    property TabSize: TTMSFNCTabSetTabSize read FTabSize write SetTabSize;
    property Layout: TTMSFNCTabSetLayout read FLayout write SetLayout;
    property TabAppearance: TTMSFNCTabSetTabAppearance read FTabAppearance write SetTabAppearance;
    property ButtonAppearance: TTMSFNCTabSetButtonAppearance read FButtonAppearance write SetButtonAppearance;
    property Interaction: TTMSFNCTabSetInteraction read FInteraction write SetInteraction;
    property Options: TTMSFNCTabSetOptions read FOptions write SetOptions;
    property ActiveTab: TTMSFNCTabSetTab read GetActiveTab write SetActiveTab;
    property ActiveTabIndex: Integer read GetActiveTabIndex write SetActiveTabIndex default 0;
    property Version: String read GetVersion;
    property GlobalFont: TTMSFNCAppearanceGlobalFont read FGlobalFont write SetGlobalFont;
    property OnBeforeCloseTab: TTMSFNCTabSetBeforeCloseTabEvent read FOnBeforeCloseTab write FOnBeforeCloseTab;
    property OnCloseTab: TTMSFNCTabSetCloseTabEvent read FOnCloseTab write FOnCloseTab;
    property OnAnchorTabClick: TTMSFNCTabSetAnchorTabClickEvent read FOnAnchorTabClick write FOnAnchorTabClick;
    property OnBeforeInsertTab: TTMSFNCTabSetBeforeInsertTabEvent read FOnBeforeInsertTab write FOnBeforeInsertTab;
    property OnInsertTab: TTMSFNCTabSetInsertTabEvent read FOnInsertTab write FOnInsertTab;
    property OnBeforeChangeTab: TTMSFNCTabSetBeforeChangeTabEvent read FOnBeforeChangeTab write FOnBeforeChangeTab;
    property OnChangeTab: TTMSFNCTabSetChangeTabEvent read FOnChangeTab write FOnChangeTab;
    property OnBeforeReorderTab: TTMSFNCTabSetBeforeReorderTabEvent read FOnBeforeReorderTab write FOnBeforeReorderTab;
    property OnReorderTab: TTMSFNCTabSetReorderTabEvent read FOnReorderTab write FOnReorderTab;
    property OnBeforeUpdateTab: TTMSFNCTabSetBeforeUpdateTabEvent read FOnBeforeUpdateTab write FOnBeforeUpdateTab;
    property OnUpdateTab: TTMSFNCTabSetUpdateTabEvent read FOnUpdateTab write FOnUpdateTab;
    property OnBeforeOpenInplaceEditor: TTMSFNCTabSetBeforeOpenInplaceEditorEvent read FOnBeforeOpenInplaceEditor write FOnBeforeOpenInplaceEditor;
    property OnCloseInplaceEditor: TTMSFNCTabSetCloseInplaceEditorEvent read FOnCloseInplaceEditor write FOnCloseInplaceEditor;
    property OnOpenInplaceEditor: TTMSFNCTabSetOpenInplaceEditorEvent read FOnOpenInplaceEditor write FOnOpenInplaceEditor;
    property OnGetInplaceEditor: TTMSFNCTabSetGetInplaceEditorEvent read FOnGetInplaceEditor write FOnGetInplaceEditor;
    property OnCustomizeInplaceEditor: TTMSFNCTabSetCustomizeInplaceEditorEvent read FOnCustomizeInplaceEditor write FOnCustomizeInplaceEditor;
    property OnGetInplaceEditorRect: TTMSFNCTabSetGetInplaceEditorRectEvent read FOnGetInplaceEditorRect write FOnGetInplaceEditorRect;
    property OnBeforeDrawTabBackground: TTMSFNCTabSetBeforeDrawTabBackgroundEvent read FOnBeforeDrawTabBackground write FOnBeforeDrawTabBackground;
    property OnBeforeDrawTabProgress: TTMSFNCTabSetBeforeDrawTabProgressEvent read FOnBeforeDrawTabProgress write FOnBeforeDrawTabProgress;
    property OnBeforeDrawTabBadge: TTMSFNCTabSetBeforeDrawTabBadgeEvent read FOnBeforeDrawTabBadge write FOnBeforeDrawTabBadge;
    property OnBeforeDrawTabText: TTMSFNCTabSetBeforeDrawTabTextEvent read FOnBeforeDrawTabText write FOnBeforeDrawTabText;
    property OnBeforeDrawTabCloseButton: TTMSFNCTabSetBeforeDrawTabCloseButtonEvent read FOnBeforeDrawTabCloseButton write FOnBeforeDrawTabCloseButton;
    property OnBeforeDrawTabBitmap: TTMSFNCTabSetBeforeDrawTabBitmapEvent read FOnBeforeDrawTabBitmap write FOnBeforeDrawTabBitmap;
    property OnAfterDrawTabBackground: TTMSFNCTabSetAfterDrawTabBackgroundEvent read FOnAfterDrawTabBackground write FOnAfterDrawTabBackground;
    property OnAfterDrawTabProgress: TTMSFNCTabSetAfterDrawTabProgressEvent read FOnAfterDrawTabProgress write FOnAfterDrawTabProgress;
    property OnAfterDrawTabBadge: TTMSFNCTabSetAfterDrawTabBadgeEvent read FOnAfterDrawTabBadge write FOnAfterDrawTabBadge;
    property OnAfterDrawTabText: TTMSFNCTabSetAfterDrawTabTextEvent read FOnAfterDrawTabText write FOnAfterDrawTabText;
    property OnAfterDrawTabCloseButton: TTMSFNCTabSetAfterDrawTabCloseButtonEvent read FOnAfterDrawTabCloseButton write FOnAfterDrawTabCloseButton;
    property OnAfterDrawTabBitmap: TTMSFNCTabSetAfterDrawTabBitmapEvent read FOnAfterDrawTabBitmap write FOnAfterDrawTabBitmap;
    property OnBeforeDrawMenuButton: TTMSFNCTabSetBeforeDrawMenuButton read FOnBeforeDrawMenuButton write FOnBeforeDrawMenuButton;
    property OnAfterDrawMenuButton: TTMSFNCTabSetAfterDrawMenuButton read FOnAfterDrawMenuButton write FOnAfterDrawMenuButton;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    function FindNextTab(ATabIndex: Integer; ACheckTabVisible: Boolean): Integer; virtual;
    function FindPreviousTab(ATabIndex: Integer; ACheckTabVisible: Boolean): Integer; virtual;
    function InsertTab(ATabIndex: Integer; AText: string = ''): TTMSFNCTabSetTab; virtual;
    function AddTab(AText: string = ''): TTMSFNCTabSetTab; virtual;
    function XYToTab(X, Y: Single; AExcludeTab: TTMSFNCTabSetTab = nil): TTMSFNCTabSetDisplayTab; virtual;
    function XYToCloseTab(X, Y: Single): TTMSFNCTabSetDisplayTab; virtual;
    function XYToScrollNextButton(X, Y: Single): Boolean; virtual;
    function XYToScrollPreviousButton(X, Y: Single): Boolean; virtual;
    function XYToCloseButton(X, Y: Single): Boolean; virtual;
    function XYToInsertButton(X, Y: Single): Boolean; virtual;
    function XYToTabListButton(X, Y: Single): Boolean; virtual;
    function IsTabVisible(ATabIndex: Integer): Boolean; virtual;
    function IsTabEnabled(ATabIndex: Integer): Boolean; virtual;
    function IsEditing: Boolean; virtual;
    procedure LoadSettingsFromFile(AFileName: string); override;
    procedure LoadSettingsFromStream(AStream: TStreamEx); override;
    procedure MoveTab(AFromTabIndex, AToTabIndex: Integer); virtual;
    procedure RemoveTab(ATabIndex: Integer); virtual;
    procedure Assign(Source: TPersistent); override;
    procedure BeginUpdate; override;
    procedure EndUpdate; override;
    procedure InitSample; virtual;
    procedure SelectNextTab; virtual;
    procedure SelectPreviousTab; virtual;
    procedure SelectTab(ATabIndex: Integer); virtual;
    procedure ScrollToTab(ATabIndex: Integer); virtual;
    procedure StopEditing; virtual;
    procedure CancelEditing; virtual;
    procedure CloseInplaceEditor(ACancel: Boolean); virtual;
    procedure FocusNextTab(ASelect: Boolean = False); virtual;
    procedure FocusPreviousTab(ASelect: Boolean = False); virtual;
    procedure FocusTab(ATabIndex: Integer; ASelect: Boolean = False); virtual;
    property TabListPopup: TTMSFNCPopup read FTabListPopup;
    property TabList: TListBox read FTabList;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCTabSet = class(TTMSFNCCustomTabSet)
  protected
    procedure RegisterRuntimeClasses; override;
  public
    constructor Create(AOwner: TComponent); override;
    property ActiveTab;
  published
    property ActiveTabIndex;
    property Fill;
    property Stroke;
    property TabAppearance;
    property ButtonAppearance;
    property BitmapContainer;
    property Interaction;
    property Options;
    property Tabs;
    property TabSize;
    property Layout;
    property Version;
    property ShowHint default True;
    property GlobalFont;
    property OnBeforeCloseTab;
    property OnBeforeChangeTab;
    property OnCloseTab;
    property OnChangeTab;
    property OnAnchorTabClick;
    property OnBeforeInsertTab;
    property OnInsertTab;
    property OnBeforeUpdateTab;
    property OnUpdateTab;
    property OnBeforeOpenInplaceEditor;
    property OnCloseInplaceEditor;
    property OnOpenInplaceEditor;
    property OnGetInplaceEditor;
    property OnCustomizeInplaceEditor;
    property OnGetInplaceEditorRect;
    property OnBeforeReorderTab;
    property OnReorderTab;
    property OnBeforeDrawTabBackground;
    property OnBeforeDrawTabProgress;
    property OnBeforeDrawTabBadge;
    property OnBeforeDrawTabText;
    property OnBeforeDrawTabCloseButton;
    property OnBeforeDrawTabBitmap;
    property OnAfterDrawTabBackground;
    property OnAfterDrawTabProgress;
    property OnAfterDrawTabBadge;
    property OnAfterDrawTabText;
    property OnAfterDrawTabCloseButton;
    property OnAfterDrawTabBitmap;
    property OnBeforeDrawMenuButton;
    property OnAfterDrawMenuButton;
  end;

{$IFDEF WEBLIB}
const
  TMSFNCTABSETLIST = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAJCAYAAAD+WDajAAAABGdBTUEAALGPC/xhBQAAAAlwSF'+
                     'lzAAAOvwAADr8BOAVTJAAAABh0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMC4zjOaXUAAAACpJREFUKFNjwAvCwsL+48IM//'+
                     '/jxvh1YhOEYZz2giVgAKcEDOCUwAQMDAAqj1EKDJG5XAAAAABJRU5ErkJggg==';
  TMSFNCTABSETCLOSE = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAARnQU1BA'+
                      'ACxjwv8YQUAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuOWwzfk4AAABVSURB'+
                      'VDhPYxh+QBSI/0OYWAFIThjCxA5ACmAYHeCTQwHYFGITwwuQNSBjkgBFmkGAIgNAGv5BaZINQdYMA0Qbgk0zDBBlCCiR4FM'+
                      'AkhOCMIcJYGAAAHvVMBv6PIFYAAAAAElFTkSuQmCC';
  TMSFNCTABSETPREVIOUS = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAA'+
                         'lwSFlzAAALEwAACxMBAJqcGAAAAEpJREFUOI3lzKENgEAQAMEJBkkIDdDTSxp6gqMyCkB8IQgMFdwZAutn+U19Bi84MU'+
                         'bxhZrBawSXB+8RDDMaDkyvmQyZyYYuOvh6N24uDUKEV//MAAAAAElFTkSuQmCC';
  TMSFNCTABSETNEXT = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSF'+
                     'lzAAALEwAACxMBAJqcGAAAAFNJREFUOI3tzzsSQFAQRNFDqSezHUuwYBJLsBuhEomE4/ll3HBq+nY1PxENRrS5xzK4L1jPSi'+
                     'ISesxPJcORJJqwU9xthjrX/ko4mpBQocN0tf1rbDeZDIfcSud0AAAAAElFTkSuQmCC';
  TMSFNCTABSETADD = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAALGPC/xhBQAAAAlwSFl'+
                    'zAAALEAAACxABrSO9dQAAABh0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMC45bDN+TgAAAEtJREFUOE/dzEEKACAIRFHvf2nL'+
                    'hZDyI6VVBS9yahJVvYJhB4YdGLq5bJtHvjcYugc/8MJJ6IQhPdwJnXXIqJBh6D74oALDDgzrVAajy7VnCteJXAAAAABJRU5ErkJggg==';
{$ENDIF}

implementation

uses
  WEBLib.TMSFNCUtils, WEBLib.Forms, WEBLib.Graphics, SysUtils, Math,
  WEBLib.TMSFNCStyles
  {$IFNDEF LCLLIB}
  {$IFNDEF WEBLIB}
  ,Rtti
  {$ENDIF}
  {$ENDIF}
  {$IFDEF FMXLIB}
  ,FMX.Styles.Objects
  {$ENDIF}
  ;

{$R TMSFNCTabSet.res}

function EmptyDisplayTab: TTMSFNCTabSetDisplayTab;
begin
  Result.Tab := nil;
  Result.ContentRect := RectF(0, 0, 0, 0);
  Result.BitmapRect := RectF(0, 0, 0, 0);
  Result.TextRect := RectF(0, 0, 0, 0);
  Result.ProgressRect := RectF(0, 0, 0, 0);
  Result.CloseButtonRect := RectF(0, 0, 0, 0);
  Result.Rect := RectF(0, 0, 0, 0);
  Result.First := False;
  Result.Last := False;
  Result.Index := -1;
  Result.Row := 0;
end;

function TTMSFNCCustomTabSet.AddTab(AText: string = ''): TTMSFNCTabSetTab;
begin
  Result := Tabs.Add;
  Result.Text := AText;
end;

procedure TTMSFNCCustomTabSet.AnimateTimerChanged(Sender: TObject);
var
  I: Integer;
  tbd: TTMSFNCTabSetDisplayTab;
  en: Boolean;
begin
  en := False;
  for I := 0 to GetDisplayCount - 1 do
  begin
    tbd := FDisplayList[I];
    if Assigned(tbd.Tab) then
    begin
      if not en and (tbd.Tab.ProgressMode = tpmMarquee) and (tbd.Tab.ProgressKind <> tpkNone) then
        en := True;

      if not tbd.Tab.FAnimMarqueeSwitch then
        tbd.Tab.FAnimMarqueeSweepAngle := tbd.Tab.FAnimMarqueeSweepAngle + 5
      else
      begin
        tbd.Tab.FAnimMarqueeStartAngle := tbd.Tab.FAnimMarqueeStartAngle + 5;
        tbd.Tab.FAnimMarqueeSweepAngle := tbd.Tab.FAnimMarqueeSweepAngle - 5;
      end;

      if (tbd.Tab.FAnimMarqueeSweepAngle = 360) and (tbd.Tab.FAnimMarqueeStartAngle = 0) then
        tbd.Tab.FAnimMarqueeSwitch := True
      else if ((tbd.Tab.FAnimMarqueeStartAngle = 360) and (tbd.Tab.FAnimMarqueeSweepAngle = 0)) then
      begin
        tbd.Tab.FAnimMarqueeSwitch := False;
        tbd.Tab.FAnimMarqueeStartAngle := 0;
      end;
    end;
  end;

  if en then
    Invalidate;

  FAnimTimer.Enabled := en;
end;

procedure TTMSFNCCustomTabSet.ApplyStyle;
var
  c, sc, bc: TTMSFNCGraphicsColor;
  I: Integer;
begin
  inherited;

  c := gcNull;
  bc := c;
  if TTMSFNCStyles.GetStyleBackgroundFillColor(c) then
  begin
    bc := c;
    TabAppearance.ActiveFill.Color := c;
  end;

  c := gcNull;
  sc := gcNull;
  if TTMSFNCStyles.GetStyleSelectionFillColor(c) then
  begin
    c := MakeGraphicsColor(TTMSFNCGraphics.GetColorRed(c), TTMSFNCGraphics.GetColorGreen(c), TTMSFNCGraphics.GetColorBlue(c));
    sc := c;
    TabAppearance.HoverFill.Color := Lighter(c, 20);
    TabAppearance.DownFill.Color := Darker(c, 20);
    TabAppearance.Fill.Color := c;
    TabAppearance.BadgeFill.Color := c;
    ButtonAppearance.HoverFill.Color := TabAppearance.HoverFill.Color;
    ButtonAppearance.DownFill.Color := TabAppearance.DownFill.Color;
    ButtonAppearance.Fill.Color := TabAppearance.Fill.Color;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleLineFillColor(c) then
  begin
    TabAppearance.ActiveStroke.Color := c;
    TabAppearance.Stroke.Color := c;
    TabAppearance.HoverStroke.Color := c;
    TabAppearance.DownStroke.Color := c;
    ButtonAppearance.Stroke.Color := c;
    ButtonAppearance.HoverStroke.Color := c;
    ButtonAppearance.DownStroke.Color := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleTextFontColor(c) then
  begin
    if bc <> c then
      TabAppearance.ActiveTextColor := c
    else if c = gcWhite then
      TabAppearance.ActiveTextColor := gcGray
    else
      TabAppearance.ActiveTextColor := Darker(c, 40);

    if sc <> c then
      TabAppearance.DownTextColor := c
    else if c = gcWhite then
      TabAppearance.DownTextColor := gcGray
    else
      TabAppearance.DownTextColor := Darker(c, 40);

    if sc <> c then
      TabAppearance.HoverTextColor := c
    else if c = gcWhite then
      TabAppearance.HoverTextColor := gcGray
    else
      TabAppearance.HoverTextColor := Darker(c, 40);

    if sc <> c then
      TabAppearance.TextColor := c
    else if c = gcWhite then
      TabAppearance.TextColor := gcGray
    else
      TabAppearance.TextColor := Darker(c, 40);
  end;

  for I := 0 to Tabs.Count - 1 do
    Tabs[I].UseDefaultAppearance := True;

  if Assigned(FInsertTab) then
    FInsertTab.UseDefaultAppearance := True;
end;

procedure TTMSFNCCustomTabSet.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCCustomTabSet) then
  begin
    FTabSize.Assign((Source as TTMSFNCCustomTabSet).TabSize);
    FTabAppearance.Assign((Source as TTMSFNCCustomTabSet).TabAppearance);
    FInteraction.Assign((Source as TTMSFNCCustomTabSet).Interaction);
    FOptions.Assign((Source as TTMSFNCCustomTabSet).Options);
    FLayout.Assign((Source as TTMSFNCCustomTabSet).Layout);
    FTabs.Assign((Source as TTMSFNCCustomTabSet).Tabs);
  end;
end;

procedure TTMSFNCCustomTabSet.BeginUpdate;
begin
  inherited;
  Inc(FUpdateCount);
end;

procedure TTMSFNCCustomTabSet.CalculateRects(var ATab: TTMSFNCTabSetDisplayTab);
var
  r: TRectF;
begin
  r := ATab.Rect;
  case Layout.Position of
    tlpTop, tlpBottom:
    begin
      case TabAppearance.Shape of
        tsRectangle: ATab.ContentRect := RectF(r.Left + TabAppearance.ShapeOverlap, r.Top, r.Right, r.Bottom);
        tsPyramidLeft: ATab.ContentRect := RectF(r.Left + TabAppearance.ShapeSlope, r.Top, r.Right, r.Bottom);
        tsPyramidRight:
        begin
          if not ATab.First and (FActiveTab <> ATab.Tab) then
            ATab.ContentRect := RectF(r.Left + TabAppearance.ShapeSlope / 2, r.Top, r.Right - TabAppearance.ShapeSlope, r.Bottom)
          else
            ATab.ContentRect := RectF(r.Left, r.Top, r.Right - TabAppearance.ShapeSlope, r.Bottom);
        end;
        tsPyramid: ATab.ContentRect := RectF(r.Left + TabAppearance.ShapeSlope, r.Top, r.Right - TabAppearance.ShapeSlope, r.Bottom);
        tsRoundLeft: ATab.ContentRect := RectF(r.Left + TabAppearance.ShapeSlope / 2, r.Top, r.Right, r.Bottom);
        tsRoundRight:
        begin
          if not ATab.First and (FActiveTab <> ATab.Tab) then
            ATab.ContentRect := RectF(r.Left + TabAppearance.ShapeSlope / 2, r.Top, r.Right - TabAppearance.ShapeSlope / 2, r.Bottom)
          else
            ATab.ContentRect := RectF(r.Left, r.Top, r.Right - TabAppearance.ShapeSlope / 2, r.Bottom)
        end;
        tsRound: ATab.ContentRect := RectF(r.Left + TabAppearance.ShapeSlope / 2, r.Top, r.Right - TabAppearance.ShapeSlope / 2, r.Bottom);
      end;
    end;
    tlpLeft, tlpRight:
    begin
      case TabAppearance.Shape of
        tsRectangle: ATab.ContentRect := RectF(r.Left, r.Top + TabAppearance.ShapeOverlap, r.Right, r.Bottom);
        tsPyramidLeft: ATab.ContentRect := RectF(r.Left, r.Top + TabAppearance.ShapeSlope, r.Right, r.Bottom);
        tsPyramidRight:
        begin
          if not ATab.First and (FActiveTab <> ATab.Tab) then
            ATab.ContentRect := RectF(r.Left, r.Top + TabAppearance.ShapeSlope / 2, r.Right, r.Bottom - TabAppearance.ShapeSlope)
          else
            ATab.ContentRect := RectF(r.Left, r.Top, r.Right, r.Bottom - TabAppearance.ShapeSlope);
        end;
        tsPyramid: ATab.ContentRect := RectF(r.Left, r.Top + TabAppearance.ShapeSlope, r.Right, r.Bottom - TabAppearance.ShapeSlope);
        tsRoundLeft: ATab.ContentRect := RectF(r.Left, r.Top + TabAppearance.ShapeSlope / 2, r.Right, r.Bottom);
        tsRoundRight:
        begin
          if not ATab.First and (FActiveTab <> ATab.Tab) then
            ATab.ContentRect := RectF(r.Left, r.Top + TabAppearance.ShapeSlope / 2, r.Right, r.Bottom - TabAppearance.ShapeSlope / 2)
          else
            ATab.ContentRect := RectF(r.Left, r.Top, r.Right, r.Bottom - TabAppearance.ShapeSlope / 2)
        end;
        tsRound: ATab.ContentRect := RectF(r.Left, r.Top + TabAppearance.ShapeSlope / 2, r.Right, r.Bottom - TabAppearance.ShapeSlope / 2);
      end;
    end;
  end;

  ATab.ProgressRect := ATab.Tab.GetProgressRect(ATab.ContentRect);
  ATab.BitmapRect := ATab.Tab.GetBitmapRect(ATab.ContentRect);
  ATab.TextRect := ATab.Tab.GetTextRect(ATab.ContentRect);
  ATab.CloseButtonRect := ATab.Tab.GetCloseButtonRect(ATab.ContentRect);
end;

procedure TTMSFNCCustomTabSet.CancelEditing;
begin
  if FInplaceEditorActive then
    CloseInplaceEditor(True);
end;

procedure TTMSFNCCustomTabSet.ChangeDPIScale(M, D: Integer);
begin
  inherited;
//  FTotalTabHeight: Single;
  FTabAppearance.ChangeDPIScale(M, D);
  FTabSize.ChangeDPIScale(M, D);
  ButtonAppearance.Size := TTMSFNCUtils.MulDivSingle(ButtonAppearance.Size, M, D);
  ButtonAppearance.Rounding := TTMSFNCUtils.MulDivSingle(ButtonAppearance.Rounding, M, D);
end;

procedure TTMSFNCCustomTabSet.ClearButtonState;
begin
  FTabListButtonState := tbsNormal;
  FCloseButtonState := tbsNormal;
  FScrollButtonPreviousState := tbsNormal;
  FScrollButtonNextState := tbsNormal;
  FInsertButtonState := tbsNormal;
end;

procedure TTMSFNCCustomTabSet.CloseInplaceEditor(ACancel: Boolean);
var
  {$IFNDEF LCLLIB}
  {$IFNDEF WEBLIB}
  AContext: TRttiContext;
  rt: TRttiType;
  propt: TRttiProperty;
  {$ENDIF}
  {$ENDIF}
  str: String;
  b, c: Boolean;
  n: String;
  {$IFDEF FMXLIB}
  propi: TRttiProperty;
  li: TListBoxItem;
  obj: TObject;
  {$ENDIF}
begin
  if FClosing then
    Exit;

  FClosing := True;
  if Assigned(FUpdateTab.Tab) and Assigned(FInplaceEditor) then
  begin
    if not ACancel then
    begin
      {$IFNDEF LCLLIB}
      {$IFNDEF WEBLIB}
      AContext := TRttiContext.Create;
      {$ENDIF}
      {$ENDIF}
      try
        n := '';
        {$IFNDEF LCLLIB}
        {$IFNDEF WEBLIB}
        rt := AContext.GetType(FInplaceEditor.ClassInfo);
        propt := rt.GetProperty('Text');
        if Assigned(Propt) then
          str := propt.GetValue(FInplaceEditor).AsString
        else
        {$ENDIF}
        {$ENDIF}
        begin
          {$IFDEF FMXLIB}
          propi := rt.GetProperty('Selected');
          if Assigned(propi) then
          begin
            obj := propi.GetValue(FInplaceEditor).AsObject;
            if Assigned(obj) and (obj is TListBoxItem) then
            begin
              li := obj as TListBoxItem;
              if Assigned(li) then
                str := li.Text;
            end;
          end;
          {$ENDIF}
        end;

        {$IFDEF LCLWEBLIB}
        if FInplaceEditor is TEdit then
          str := (FInplaceEditor as TEdit).Text;
        {$ENDIF}

        n := str;
      finally
        {$IFNDEF LCLLIB}
        {$IFNDEF WEBLIB}
        AContext.Free;
        {$ENDIF}
        {$ENDIF}
      end;
    end;

    c := True;
    DoCloseInplaceEditor(FUpdateTab.Tab.Index, FInplaceEditor, ACancel, c);
    if c then
    begin
      b := True;
      DoBeforeUpdateTab(FUpdateTab.Tab.Index, n, b);
      if b and not ACancel then
      begin
        Inc(FUpdateCount);
        if Assigned(FUpdateTab.Tab) then
          FUpdateTab.Tab.Text := n;
        Dec(FUpdateCount);

        DoUpdateTab(FUpdateTab.Tab.Index);
      end;

      FInplaceEditor.Parent := nil;
      {$IFDEF FMXLIB}
      FInplaceEditorTimer.Enabled := True;
      while FInplaceEditorTimer.Enabled do
        Application.ProcessMessages;
      {$ENDIF}
      {$IFDEF CMNWEBLIB}
      FInplaceEditor.Free;
      FInplaceEditor := nil;
      {$ENDIF}
      FInplaceEditorActive := False;
    end;

    if c then
    begin
      UpdateTabs;
      if CanFocus then
        SetFocus;
    end;
  end;
  FClosing := False;
end;

{$IFDEF FMXLIB}
procedure TTMSFNCCustomTabSet.ApplyInplaceEditorStyleLookup(Sender: TObject);
var
  obj: TFmxObject;
  function FindSRes(AObject: TFmxObject; AResName: String): TFMXObject;
  var
    I: Integer;
  begin
    Result := nil;
    if Assigned(AObject) then
    begin
      Result := AObject.FindStyleResource(AResName);
      if not Assigned(Result) then
      begin
        for I := 0 to AObject.ChildrenCount - 1 do
        begin
          Result := FindSRes(AObject.Children[I], AResName);
          if Assigned(Result) then
            Break;
        end;
      end;
    end;
  end;
begin
  if (Sender = FInplaceEditor) and (FInplaceEditor is TEdit) then
  begin
    obj := FindSRes(FInplaceEditor, 'background');
    if Assigned(obj) and (obj is TCustomStyleObject) then
      TCustomStyleObject(obj).Source := nil
  end;
end;

procedure TTMSFNCCustomTabSet.PopupListSelected(const Sender: TCustomListBox; const Item: TListBoxItem);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCCustomTabSet.PopupListSelected(Sender: TObject);
{$ENDIF}
var
  tb: TTMSFNCTabSetTab;
begin
  FTabListPopup.IsOpen := False;
  if (FTabList.ItemIndex >= 0) and (FTabList.ItemIndex <= FTabList.Count - 1) then
  begin
    tb := FTabList.Items.Objects[FTabList.ItemIndex] as TTMSFNCTabSetTab;
    if Assigned(tb) then
      tb.Visible := True;
  end;
end;

constructor TTMSFNCCustomTabSet.Create(AOwner: TComponent);
begin
  inherited;
  FInsertTab := TTMSFNCTabSetTab.Create(nil);
  FInsertTab.CloseButton := False;
  FInsertTab.TextVisible := False;
  FInsertTab.Text := '';
  FInsertTab.ProgressKind := tpkNone;

  FActiveTabIndex := 0;
  FAnimTimer := TTimer.Create(Self);
  FAnimTimer.Enabled := False;
  FAnimTimer.Interval := 1;
  FAnimTimer.OnTimer := AnimateTimerChanged;
  FDownTimer := TTimer.Create(Self);
  FDownTimer.Enabled := False;
  FDownTimer.Interval := 100;
  FDownTimer.OnTimer := DownTimerChanged;
  FDisplayList := TTMSFNCTabSetDisplayList.Create;
  FInvisibleTabList := TTMSFNCTabSetInvisibleTabList.Create;

  FTabs := CreateTabs;
  FTabSize := TTMSFNCTabSetTabSize.Create(Self);
  FTabAppearance := TTMSFNCTabSetTabAppearance.Create(Self);
  FButtonAppearance := TTMSFNCTabSetButtonAppearance.Create(Self);
  FInteraction := TTMSFNCTabSetInteraction.Create(Self);
  FOptions := TTMSFNCTabSetOptions.Create(Self);
  FLayout := TTMSFNCTabSetLayout.Create(Self);
  FStartTab := 0;
  FAllowScrolling := True;

  FInsertTab.Bitmaps.AddBitmap(ButtonAppearance.InsertIcon);
  FInsertTab.DisabledBitmaps.AddBitmap(ButtonAppearance.InsertIcon);
  FInsertTab.FTabSet := Self;

  FTabListPopup := TTMSFNCPopup.Create(Self);
  FTabList := TListBox.Create(FTabList);
  {$IFDEF LCLLIB}
  FTabList.ClickOnSelChange := False;
  {$ENDIF}
  {$IFDEF FMXLIB}
  FTabList.OnItemClick := PopupListSelected;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  FTabList.OnClick := PopupListSelected;
  {$ENDIF}
  FTabListPopup.ContentControl := FTabList;
  FTabListPopup.FocusedControl := FTabList;
  FTabListPopup.Placement := ppBottom;
  FTabListPopup.DropDownHeight := 125;
  FTabListPopup.DropDownWidth := 100;

  {$IFDEF FMXLIB}
  FInplaceEditorTimer := TTimer.Create(Self);
  FInplaceEditorTimer.Interval := 1;
  FInplaceEditorTimer.OnTimer := DoInplaceEditorTimer;
  FInplaceEditorTimer.Enabled := False;
  {$ENDIF}

  FGlobalFont := TTMSFNCAppearanceGlobalFont.Create(Self);
end;

function TTMSFNCCustomTabSet.GetTabClass: TCollectionItemClass;
begin
  Result := TTMSFNCTabSetTab;
end;

function TTMSFNCCustomTabSet.CreateObject(const AClassName: string;
  const ABaseClass: TClass): TObject;
begin
  Result := GetTabClass.Create(nil);
end;

{$IFDEF FMXLIB}
procedure TTMSFNCCustomTabSet.DoInplaceEditorTimer(Sender: TObject);
begin
  if Assigned(FInplaceEditor) then
  begin
    FInplaceEditor.Free;
    FInplaceEditor := nil;
  end;

  FInplaceEditorTimer.Enabled := False;
end;
{$ENDIF}

function TTMSFNCCustomTabSet.CreateTabs: TTMSFNCTabSetTabs;
begin
  Result := TTMSFNCTabSetTabs.Create(Self);
end;

destructor TTMSFNCCustomTabSet.Destroy;
begin
  FGlobalFont.Free;
  FAnimTimer.Free;
  {$IFDEF FMXLIB}
  FInplaceEditorTimer.Free;
  {$ENDIF}
  FTabList.Free;
  FTabListPopup.Free;
  FInsertTab.Free;
  FTabSize.Free;
  FTabs.Free;
  FTabAppearance.Free;
  FButtonAppearance.Free;
  FInteraction.Free;
  FOptions.Free;
  FLayout.Free;
  FInvisibleTabList.Free;
  FDisplayList.Free;
  inherited;
end;

function TTMSFNCCustomTabSet.IsButtonStateChanged: Boolean;
begin
  Result := ((FTabListButtonState <> FPrevTabListButtonState) or (FInsertButtonState <> FPrevInsertButtonState) or (FCloseButtonState <> FPrevCloseButtonState)
    or (FScrollButtonPreviousState <> FPrevScrollButtonPreviousState) or (FScrollButtonNextState <> FPrevScrollButtonNextState));
end;

procedure TTMSFNCCustomTabSet.DoOpenInplaceEditor(ATabIndex: Integer;
  AInplaceEditor: TTMSFNCTabSetInplaceEditor; AInplaceEditorRect: TRectF);
begin
  if Assigned(OnOpenInplaceEditor) then
    OnOpenInplaceEditor(Self, ATabIndex, AInplaceEditor, AInplaceEditorRect);
end;

procedure TTMSFNCCustomTabSet.DoUpdateTab(ATabIndex: Integer);
begin
  if Assigned(OnUpdateTab) then
    OnUpdateTab(Self, ATabIndex);
end;

procedure TTMSFNCCustomTabSet.DoBeforeChangeTab(ACurrentTabIndex,
  ANewTabIndex: Integer; var ACanChange: Boolean);
begin
  if Assigned(OnBeforeChangeTab) then
    OnBeforeChangeTab(Self, ACurrentTabIndex, ANewTabIndex, ACanChange);
end;

procedure TTMSFNCCustomTabSet.DoBeforeReorderTab(ACurrentTabIndex,
  ANewTabIndex: Integer; var ACanReorder: Boolean);
begin
  if Assigned(OnBeforeReorderTab) then
    OnBeforeReorderTab(Self, ACurrentTabIndex, ANewTabIndex, ACanReorder);
end;

procedure TTMSFNCCustomTabSet.DoBeforeCloseTab(ATabIndex: Integer;
  var ACloseAction: TTMSFNCTabSetTabCloseAction);
begin
  if Assigned(OnBeforeCloseTab) then
    OnBeforeCloseTab(Self, ATabIndex, ACloseAction);
end;

procedure TTMSFNCCustomTabSet.DoBeforeDrawMenuButton(AGraphics: TTMSFNCGraphics; ARect: TRectF; AButton: TTMSFNCTabSetMenuButton; AState: TTMSFNCTabSetButtonState;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawMenuButton) then
    OnBeforeDrawMenuButton(Self, AGraphics, ARect, AButton, AState, ADefaultDraw);
end;

procedure TTMSFNCCustomTabSet.DoBeforeDrawTabBackground(
  AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF;
  AState: TTMSFNCTabSetTabState; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawTabBackground) then
    OnBeforeDrawTabBackground(Self, AGraphics, ATabIndex, ARect, AState, ADefaultDraw);
end;

procedure TTMSFNCCustomTabSet.DoBeforeDrawTabBadge(AGraphics: TTMSFNCGraphics;
  ATabIndex: Integer; ARect: TRectF; AText: String; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawTabBadge) then
    OnBeforeDrawTabBadge(Self, AGraphics, ATabIndex, ARect, AText, ADefaultDraw);
end;

procedure TTMSFNCCustomTabSet.DoBeforeDrawTabBitmap(AGraphics: TTMSFNCGraphics;
  ATabIndex: Integer; ARect: TRectF; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawTabBitmap) then
    OnBeforeDrawTabBitmap(Self, AGraphics, ATabIndex, ARect, ADefaultDraw);
end;

procedure TTMSFNCCustomTabSet.DoBeforeDrawTabCloseButton(
  AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF;
  AState: TTMSFNCTabSetButtonState; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawTabCloseButton) then
    OnBeforeDrawTabCloseButton(Self, AGraphics, ATabIndex, ARect, AState, ADefaultDraw);
end;

procedure TTMSFNCCustomTabSet.DoBeforeDrawTabProgress(
  AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; AProgressMode: TTMSFNCTabSetTabProgressMode; AProgressKind: TTMSFNCTabSetTabProgressKind;
  AAnimationStartAngle, AAnimationSweepAngle, AProgress, AProgressMax: Single;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawTabProgress) then
    OnBeforeDrawTabProgress(Self, AGraphics, ATabIndex, ARect, AProgressMode, AProgressKind, AAnimationStartAngle, AAnimationSweepAngle, AProgress, AProgressMax, ADefaultDraw);
end;

procedure TTMSFNCCustomTabSet.DoBeforeDrawTabText(AGraphics: TTMSFNCGraphics;
  ATabIndex: Integer; ARect: TRectF; AText: String; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawTabText) then
    OnBeforeDrawTabText(Self, AGraphics, ATabIndex, ARect, AText, ADefaultDraw);
end;

procedure TTMSFNCCustomTabSet.DoBeforeInsertTab(var ANewTabIndex: Integer;
  var ACanInsert: Boolean);
begin
  if Assigned(OnBeforeInsertTab) then
    OnBeforeInsertTab(Self, ANewTabIndex, ACanInsert);
end;

procedure TTMSFNCCustomTabSet.DoBeforeOpenInplaceEditor(ATabIndex: Integer;
  var ACanOpen: Boolean);
begin
  if Assigned(OnBeforeOpenInplaceEditor) then
    OnBeforeOpenInplaceEditor(Self, ATabIndex, ACanOpen);
end;

procedure TTMSFNCCustomTabSet.DoBeforeUpdateTab(ATabIndex: Integer;
  var AText: String; var ACanUpdate: Boolean);
begin
  if Assigned(OnBeforeUpdateTab) then
    OnBeforeUpdateTab(Self, ATabIndex, AText, ACanUpdate);
end;

procedure TTMSFNCCustomTabSet.DoChangeTab(APreviousTabIndex,
  ACurrentTabIndex: Integer);
begin
  if Assigned(OnChangeTab) then
    OnChangeTab(Self, APreviousTabIndex, ACurrentTabIndex);
end;

procedure TTMSFNCCustomTabSet.DoReorderTab(APreviousTabIndex,
  ACurrentTabIndex: Integer);
begin
  if Assigned(OnReorderTab) then
    OnReorderTab(Self, APreviousTabIndex, ACurrentTabIndex);
end;

procedure TTMSFNCCustomTabSet.DoInvalidate;
begin

end;

procedure TTMSFNCCustomTabSet.DoCloseInplaceEditor(ATabIndex: Integer;
  AInplaceEditor: TTMSFNCTabSetInplaceEditor; ACancelled: Boolean;
  var ACanClose: Boolean);
begin
  if Assigned(OnCloseInplaceEditor) then
    OnCloseInplaceEditor(Self, ATabIndex, AInplaceEditor, ACancelled, ACanClose);
end;

procedure TTMSFNCCustomTabSet.DoCloseTab(ATabIndex: Integer; ACloseAction: TTMSFNCTabSetTabCloseAction);
begin
  if Assigned(OnCloseTab) then
    OnCloseTab(Self, ATabIndex, ACloseAction);
end;

procedure TTMSFNCCustomTabSet.DoEnter;
begin
  inherited;
  Invalidate;
end;

procedure TTMSFNCCustomTabSet.DoExit;
begin
  inherited;
  if FInplaceEditorActive then
    CloseInplaceEditor(False);

  Invalidate;
end;

procedure TTMSFNCCustomTabSet.DoGetInplaceEditor(
  ATabIndex: Integer; var AInplaceEditorClass: TTMSFNCTabSetInplaceEditorClass);
begin
  if Assigned(OnGetInplaceEditor) then
    OnGetInplaceEditor(Self, ATabIndex, AInplaceEditorClass);
end;

procedure TTMSFNCCustomTabSet.DoInsertTab(ANewTabIndex: Integer);
begin
  if Assigned(OnInsertTab) then
    OnInsertTab(Self, ANewTabIndex);
end;

procedure TTMSFNCCustomTabSet.DownTimerChanged(Sender: TObject);
var
  b: Boolean;
begin
  Inc(FDownCount);
  if (FDownCount > 3) then
  begin
    b := False;

    if (FScrollButtonNextState = tbsDown) and not FScrollButtonNextDisabled then
      HandleScrollNext(b);

    if (FScrollButtonPreviousState = tbsDown) and not FScrollButtonPreviousDisabled then
      HandleScrollPrevious(b);

     if b then
       UpdateTabs;
  end;
end;

procedure TTMSFNCCustomTabSet.DoAfterDrawMenuButton(AGraphics: TTMSFNCGraphics; ARect: TRectF; AButton: TTMSFNCTabSetMenuButton; AState: TTMSFNCTabSetButtonState);
begin
  if Assigned(OnAfterDrawMenuButton) then
    OnAfterDrawMenuButton(Self, AGraphics, ARect, AButton, AState);
end;

procedure TTMSFNCCustomTabSet.DoAfterDrawTabBackground(
  AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF;
  AState: TTMSFNCTabSetTabState);
begin
  if Assigned(OnAfterDrawTabBackground) then
    OnAfterDrawTabBackground(Self, AGraphics, ATabIndex, ARect, AState);
end;

procedure TTMSFNCCustomTabSet.DoAfterDrawTabBadge(AGraphics: TTMSFNCGraphics;
  ATabIndex: Integer; ARect: TRectF; AText: String);
begin
  if Assigned(OnAfterDrawTabBadge) then
    OnAfterDrawTabBadge(Self, AGraphics, ATabIndex, ARect, AText);
end;

procedure TTMSFNCCustomTabSet.DoAfterDrawTabBitmap(AGraphics: TTMSFNCGraphics;
  ATabIndex: Integer; ARect: TRectF);
begin
  if Assigned(OnAfterDrawTabBitmap) then
    OnAfterDrawTabBitmap(Self, AGraphics, ATabIndex, ARect);
end;

procedure TTMSFNCCustomTabSet.DoAfterDrawTabCloseButton(
  AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; AState: TTMSFNCTabSetButtonState);
begin
  if Assigned(OnAfterDrawTabCloseButton) then
    OnAfterDrawTabCloseButton(Self, AGraphics, ATabIndex, ARect, AState);
end;

procedure TTMSFNCCustomTabSet.DoAfterDrawTabProgress(
  AGraphics: TTMSFNCGraphics; ATabIndex: Integer; ARect: TRectF; AProgressMode: TTMSFNCTabSetTabProgressMode; AProgressKind: TTMSFNCTabSetTabProgressKind;
  AAnimationStartAngle, AAnimationSweepAngle, AProgress, AProgressMax: Single);
begin
  if Assigned(OnAfterDrawTabProgress) then
    OnAfterDrawTabProgress(Self, AGraphics, ATabIndex, ARect, AProgressMode, AProgressKind, AAnimationStartAngle, AAnimationSweepAngle, AProgress, AProgressMax);
end;

procedure TTMSFNCCustomTabSet.DoAfterDrawTabText(AGraphics: TTMSFNCGraphics;
  ATabIndex: Integer; ARect: TRectF; AText: String);
begin
  if Assigned(OnAfterDrawTabText) then
    OnAfterDrawTabText(Self, AGraphics, ATabIndex, ARect, AText);
end;

procedure TTMSFNCCustomTabSet.DoAnchorTabClick(ATabIndex: Integer;
  AAnchor: String);
begin
  if Interaction.AutoOpenURL then
    TTMSFNCUtils.OpenURL(AAnchor)
  else
  begin
    if Assigned(OnAnchorTabClick) then
      OnAnchorTabClick(Self, ATabIndex, AAnchor);
  end;
end;

function TTMSFNCCustomTabSet.DragTabDisplay: TTMSFNCTabSetDisplayTab;
begin
  Result := FDragTabDisplay;
end;

procedure TTMSFNCCustomTabSet.Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  st: TTMSFNCGraphicsSaveState;
  r: TRectF;
begin
  inherited;
  st := AGraphics.SaveState;
  DrawCustom(AGraphics);
  r := LocalRect;
  InflateRectEx(r, -1, -1);
  AGraphics.ClipRect(r);
  AGraphics.BitmapContainer := BitmapContainer;
  DrawTabs(AGraphics);
  DrawBadges(AGraphics);
  DrawMenu(AGraphics);
  AGraphics.RestoreState(st);
end;

procedure TTMSFNCCustomTabSet.DrawBadges(AGraphics: TTMSFNCGraphics);
var
  I: Integer;
  tbd: TTMSFNCTabSetDisplayTab;
  tb: TTMSFNCTabSetTab;
begin
  for I := GetDisplayCount - 1 downto 0 do
  begin
    tbd := FDisplayList.Items[I];
    tb := tbd.Tab;
    if Assigned(tb) then
      tb.DrawBadge(AGraphics, tbd);
  end;
end;

procedure TTMSFNCCustomTabSet.DrawCustom(AGraphics: TTMSFNCGraphics);
begin

end;

procedure TTMSFNCCustomTabSet.DrawMenu(AGraphics: TTMSFNCGraphics);
var
  r: TRectF;
  bmp: TTMSFNCBitmap;
  c: Boolean;
begin
  if FShowTabListButton then
  begin
    case FTabListButtonState of
      tbsNormal:
      begin
        AGraphics.Fill.Assign(ButtonAppearance.Fill);
        AGraphics.Stroke.Assign(ButtonAppearance.Stroke);
      end;
      tbsHover:
      begin
        AGraphics.Fill.Assign(ButtonAppearance.HoverFill);
        AGraphics.Stroke.Assign(ButtonAppearance.HoverStroke);
      end;
      tbsDown:
      begin
        AGraphics.Fill.Assign(ButtonAppearance.DownFill);
        AGraphics.Stroke.Assign(ButtonAppearance.DownStroke);
      end;
    end;

    r := GetTabListButtonRect;
    c := True;
    DoBeforeDrawMenuButton(AGraphics, r, tmbTabList, FTabListButtonState, c);
    if c then
    begin
      AGraphics.DrawRoundRectangle(r, ButtonAppearance.Rounding);
      bmp := ButtonAppearance.TabListIcon;
      if Assigned(bmp) then
        AGraphics.DrawBitmap(r, bmp);
      DoAfterDrawMenuButton(AGraphics, r, tmbTabList, FTabListButtonState);
    end;
  end;

  if FShowCloseButton then
  begin
    case FCloseButtonState of
      tbsNormal:
      begin
        AGraphics.Fill.Assign(ButtonAppearance.Fill);
        AGraphics.Stroke.Assign(ButtonAppearance.Stroke);
      end;
      tbsHover:
      begin
        AGraphics.Fill.Assign(ButtonAppearance.HoverFill);
        AGraphics.Stroke.Assign(ButtonAppearance.HoverStroke);
      end;
      tbsDown:
      begin
        AGraphics.Fill.Assign(ButtonAppearance.DownFill);
        AGraphics.Stroke.Assign(ButtonAppearance.DownStroke);
      end;
    end;

    r := GetCloseButtonRect;
    c := True;
    DoBeforeDrawMenuButton(AGraphics, r, tmbClose, FCloseButtonState, c);
    if c then
    begin
      AGraphics.DrawRoundRectangle(r, ButtonAppearance.Rounding);
      bmp := ButtonAppearance.CloseIcon;
      if Assigned(bmp) then
        AGraphics.DrawBitmap(r, bmp);
      DoAfterDrawMenuButton(AGraphics, r, tmbClose, FCloseButtonState);
    end;
  end;

  if FShowInsertButton then
  begin
    case FInsertButtonState of
      tbsNormal:
      begin
        AGraphics.Fill.Assign(ButtonAppearance.Fill);
        AGraphics.Stroke.Assign(ButtonAppearance.Stroke);
      end;
      tbsHover:
      begin
        AGraphics.Fill.Assign(ButtonAppearance.HoverFill);
        AGraphics.Stroke.Assign(ButtonAppearance.HoverStroke);
      end;
      tbsDown:
      begin
        AGraphics.Fill.Assign(ButtonAppearance.DownFill);
        AGraphics.Stroke.Assign(ButtonAppearance.DownStroke);
      end;
    end;

    r := GetInsertButtonRect;
    c := True;
    DoBeforeDrawMenuButton(AGraphics, r, tmbInsert, FInsertButtonState, c);
    if c then
    begin
      AGraphics.DrawRoundRectangle(r, ButtonAppearance.Rounding);
      bmp := ButtonAppearance.InsertIcon;
      if Assigned(bmp) then
        AGraphics.DrawBitmap(r, bmp);
      DoAfterDrawMenuButton(AGraphics, r, tmbInsert, FInsertButtonState);
    end;
  end;

  if FShowScrollButtons then
  begin
    if not FScrollButtonPreviousDisabled then
    begin
      case FScrollButtonPreviousState of
        tbsNormal:
        begin
          AGraphics.Fill.Assign(ButtonAppearance.Fill);
          AGraphics.Stroke.Assign(ButtonAppearance.Stroke);
        end;
        tbsHover:
        begin
          AGraphics.Fill.Assign(ButtonAppearance.HoverFill);
          AGraphics.Stroke.Assign(ButtonAppearance.HoverStroke);
        end;
        tbsDown:
        begin
          AGraphics.Fill.Assign(ButtonAppearance.DownFill);
          AGraphics.Stroke.Assign(ButtonAppearance.DownStroke);
        end;
      end;
    end
    else
    begin
      AGraphics.Fill.Assign(ButtonAppearance.DisabledFill);
      AGraphics.Stroke.Assign(ButtonAppearance.DisabledStroke);
    end;

    r := GetScrollButtonPreviousRect;
    c := True;
    if FScrollButtonPreviousDisabled then
      DoBeforeDrawMenuButton(AGraphics, r, tmbScrollPrevious, tbsDisabled, c)
    else
      DoBeforeDrawMenuButton(AGraphics, r, tmbScrollPrevious, FScrollButtonPreviousState, c);

    if c then
    begin
      AGraphics.DrawRoundRectangle(r, ButtonAppearance.Rounding);
      bmp := ButtonAppearance.ScrollPreviousIcon;
      if Assigned(bmp) then
        AGraphics.DrawBitmap(r, bmp);

      if FScrollButtonPreviousDisabled then
        DoAfterDrawMenuButton(AGraphics, r, tmbScrollPrevious, tbsDisabled)
      else
        DoAfterDrawMenuButton(AGraphics, r, tmbScrollPrevious, FScrollButtonPreviousState);
    end;

    if not FScrollButtonNextDisabled then
    begin
      case FScrollButtonNextState of
        tbsNormal:
        begin
          AGraphics.Fill.Assign(ButtonAppearance.Fill);
          AGraphics.Stroke.Assign(ButtonAppearance.Stroke);
        end;
        tbsHover:
        begin
          AGraphics.Fill.Assign(ButtonAppearance.HoverFill);
          AGraphics.Stroke.Assign(ButtonAppearance.HoverStroke);
        end;
        tbsDown:
        begin
          AGraphics.Fill.Assign(ButtonAppearance.DownFill);
          AGraphics.Stroke.Assign(ButtonAppearance.DownStroke);
        end;
      end;
    end
    else
    begin
      AGraphics.Fill.Assign(ButtonAppearance.DisabledFill);
      AGraphics.Stroke.Assign(ButtonAppearance.DisabledStroke);
    end;

    r := GetScrollButtonNextRect;
    c := True;
    if FScrollButtonNextDisabled then
      DoBeforeDrawMenuButton(AGraphics, r, tmbScrollNext, tbsDisabled, c)
    else
      DoBeforeDrawMenuButton(AGraphics, r, tmbScrollNext, FScrollButtonNextState, c);

    if c then
    begin
      AGraphics.DrawRoundRectangle(r, ButtonAppearance.Rounding);
      bmp := ButtonAppearance.ScrollNextIcon;
      if Assigned(bmp) then
        AGraphics.DrawBitmap(r, bmp);

      if FScrollButtonNextDisabled then
        DoAfterDrawMenuButton(AGraphics, r, tmbScrollNext, tbsDisabled)
      else
        DoAfterDrawMenuButton(AGraphics, r, tmbScrollNext, FScrollButtonNextState);
    end;
  end;
end;

procedure TTMSFNCCustomTabSet.DrawTabs(AGraphics: TTMSFNCGraphics);
var
  I: Integer;
  tbd, tbdsel: TTMSFNCTabSetDisplayTab;
  tb: TTMSFNCTabSetTab;
begin
  tbdsel := EmptyDisplayTab;

  for I := GetDisplayCount - 1 downto 0 do
  begin
    tbd := FDisplayList.Items[I];
    tb := tbd.Tab;
    if Assigned(tb) then
    begin
      if tb = FActiveTab then
        tbdsel := tbd
      else if tb = FDragTab then
        Continue
      else
        tb.Draw(AGraphics, tbd);
    end;
  end;

  if Assigned(FActiveTab) and (FActiveTab <> FDragTab) and Assigned(tbdsel.Tab) then
    FActiveTab.Draw(AGraphics, tbdsel);
  if Assigned(FDragTab) and Assigned(FDragTabDisplay.Tab) then
    FDragTab.Draw(AGraphics, FDragTabDisplay);
end;

procedure TTMSFNCCustomTabSet.EndUpdate;
begin
  inherited;
  Dec(FUpdateCount);
  if FUpdateCount = 0 then
    UpdateTabs;
end;

{$IFDEF FNCLIB}
{$IFNDEF WEBLIB}
function TTMSFNCCustomTabSet.ExecuteShortCutMethod(AShortCut: string): Boolean;
var
  tbd: TTMSFNCTabSetDisplayTab;
begin
  Result := False;
  tbd := FindDisplayTabWithShortCutHint(AShortCut);
  if Assigned(tbd.Tab) then
  begin
    ActiveTab := tbd.Tab;
    Result := True;
  end;
end;
{$ENDIF}
{$ENDIF}

procedure TTMSFNCCustomTabSet.FillTabList;
var
  I: Integer;
begin
  FTabList.Parent := Self;
  {$IFDEF FMXLIB}
  FTabList.BeginUpdate;
  {$ENDIF}
  FTabList.Items.Clear;
  for I := 0 to FInvisibleTabList.Count - 1 do
    FTabList.Items.AddObject(FInvisibleTabList[I].GetFriendlyName, FInvisibleTabList[I]);
  {$IFDEF FMXLIB}
  FTabList.EndUpdate;
  {$ENDIF}
  FTabList.Parent := nil;
end;

function TTMSFNCCustomTabSet.FindNextTab(ATabIndex: Integer;
  ACheckTabVisible: Boolean): Integer;
var
  i, j: Integer;
begin
  Result := -1;
  if (ATabIndex >= 0) and (ATabIndex < FTabs.Count) then
    Exit;

  i := ATabIndex;
  j := 1;
  while (j < FTabs.Count) do
  begin
    Inc(i);

    if (i >= FTabs.Count) then
      i := 0;

    if (ACheckTabVisible and IsTabVisible(I)) or not ACheckTabVisible then
    begin
      Result := i;
      Break;
    end;

    Inc(j);
  end;
end;

function TTMSFNCCustomTabSet.FindPreviousTab(ATabIndex: Integer;
  ACheckTabVisible: Boolean): Integer;
var
  i, j: Integer;
begin
  Result := -1;
  if (ATabIndex >= 0) and (ATabIndex < FTabs.Count) then
    Exit;

  i := ATabIndex;
  j := 1;
  while (j < FTabs.Count) do
  begin
    Dec(i);

    if (i >= FTabs.Count) then
      i := 0;

    if (i < 0) then
      i := FTabs.Count-1;

    if (ACheckTabVisible and IsTabVisible(I)) or not ACheckTabVisible then
    begin
      Result := i;
      Break;
    end;

    Inc(j);
  end;
end;

{$IFDEF FNCLIB}
{$IFNDEF WEBLIB}
function TTMSFNCCustomTabSet.FindDisplayTabWithShortCutHint(
  AShortCutHint: string): TTMSFNCTabSetDisplayTab;
var
  I: Integer;
  tbd: TTMSFNCTabSetDisplayTab;
begin
  Result := EmptyDisplayTab;
  for I := 0 to FDisplayList.Count - 1 do
  begin
    tbd := FDisplayList[I];
    if Assigned(tbd.Tab) then
    begin
      if tbd.Tab.ShortCutHint = AShortCutHint then
      begin
        Result := tbd;
        Break;
      end;
    end;
  end;
end;
{$ENDIF}
{$ENDIF}

procedure TTMSFNCCustomTabSet.FixStartTab;
var
  tbdfirst, tbdlast: TTMSFNCTabSetDisplayTab;
  news, sz: Single;
  tr: TRectF;
  i: Integer;
  trw: Single;
begin
  if (FDisplayList.Count > 0) and (FStartTab > 0) and (TabSize.Mode <> tsmFixedSizeAutoShrink) and FAllowScrolling and (Layout.Multiline = tlmNone) and (FScrollCount > 0)
    and (FLastTabIndex = GetLastScrollableTabIndex) then
  begin
    tbdlast := FDisplayList[FDisplayList.Count - 1];
    tbdfirst := FDisplayList[0];
    if Assigned(tbdlast.Tab) and Assigned(tbdfirst.Tab) then
    begin
      i := GetPreviousTab(tbdfirst.Tab.Index);
      if (i >= 0) and (i <= FTabs.Count - 1) then
      begin
        tr := GetTabRect;
        sz := GetTabSize(I);
        case Layout.Position of
          tlpTop, tlpBottom:
          begin
            news := tbdlast.Rect.Right + sz + TabSize.Spacing - TabAppearance.ShapeOverlap;
            if (Options.InsertMode = timTab) then
              news := news + GetAdditionalTabSpacing
            else
              news := news + 5;

           trw := tr.Right;
          end;
          tlpLeft, tlpRight:
          begin
            news := tbdlast.Rect.Bottom + sz + TabSize.Spacing - TabAppearance.ShapeOverlap;
            if (Options.InsertMode = timTab) then
              news := news + GetAdditionalTabSpacing
            else
              news := news + 5;

            trw := tr.Bottom;
          end;
          else
          begin
            news := 0;
            trw := 0;
          end;
        end;

        if (news < trw) then
        begin
          Dec(FStartTab);
          FStartTab := Max(0, FStartTab);
          UpdateTabs;
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomTabSet.FocusNextTab(ASelect: Boolean = False);
var
  i, j: Integer;
  fi: Integer;
begin
  if not Assigned(FFocusedTab) then
    Exit;

  fi := FFocusedTab.Index;
  i := fi;
  j := 1;
  while (j < FTabs.Count) do
  begin
    Inc(i);

    if (i >= FTabs.Count) then
      i := 0;

    if (fi <> i) and IsTabVisible(I) and IsTabEnabled(I) then
    begin
      fi := i;
      Break;
    end;

    Inc(j);
  end;

  if (fi >= 0) and (fi <= FTabs.Count - 1) then
  begin
    FFocusedTab := FTabs[fi];
    if ASelect then
      HandleChangeTab(FFocusedTab)
    else
      ScrollToTab(fi);
  end;
end;

procedure TTMSFNCCustomTabSet.FocusPreviousTab(ASelect: Boolean = False);
var
  i, j: Integer;
  fi: Integer;
begin
  if not Assigned(FFocusedTab) then
    Exit;

  fi := FFocusedTab.Index;
  i := fi;
  j := 1;
  while (j < FTabs.Count) do
  begin
    Dec(i);

    if (i >= FTabs.Count) then
      i := 0;

    if (i < 0) then
      i := FTabs.Count - 1;

    if (fi <> i) and IsTabVisible(I) and IsTabEnabled(I) then
    begin
      fi := i;
      Break;
    end;

    Inc(j);
  end;

  if (fi >= 0) and (fi <= FTabs.Count - 1) then
  begin
    FFocusedTab := FTabs[fi];
    if ASelect then
      HandleChangeTab(FFocusedTab)
    else
      ScrollToTab(fi);
  end;
end;

procedure TTMSFNCCustomTabSet.FocusTab(ATabIndex: Integer; ASelect: Boolean);
begin
  if (ATabIndex >= 0) and (ATabIndex <= FTabs.Count - 1) and IsTabVisible(ATabIndex) then
  begin
    FFocusedTab := FTabs[ATabIndex];
    if ASelect then
      HandleChangeTab(FFocusedTab)
    else
      ScrollToTab(ATabIndex);
  end;
end;

function TTMSFNCCustomTabSet.GetActiveTab: TTMSFNCTabSetTab;
begin
  Result := nil;
  if (FActiveTabIndex >= 0) and (FActiveTabIndex <= FTabs.Count - 1) then
    Result := FTabs[FActiveTabIndex];
end;

function TTMSFNCCustomTabSet.GetActiveTabIndex: Integer;
begin
  Result := FActiveTabIndex;
  if Assigned(FActiveTab) then
    Result := FActiveTab.Index;
end;

function TTMSFNCCustomTabSet.GetAdditionalTabSpacing: Single;
begin
  Result := 0;
  if Options.InsertMode = timTab then
    Result := Result + TabAppearance.InsertSize + 10;
end;

function TTMSFNCCustomTabSet.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := FBitmapContainer;
end;

function TTMSFNCCustomTabSet.GetButtonShapePath(
  ARect: TRectF): TTMSFNCGraphicsPath;
var
  r: TRectF;
  rnd: Single;
begin
  r := ARect;
  rnd := 5;
  Result := TTMSFNCGraphicsPath.Create;
  Result.AddArc(PointF(r.Left + rnd, r.Top + rnd), PointF(rnd, rnd), 180, 90);
  Result.AddArc(PointF(r.Right - rnd, r.Top + rnd), PointF(rnd, rnd), -90, 90);
  Result.AddArc(PointF(r.Right - rnd, r.Bottom - rnd), PointF(rnd, rnd), 0, 90);
  Result.AddArc(PointF(r.Left + rnd, r.Bottom - rnd), PointF(rnd, rnd), -270, 90);
  Result.ClosePath;
end;

function TTMSFNCCustomTabSet.GetCloseButtonRect: TRectF;
var
  mnr: TRectF;
  sz, spc: Single;
begin
  mnr := GetTabListButtonRect;
  sz := ButtonAppearance.Size;
  if FShowTabListButton then
    spc := ScalePaintValue(6)
  else
    spc := ScalePaintValue(3);

  case Layout.Position of
    tlpTop, tlpBottom:
    begin
      if FShowCloseButton then
        Result := RectF(mnr.Left - spc - sz, mnr.Top + ((mnr.Bottom - mnr.Top) - sz) / 2, mnr.Left - spc, mnr.Top + ((mnr.Bottom - mnr.Top) - sz) / 2 + sz)
      else
        Result := RectF(mnr.Left, mnr.Top, mnr.Left, mnr.Bottom);
    end;
    tlpLeft, tlpRight:
    begin
      if FShowCloseButton then
        Result := RectF(mnr.Left + ((mnr.Right - mnr.Left) - sz) / 2, mnr.Top - spc - sz, mnr.Left + ((mnr.Right - mnr.Left) - sz) / 2 + sz, mnr.Top - spc)
      else
        Result := RectF(mnr.Left, mnr.Top, mnr.Right, mnr.Top);
    end;
  end;
end;

function TTMSFNCCustomTabSet.GetDisplayCount: Integer;
begin
  Result := FDisplayList.Count;
end;

function TTMSFNCCustomTabSet.GetDisplayTab(
  ATabIndex: Integer): TTMSFNCTabSetDisplayTab;
var
  I: Integer;
  tbd: TTMSFNCTabSetDisplayTab;
begin
  Result := EmptyDisplayTab;
  for I := 0 to GetNormalDisplayCount - 1 do
  begin
    tbd := FDisplayList[I];
    if Assigned(tbd.Tab) and (tbd.Tab.Index = ATabIndex) then
    begin
      Result := tbd;
      Break;
    end;
  end;
end;

function TTMSFNCCustomTabSet.GetDocURL: string;
begin
  Result := TTMSFNCBaseDocURL + 'tmsfncuipack/components/' + LowerCase(ClassName);
end;

function TTMSFNCCustomTabSet.GetFirstScrollableTabIndex: Integer;
var
  I: Integer;
begin
  Result := 0;
  for I := 0 to FTabs.Count - 1 do
  begin
    if IsTabVisible(I) and IsTabEnabled(I) then
    begin
      Result := I;
      Break;
    end;
  end;
end;

function TTMSFNCCustomTabSet.GetHintString: string;
begin
  Result := inherited GetHintString;
  if Assigned(FHoverTab) then
    Result := FHoverTab.Hint;
end;

function TTMSFNCCustomTabSet.GetInplaceEditorRect(
  ATab: TTMSFNCTabSetDisplayTab): TRectF;
var
  r: TRectF;
begin
  Result := RectF(0, 0, 0, 0);
  if not Assigned(ATab.Tab) then
    Exit;

  r := ATab.TextRect;
  InflateRectEx(r, 2, 0);
  Result := RectF(Floor(r.Left), Floor(r.Top), Round(r.Right), Round(r.Bottom));

  if Assigned(OnGetInplaceEditorRect) then
    OnGetInplaceEditorRect(Self, ATab.Tab.Index, FInplaceEditor, Result);
end;

function TTMSFNCCustomTabSet.GetInsertButtonRect: TRectF;
var
  mnr: TRectF;
  sz, spc: Single;
begin
  mnr := GetCloseButtonRect;
  sz := ButtonAppearance.Size;
  if FShowTabListButton or FShowCloseButton then
    spc := 6
  else
    spc := 3;

  case Layout.Position of
    tlpTop, tlpBottom:
    begin
      if FShowInsertButton then
        Result := RectF(mnr.Left - spc - sz, mnr.Top + ((mnr.Bottom - mnr.Top) - sz) / 2, mnr.Left - spc, mnr.Top + ((mnr.Bottom - mnr.Top) - sz) / 2 + sz)
      else
        Result := RectF(mnr.Left, mnr.Top, mnr.Left, mnr.Bottom);
    end;
    tlpLeft, tlpRight:
    begin
      if FShowInsertButton then
        Result := RectF(mnr.Left + ((mnr.Right - mnr.Left) - sz) / 2, mnr.Top - spc - sz, mnr.Left + ((mnr.Right - mnr.Left) - sz) / 2 + sz, mnr.Top - spc)
      else
        Result := RectF(mnr.Left, mnr.Top, mnr.Right, mnr.Top);
    end;
  end;
end;

function TTMSFNCCustomTabSet.GetInvisibleTabCount: Integer;
var
  I: Integer;
begin
  Result := 0;
  for I := 0 to Tabs.Count - 1 do
  begin
    if not IsTabVisible(I) then
      Inc(Result);
  end;
end;

function TTMSFNCCustomTabSet.GetInvisibleTabCountForScrolling: Integer;
var
  I: Integer;
begin
  Result := 0;
  for I := FStartTab to FLastTabIndex do
  begin
    if not IsTabVisible(I) then
      Inc(Result);
  end;
end;

function TTMSFNCCustomTabSet.GetItemClass: TClass;
begin
  FPersistence := True;
  Result := GetTabClass;
end;

function TTMSFNCCustomTabSet.GetLastScrollableTabIndex: Integer;
var
  I: Integer;
begin
  Result := 0;
  for I := FTabs.Count - 1 downto 0 do
  begin
    if IsTabVisible(I) and IsTabEnabled(I) then
    begin
      Result := I;
      Break;
    end;
  end;
end;

function TTMSFNCCustomTabSet.GetMenuRect: TRectF;
var
  r: TRectF;
  sz: Single;
begin
  Result := GetTabRect;
  r := Result;
  sz := FMenuSize;
  case Layout.Position of
    tlpTop, tlpBottom:
    begin
      Result.Left := r.Right;
      Result.Top := r.Top;
      Result.Right := r.Right + sz;
      Result.Bottom := r.Bottom;
    end;
    tlpLeft, tlpRight:
    begin
      Result.Left := r.Left;
      Result.Top := r.Bottom;
      Result.Right := r.Right;
      Result.Bottom := r.Bottom + sz;
    end;
  end;
end;

function TTMSFNCCustomTabSet.GetNextTab(ATabIndex: Integer): Integer;
var
  i, j: Integer;
begin
  Result := ATabIndex;
  if (ATabIndex < 0) then
    Exit;

  i := ATabIndex;
  j := 1;
  while (j < FTabs.Count) do
  begin
    Inc(i);

    if (i >= FTabs.Count) then
      i := 0;

    if (ATabIndex <> i) and IsTabVisible(I) and IsTabEnabled(I) then
    begin
      Result := i;
      Break;
    end;

    Inc(j);
  end;
end;

function TTMSFNCCustomTabSet.GetNormalDisplayCount: Integer;
begin
  Result := GetDisplayCount;
  if Options.InsertMode = timTab then
    Dec(Result);
end;

function TTMSFNCCustomTabSet.GetPreviousTab(ATabIndex: Integer): Integer;
var
  i, j: Integer;
begin
  Result := ATabIndex;
  if (ATabIndex < 0) then
    Exit;

  i := ATabIndex;
  j := 1;
  while (j < FTabs.Count) do
  begin
    Dec(i);

    if (i >= FTabs.Count) then
      i := 0;

    if (i < 0) then
      i := FTabs.Count - 1;

    if (ATabIndex <> i) and IsTabVisible(I) and IsTabEnabled(I) then
    begin
      Result := i;
      Break;
    end;

    Inc(j);
  end;
end;

function TTMSFNCCustomTabSet.GetScrollButtonNextRect: TRectF;
var
  mnr: TRectF;
  sz, spc: Single;
begin
  mnr := GetInsertButtonRect;
  sz := ButtonAppearance.Size;
  if FShowTabListButton or FShowCloseButton or FShowInsertButton then
    spc := ScalePaintValue(6)
  else
    spc := ScalePaintValue(3);

  case Layout.Position of
    tlpTop, tlpBottom:
    begin
      if FShowScrollButtons then
        Result := RectF(mnr.Left - spc - sz, mnr.Top + ((mnr.Bottom - mnr.Top) - sz) / 2, mnr.Left - spc, mnr.Top + ((mnr.Bottom - mnr.Top) - sz) / 2 + sz)
      else
        Result := RectF(mnr.Left, mnr.Top, mnr.Left, mnr.Bottom);
    end;
    tlpLeft, tlpRight:
    begin
      if FShowScrollButtons then
        Result := RectF(mnr.Left + ((mnr.Right - mnr.Left) - sz) / 2, mnr.Top - spc - sz, mnr.Left + ((mnr.Right - mnr.Left) - sz) / 2 + sz, mnr.Top - spc)
      else
        Result := RectF(mnr.Left, mnr.Top, mnr.Right, mnr.Top);
    end;
  end;
end;

function TTMSFNCCustomTabSet.GetScrollButtonPreviousRect: TRectF;
var
  mnr: TRectF;
  sz, spc: Single;
begin
  mnr := GetScrollButtonNextRect;
  sz := ButtonAppearance.Size;
  if FShowScrollButtons then
    spc := ScalePaintValue(6)
  else
    spc := ScalePaintValue(3);

  case Layout.Position of
    tlpTop, tlpBottom:
    begin
      if FShowScrollButtons then
        Result := RectF(mnr.Left - spc - sz, mnr.Top + ((mnr.Bottom - mnr.Top) - sz) / 2, mnr.Left - spc, mnr.Top + ((mnr.Bottom - mnr.Top) - sz) / 2 + sz)
      else
        Result := RectF(mnr.Left, mnr.Top, mnr.Left, mnr.Bottom);
    end;
    tlpLeft, tlpRight:
    begin
      if FShowScrollButtons then
        Result := RectF(mnr.Left + ((mnr.Right - mnr.Left) - sz) / 2, mnr.Top - spc - sz, mnr.Left + ((mnr.Right - mnr.Left) - sz) / 2 + sz, mnr.Top - spc)
      else
        Result := RectF(mnr.Left, mnr.Top, mnr.Right, mnr.Top);
    end;
  end;
end;

{$IFDEF FNCLIB}
{$IFNDEF WEBLIB}
procedure TTMSFNCCustomTabSet.GetShortCutHints(AShortCutHints: TStringList);
var
  I: Integer;
  tbd: TTMSFNCTabSetDisplayTab;
begin
  inherited;
  for I := 0 to Tabs.Count - 1 do
  begin
    tbd := GetDisplayTab(I);
    if Assigned(tbd.Tab) then
      AShortCutHints.Add(tbd.Tab.ShortCutHint);
  end;
end;
{$ENDIF}
{$ENDIF}

function TTMSFNCCustomTabSet.GetTabListButtonRect: TRectF;
var
  mnr: TRectF;
  sz, spc: Single;
begin
  mnr := GetMenuRect;
  sz := ButtonAppearance.Size;
  spc := ScalePaintValue(3);
  case Layout.Position of
    tlpTop, tlpBottom:
    begin
      if FShowTabListButton then
        Result := RectF(mnr.Right - spc - sz, mnr.Top + ((mnr.Bottom - mnr.Top) - sz) / 2, mnr.Right - spc, mnr.Top + ((mnr.Bottom - mnr.Top) - sz) / 2 + sz)
      else
        Result := RectF(mnr.Right, mnr.Top, mnr.Right, mnr.Bottom);
    end;
    tlpLeft, tlpRight:
    begin
      if FShowTabListButton then
        Result := RectF(mnr.Left + ((mnr.Right - mnr.Left) - sz) / 2, mnr.Bottom - spc - sz, mnr.Left + ((mnr.Right - mnr.Left) - sz) / 2 + sz, mnr.Bottom - spc)
      else
        Result := RectF(mnr.Left, mnr.Bottom, mnr.Right, mnr.Bottom);
    end;
  end;
end;

function TTMSFNCCustomTabSet.GetTabRect: TRectF;
var
  r: TRectF;
  sz: Single;
begin
  Result := LocalRect;
  r := Result;
  sz := FMenuSize;
  case Layout.Position of
    tlpLeft:
    begin
      Result.Left := r.Left + TabSize.Margins.Left + 1;
      Result.Top := r.Top + TabSize.Margins.Top;
      Result.Right := r.Left + TabSize.Margins.Left + TabSize.Height;
      if FShowMenu then
        Result.Bottom := r.Bottom - TabSize.Margins.Bottom - sz
      else
        Result.Bottom := r.Bottom - TabSize.Margins.Bottom;
    end;
    tlpTop:
    begin
      Result.Left := r.Left + TabSize.Margins.Left;
      Result.Top := r.Top + TabSize.Margins.Top + 1;
      if FShowMenu then
        Result.Right := r.Right - TabSize.Margins.Right - sz
      else
        Result.Right := r.Right - TabSize.Margins.Right;

      Result.Bottom := r.Top + TabSize.Margins.Top + TabSize.Height;
    end;
    tlpBottom:
    begin
      Result.Left := r.Left + TabSize.Margins.Left;
      Result.Top := r.Bottom - TabSize.Margins.Bottom - TabSize.Height;
      if FShowMenu then
        Result.Right := r.Right - TabSize.Margins.Right - sz
      else
        Result.Right := r.Right - TabSize.Margins.Right;
      Result.Bottom := r.Bottom - TabSize.Margins.Bottom - 1;
    end;
    tlpRight:
    begin
      Result.Left := r.Right - TabSize.Margins.Right - TabSize.Height;
      Result.Top := r.Top + TabSize.Margins.Top;
      Result.Right := r.Right - TabSize.Margins.Right - 1;
      if FShowMenu then
        Result.Bottom := r.Bottom - TabSize.Margins.Bottom - sz
      else
        Result.Bottom := r.Bottom - TabSize.Margins.Bottom;
    end;
  end;
end;

function TTMSFNCCustomTabSet.GetTabSize(ATabIndex: Integer): Single;
begin
  Result := 0;
  if (ATabIndex = GetTotalTabCount - 1) and (Options.InsertMode = timTab) then
    Result := TabAppearance.InsertSize
  else if (ATabIndex >= 0) and (ATabIndex <= FTabs.Count - 1) then
  begin
    case TabSize.Mode of
      tsmAutoTabSize:
      begin
        Result := Tabs[ATabIndex].GetAutoSize;
        if TabAppearance.Shape in [tsPyramidLeft, tsPyramidRight, tsPyramid] then
          Result := Result + TabAppearance.ShapeSlope * 2
        else if TabAppearance.Shape in [tsRoundLeft, tsRoundRight, tsRound] then
          Result := Result + TabAppearance.ShapeSlope
        else
          Result := Result + TabAppearance.ShapeOverlap * 2;

        Result := Result + TabAppearance.TextSpacing;
      end;
      tsmFixedSize, tsmFixedSizeAutoShrink:
      begin
        if Tabs[ATabIndex].Width > -1 then
          Result := Tabs[ATabIndex].Width
        else
          Result := TabSize.Width;
      end;
    end;
  end;
end;

function TTMSFNCCustomTabSet.GetTotalTabCount: Integer;
begin
  Result := Tabs.Count;
  if Options.InsertMode = timTab then
    Result := Result + 1;
end;

function TTMSFNCCustomTabSet.GetTotalTabHeight: Single;
begin
  Result := FTotalTabHeight;
end;

function TTMSFNCCustomTabSet.GetInnerTabRect: TRectF;
var
  I: Integer;
  tbd: TTMSFNCTabSetDisplayTab;
  tb: TTMSFNCTabSetTab;
  r: TRectF;
begin
  Result := LocalRect;
  r := Result;
  case Layout.Position of
    tlpLeft:
    begin
      Result.Left := r.Left + TabSize.Margins.Left + 1;
      Result.Top := r.Top + TabSize.Margins.Top;
      Result.Right := r.Left + TabSize.Margins.Left + TabSize.Height;
      Result.Bottom := Result.Top;
    end;
    tlpTop:
    begin
      Result.Left := r.Left + TabSize.Margins.Left;
      Result.Top := r.Top + TabSize.Margins.Top + 1;
      Result.Bottom := r.Top + TabSize.Margins.Top + TabSize.Height;
      Result.Right := Result.Left;
    end;
    tlpBottom:
    begin
      Result.Left := r.Left + TabSize.Margins.Left;
      Result.Top := r.Bottom - TabSize.Margins.Bottom - TabSize.Height;
      Result.Bottom := r.Bottom - TabSize.Margins.Bottom - 1;
      Result.Right := Result.Left;
    end;
    tlpRight:
    begin
      Result.Left := r.Right - TabSize.Margins.Right - TabSize.Height;
      Result.Top := r.Top + TabSize.Margins.Top;
      Result.Right := r.Right - TabSize.Margins.Right - 1;
      Result.Bottom := Result.Top;
    end;
  end;

  for I := GetDisplayCount - 1 downto 0 do
  begin
    tbd := FDisplayList.Items[I];
    tb := tbd.Tab;
    if Assigned(tb) then
    begin
      r := tb.GetFixedRect(tbd.Rect, tbd);
      case Layout.Position of
        tlpTop, tlpBottom:
        begin
          if r.Right > Result.Right then
            Result.Right := r.Right;
        end;
        tlpLeft, tlpRight:
        begin
          if r.Bottom > Result.Bottom then
            Result.Bottom := r.Bottom;
        end;
      end;
    end;
  end;
end;

function TTMSFNCCustomTabSet.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

function TTMSFNCCustomTabSet.GetVisibleTabCount: Integer;
var
  I: Integer;
begin
  Result := 0;
  for I := 0 to Tabs.Count - 1 do
  begin
    if IsTabVisible(I) then
      Inc(Result);
  end;
end;

procedure TTMSFNCCustomTabSet.HandleButtonDownState(X, Y: Single);
begin
  if XYToScrollPreviousButton(X, Y) then
  begin
    FScrollButtonPreviousState := tbsDown;
    Exit;
  end;

  if XYToScrollNextButton(X, Y) then
  begin
    FScrollButtonNextState := tbsDown;
    Exit;
  end;

  if XYToCloseButton(X, Y) then
  begin
    FCloseButtonState := tbsDown;
    Exit;
  end;

  if XYToInsertButton(X, Y) then
  begin
    FInsertButtonState := tbsDown;
    Exit;
  end;

  if XYToTabListButton(X, Y) then
  begin
    FTabListButtonState := tbsDown;
    Exit;
  end;
end;

procedure TTMSFNCCustomTabSet.HandleButtonHoverState(X, Y: Single);
begin
  if XYToScrollPreviousButton(X, Y) then
  begin
    FScrollButtonPreviousState := tbsHover;
    Exit;
  end;

  if XYToScrollNextButton(X, Y) then
  begin
    FScrollButtonNextState := tbsHover;
    Exit;
  end;

  if XYToCloseButton(X, Y) then
  begin
    FCloseButtonState := tbsHover;
    Exit;
  end;

  if XYToInsertButton(X, Y) then
  begin
    FInsertButtonState := tbsHover;
    Exit;
  end;

  if XYToTabListButton(X, Y) then
  begin
    FTabListButtonState := tbsHover;
    Exit;
  end;
end;

procedure TTMSFNCCustomTabSet.HandleChangeTab(ATab: TTMSFNCTabSetTab);
var
  b: Boolean;
  i, it: Integer;
begin
  FPrevTab := FActiveTab;
  if (FPrevTab <> ATab) and ((Assigned(ATab) and IsTabVisible(ATab.Index) and IsTabEnabled(ATab.Index)) or not Assigned(ATab)) then
  begin
    if Assigned(FPrevTab) then
      i := FPrevTab.Index
    else
      i := -1;

    if Assigned(ATab) then
      it := ATab.Index
    else
      it := -1;

    b := True;
    DoBeforeChangeTab(i, it, b);
    if b then
    begin
      FActiveTab := ATab;
      FFocusedTab := ATab;
      FActiveTabIndex := it;
      FTabChanging := True;
      DoChangeTab(i, it);
      FTabChanging := False;
      if (Layout.Multiline = tlmEnabledActiveTab) and (ActiveTabIndex <> -1) then
        UpdateTabs
      else
        ScrollToTab(FActiveTabIndex);
    end;
  end;
end;

procedure TTMSFNCCustomTabSet.HandleCloseTab(ATab: TTMSFNCTabSetTab; var AChanged: Boolean);
var
  ca: TTMSFNCTabSetTabCloseAction;
  I: Integer;
  dochange, b: Boolean;
  tb: TTMSFNCTabSetTab;
  idx: Integer;
begin
  if not Assigned(ATab) then
    Exit;

  ca := Options.CloseAction;
  idx := ATab.Index;
  DoBeforeCloseTab(ATab.Index, ca);
  if ca <> ttcaNone then
  begin
    case ca of
      ttcaFree:
      begin
        i := ATab.Index;
        doChange := I = FActiveTabIndex;
        Inc(FUpdateCount);
        ATab.Free;
        AChanged := True;
        Dec(FUpdateCount);
        if I <= FActiveTabIndex then
          FActiveTabIndex := Max(0, FActiveTabIndex - 1);

        if (FActiveTabIndex >= 0) and (FActiveTabIndex <= FTabs.Count - 1) then
        begin
          if not IsTabEnabled(FActiveTabIndex) or not IsTabVisible(FActiveTabIndex) then
            SelectNextSequentialTab;
        end
        else
        begin
          FActiveTabIndex := -1;
          FActiveTab := nil;
          FFocusedTab := nil;
        end;

        if dochange then
        begin
          b := True;
          if (FActiveTabIndex >= 0) and (FActiveTabIndex <= FTabs.Count - 1) then
          begin
            tb := FTabs[FActiveTabIndex];
            if tb.Enabled and tb.Visible then
            begin
              DoBeforeChangeTab(i, tb.Index, b);
              if b then
              begin
                FActiveTab := tb;
                FActiveTabIndex := FActiveTab.Index;
                FFocusedTab := FActiveTab;
                FTabChanging := True;
                DoChangeTab(i, FActiveTab.Index);
                FTabChanging := False;
              end;
            end
            else
            begin
              FTabChanging := True;
              DoChangeTab(i, FActiveTabIndex);
              FTabChanging := False;
            end;
          end;
        end;
      end;
      ttcaHide:
      begin
        ATab.Visible := False;
        if FActiveTabIndex = ATab.Index then
        begin
          SelectNextSequentialTab;
          if (FActiveTabIndex >= 0) and (FActiveTabIndex <= FTabs.Count - 1) and (GetNormalDisplayCount > 0) then
            HandleChangeTab(FTabs[FActiveTabIndex])
          else
            HandleChangeTab(nil);
        end;

        AChanged := True;
      end;
    end;
    DoCloseTab(idx, ca);
  end;
end;

procedure TTMSFNCCustomTabSet.HandleDblClick(X, Y: Single);
begin
  inherited;
  FDblClicked := True;
end;

function TTMSFNCCustomTabSet.HandleDesignHitTest(const APoint: TPoint): Boolean;
var
  tb: TTMSFNCTabSetDisplayTab;
begin
  Result := inherited HandleDesignHitTest(APoint);
  tb := XYToTab(APoint.X, APoint.Y);
  if Assigned(tb.Tab) then
    Result := True;
end;

procedure TTMSFNCCustomTabSet.HandleDialogKey(var Key: Word;
  Shift: TShiftState);
begin
  if FInplaceEditorClosed then
  begin
    inherited;
    Exit;
  end;

  if Assigned(FInplaceEditor) and FInplaceEditorActive and ((Key = KEY_ESCAPE) or (Key = KEY_TAB) or (Key = KEY_F2)
    or ((FInplaceEditor is TEdit) and (Key = KEY_RETURN))) then
  begin
    FCloseWithDialogKey := True;
    CloseInplaceEditor(Key = KEY_ESCAPE);
  end;

  inherited;
end;

procedure TTMSFNCCustomTabSet.HandleDragTab(ATab, ADropTab: TTMSFNCTabSetTab);
var
  c: Boolean;
  pri: Integer;
begin
  if Assigned(ATab) and Assigned(ADropTab) then
  begin
    c := True;
    DoBeforeReorderTab(ATab.Index, ADropTab.Index, c);
    if c then
    begin
      pri := FDragTab.Index;
      ATab.Index := ADropTab.Index;
      if Assigned(FActiveTab) then
        FActiveTabIndex := FActiveTab.Index;

      DoReorderTab(pri, ATab.Index);
    end;
  end;
end;

function TTMSFNCCustomTabSet.HandleInsertTab(var AChanged: Boolean): TTMSFNCTabSetTab;
var
  idx: Integer;
  b: Boolean;
begin
  Result := nil;
  idx := Tabs.Count;
  b := True;
  DoBeforeInsertTab(idx, b);
  if b then
  begin
    Result := Tabs.Add;
    Result.Index := idx;
    DoInsertTab(idx);
    AChanged := True;
  end;
end;

procedure TTMSFNCCustomTabSet.HandleKeyDown(var Key: Word; Shift: TShiftState);
var
  i: Integer;
begin
  inherited;
  if FInplaceEditorClosed then
    Exit;

  case Key of
    KEY_LEFT, KEY_UP: FocusPreviousTab(Interaction.SelectTabOnFocus);
    KEY_RIGHT, KEY_DOWN: FocusNextTab(Interaction.SelectTabOnFocus);
    KEY_HOME:
    begin
      i := GetFirstScrollableTabIndex;
      if IsTabVisible(I) and IsTabEnabled(I) then
        FocusTab(i, Interaction.SelectTabOnFocus)
      else
      begin
        if (I >= 0) and (i < FTabs.Count - 1) then
          FFocusedTab := FTabs[i];

        FocusNextTab(Interaction.SelectTabOnFocus);
      end;
    end;
    KEY_END:
    begin
      i := GetLastScrollableTabIndex;
      if IsTabVisible(I) and IsTabEnabled(I) then
        FocusTab(i, Interaction.SelectTabOnFocus)
      else
      begin
        if (I >= 0) and (i < FTabs.Count - 1) then
          FFocusedTab := FTabs[i];
        FocusPreviousTab(Interaction.SelectTabOnFocus);
      end;
    end;
  end;
end;

procedure TTMSFNCCustomTabSet.HandleKeyUp(var Key: Word; Shift: TShiftState);
var
  tbd: TTMSFNCTabSetDisplayTab;
  d, c: Boolean;
  ins: TTMSFNCTabSetTab;
begin
  inherited;
  if FInplaceEditorClosed then
  begin
    FInplaceEditorClosed := False;
    Exit;
  end;

  if FCloseWithDialogKey then
  begin
    FCloseWithDialogKey := False;
    Exit;
  end;

  c := False;
  d := False;
  ins := nil;

  case Key of
    KEY_RETURN, KEY_SPACE:
    begin
      if not Interaction.SelectTabOnFocus and Assigned(FFocusedTab) and (FFocusedTab <> FActiveTab) then
      begin
        HandleChangeTab(FFocusedTab);
        d := True;
      end;
    end;
    KEY_INSERT:
    begin
      if Interaction.InsertTabWithKeyboard then
        ins := HandleInsertTab(c);
    end;
  end;

  if Assigned(FFocusedTab) and not d then
  begin
    case Key of
      KEY_DELETE:
      begin
        if Interaction.CloseTabWithKeyboard then
          HandleCloseTab(FFocusedTab, c);
      end;

      KEY_F2, KEY_RETURN:
      begin
        if IsTabEnabled(FFocusedTab.Index) and Interaction.Editing then
        begin
          tbd := GetDisplayTab(FFocusedTab.Index);
          HandleTabEditing(tbd);
        end;
      end;
    end;
  end;

  if c then
    UpdateTabs;

  if Assigned(ins) and Interaction.SelectTabOnInsert then
    HandleChangeTab(ins)
  else if not c then
    Invalidate;
end;

procedure TTMSFNCCustomTabSet.HandleMouseDown(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
var
  tbd, tbdc: TTMSFNCTabSetDisplayTab;
begin
  inherited;

  if Button = {$IFNDEF WEBLIB}TTMSFNCMouseButton.{$ENDIF}mbRight then
    Exit;

  FDownX := X;
  FDownY := Y;
  FDownCount := 0;
  FDragDownX := X;
  FDragDownY := Y;

  CaptureEx;

  if not FDblClicked then
  begin
    if CanFocus then
      SetFocus;

    if FInplaceEditorActive then
    begin
      CloseInplaceEditor(False);
      if FInplaceEditorActive then
        Exit;
    end;
  end;

  FDblClicked := False;
  tbd := XYToTab(X, Y);
  tbdc := XYToCloseTab(X, Y);
  if Assigned(tbdc.Tab) then
    FDownCloseTab := tbdc.Tab
  else if Assigned(tbd.Tab) and not (XYToScrollNextButton(X, Y) or XYToScrollPreviousButton(X, Y)) then
  begin
    FTabAnchor := XYToAnchor(tbd, X, Y);
    if (FTabAnchor = '') and tbd.Tab.Enabled then
    begin
      FDownTab := tbd.Tab;
      FDragTabDisplay := GetDisplayTab(FDownTab.Index);
      FOrigRect := FDragTabDisplay.Rect;
    end;
  end
  else
  begin
    SaveButtonState;
    ClearButtonState;
    HandleButtonDownState(X, Y);
    if IsButtonStateDown then
      StartDownTimer;
  end;

  Invalidate;
end;

procedure TTMSFNCCustomTabSet.HandleMouseEnter;
begin
  inherited;
  Invalidate;
end;

procedure TTMSFNCCustomTabSet.HandleMouseLeave;
begin
  inherited;
  ClearButtonState;
  FHoverTab := nil;
  FDownTab := nil;
  FHoverCloseTab := nil;
  FDownCloseTab := nil;
  Invalidate;
end;

procedure TTMSFNCCustomTabSet.HandleMouseMove(Shift: TShiftState; X, Y: Single);
var
  tbd, tbdd: TTMSFNCTabSetDisplayTab;
  off: Single;
  b: Boolean;
  r: TRectF;
begin
  inherited;
  if not Assigned(FDownTab) and not Assigned(FDownCloseTab) then
  begin
    tbd := XYToTab(X, Y);
    FPrevHoverTab := FHoverTab;
    FHoverTab := tbd.Tab;
    if Assigned(FHoverTab) and not (XYToScrollNextButton(X, Y) or XYToScrollPreviousButton(X, Y)) then
    begin
      tbdd := XYToCloseTab(X, Y);
      FPrevHoverCloseTab := FHoverCloseTab;
      FHoverCloseTab := tbdd.Tab;

      if not Assigned(FHoverCloseTab) then
      begin
        if (XYToAnchor(tbd, x, y) <> '') and FHoverTab.IsHTML then
          Cursor := crHandPoint
        else
          Cursor := crDefault;
      end;
    end
    else if not IsButtonStateDown then
    begin
      Cursor := crDefault;
      FPrevHoverCloseTab := nil;
      FHoverCloseTab := nil;
      SaveButtonState;
      ClearButtonState;
      HandleButtonHoverState(X, Y);
    end;

    if FHoverTab <> FPrevHoverTab then
      CancelHint;

    if (FHoverTab <> FPrevHoverTab) or (FPrevHoverCloseTab <> FHoverCloseTab) or (IsButtonStateChanged and not IsButtonStateDown) then
      Invalidate;
  end
  else if not Assigned(FDownCloseTab) and Interaction.Reorder and ((Abs(FDownX - X) > 3) or (Abs(FDownY - Y) > 3)) then
  begin
    FDragTab := FDownTab;
    r := GetTabRect;
    case Layout.Position of
      tlpTop, tlpBottom:
      begin
        off := X - FDragDownX;
        if (FOrigRect.Left + off < r.Left) then
          off := r.Left - FOrigRect.Left
        else if (FOrigRect.Right + off > r.Right) then
          off := r.Right - FOrigRect.Right;

        FDragTabDisplay.Rect := RectF(FOrigRect.Left + off, FOrigRect.Top, FOrigRect.Right + off, FOrigRect.Bottom);
      end;
      tlpLeft, tlpRight:
      begin
        off := Y - FDragDownY;
        if (FOrigRect.Top + off < r.Top) then
          off := r.Top - FOrigRect.Top
        else if (FOrigRect.Bottom + off > r.Bottom) then
          off := r.Bottom - FOrigRect.Bottom;

        FDragTabDisplay.Rect := RectF(FOrigRect.Left, FOrigRect.Top + off, FOrigRect.Right, FOrigRect.Bottom + off);
      end;
    end;

    CalculateRects(FDragTabDisplay);

    b := False;
    case Layout.Position of
      tlpTop, tlpBottom:
      begin
        FDownX := X;
        if (X > r.Right - 10) then
          HandleScrollNext(b, False);

        if (X < r.Left + 10) then
          HandleScrollPrevious(b, False);
      end;
      tlpLeft, tlpRight:
      begin
        FDownY := Y;
        if (Y < r.Top + 10) then
          HandleScrollNext(b, False);

        if (Y > r.Bottom - 10) then
          HandleScrollPrevious(b, False);
      end;
    end;

    if b then
      UpdateTabs
    else
    begin
      Invalidate;
      DoInvalidate;
    end;
  end;
end;

procedure TTMSFNCCustomTabSet.HandleMouseUp(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
var
  tbd, tbdb: TTMSFNCTabSetDisplayTab;
  ins: TTMSFNCTabSetTab;
  pr: TRectF;
  bc: Boolean;
  ac: TTMSFNCTabSetTab;
begin
  inherited;
  ins := nil;
  bc := False;
  if Assigned(FDragTab) then
  begin
    case Layout.Position of
      tlpTop, tlpBottom: tbd := XYToTab(X, FDragTabDisplay.Rect.Top + (FDragTabDisplay.Rect.Bottom - FDragTabDisplay.Rect.Top) / 2, FDragTab);
      tlpLeft, tlpRight: tbd := XYToTab(FDragTabDisplay.Rect.Left + (FDragTabDisplay.Rect.Right - FDragTabDisplay.Rect.Left) / 2, Y, FDragTab);
    end;
  end
  else
  begin
    tbd := XYToTab(X, Y);
    tbdb := XYToCloseTab(X, Y);
  end;
  ReleaseCaptureEx;

  if Assigned(FDragTab) then
  begin
    HandleDragTab(FDragTab, tbd.Tab);
    bc := True;
  end
  else
  begin
    if (FTabAnchor <> '') and Assigned(tbd.Tab) and (XYToAnchor(tbd, X, Y) <> '') then
      DoAnchorTabClick(tbd.Tab.Index, FTabAnchor)
    else
    begin
      Inc(FUpdateCount);
      if Assigned(FDownCloseTab) and (FDownCloseTab = tbdb.Tab) then
        HandleCloseTab(FDownCloseTab, bc)
      else if Assigned(FDownTab) and (FDownTab = FInsertTab) then
        ins := HandleInsertTab(bc)
      else if Assigned(FDownTab) and (FDownTab = tbd.Tab) then
      begin
        ac := FActiveTab;
        if (ac = FDownTab) and Interaction.Editing then
          HandleTabEditing(tbd)
        else
        begin
          FFocusedTab := FDownTab;
          HandleChangeTab(FDownTab);
          bc := (Layout.Multiline = tlmEnabledActiveTab) and (ac <> FActiveTab) and (ActiveTabIndex <> -1);
        end;
      end
      else
      begin
        if (FTabListButtonState = tbsDown) and XYToTabListButton(X, Y) then
        begin
          FillTabList;
          pr := GetTabListButtonRect;
          FTabListPopup.PlacementControl := Self;
          FTabListPopup.PlacementRectangle.Left := pr.Left;
          FTabListPopup.PlacementRectangle.Top := pr.Top;
          FTabListPopup.PlacementRectangle.Right := pr.Right;
          FTabListPopup.PlacementRectangle.Bottom := pr.Bottom;
          FTabListPopup.Popup;
        end
        else if (FCloseButtonState = tbsDown) and XYToCloseButton(X, Y) then
          HandleCloseTab(FActiveTab, bc)
        else if (FInsertButtonState = tbsDown) and XYToInsertButton(X, Y) then
          ins := HandleInsertTab(bc)
        else if (FScrollButtonPreviousState = tbsDown) and XYToScrollPreviousButton(X, Y) then
          HandleScrollPrevious(bc)
        else if (FScrollButtonNextState = tbsDown) and XYToScrollNextButton(X, Y) then
          HandleScrollNext(bc);
      end;
    end;
  end;

  StopDownTimer;
  FTabAnchor := '';
  FDragTab := nil;
  FDragTabDisplay := EmptyDisplayTab;
  FDownTab := nil;
  FHoverTab := nil;
  FDownCloseTab := nil;
  FHoverCloseTab := nil;
  ClearButtonState;
  Dec(FUpdateCount);
  if bc then
    UpdateTabs;

  if Assigned(ins) and Interaction.SelectTabOnInsert then
    HandleChangeTab(ins)
  else if not bc then
    Invalidate;
end;

procedure TTMSFNCCustomTabSet.HandleMouseWheel(Shift: TShiftState;
  WheelDelta: Integer; var Handled: Boolean);
var
  b: Boolean;
begin
  inherited;

  if not IsFocused then
    Exit;

  b := False;
  if WheelDelta > 0 then
    HandleScrollNext(b)
  else
    HandleScrollPrevious(b);

  if b then
    UpdateTabs;
end;

procedure TTMSFNCCustomTabSet.HandleScrollNext(var AChanged: Boolean; ASelect: Boolean = True);
var
  pr: Integer;
begin
  if Interaction.SelectTabOnScroll and ASelect then
    SelectNextTab
  else
  begin
    pr := FStartTab;
    if FStartTab < FScrollCount then
      Inc(FStartTab);

    if not IsTabVisible(FStartTab) and (FStartTab < GetLastScrollableTabIndex) then
      FStartTab := GetNextTab(FStartTab);

    AChanged := pr <> FStartTab;
  end;
end;

procedure TTMSFNCCustomTabSet.HandleScrollPrevious(var AChanged: Boolean; ASelect: Boolean = True);
var
  pr: Integer;
begin
  if Interaction.SelectTabOnScroll and ASelect then
    SelectPreviousTab
  else
  begin
    pr := FStartTab;
    if FStartTab > 0 then
      Dec(FStartTab);

    if not IsTabVisible(FStartTab) and (FStartTab > GetFirstScrollableTabIndex) then
      FStartTab := GetPreviousTab(FStartTab);

    AChanged := pr <> FStartTab;
  end;
end;

procedure TTMSFNCCustomTabSet.CustomizeInplaceEditor(AInplaceEditor: TTMSFNCTabSetInplaceEditor; ATab: TTMSFNCTabSetDisplayTab);
{$IFDEF FMXLIB}
var
  f: TTMSFNCGraphicsFont;
  c: TAlphaColor;
{$ENDIF}
begin
  if not Assigned(ATab.Tab) or not Assigned(AInplaceEditor) then
    Exit;

  {$IFDEF FMXLIB}

  f := TabAppearance.Font;

  if ATab.Tab.UseDefaultAppearance then
    c := TabAppearance.ActiveTextColor
  else
    c := ATab.Tab.ActiveTextColor;

  if (AInplaceEditor is TEdit) then
  begin
    (AInplaceEditor as TEdit).StyledSettings := [];
    (AInplaceEditor as TEdit).Font.Assign(f);
    (AInplaceEditor as TEdit).FontColor := c;
  end;
  {$ENDIF}

  if Assigned(OnCustomizeInplaceEditor) then
    OnCustomizeInplaceEditor(Self, ATab.Tab.Index, FInplaceEditor);
end;

{$IFDEF FNCLIB}
{$IFNDEF WEBLIB}
procedure TTMSFNCCustomTabSet.CustomizeShortCut(AShortCutWindow: TTMSFNCHint;
  AShortCut: string; AShortCutRect: TRectF; var AShortCutPosition: TPointF);
var
  tbd: TTMSFNCTabSetDisplayTab;
  r: TRectF;
begin
  inherited;
  tbd := FindDisplayTabWithShortCutHint(AShortCut);
  if Assigned(tbd.Tab) then
  begin
    r := tbd.Tab.GetFixedRect(tbd.Rect, tbd);
    AShortCutPosition := PointF(r.Left + ((r.Right - r.Left) - AShortCutRect.Width) / 2, r.Top + (r.Bottom - r.Top) - AShortCutRect.Height / 2);
  end;
end;
{$ENDIF}
{$ENDIF}

procedure TTMSFNCCustomTabSet.HandleTabEditing(ATab: TTMSFNCTabSetDisplayTab);
var
  ins: Boolean;
  r: TRectF;
  {$IFNDEF LCLLIB}
  {$IFNDEF WEBLIB}
  AContext: TRttiContext;
  rt: TRttiType;
  propt: TRttiProperty;
  {$ENDIF}
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  rtr: TRect;
  {$ENDIF}
  n: String;
begin
  if Assigned(ATab.Tab) then
  begin
    FInplaceEditorClass := TTMSFNCTabSetEdit;

    ins := True;
    DoBeforeOpenInplaceEditor(ATab.Index, ins);
    if ins then
    begin
      DoGetInplaceEditor(ATab.Tab.Index, FInplaceEditorClass);

      if Assigned(FInplaceEditor) then
      begin
        {$IFDEF FMXLIB}
        FInplaceEditorTimer.Enabled := True;
        {$ENDIF}
        {$IFDEF CMNWEBLIB}
        FInplaceEditor.Free;
        FInplaceEditor := nil;
        {$ENDIF}
      end;

      if Assigned(FInplaceEditorClass) then
        FInplaceEditor := FInplaceEditorClass.Create(Self)
      else
        FInplaceEditor := TTMSFNCTabSetEdit.Create(Self);

      r := GetInplaceEditorRect(ATab);
      if Assigned(FInplaceEditor) then
      begin
        FUpdateTab := ATab;
        {$IFDEF FMXLIB}
        FInplaceEditor.DisableFocusEffect := True;
        FInplaceEditor.OnApplyStyleLookup := ApplyInplaceEditorStyleLookup;
        {$ENDIF}
        {$IFDEF WEBLIB}
        FInplaceEditor.ShowFocus := False;
        {$ENDIF}

        FInplaceEditor.Parent := Self;
        FInplaceEditor.Visible := False;
        {$IFDEF FMXLIB}
        FInplaceEditor.BoundsRect := r;
        FInplaceEditor.BoundsRect := RectF(r.Left, r.Top + (r.Bottom - r.Top - FInplaceEditor.Height) / 2, r.Right, r.Top + (r.Bottom - r.Top - FInplaceEditor.Height) / 2 + FInplaceEditor.Height);
        {$ENDIF}
        {$IFDEF CMNWEBLIB}
        rtr := Rect(Round(r.Left), Round(r.Top), Round(r.Right), Round(r.Bottom));
        FInplaceEditor.BoundsRect := rtr;
        FInplaceEditor.BoundsRect := Rect(rtr.Left, rtr.Top + (rtr.Bottom - rtr.Top - FInplaceEditor.Height) div 2, rtr.Right, rtr.Top + (rtr.Bottom - rtr.Top - FInplaceEditor.Height) div 2 + FInplaceEditor.Height);
        {$ENDIF}
        FInplaceEditor.Visible := True;

        CustomizeInplaceEditor(FInplaceEditor, ATab);

        {$IFNDEF LCLLIB}
        {$IFNDEF WEBLIB}
        AContext := TRttiContext.Create;
        {$ENDIF}
        {$ENDIF}
        try
          {$IFNDEF LCLLIB}
          {$IFNDEF WEBLIB}
          rt := AContext.GetType(FInplaceEditor.ClassInfo);
          {$ENDIF}
          {$ENDIF}
          n := ATab.Tab.Text;
          {$IFNDEF LCLLIB}
          {$IFNDEF WEBLIB}
          propt := rt.GetProperty('Text');
          if Assigned(propt) then
            propt.SetValue(FInplaceEditor, n);
          {$ENDIF}
          {$ENDIF}

          {$IFDEF LCLLIB}
          if FInplaceEditor is TEdit then
            (FInplaceEditor as TEdit).Text := n;
          {$ENDIF}
        finally
          {$IFNDEF LCLLIB}
          {$IFNDEF WEBLIB}
          AContext.Free;
          {$ENDIF}
          {$ENDIF}
        end;

        if FInplaceEditor.CanFocus then
          FInplaceEditor.SetFocus;

        if FInplaceEditor is TEdit then
          (FInplaceEditor as TEdit).SelStart := Length((FInplaceEditor as TEdit).Text);
        FInplaceEditorActive := True;
        Invalidate;
      end;

      DoOpenInplaceEditor(ATab.Tab.Index, FInplaceEditor, r);
    end;
  end;
end;

function TTMSFNCCustomTabSet.HasHint: Boolean;
begin
  Result := Assigned(FHoverTab) and (FHoverTab.Hint <> '');
end;

procedure TTMSFNCCustomTabSet.InitializeInvisibleTabs;
var
  I: Integer;
  tb: TTMSFNCTabSetTab;
begin
  FInvisibleTabList.Clear;
  for I := 0 to FTabs.Count - 1 do
  begin
    tb := FTabs[I];
    if not tb.Visible then
      FInvisibleTabList.Add(tb);
  end;
end;

procedure TTMSFNCCustomTabSet.InitializeMenu;
begin
  FMenuSize := 0;

  FShowCloseButton := (Options.CloseMode = tcmMenu);
  FShowInsertButton := (Options.InsertMode = timMenu);
  FShowTabListButton := (GetInvisibleTabCount > 0) and Options.TabListButton;

  if FShowTabListButton then
  begin
    FMenuSize := FMenuSize + ButtonAppearance.Size + 8;
    FShowMenu := True;
  end;

  if FShowCloseButton then
  begin
    FMenuSize := FMenuSize + ButtonAppearance.Size + 8;
    FShowMenu := True;
  end;

  if FShowInsertButton then
  begin
    FMenuSize := FMenuSize + ButtonAppearance.Size + 8;
    FShowMenu := True;
  end;

  if FShowScrollButtons then
  begin
    FMenuSize := FMenuSize + ((ButtonAppearance.Size + 8) * 2);
    FShowMenu := True;
  end;
end;

procedure TTMSFNCCustomTabSet.InitializeScrollButtonState;
begin
  if Interaction.SelectTabOnScroll then
  begin
    FScrollButtonPreviousDisabled := (ActiveTabIndex = GetFirstScrollableTabIndex) or (ActiveTabIndex = -1);
    FScrollButtonNextDisabled := (ActiveTabIndex = GetLastScrollableTabIndex) or (ActiveTabIndex = -1);
  end
  else
  begin
    FScrollButtonPreviousDisabled := (FStartTab = 0);
    FScrollButtonNextDisabled := (FScrollCount - FStartTab = 0);
  end;
end;

procedure TTMSFNCCustomTabSet.InitSample;
begin
  BeginUpdate;

  ResetToDefaultStyle;

  Tabs.Clear;
  AddTab('Tab 1').UseDefaultAppearance := True;
  AddTab('Tab 2').UseDefaultAppearance := True;
  AddTab('Tab 3').UseDefaultAppearance := True;
  EndUpdate;
end;

function TTMSFNCCustomTabSet.InsertTab(ATabIndex: Integer; AText: string = ''): TTMSFNCTabSetTab;
begin
  Result := Tabs.Insert(ATabIndex);
  Result.Text := AText;
end;

function TTMSFNCCustomTabSet.IsButtonStateDown: Boolean;
begin
  Result := (FTabListButtonState = tbsDown) or (FInsertButtonState = tbsDown) or (FCloseButtonState = tbsDown)
    or (FScrollButtonPreviousState = tbsDown) or (FScrollButtonNextState = tbsDown);
end;

function TTMSFNCCustomTabSet.IsEditing: Boolean;
begin
  Result := FInplaceEditorActive;
end;

function TTMSFNCCustomTabSet.IsTabEnabled(ATabIndex: Integer): Boolean;
begin
  Result := False;
  if (ATabIndex >= 0) and (ATabIndex <= FTabs.Count - 1) then
    Result := FTabs[ATabIndex].Enabled;
end;

function TTMSFNCCustomTabSet.IsTabVisible(ATabIndex: Integer): Boolean;
begin
  Result := False;
  if (ATabIndex >= 0) and (ATabIndex <= FTabs.Count - 1) then
    Result := FTabs[ATabIndex].Visible;
end;

procedure TTMSFNCCustomTabSet.LoadSettingsFromFile(AFileName: string);
var
  I: Integer;
begin
  for I := 0 to Tabs.Count - 1 do
    Tabs[I].UseDefaultAppearance := True;

  if Assigned(FInsertTab) then
    FInsertTab.UseDefaultAppearance := True;

  inherited;
end;

procedure TTMSFNCCustomTabSet.LoadSettingsFromStream(AStream: TStreamEx);
var
  I: Integer;
begin
  for I := 0 to Tabs.Count - 1 do
    Tabs[I].UseDefaultAppearance := True;

  if Assigned(FInsertTab) then
    FInsertTab.UseDefaultAppearance := True;

  inherited;
end;

procedure TTMSFNCCustomTabSet.MoveTab(AFromTabIndex, AToTabIndex: Integer);
var
  act: Integer;
begin
  if (AFromTabIndex >= 0) and (AFromTabIndex <= Tabs.Count - 1) and (AToTabIndex >= 0) and (AToTabIndex <= Tabs.Count - 1) then
  begin
    act := ActiveTabIndex;
    Tabs[AFromTabIndex].Index := Tabs[AToTabIndex].Index;
    if act = AFromTabIndex then
      ActiveTabIndex := AToTabIndex;
    if act = AToTabIndex then
      ActiveTabIndex := AFromTabIndex;
  end;
end;

procedure TTMSFNCCustomTabSet.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = FBitmapContainer) then
    FBitmapContainer := nil;
end;

procedure TTMSFNCCustomTabSet.RemoveTab(ATabIndex: Integer);
begin
  if (ATabIndex >= 0) and (ATabIndex <= Tabs.Count - 1) then
    Tabs.Delete(ATabIndex);
end;

procedure TTMSFNCCustomTabSet.ResetToDefaultStyle;
var
  I: Integer;
begin
  inherited;

  BeginUpdate;

  TabAppearance.BadgeFill.Color := gcOrange;
  ButtonAppearance.HoverFill.Color := TabAppearance.HoverFill.Color;
  ButtonAppearance.DownFill.Color := TabAppearance.DownFill.Color;
  ButtonAppearance.Fill.Color := TabAppearance.Fill.Color;

  TabAppearance.ActiveStroke.Color := gcDarkgray;
  TabAppearance.Stroke.Color := gcDarkgray;
  TabAppearance.HoverStroke.Color := gcDarkgray;
  TabAppearance.DownStroke.Color := gcDarkgray;
  ButtonAppearance.Stroke.Color := gcDarkgray;
  ButtonAppearance.HoverStroke.Color := gcDarkgray;
  ButtonAppearance.DownStroke.Color := gcDarkgray;

  {$IFDEF FMXLIB}
  TabAppearance.Fill.Color := $FFF6F8FC;
  TabAppearance.HoverFill.Color := $FFEEF2F9;
  TabAppearance.DownFill.Color := $FFA8BCF0;
  TabAppearance.HoverStroke.Color := $FF2D9BEF;
  TabAppearance.TextColor := $FF7A7A7A;
  TabAppearance.ActiveTextColor := $FF454545;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  TabAppearance.Fill.Color := $FCF8F6;
  TabAppearance.HoverFill.Color := $F9F2EE;
  TabAppearance.DownFill.Color := $F0BCA8;
  TabAppearance.HoverStroke.Color := $EF9B2D;
  TabAppearance.TextColor := $7A7A7A;
  TabAppearance.ActiveTextColor := $454545;
  {$ENDIF}

  TabAppearance.ActiveFill.Color :=gcWhite;
  TabAppearance.HoverTextColor := TabAppearance.TextColor;
  TabAppearance.DownTextColor := TabAppearance.ActiveTextColor;
  TabAppearance.DownStroke.Color := TabAppearance.HoverStroke.Color;
  TabAppearance.HoverFill.Kind := gfkSolid;
  TabAppearance.DownFill.Kind := gfkSolid;
  TabAppearance.ActiveFill.Kind := gfkSolid;
  TabAppearance.Fill.Kind := gfkSolid;
  TabAppearance.ShowFocus := False;

  for I := 0 to Tabs.Count - 1 do
    Tabs[I].UseDefaultAppearance := True;

  if Assigned(FInsertTab) then
    FInsertTab.UseDefaultAppearance := False;

  EndUpdate;
end;

procedure TTMSFNCCustomTabSet.UpdateControlAfterResize;
begin
  inherited;
  UpdateTabs;
end;

procedure TTMSFNCCustomTabSet.SaveButtonState;
begin
  FPrevTabListButtonState := FTabListButtonState;
  FPrevCloseButtonState := FCloseButtonState;
  FPrevScrollButtonPreviousState := FScrollButtonPreviousState;
  FPrevScrollButtonNextState := FScrollButtonNextState;
  FPrevInsertButtonState := FInsertButtonState;
end;

procedure TTMSFNCCustomTabSet.ScrollToTab(ATabIndex: Integer);
begin
  if (TabSize.Mode <> tsmFixedSizeAutoShrink) and FAllowScrolling and (Layout.Multiline = tlmNone) and (FScrollCount > 0)
    and (ATabIndex >= 0) and (ATabIndex <= FTabs.Count - 1) and IsTabVisible(ATabIndex) then
  begin
    if (ATabIndex > FLastTabIndex) or (ATabIndex < FFirstTabIndex) then
    begin
      FStartTab := Max(0, ATabIndex - GetNormalDisplayCount - GetInvisibleTabCount);
      UpdateTabs(False, True);
      if ATabIndex > FLastTabIndex then
      begin
        while FLastTabIndex < ATabIndex do
        begin
          Inc(FStartTab);
          if not IsTabVisible(FStartTab) then
            FStartTab := GetNextTab(FStartTab);

          UpdateTabs(False, True);
        end;
      end
      else if ATabIndex < FFirstTabIndex then
      begin
        while FFirstTabIndex > ATabIndex do
        begin
          Dec(FStartTab);
          if not IsTabVisible(FStartTab) then
            FStartTab := GetPreviousTab(FStartTab);

          UpdateTabs(False, True);
        end;
      end;
    end
    else
    begin
      InitializeScrollButtonState;
      Invalidate;
    end;
  end
  else
    Invalidate;
end;

procedure TTMSFNCCustomTabSet.SelectNextSequentialTab;
var
  i, j: Integer;
  found: Boolean;
begin
  if (FActiveTabIndex < 0) then
    Exit;

  found := False;
  i := FActiveTabIndex;
  j := 1;
  while (j < FTabs.Count) do
  begin
    Inc(i);

    if (i >= FTabs.Count) then
      Break;

    if (FActiveTabIndex <> i) and IsTabVisible(I) and IsTabEnabled(I) then
    begin
      FActiveTabIndex := i;
      found := True;
      Break;
    end;

    Inc(j);
  end;

  if not found then
  begin
    i := FActiveTabIndex;
    j := 1;
    while (j < FTabs.Count) do
    begin
      Dec(i);

      if (i >= FTabs.Count) then
        Break;

      if (i < 0) then
        Break;

      if (FActiveTabIndex <> i) and IsTabVisible(I) and IsTabEnabled(I) then
      begin
        FActiveTabIndex := i;
        Break;
      end;

      Inc(j);
    end;
  end;
end;

procedure TTMSFNCCustomTabSet.SelectNextTab;
var
  i, j: Integer;
begin
  if (FActiveTabIndex < 0) then
    Exit;

  i := FActiveTabIndex;
  j := 1;
  while (j < FTabs.Count) do
  begin
    Inc(i);

    if (i >= FTabs.Count) then
      i := 0;

    if (FActiveTabIndex <> i) and IsTabVisible(I) and IsTabEnabled(I) then
    begin
      FActiveTabIndex := i;
      Break;
    end;

    Inc(j);
  end;

  if (FActiveTabIndex >= 0) and (FActiveTabIndex <= FTabs.Count - 1) then
  begin
    HandleChangeTab(FTabs[FActiveTabIndex]);
    if FUpdateCount = 0 then
      Invalidate;
  end;
end;

procedure TTMSFNCCustomTabSet.SelectPreviousTab;
var
  i, j: Integer;
begin
  if (FActiveTabIndex < 0) then
    Exit;

  i := FActiveTabIndex;
  j := 1;
  while (j < FTabs.Count) do
  begin
    Dec(i);

    if (i >= FTabs.Count) then
      i := 0;

    if (i < 0) then
      i := FTabs.Count - 1;

    if (FActiveTabIndex <> i) and IsTabVisible(I) and IsTabEnabled(I) then
    begin
      FActiveTabIndex := i;
      Break;
    end;

    Inc(j);
  end;

  if (FActiveTabIndex >= 0) and (FActiveTabIndex <= FTabs.Count - 1) then
  begin
    HandleChangeTab(FTabs[FActiveTabIndex]);
    if FUpdateCount = 0 then
      Invalidate;
  end;
end;

procedure TTMSFNCCustomTabSet.SelectTab(ATabIndex: Integer);
begin
  ActiveTabIndex := ATabIndex;
end;

procedure TTMSFNCCustomTabSet.SetActiveTab(const Value: TTMSFNCTabSetTab);
begin
  HandleChangeTab(Value);
end;

procedure TTMSFNCCustomTabSet.SetActiveTabIndex(const Value: Integer);
begin
  FActiveTabIndex := Max(0, Min(Value, Tabs.Count - 1));
  if (FActiveTabIndex >= 0) and (FActiveTabIndex <= FTabs.Count - 1) then
    ActiveTab := FTabs[FActiveTabIndex]
  else
    ActiveTab := nil;
end;

procedure TTMSFNCCustomTabSet.SetAllowScrolling(const Value: Boolean);
begin
  if FAllowScrolling <> Value then
  begin
    FAllowScrolling := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCCustomTabSet.SetTabAppearance(const Value: TTMSFNCTabSetTabAppearance);
begin
  FTabAppearance.Assign(Value);
end;

procedure TTMSFNCCustomTabSet.SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
begin
  FBitmapContainer := Value;
  UpdateTabs;
end;

procedure TTMSFNCCustomTabSet.SetButtonAppearance(const Value: TTMSFNCTabSetButtonAppearance);
begin
  FButtonAppearance.Assign(Value);
end;

procedure TTMSFNCCustomTabSet.SetFonts(ASetType: TTMSFNCAppearanceGlobalFontType);
var
  I: Integer;
begin
  BeginUpdate;

  GlobalFont.ApplyChange(TabAppearance.Font, ASetType);
  GlobalFont.ApplyChange(TabAppearance.BadgeFont, ASetType);

  if ASetType = aftColor then
  begin
    TabAppearance.TextColor := GlobalFont.Color;
    TabAppearance.HoverTextColor := GlobalFont.Color;
    TabAppearance.ActiveTextColor := GlobalFont.Color;
    TabAppearance.DownTextColor := GlobalFont.Color;

    for I := 0 to Tabs.Count -1 do
    begin
      Tabs[I].TextColor := GlobalFont.Color;
      Tabs[I].HoverTextColor := GlobalFont.Color;
      Tabs[I].ActiveTextColor := GlobalFont.Color;
      Tabs[I].DownTextColor := GlobalFont.Color;
    end;
  end;

  EndUpdate;
end;

procedure TTMSFNCCustomTabSet.SetGlobalFont(const Value: TTMSFNCAppearanceGlobalFont);
begin
  FGlobalFont.Assign(Value);
end;

procedure TTMSFNCCustomTabSet.SetInteraction(
  const Value: TTMSFNCTabSetInteraction);
begin
  FInteraction.Assign(Value);
end;

procedure TTMSFNCCustomTabSet.SetLayout(
  const Value: TTMSFNCTabSetLayout);
begin
  if FLayout <> Value then
  begin
    FLayout := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCCustomTabSet.SetOptions(const Value: TTMSFNCTabSetOptions);
begin
  FOptions.Assign(Value);
end;

procedure TTMSFNCCustomTabSet.SetTabs(const Value: TTMSFNCTabSetTabs);
begin
  FTabs.Assign(Value);
end;

procedure TTMSFNCCustomTabSet.SetTabSize(const Value: TTMSFNCTabSetTabSize);
begin
  FTabSize.Assign(Value);
end;

procedure TTMSFNCCustomTabSet.StartDownTimer;
begin
  FDownTimer.Enabled := not IsDesignTime;
end;

procedure TTMSFNCCustomTabSet.StartProgressAnimationTimer;
begin
  FAnimTimer.Enabled := not IsDesignTime;
end;

procedure TTMSFNCCustomTabSet.StopDownTimer;
begin
  FDownTimer.Enabled := False;
end;

procedure TTMSFNCCustomTabSet.StopEditing;
begin
  if FInplaceEditorActive then
    CloseInplaceEditor(False);
end;

procedure TTMSFNCCustomTabSet.UpdateInplaceEditorPosition;
var
  r: TRectF;
  {$IFDEF CMNWEBLIB}
  rt: TRect;
  {$ENDIF}
begin
  if (FUpdateCount > 0) or (csDestroying in ComponentState) then
    Exit;

  if Assigned(FUpdateTab.Tab) and Assigned(FInplaceEditor) and FInplaceEditorActive then
  begin
    if Assigned(GetDisplayTab(FUpdateTab.Index).Tab) then
    begin
      r := GetInplaceEditorRect(FUpdateTab);
      FInplaceEditor.Parent := Self;
      FInplaceEditor.Visible := False;
      {$IFDEF FMXLIB}
      FInplaceEditor.BoundsRect := r;
      FInplaceEditor.BoundsRect := RectF(r.Left, r.Top + (r.Bottom - r.Top - FInplaceEditor.Height) / 2, r.Right, r.Top + (r.Bottom - r.Top - FInplaceEditor.Height) / 2 + FInplaceEditor.Height);
      {$ENDIF}
      {$IFDEF CMNWEBLIB}
      rt := Rect(Round(r.Left), Round(r.Top), Round(r.Right), Round(r.Bottom));
      FInplaceEditor.BoundsRect := rt;
      FInplaceEditor.BoundsRect := Rect(rt.Left, rt.Top + (rt.Bottom - rt.Top - FInplaceEditor.Height) div 2, rt.Right, rt.Top + (rt.Bottom - rt.Top - FInplaceEditor.Height) div 2 + FInplaceEditor.Height);
      {$ENDIF}
      FInplaceEditor.Visible := True;
    end
    else
      FInplaceEditor.Parent := nil;
  end;
end;

procedure TTMSFNCCustomTabSet.UpdateTabs(ACalculateScrolling: Boolean = False; AForceUpdate: Boolean = False);
var
  I: Integer;
  tbd, tbdinsert: TTMSFNCTabSetDisplayTab;
  x, y, w, h, dw, dh, sz: Single;
  tc, st: Integer;
  tr: TRectF;
  das: Integer;
  newx: Single;
  newy: Single;
  trw, trh: Single;
  ar: Integer;
  chrw: Boolean;
  cnt: Integer;
  off: Single;
  r: TRectF;
begin
  if FTabChanging then
    Exit;

  FDisplayList.Clear;
  FInvisibleTabList.Clear;
  if not AForceUpdate then
  begin
    if (FUpdateCount > 0) or (csDestroying in ComponentState) then
      Exit;
  end;

  FShowMenu := False;
  if not ACalculateScrolling then
    FShowScrollButtons := False;

  InitializeMenu;

  if FTabs.Count = 0 then
  begin
    Invalidate;
    Exit;
  end;

  tr := GetTabRect;
  x := tr.Left;
  y := tr.Top;
  das := 0;
  ar := 0;

  case Layout.Position of
    tlpTop, tlpBottom:
    begin
      dw := TabSize.Width;
      dh := tr.Bottom - tr.Top;
    end;
    tlpLeft, tlpRight:
    begin
      dw := tr.Right - tr.Left;
      dh := TabSize.Width;
    end;
    else
    begin
      dw := 0;
      dh := 0;
    end;
  end;

  tc := GetVisibleTabCount;
  case TabSize.Mode of
    tsmAutoSize:
    begin
      case Layout.Position of
        tlpTop, tlpBottom:
        begin
          if tc > 0 then
            w := ((tr.Right - tr.Left) + ((tc - 1) * TabAppearance.ShapeOverlap) - ((tc - 1) * TabSize.Spacing) - GetAdditionalTabSpacing) / tc
          else
            w := 0;

          if w > 0 then
          begin
            for I := 0 to Tabs.Count - 1 do
            begin
              if IsTabVisible(I) then
              begin
                tbd.First := False;
                tbd.Last := False;
                tbd.Tab := Tabs[I];
                tbd.Rect := RectF(x, y, x + w, y + dh);
                tbd.Row := 0;
                FDisplayList.Add(tbd);
                x := x + w + TabSize.Spacing - TabAppearance.ShapeOverlap;
              end;
            end;
          end;

          if Options.InsertMode = timTab then
          begin
            tbdinsert.Tab := FInsertTab;
            tbdinsert.TabKind := ttkInsert;
            tbdinsert.First := False;
            tbdinsert.Last := False;
            tbdinsert.Row := 0;
            if FDisplayList.Count > 0 then
              tbdinsert.Rect := RectF(x + 5 + TabAppearance.ShapeOverlap, y, x + 5 + TabAppearance.ShapeOverlap + TabAppearance.InsertSize, y + dh)
            else
              tbdinsert.Rect := RectF(x + 5, y, x + 5 + TabAppearance.InsertSize, y + dh);
            FDisplayList.Add(tbdinsert);
          end;
        end;
        tlpLeft, tlpRight:
        begin
          if tc > 0 then
            h := ((tr.Bottom - tr.Top) + ((tc - 1) * TabAppearance.ShapeOverlap) - ((tc - 1) * TabSize.Spacing) - GetAdditionalTabSpacing) / tc
          else
            h := 0;

          if h > 0 then
          begin
            for I := 0 to Tabs.Count - 1 do
            begin
              if IsTabVisible(I) then
              begin
                tbd.First := False;
                tbd.Last := False;
                tbd.Tab := Tabs[I];
                tbd.Rect := RectF(x, y, x + dw, y + h);
                tbd.Row := 0;
                FDisplayList.Add(tbd);
                y := y + h + TabSize.Spacing - TabAppearance.ShapeOverlap;
              end;
            end;
          end;

          if Options.InsertMode = timTab then
          begin
            tbdinsert.Tab := FInsertTab;
            tbdinsert.TabKind := ttkInsert;
            tbdinsert.First := False;
            tbdinsert.Last := False;
            tbdinsert.Row := 0;
            if FDisplayList.Count > 0 then
              tbdinsert.Rect := RectF(x, y + 5 + TabAppearance.ShapeOverlap, x + dw, y + 5 + TabAppearance.ShapeOverlap + TabAppearance.InsertSize)
            else
              tbdinsert.Rect := RectF(x, y + 5, x + dw, y + 5 + TabAppearance.InsertSize);
            FDisplayList.Add(tbdinsert);
          end;
        end;
      end;
    end;
    tsmFixedSize, tsmFixedSizeAutoShrink, tsmAutoTabSize:
    begin
      st := 0;
      if (TabSize.Mode <> tsmFixedSizeAutoShrink) and FAllowScrolling and (Layout.Multiline = tlmNone) then
        st := FStartTab;

      cnt := Tabs.Count;
      if Options.InsertMode = timTab then
        cnt := cnt + 1;

      for I := st to cnt - 1 do
      begin
        if ((I <= Tabs.Count - 1) and IsTabVisible(I)) or (I > Tabs.Count - 1) then
        begin
          case Layout.Position of
            tlpTop, tlpBottom:
            begin
              w := GetTabSize(I);
              if w > 0 then
              begin
                if (I = cnt - 1) and (Options.InsertMode = timTab) then
                begin
                  tbd.Tab := FInsertTab;
                  tbd.TabKind := ttkInsert;
                  tbd.First := False;
                  tbd.Last := False;
                  tbd.Row := das;
                  if FDisplayList.Count > 0 then
                  begin
                    tbd.Rect := RectF(x + 5 + TabAppearance.ShapeOverlap, y, x + 5 + TabAppearance.ShapeOverlap + w, y + dh);
                    newx := x + w + 5 + TabAppearance.ShapeOverlap;
                  end
                  else
                  begin
                    tbd.Rect := RectF(x + 5, y, x + 5 + w, y + dh);
                    newx := x + w + 5;
                  end;

                  FDisplayList.Add(tbd);
                end
                else
                begin
                  tbd.First := FDisplayList.Count = 0;
                  tbd.Last := False;
                  tbd.Tab := Tabs[I];
                  tbd.Row := das;
                  tbd.Rect := RectF(x, y, x + w, y + dh);
                  if I = ActiveTabIndex then
                    ar := das;
                  FDisplayList.Add(tbd);
                  newx := x + w + TabSize.Spacing;
                  if (Options.InsertMode = timTab) and (I = cnt - 2) then
                    newx := newx + TabAppearance.InsertSize + 5;
                end;

                trw := tr.Right;
                if (Options.InsertMode = timTab) then
                  trw := trw - GetAdditionalTabSpacing
                else
                  trw := trw - 5;

                if (newx > trw) and FAllowScrolling and (FDisplayList.Count > 1) and (((I < cnt - 1) and (Options.InsertMode = timTab)) or not (Options.InsertMode = timTab)) then
                begin
                  Inc(das);
                  if (TabSize.Mode = tsmFixedSizeAutoShrink) or (Layout.Multiline = tlmNone) then
                  begin
                    FDisplayList.Remove(tbd);
                    Break
                  end
                  else
                  begin
                    x := tr.Left;
                    case Layout.Position of
                      tlpTop: y := y + dh;
                      tlpBottom: y := y - dh;
                    end;
                    tbd.Rect := RectF(x, y, x + w, y + dh);
                    tbd.First := True;
                    tbd.Row := das;
                    if I = ActiveTabIndex then
                      ar := das;
                    FDisplayList[FDisplayList.Count - 1] := tbd;
                    if (FDisplayList.Count > 1) then
                    begin
                      tbd := FDisplayList[FDisplayList.Count - 2];
                      tbd.Last := True;
                      FDisplayList[FDisplayList.Count - 2] := tbd;
                    end;

                    newx := x + w + TabSize.Spacing - TabAppearance.ShapeOverlap;
                    x := newx;
                  end;
                end
                else
                begin
                  if (Options.InsertMode = timTab) and (I = cnt - 2) then
                    newx := newx - TabAppearance.InsertSize - 5;

                  x := newx - TabAppearance.ShapeOverlap;
                end;
              end;
            end;
            tlpLeft, tlpRight:
            begin
              h := GetTabSize(I);
              if h > 0 then
              begin
                if (I = cnt - 1) and (Options.InsertMode = timTab) then
                begin
                  tbd.Tab := FInsertTab;
                  tbd.TabKind := ttkInsert;
                  tbd.First := False;
                  tbd.Last := False;
                  tbd.Row := das;
                  if FDisplayList.Count > 0 then
                  begin
                    tbd.Rect := RectF(x, y + 5 + TabAppearance.ShapeOverlap, x + dw, y + 5 + TabAppearance.ShapeOverlap + h);
                    newy := y + h + 5 + TabAppearance.ShapeOverlap;
                  end
                  else
                  begin
                    tbd.Rect := RectF(x, y + 5, x + dw, y + 5 + h);
                    newy := y + h + 5;
                  end;

                  FDisplayList.Add(tbd);
                end
                else
                begin
                  tbd.First := FDisplayList.Count = 0;
                  tbd.Last := False;
                  tbd.Tab := Tabs[I];
                  tbd.Row := das;
                  tbd.Rect := RectF(x, y, x + dw, y + h);
                  if I = ActiveTabIndex then
                    ar := das;
                  FDisplayList.Add(tbd);
                  newy := y + h + TabSize.Spacing;
                  if (Options.InsertMode = timTab) and (I = cnt - 2) then
                    newy := newy + TabAppearance.InsertSize + 5;
                end;

                trh := tr.Bottom;
                if (Options.InsertMode = timTab) then
                  trh := trh - GetAdditionalTabSpacing
                else
                  trh := trh - 5;

                if (newy > trh) and FAllowScrolling and (FDisplayList.Count > 1) and (((I < cnt - 1) and (Options.InsertMode = timTab)) or not (Options.InsertMode = timTab)) then
                begin
                  Inc(das);
                  if (TabSize.Mode = tsmFixedSizeAutoShrink) or (Layout.Multiline = tlmNone) then
                  begin
                    FDisplayList.Remove(tbd);
                    Break;
                  end
                  else
                  begin
                    y := tr.Top;
                    case Layout.Position of
                      tlpLeft: x := x + dw;
                      tlpRight: x := x - dw;
                    end;
                    tbd.Rect := RectF(x, y, x + dw, y + h);
                    tbd.First := True;
                    tbd.Row := das;
                    if I = ActiveTabIndex then
                      ar := das;
                    FDisplayList[FDisplayList.Count - 1] := tbd;
                    if (FDisplayList.Count > 1) then
                    begin
                      tbd := FDisplayList[FDisplayList.Count - 2];
                      tbd.Last := True;
                      FDisplayList[FDisplayList.Count - 2] := tbd;
                    end;
                    newy := y + h + TabSize.Spacing - TabAppearance.ShapeOverlap;
                    y := newy;
                  end;
                end
                else
                begin
                  if (Options.InsertMode = timTab) and (I = cnt - 2) then
                    newy := newy - TabAppearance.InsertSize - 5;

                  y := newy - TabAppearance.ShapeOverlap;
                end;
              end;
            end;
          end;
        end;
      end;

      if (das + FStartTab > 0) and (GetDisplayCount > 0) then
      begin
        if (TabSize.Mode = tsmFixedSizeAutoShrink) then
        begin
          x := tr.Left;
          y := tr.Top;
          FDisplayList.Clear;
          case Layout.Position of
            tlpTop, tlpBottom:
            begin
              if tc > 0 then
                w := ((tr.Right - tr.Left) + ((tc - 1) * TabAppearance.ShapeOverlap) - ((tc - 1) * TabSize.Spacing) - GetAdditionalTabSpacing) / tc
              else
                w := 0;

              if w > 0 then
              begin
                for I := 0 to Tabs.Count - 1 do
                begin
                  if IsTabVisible(I) then
                  begin
                    tbd.First := False;
                    tbd.Last := False;
                    tbd.Tab := Tabs[I];
                    tbd.Row := 0;
                    tbd.Rect := RectF(x, y, x + w, y + dh);
                    FDisplayList.Add(tbd);
                    x := x + w + TabSize.Spacing - TabAppearance.ShapeOverlap;
                  end;
                end;
              end;

              if Options.InsertMode = timTab then
              begin
                tbdinsert.Tab := FInsertTab;
                tbdinsert.TabKind := ttkInsert;
                tbdinsert.First := False;
                tbdinsert.Last := False;
                tbdinsert.Row := 0;
                if FDisplayList.Count > 0 then
                  tbdinsert.Rect := RectF(x + 5 + TabAppearance.ShapeOverlap, y, x + 5 + TabAppearance.ShapeOverlap + TabAppearance.InsertSize, y + dh)
                else
                  tbdinsert.Rect := RectF(x + 5, y, x + 5 + TabAppearance.InsertSize, y + dh);
                FDisplayList.Add(tbdinsert);
              end;
            end;
            tlpLeft, tlpRight:
            begin
              if tc > 0 then
                h := ((tr.Bottom - tr.Top) + ((tc - 1) * TabAppearance.ShapeOverlap) - ((tc - 1) * TabSize.Spacing) - GetAdditionalTabSpacing) / tc
              else
                h := 0;

              if h > 0 then
              begin
                for I := 0 to Tabs.Count - 1 do
                begin
                  if IsTabVisible(I) then
                  begin
                    tbd.First := False;
                    tbd.Last := False;
                    tbd.Tab := Tabs[I];
                    tbd.Row := 0;
                    tbd.Rect := RectF(x, y, x + dw, y + h);
                    FDisplayList.Add(tbd);
                    y := y + h + TabSize.Spacing - TabAppearance.ShapeOverlap;
                  end;
                end;
              end;

              if Options.InsertMode = timTab then
              begin
                tbdinsert.Tab := FInsertTab;
                tbdinsert.TabKind := ttkInsert;
                tbdinsert.First := False;
                tbdinsert.Last := False;
                tbdinsert.Row := 0;
                if FDisplayList.Count > 0 then
                  tbdinsert.Rect := RectF(x, y + 5 + TabAppearance.ShapeOverlap, x + dw, y + 5 + TabAppearance.ShapeOverlap + TabAppearance.InsertSize)
                else
                  tbdinsert.Rect := RectF(x, y + 5, x + dw, y + 5 + TabAppearance.InsertSize);
                FDisplayList.Add(tbdinsert);
              end;
            end;
          end;
        end
        else if Layout.Multiline = tlmNone then
        begin
          if not FShowScrollButtons and FAllowScrolling then
          begin
            FShowScrollButtons := True;
            UpdateTabs(True, AForceUpdate);
          end
          else if (Options.InsertMode = timTab) and (das > 0) then
          begin
            case Layout.Position of
              tlpTop, tlpBottom:
              begin
                tbdinsert.Tab := FInsertTab;
                tbdinsert.TabKind := ttkInsert;
                tbdinsert.First := False;
                tbdinsert.Last := False;
                tbdinsert.Row := das;
                tbd := FDisplayList[FDisplayList.Count - 1];
                tbdinsert.Rect := RectF(tbd.Rect.Right + 5, tbd.Rect.Top, tbd.Rect.Right + 5 + TabAppearance.InsertSize, tbd.Rect.Top + dh);
                FDisplayList.Add(tbdinsert);
              end;
              tlpLeft, tlpRight:
              begin
                tbdinsert.Tab := FInsertTab;
                tbdinsert.TabKind := ttkInsert;
                tbdinsert.First := False;
                tbdinsert.Last := False;
                tbdinsert.Row := das;
                tbd := FDisplayList[FDisplayList.Count - 1];
                tbdinsert.Rect := RectF(tbd.Rect.Left, tbd.Rect.Bottom + 5, tbd.Rect.Left + dw, tbd.Rect.Bottom + 5 + TabAppearance.InsertSize);
                FDisplayList.Add(tbdinsert);
              end;
            end;
          end;
        end;
      end
    end;
  end;

  chrw := (das > 0) and (Layout.Multiline = tlmEnabledActiveTab) and (ActiveTabIndex <> -1) and ((TabSize.Mode = tsmFixedSize) or (TabSize.Mode = tsmAutoTabSize));

  for I := 0 to GetNormalDisplayCount - 1 do
  begin
    tbd := FDisplayList[I];
    tbd.Index := I;
    tbd.TabKind := ttkNormal;

    if I = 0 then
    begin
      tbd.First := True;
      if Assigned(tbd.Tab) then
        FFirstTabIndex := tbd.Tab.Index;
    end;

    if I = GetNormalDisplayCount - 1 then
    begin
      tbd.Last := True;
      if Assigned(tbd.Tab) then
        FLastTabIndex := tbd.Tab.Index;
    end;

    if chrw then
    begin
      off := 0;
      case Layout.Position of
        tlpLeft, tlpRight: sz := dw;
        tlpTop, tlpBottom: sz := dh;
        else
          sz := 0;
      end;

      if tbd.Row = ar then
        off := (das - ar) * sz
      else if tbd.Row > ar then
        off := -sz;

      case Layout.Position of
        tlpLeft: tbd.Rect := RectF(tbd.Rect.Left + off, tbd.Rect.Top, tbd.Rect.Right + off, tbd.Rect.Bottom);
        tlpTop: tbd.Rect := RectF(tbd.Rect.Left, tbd.Rect.Top + off, tbd.Rect.Right, tbd.Rect.Bottom + off);
        tlpBottom: tbd.Rect := RectF(tbd.Rect.Left, tbd.Rect.Top - off, tbd.Rect.Right, tbd.Rect.Bottom - off);
        tlpRight: tbd.Rect := RectF(tbd.Rect.Left - off, tbd.Rect.Top, tbd.Rect.Right - off, tbd.Rect.Bottom);
      end;
    end;

    CalculateRects(tbd);

    FDisplayList[I] := tbd;
  end;

  if (Options.InsertMode = timTab) and (FDisplayList.Count > 0) then
  begin
    tbdinsert := FDisplayList[FDisplayList.Count - 1];
    if Assigned(tbdinsert.Tab) then
    begin
      r := tbdinsert.Rect;
      case Layout.Position of
        tlpLeft, tlpRight: tbdinsert.Rect := RectF(r.Left + ((r.Right - r.Left) - TabAppearance.InsertSize) / 2, r.Top, r.Left + ((r.Right - r.Left) - TabAppearance.InsertSize) / 2 + TabAppearance.InsertSize, r.Bottom);
        tlpTop, tlpBottom: tbdinsert.Rect := RectF(r.Left, r.Top + ((r.Bottom - r.Top) - TabAppearance.InsertSize) / 2, r.Right, r.Top + ((r.Bottom - r.Top) - TabAppearance.InsertSize) / 2 + TabAppearance.InsertSize);
      end;

      if chrw then
      begin
        off := 0;
        case Layout.Position of
          tlpLeft, tlpRight: sz := dw;
          tlpTop, tlpBottom: sz := dh;
          else
            sz := 0;
        end;

        if tbd.Row = ar then
          off := (das - ar) * sz
        else if tbd.Row > ar then
          off := -sz;

        case Layout.Position of
          tlpLeft: tbdinsert.Rect := RectF(tbdinsert.Rect.Left + off, tbdinsert.Rect.Top, tbdinsert.Rect.Right + off, tbdinsert.Rect.Bottom);
          tlpTop: tbdinsert.Rect := RectF(tbdinsert.Rect.Left, tbdinsert.Rect.Top + off, tbdinsert.Rect.Right, tbdinsert.Rect.Bottom + off);
          tlpBottom: tbdinsert.Rect := RectF(tbdinsert.Rect.Left, tbdinsert.Rect.Top - off, tbdinsert.Rect.Right, tbdinsert.Rect.Bottom - off);
          tlpRight: tbdinsert.Rect := RectF(tbdinsert.Rect.Left - off, tbdinsert.Rect.Top, tbdinsert.Rect.Right - off, tbdinsert.Rect.Bottom);
        end;
      end;

      r := tbdinsert.Rect;
      tbdinsert.BitmapRect := RectF(Floor(r.Left), Floor(r.Top), Floor(r.Right), Floor(r.Bottom));
      FDisplayList[FDisplayList.Count - 1] := tbdinsert;
    end;
  end;

  if (FActiveTabIndex >= 0) and (FActiveTabIndex <= FTabs.Count - 1) then
    FActiveTab := FTabs[FActiveTabIndex]
  else
    FActiveTab := nil;

  FScrollCount := 0;
  if (TabSize.Mode <> tsmFixedSizeAutoShrink) and (Layout.Multiline = tlmNone) and FAllowScrolling then
    FScrollCount := FTabs.Count - GetNormalDisplayCount - GetInvisibleTabCountForScrolling;

  InitializeScrollButtonState;
  InitializeInvisibleTabs;
  StartProgressAnimationTimer;
  FixStartTab;
  if (Layout.Multiline <> tlmNone) and (TabSize.Mode in [tsmFixedSize, tsmAutoTabSize]) then
    FTotalTabHeight := (das + 1) * TabSize.Height
  else
    FTotalTabHeight := TabSize.Height;

  Invalidate;
end;

function TTMSFNCCustomTabSet.XYToAnchor(ATab: TTMSFNCTabSetDisplayTab; X,
  Y: Single): string;
var
  g: TTMSFNCGraphics;
  ha, va: TTMSFNCGraphicsTextAlign;
  ww: Boolean;
  tr: TTMSFNCGraphicsTextTrimming;
begin
  Result := '';
  if not Assigned(ATab.Tab) then
    Exit;

  if ATab.Tab.IsHTML then
  begin
    g := TTMSFNCGraphics.CreateBitmapCanvas(1, 1, NativeCanvas);
    if not NativeCanvas then
      g.BeginScene;
    g.BitmapContainer := BitmapContainer;
    g.OptimizedHTMLDrawing := OptimizedHTMLDrawing;
    try
      g.Font.Assign(TabAppearance.Font);

      if ATab.Tab.UseDefaultAppearance then
      begin
        ww := TabAppearance.WordWrapping;
        tr := TabAppearance.Trimming;
        ha := TabAppearance.TextAlign;
      end
      else
      begin
        ww := ATab.Tab.WordWrapping;
        tr := ATab.Tab.Trimming;
        ha := ATab.Tab.TextAlign;
      end;

      va := gtaCenter;

      case Layout.Position of
        tlpTop, tlpBottom: Result := g.DrawText(ATab.TextRect, ATab.Tab.Text, ww, ha, va, tr, 0, -1, -1, True, True, X, Y);
        tlpLeft: Result := g.DrawText(ATab.TextRect, ATab.Tab.Text, ww, ha, va, tr, -90, -1, -1, True, True, X, Y);
        tlpRight: Result := g.DrawText(ATab.TextRect, ATab.Tab.Text, ww, ha, va, tr, 90, -1, -1, True, True, X, Y);
      end;

    finally
      if not NativeCanvas then
        g.EndScene;
      g.Free;
    end;
  end;
end;

function TTMSFNCCustomTabSet.XYToCloseButton(X, Y: Single): Boolean;
begin
  Result := FShowCloseButton and PtInRectEx(GetCloseButtonRect, PointF(X, Y));
end;

function TTMSFNCCustomTabSet.XYToCloseTab(X,
  Y: Single): TTMSFNCTabSetDisplayTab;
var
  I: Integer;
  tbd: TTMSFNCTabSetDisplayTab;
begin
  Result := EmptyDisplayTab;
  if Options.CloseMode <> tcmTab then
    Exit;

  for I := GetDisplayCount - 1 downto 0 do
  begin
    tbd := FDisplayList[I];
    if Assigned(tbd.Tab) and tbd.Tab.CloseButton then
    begin
      if PtInRectEx(tbd.CloseButtonRect, PointF(X, Y)) then
      begin
        Result := tbd;
        Break;
      end;
    end;
  end;
end;

function TTMSFNCCustomTabSet.XYToInsertButton(X, Y: Single): Boolean;
begin
  Result := FShowInsertButton and PtInRectEx(GetInsertButtonRect, PointF(X, Y));
end;

function TTMSFNCCustomTabSet.XYToScrollNextButton(X, Y: Single): Boolean;
begin
  Result := FShowScrollButtons and not FScrollButtonNextDisabled and PtInRectEx(GetScrollButtonNextRect, PointF(X, Y));
end;

function TTMSFNCCustomTabSet.XYToScrollPreviousButton(X, Y: Single): Boolean;
begin
  Result := FShowScrollButtons and not FScrollButtonPreviousDisabled and PtInRectEx(GetScrollButtonPreviousRect, PointF(X, Y));
end;

function TTMSFNCCustomTabSet.XYToTab(X, Y: Single; AExcludeTab: TTMSFNCTabSetTab = nil): TTMSFNCTabSetDisplayTab;
var
  I: Integer;
  tbd: TTMSFNCTabSetDisplayTab;
  pth: TTMSFNCGraphicsPath;
  fnd: Boolean;
begin
  fnd := False;
  Result := EmptyDisplayTab;
  for I := GetDisplayCount - 1 downto 0 do
  begin
    tbd := FDisplayList[I];
    if Assigned(tbd.Tab) and (tbd.Tab <> AExcludeTab) then
    begin
      if tbd.Tab = FInsertTab then
        pth := tbd.Tab.GetInsertShapePath(tbd.Rect)
      else
        pth := tbd.Tab.GetShapePath(tbd.Rect);

      if Assigned(pth) then
      begin
        if TTMSFNCGraphics.PointInPath(PointF(X, Y), pth) then
        begin
          Result := tbd;
          fnd := True;
        end;
        pth.Free;
      end;

      if fnd then
        Break;
    end;
  end;
end;

function TTMSFNCCustomTabSet.XYToTabListButton(X, Y: Single): Boolean;
begin
  Result := FShowTabListButton and PtInRectEx(GetTabListButtonRect, PointF(X, Y));
end;

{ TTMSFNCTabSetTabs }

function TTMSFNCTabSetTabs.Add: TTMSFNCTabSetTab;
begin
  Result := TTMSFNCTabSetTab(inherited Add);
end;

procedure TTMSFNCTabSetTabs.Clear;
var
  l: TTMSFNCCustomTabSet;
  ci: TCollectionItem;
begin
  l := TabSet;
  if Assigned(l) then
  begin
    l.BeginUpdate;
    l.ActiveTabIndex := -1;
  end;

  if Count > 0 then
  begin
    while Count > 0 do
    begin
      ci := TCollectionItem(Items[Count - 1]);
      ci.Free;
    end;
  end;

  if Assigned(l) then
    l.EndUpdate;
end;

{$IFDEF LCLLIB}
function TTMSFNCTabSetTabs.QueryInterface({$IFDEF FPC_HAS_CONSTREF}constref{$ELSE}const{$ENDIF} iid : tguid;out obj) : longint;{$IFNDEF WINDOWS}cdecl{$ELSE}stdcall{$ENDIF};
{$ELSE}
function TTMSFNCTabSetTabs.QueryInterface(const IID: TGUID; out Obj): HResult;
{$ENDIF}
const
  E_NOINTERFACE = HResult($80004002);
begin
  if GetInterface(IID, Obj) then Result := 0 else Result := E_NOINTERFACE;
end;

{$IFDEF LCLLIB}
function TTMSFNCTabSetTabs._AddRef: longint;{$IFNDEF WINDOWS}cdecl{$ELSE}stdcall{$ENDIF};
{$ELSE}
function TTMSFNCTabSetTabs._AddRef: Integer;
{$ENDIF}
begin
  if FOwnerInterface <> nil then
    Result := FOwnerInterface._AddRef else
    Result := -1;
end;

{$IFDEF LCLLIB}
function TTMSFNCTabSetTabs._Release: longint;{$IFNDEF WINDOWS}cdecl{$ELSE}stdcall{$ENDIF};
{$ELSE}
function TTMSFNCTabSetTabs._Release: Integer;
{$ENDIF}
begin
  if FOwnerInterface <> nil then
    Result := FOwnerInterface._Release else
    Result := -1;
end;

constructor TTMSFNCTabSetTabs.Create(ATabSet: TTMSFNCCustomTabSet);
begin
  inherited Create(ATabSet, GetTabClass);
  FTabSet := ATabSet;
end;

function TTMSFNCTabSetTabs.GetTab(Index: Integer): TTMSFNCTabSetTab;
begin
  Result := TTMSFNCTabSetTab(inherited Items[Index]);
end;

function TTMSFNCTabSetTabs.CreateObject(const AClassName: string;
  const ABaseClass: TClass): TObject;
begin
  Result := GetTabClass.Create(nil);
end;

function TTMSFNCTabSetTabs.GetInterfaceItemClass: TClass;
begin
  Result := GetTabClass;
end;

function TTMSFNCTabSetTabs.GetTabClass: TCollectionItemClass;
begin
  Result := TTMSFNCTabSetTab;
end;

function TTMSFNCTabSetTabs.Insert(Index: Integer): TTMSFNCTabSetTab;
begin
  Result := TTMSFNCTabSetTab(inherited Insert(Index));
end;

function TTMSFNCTabSetTabs.TabSet: TTMSFNCCustomTabSet;
begin
  Result := FTabSet;
end;

procedure TTMSFNCTabSetTabs.SetTab(Index: Integer;
  const Value: TTMSFNCTabSetTab);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCTabSetTab }

procedure TTMSFNCTabSetTab.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCTabSetTab then
  begin
    FTag := (Source as TTMSFNCTabSetTab).Tag;
    FText := (Source as TTMSFNCTabSetTab).Text;
    FTrimming := (Source as TTMSFNCTabSetTab).Trimming;
    FWordWrapping := (Source as TTMSFNCTabSetTab).WordWrapping;
    FTextAlign := (Source as TTMSFNCTabSetTab).TextAlign;
    FTextColor := (Source as TTMSFNCTabSetTab).TextColor;
    FActiveTextColor := (Source as TTMSFNCTabSetTab).ActiveTextColor;
    FDisabledTextColor := (Source as TTMSFNCTabSetTab).DisabledTextColor;
    FDownTextColor := (Source as TTMSFNCTabSetTab).DownTextColor;
    FHoverTextColor := (Source as TTMSFNCTabSetTab).HoverTextColor;
    FColor := (Source as TTMSFNCTabSetTab).Color;
    FProgressColor := (Source as TTMSFNCTabSetTab).ProgressColor;
    FActiveColor := (Source as TTMSFNCTabSetTab).ActiveColor;
    FDisabledColor := (Source as TTMSFNCTabSetTab).DisabledColor;
    FDownColor := (Source as TTMSFNCTabSetTab).DownColor;
    FHoverColor := (Source as TTMSFNCTabSetTab).HoverColor;
    FEnabled := (Source as TTMSFNCTabSetTab).Enabled;
    FVisible := (Source as TTMSFNCTabSetTab).Visible;
    FBitmaps.Assign((Source as TTMSFNCTabSetTab).Bitmaps);
    FDisabledBitmaps.Assign((Source as TTMSFNCTabSetTab).DisabledBitmaps);
    FWidth := (Source as TTMSFNCTabSetTab).Width;
    FBitmapVisible := (Source as TTMSFNCTabSetTab).BitmapVisible;
    FBitmapSize := (Source as TTMSFNCTabSetTab).BitmapSize;
    FUseDefaultAppearance := (Source as TTMSFNCTabSetTab).UseDefaultAppearance;
    FTextVisible := (Source as TTMSFNCTabSetTab).TextVisible;
    FStretchText := (Source as TTMSFNCTabSetTab).StretchText;
    FShape := (Source as TTMSFNCTabSetTab).Shape;
    FProgress := (Source as TTMSFNCTabSetTab).Progress;
    FProgressMax := (Source as TTMSFNCTabSetTab).ProgressMax;
    FProgressMode := (Source as TTMSFNCTabSetTab).ProgressMode;
    FProgressKind := (Source as TTMSFNCTabSetTab).ProgressKind;
    FCloseButton := (Source as TTMSFNCTabSetTab).CloseButton;
    FBadge := (Source as TTMSFNCTabSetTab).Badge;
    FBadgeColor := (Source as TTMSFNCTabSetTab).BadgeColor;
    FBadgeTextColor := (Source as TTMSFNCTabSetTab).BadgeTextColor;
    FHint := (Source as TTMSFNCTabSetTab).Hint;
  end;
end;

procedure TTMSFNCTabSetTab.BitmapsChanged(Sender: TObject);
var
  bmp: TTMSFNCBitmap;
begin
  bmp := TTMSFNCGraphics.GetScaledBitmap(Bitmaps, 0, BitmapContainer);
  BitmapVisible := Assigned(bmp) and not IsBitmapEmpty(bmp);
end;

procedure TTMSFNCTabSetTab.Changed(Sender: TObject);
begin
  UpdateTab;
end;

constructor TTMSFNCTabSetTab.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(Collection) then
    FTabSet := (Collection as TTMSFNCTabSetTabs).TabSet;

  if Assigned(FTabSet) and (FTabSet.IsDesignTime) then
    FText := 'Tab Item ' + IntToStr(Index);

  FEnabled := True;
  FProgress := 0;
  FProgressMax := 100;
  FProgressMode := tpmNormal;
  FProgressKind := tpkNone;
  FUseDefaultAppearance := False;
  FTextColor := gcBlack;
  FActiveTextColor := gcBlack;
  FDisabledTextColor := gcBlack;
  FBadgeColor := gcOrange;
  FBadgeTextColor := gcDarkred;
  FDownTextColor := gcBlack;
  FHoverTextColor := gcBlack;
  FColor := TTMSFNCTabSetColor;
  FProgressColor := TTMSFNCTabSetColorProgress;
  FActiveColor := gcWhite;
  FDisabledColor := gcLightgray;
  FDownColor := TTMSFNCTabSetDownColor;
  FHoverColor := TTMSFNCTabSetHoverColor;
  FWordWrapping := False;
  FTextAlign := gtaCenter;
  FTrimming := gttCharacter;
  FCloseButton := True;
  FWidth := -1;
  FHeight := -1;
  FStretchText := False;
  FBitmapVisible := False;
  FBitmapSize := 24;
  FTextVisible := True;
  FShape := tsPyramid;
  FVisible := True;
  FBitmaps := TTMSFNCScaledBitmaps.Create(FTabSet);
  FBitmaps.OnChange := @BitmapsChanged;
  FDisabledBitmaps := TTMSFNCScaledBitmaps.Create(FTabSet);
  FDisabledBitmaps.OnChange := @BitmapsChanged;
  UpdateTab;
end;

destructor TTMSFNCTabSetTab.Destroy;
var
  t: TTMSFNCCustomTabSet;
begin
  FDisabledBitmaps.Free;
  FBitmaps.Free;
  t := TabSet;
  if Assigned(t) then
  begin
    if t.FActiveTab = Self then
      t.FActiveTab := nil;
  end;
  inherited;
  UpdateTab;
end;

procedure TTMSFNCTabSetTab.Draw(AGraphics: TTMSFNCGraphics; ATab: TTMSFNCTabSetDisplayTab);
var
  r: TRectF;
begin
  if not Assigned(FTabSet) or not Assigned(ATab.Tab) then
    Exit;

  r := GetFixedRect(ATab.Rect, ATab);
  if (r.Right - r.Left <= 0) or (r.Bottom - r.Top <= 0) then
    Exit;

  AGraphics.Font.Assign(TabSet.TabAppearance.Font);

  if Enabled then
  begin
    if ATab.Tab = TabSet.FActiveTab then
    begin
      AGraphics.Fill.Assign(TabSet.TabAppearance.ActiveFill);
      AGraphics.Stroke.Assign(TabSet.TabAppearance.ActiveStroke);
    end
    else if ATab.Tab = TabSet.FDownTab then
    begin
      AGraphics.Fill.Assign(TabSet.TabAppearance.DownFill);
      AGraphics.Stroke.Assign(TabSet.TabAppearance.DownStroke);
    end
    else if ATab.Tab = TabSet.FHoverTab then
    begin
      AGraphics.Fill.Assign(TabSet.TabAppearance.HoverFill);
      AGraphics.Stroke.Assign(TabSet.TabAppearance.HoverStroke);
    end
    else
    begin
      AGraphics.Fill.Assign(TabSet.TabAppearance.Fill);
      AGraphics.Stroke.Assign(TabSet.TabAppearance.Stroke);
    end;
  end
  else
  begin
    AGraphics.Fill.Assign(TabSet.TabAppearance.DisabledFill);
    AGraphics.Stroke.Assign(TabSet.TabAppearance.DisabledStroke);
  end;

  if not UseDefaultAppearance then
  begin
    if Enabled then
    begin
      if ATab.Tab = TabSet.FActiveTab then
        AGraphics.Fill.Color := ActiveColor
      else if ATab.Tab = TabSet.FDownTab then
        AGraphics.Fill.Color := DownColor
      else if ATab.Tab = TabSet.FHoverTab then
        AGraphics.Fill.Color := HoverColor
      else
        AGraphics.Fill.Color := Color
    end
    else
      AGraphics.Fill.Color := DisabledColor;
  end;

  DrawBackground(AGraphics, ATab);
  DrawProgress(AGraphics, ATab);
  DrawBitmap(AGraphics, ATab);
  DrawText(AGraphics, ATab);
  DrawCloseButton(AGraphics, ATab);
  DrawFocus(AGraphics, ATab);
end;

procedure TTMSFNCTabSetTab.DrawBackground(AGraphics: TTMSFNCGraphics; ATab: TTMSFNCTabSetDisplayTab);
var
  pth: TTMSFNCGraphicsPath;
  r: TRectF;
  c: Boolean;
  st: TTMSFNCTabSetTabState;
begin
  if not Assigned(FTabSet) then
    Exit;

  r := GetFixedRect(ATab.Rect, ATab);

  if Enabled then
  begin
    if ATab.Tab = TabSet.FActiveTab then
      st := ttsActive
    else if ATab.Tab = TabSet.FDownTab then
      st := ttsDown
    else if ATab.Tab = TabSet.FHoverTab then
      st := ttsHover
    else
      st := ttsNormal
  end
  else
    st := ttsDisabled;

  c := True;
  FTabSet.DoBeforeDrawTabBackground(AGraphics, Index, r, st, c);
  if c then
  begin
    if Self = TabSet.FInsertTab then
      AGraphics.DrawRoundRectangle(r, TabSet.ButtonAppearance.Rounding)
    else
    begin
      pth := GetShapePath(r);
      if Assigned(pth) then
      begin
        AGraphics.DrawPath(pth);
        pth.Free;
      end;
    end;

    FTabSet.DoAfterDrawTabBackground(AGraphics, Index, r, st);
  end;
end;

procedure TTMSFNCTabSetTab.DrawBadge(AGraphics: TTMSFNCGraphics;
  ATab: TTMSFNCTabSetDisplayTab);
var
  r: TRectF;
  sz: TSizeF;
  s: String;
  pth: TTMSFNCGraphicsPath;
  rnd: Single;
  c: Boolean;
begin
  if not Assigned(FTabSet) then
    Exit;

  if (Badge = '') then
    Exit;

  AGraphics.Font.Assign(TabSet.TabAppearance.BadgeFont);
  AGraphics.Stroke.Assign(TabSet.TabAppearance.BadgeStroke);
  AGraphics.Fill.Assign(TabSet.TabAppearance.BadgeFill);

  if not UseDefaultAppearance then
  begin
    AGraphics.Font.Color := BadgeTextColor;
    AGraphics.Fill.Color := BadgeColor;
  end;

  s := Badge;
  sz := AGraphics.CalculateTextSize(s);
  sz.cy := sz.cy + 4;
  sz.cx := Max(sz.cy, sz.cx + 6);

  if GetShape in [tsRectangle, tsRoundLeft, tsRoundRight, tsRound] then
    r := ATab.Rect
  else
    r := ATab.ContentRect;

  if (r.Right - r.Left) / 3 * 2 < sz.cx then
    Exit;

  case TabSet.Layout.Position of
    tlpTop: r := RectF(Int(r.Right - sz.cx / 2), Int(r.Top - sz.cy / 2), Int(r.Right + sz.cx / 2), Int(r.Top + sz.cy / 2));
    tlpBottom: r := RectF(Int(r.Right - sz.cx / 2), Int(r.Bottom - sz.cy / 2), Int(r.Right + sz.cx / 2), Int(r.Bottom + sz.cy / 2));
    tlpLeft: r := RectF(Int(r.Left - sz.cy / 2), Int(r.Top - sz.cx / 2), Int(r.Left + sz.cy / 2), Int(r.Top + sz.cx / 2));
    tlpRight: r := RectF(Int(r.Right - sz.cy / 2), Int(r.Bottom - sz.cx / 2), Int(r.Right + sz.cy / 2), Int(r.Bottom + sz.cx / 2));
  end;


  c := True;
  FTabSet.DoBeforeDrawTabBadge(AGraphics, Index, r, s, c);
  if c then
  begin
    case TabSet.Layout.Position of
      tlpTop, tlpBottom: rnd := (r.Bottom - r.Top) / 2;
      tlpLeft, tlpRight: rnd := (r.Right - r.Left) / 2;
      else
        rnd := 0;
    end;

    pth := TTMSFNCGraphicsPath.Create;
    pth.AddArc(PointF(r.Left + rnd, r.Top + rnd), PointF(rnd, rnd), 180, 90);
    pth.AddArc(PointF(r.Right - rnd, r.Top + rnd), PointF(rnd, rnd), -90, 90);
    pth.AddArc(PointF(r.Right - rnd, r.Bottom - rnd), PointF(rnd, rnd), 0, 90);
    pth.AddArc(PointF(r.Left + rnd, r.Bottom - rnd), PointF(rnd, rnd), -270, 90);
    pth.ClosePath;
    AGraphics.DrawPath(pth);
    case TabSet.Layout.Position of
      tlpTop, tlpBottom: AGraphics.DrawText(r, s, False, gtaCenter, gtaCenter);
      tlpLeft: AGraphics.DrawText(r, s, False, gtaCenter, gtaCenter, gttNone, -90);
      tlpRight: AGraphics.DrawText(r, s, False, gtaCenter, gtaCenter, gttNone, 90);
    end;
    pth.Free;

    FTabSet.DoAfterDrawTabBadge(AGraphics, Index, r, s);
  end;
end;

procedure TTMSFNCTabSetTab.DrawBitmap(AGraphics: TTMSFNCGraphics; ATab: TTMSFNCTabSetDisplayTab);
var
  r: TRectF;
  c: Boolean;
begin
  if not Assigned(FTabSet) then
    Exit;

  if not BitmapVisible then
    Exit;

  r := ATab.BitmapRect;
  c := True;
  FTabSet.DoBeforeDrawTabBitmap(AGraphics, Index, r, c);
  if c then
  begin
    if Enabled then
      AGraphics.DrawScaledBitmap(r, Bitmaps)
    else
      AGraphics.DrawScaledBitmap(r, DisabledBitmaps);

    FTabSet.DoAfterDrawTabBitmap(AGraphics, Index, r);
  end;
end;

procedure TTMSFNCTabSetTab.DrawCloseButton(AGraphics: TTMSFNCGraphics;
  ATab: TTMSFNCTabSetDisplayTab);
var
  r: TRectF;
  sk: TTMSFNCGraphicsStrokeKind;
  cl: TTMSFNCGraphicsColor;
  c: Boolean;
  st: TTMSFNCTabSetButtonState;
  paintScale: Single;
begin
  if not Assigned(FTabSet) then
    Exit;

  if (TabSet.Options.CloseMode = tcmTab) and CloseButton then
  begin
    r := ATab.CloseButtonRect;
    r := RectF(int(r.Left), Int(r.Top), Int(r.Right), Int(r.Bottom));
    if ATab.Tab = TabSet.FDownCloseTab then
    begin
      AGraphics.Fill.Assign(TabSet.TabAppearance.CloseDownFill);
      AGraphics.Stroke.Assign(TabSet.TabAppearance.CloseDownStroke);
    end
    else if ATab.Tab = TabSet.FHoverCloseTab then
    begin
      AGraphics.Fill.Assign(TabSet.TabAppearance.CloseHoverFill);
      AGraphics.Stroke.Assign(TabSet.TabAppearance.CloseHoverStroke);
    end
    else
    begin
      AGraphics.Fill.Assign(TabSet.TabAppearance.CloseFill);
      AGraphics.Stroke.Assign(TabSet.TabAppearance.CloseStroke);
    end;

    if ATab.Tab = TabSet.FDownCloseTab then
      st := tbsDown
    else if ATab.Tab = TabSet.FHoverCloseTab then
      st := tbsHover
    else
      st := tbsNormal;

    c := True;
    FTabSet.DoBeforeDrawTabCloseButton(AGraphics, Index, r, st, c);
    if c then
    begin
      if AGraphics.Fill.Kind <> gfkNone then
      begin
        sk := AGraphics.Stroke.Kind;
        cl := AGraphics.Stroke.Color;
        AGraphics.Stroke.Kind := gskSolid;
        AGraphics.Stroke.Color := AGraphics.Fill.Color;
        AGraphics.DrawEllipse(r, gcrmNone);
        AGraphics.Stroke.Kind := sk;
        AGraphics.Stroke.Color := cl;
      end;

      paintScale := FTabSet.PaintScaleFactor;

      InflateRectEx(r, paintScale * -4, paintScale * -4);
      AGraphics.DrawLine(PointF(r.Left, r.Top), PointF(r.Right, r.Bottom), gcpmNone, gcpmNone);
      AGraphics.DrawLine(PointF(r.Left, r.Bottom), PointF(r.Right, r.Top), gcpmNone, gcpmNone);

      FTabSet.DoAfterDrawTabCloseButton(AGraphics, Index, r, st);
    end;
  end;
end;

procedure TTMSFNCTabSetTab.DrawFocus(AGraphics: TTMSFNCGraphics;
  ATab: TTMSFNCTabSetDisplayTab);
var
  r: TRectF;
begin
  if not Assigned(FTabSet) then
    Exit;

  if FTabSet.TabAppearance.ShowFocus and FTabSet.IsFocused and (Self = FTabSet.FFocusedTab) then
  begin
    r := ATab.ContentRect;
    InflateRectEx(r, -1, -1);
    AGraphics.DrawFocusRectangle(r, FTabSet.TabAppearance.FocusBorderColor);
  end;
end;

procedure TTMSFNCTabSetTab.DrawProgress(AGraphics: TTMSFNCGraphics;
  ATab: TTMSFNCTabSetDisplayTab);
var
  r, rp: TRectF;
  c: Boolean;
begin
  if not Assigned(FTabSet) then
    Exit;

  if (ProgressKind <> tpkNone) and (((Progress > 0) and (ProgressMax > 0) and (ProgressMode = tpmNormal)) or (ProgressMode = tpmMarquee)) then
  begin
    case ProgressKind of
      tpkCircular:
      begin
        AGraphics.Fill.Kind := gfkNone;
        AGraphics.Stroke.Assign(FTabSet.TabAppearance.ProgressStroke);
          if not UseDefaultAppearance then
            AGraphics.Stroke.Color := ProgressColor;
        AGraphics.Stroke.Width := 2;
        r := ATab.ProgressRect;
        InflateRectEx(r, -2, -2);
        c := True;
        FTabSet.DoBeforeDrawTabProgress(AGraphics, Index, r, ProgressMode, ProgressKind, FAnimMarqueeStartAngle, FAnimMarqueeSweepAngle, Progress, ProgressMax, c);
        if c then
        begin
          case ProgressMode of
            tpmNormal: AGraphics.DrawArc(CenterPointEx(r), PointF((r.Right - r.Left) / 2, (r.Bottom - r.Top) / 2), 0, 360 / ProgressMax * Progress);
            tpmMarquee: AGraphics.DrawArc(CenterPointEx(r), PointF((r.Right - r.Left) / 2, (r.Bottom - r.Top) / 2), FAnimMarqueeStartAngle, FAnimMarqueeSweepAngle);
          end;
          FTabSet.DoAfterDrawTabProgress(AGraphics, Index, r, ProgressMode, ProgressKind, FAnimMarqueeStartAngle, FAnimMarqueeSweepAngle, Progress, ProgressMax);
        end;
      end;
      tpkRectangular:
      begin
        AGraphics.Fill.Assign(FTabSet.TabAppearance.ProgressFill);
        if not UseDefaultAppearance then
          AGraphics.Fill.Color := ProgressColor;
        AGraphics.Stroke.Assign(FTabSet.TabAppearance.ProgressStroke);
        r := ATab.ContentRect;
        InflateRectEx(r, -4, -4);
        c := True;
        FTabSet.DoBeforeDrawTabProgress(AGraphics, Index, r, ProgressMode, ProgressKind, FAnimMarqueeStartAngle, FAnimMarqueeSweepAngle, Progress, ProgressMax, c);
        if c then
        begin
          case FTabSet.Layout.Position of
            tlpTop, tlpBottom:
            begin
              case ProgressMode of
                tpmNormal: rp := RectF(r.Left, r.Top, r.Left + (r.Right - r.Left) / ProgressMax * Progress, r.Bottom);
                tpmMarquee: rp := RectF(r.Left + (r.Right - r.Left) / 360 * FAnimMarqueeSweepAngle - 5, r.Top, r.Left + 5 + (r.Right - r.Left) / 360 * FAnimMarqueeSweepAngle, r.Bottom);
              end;

              AGraphics.DrawRectangle(rp);
            end;
            tlpLeft:
            begin
              case ProgressMode of
                tpmNormal: rp := RectF(r.Left, r.Bottom - (r.Bottom - r.Top) / ProgressMax * Progress, r.Right, r.Bottom);
                tpmMarquee: rp := RectF(r.Left, r.Bottom - (r.Bottom - r.Top) / 360 * FAnimMarqueeSweepAngle, r.Right, r.Bottom);
              end;

              AGraphics.DrawRectangle(rp);
            end;
            tlpRight:
            begin
              case ProgressMode of
                tpmNormal: rp := RectF(r.Left, r.Top, r.Right, r.Bottom - (r.Bottom - r.Top) / ProgressMax * Progress);
                tpmMarquee: rp := RectF(r.Left, r.Top, r.Right, r.Bottom - (r.Bottom - r.Top) / 360 * FAnimMarqueeSweepAngle);
              end;

              AGraphics.DrawRectangle(rp);
            end;
          end;

          FTabSet.DoAfterDrawTabProgress(AGraphics, Index, r, ProgressMode, ProgressKind, FAnimMarqueeStartAngle, FAnimMarqueeSweepAngle, Progress, ProgressMax);
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCTabSetTab.DrawText(AGraphics: TTMSFNCGraphics; ATab: TTMSFNCTabSetDisplayTab);
var
  r: TRectF;
  st: TTMSFNCGraphicsSaveState;
  ww: Boolean;
  tr: TTMSFNCGraphicsTextTrimming;
  ha, va: TTMSFNCGraphicsTextAlign;
  str: string;
  c: Boolean;
begin
  if not Assigned(FTabSet) then
    Exit;

  if not TextVisible or (Text = '') or (FTabSet.FInplaceEditorActive and (FTabSet.FUpdateTab.Tab = Self)) then
    Exit;

  r := ATab.TextRect;
  st := AGraphics.SaveState;
  try
    AGraphics.ClipRect(r);

    if UseDefaultAppearance then
    begin
      ww := TabSet.TabAppearance.WordWrapping;
      tr := TabSet.TabAppearance.Trimming;
      ha := TabSet.TabAppearance.TextAlign;
      if Enabled then
      begin
        if Self = TabSet.FActiveTab then
          AGraphics.Font.Color := TabSet.TabAppearance.ActiveTextColor
        else if Self = TabSet.FDownTab then
          AGraphics.Font.Color := TabSet.TabAppearance.DownTextColor
        else if Self = TabSet.FHoverTab then
          AGraphics.Font.Color := TabSet.TabAppearance.HoverTextColor
        else
          AGraphics.Font.Color := TabSet.TabAppearance.TextColor
      end
      else
        AGraphics.Font.Color := TabSet.TabAppearance.DisabledTextColor;
    end
    else
    begin
      ww := WordWrapping;
      tr := Trimming;
      ha := TextAlign;
      if Enabled then
      begin
        if Self = TabSet.FActiveTab then
          AGraphics.Font.Color := ActiveTextColor
        else if Self = TabSet.FDownTab then
          AGraphics.Font.Color := DownTextColor
        else if Self = TabSet.FHoverTab then
          AGraphics.Font.Color := HoverTextColor
        else
          AGraphics.Font.Color := TextColor
      end
      else
        AGraphics.Font.Color := DisabledTextColor;
    end;

    va := gtaCenter;
    str := Text;
    c := True;
    FTabSet.DoBeforeDrawTabText(AGraphics, Index, r, str, c);
    if c then
    begin
      case TabSet.Layout.Position of
        tlpTop, tlpBottom: AGraphics.DrawText(r, str, ww, ha, va, tr);
        tlpLeft: AGraphics.DrawText(r, str, ww, ha, va, tr, -90);
        tlpRight: AGraphics.DrawText(r, str, ww, ha, va, tr, 90);
      end;

      FTabSet.DoAfterDrawTabText(AGraphics, Index, r, str);
    end;
  finally
    AGraphics.RestoreState(st);
  end;
end;

function TTMSFNCTabSetTab.GetBitmap: TTMSFNCBitmap;
begin
  if Enabled then
    Result := TTMSFNCGraphics.GetScaledBitmap(Bitmaps, 0, BitmapContainer)
  else
    Result := TTMSFNCGraphics.GetScaledBitmap(DisabledBitmaps, 0, BitmapContainer)
end;

function TTMSFNCTabSetTab.GetBitmapContainer: TTMSFNCBitmapContainer;
var
  l: TTMSFNCCustomTabSet;
begin
  Result := nil;
  l := TabSet;
  if Assigned(l) then
    Result := l.BitmapContainer;
end;

function TTMSFNCTabSetTab.GetBitmapRect(ARect: TRectF): TRectF;
var
  r: TRectF;
  rp: TRectF;
begin
  r := ARect;
  rp := GetProgressRect(r);
  Result := r;

  case TabSet.Layout.Position of
    tlpTop, tlpBottom:
    begin
      if BitmapVisible then
      begin
        if not TextVisible or (Text = '') then
          Result := RectF(rp.Right + 2, r.Top + 2, r.Right - 2, r.Bottom - 2)
        else if TextVisible and not (Text = '') then
          Result := RectF(rp.Right + 2, r.Top + 2, rp.Right + BitmapSize + 2, r.Bottom - 2);
      end
      else
        Result := RectF(rp.Right, r.Top, rp.Right, r.Bottom);
    end;
    tlpLeft:
    begin
      if BitmapVisible then
      begin
        if not TextVisible or (Text = '') then
          Result := RectF(r.Left + 2, r.Top + 2, r.Right - 2, rp.Bottom - 2)
        else if TextVisible and not (Text = '') then
          Result := RectF(r.Left + 2, rp.Top - BitmapSize - 2, r.Right - 2, rp.Top - 2);
      end
      else
        Result := RectF(r.Left, rp.Top, r.Right, rp.Top);
    end;
    tlpRight:
    begin
      if BitmapVisible then
      begin
        if not TextVisible or (Text = '') then
          Result := RectF(r.Left + 2, rp.Top + 2, r.Right - 2, r.Bottom - 2)
        else if TextVisible and not (Text = '') then
          Result := RectF(r.Left + 2, rp.Bottom + 2, r.Right - 2, rp.Bottom + BitmapSize + 2);
      end
      else
        Result := RectF(r.Left, rp.Bottom, r.Right, rp.Bottom);
    end;
  end;
end;

function TTMSFNCTabSetTab.GetCloseButtonRect(ARect: TRectF): TRectF;
var
  sz: Single;
begin
  case FTabSet.Layout.Position of
    tlpLeft: Result := RectF(ARect.Left, ARect.Top, ARect.Right, ARect.Top);
    tlpTop, tlpBottom: Result := RectF(ARect.Right, ARect.Top, ARect.Right, ARect.Bottom);
    tlpRight: Result := RectF(ARect.Left, ARect.Bottom, ARect.Right, ARect.Bottom);
  end;

  if (FTabSet.Options.CloseMode = tcmTab) and CloseButton then
  begin
    sz := FTabSet.TabAppearance.CloseSize;
    case FTabSet.Layout.Position of
      tlpTop, tlpBottom: Result := RectF(Result.Right - sz - 4, Result.Top + ((Result.Bottom - Result.Top) - sz) / 2,
        Result.Right - 4, Result.Top + ((Result.Bottom - Result.Top) - sz) / 2 + sz);
      tlpLeft: Result := RectF(Result.Left + ((Result.Right - Result.Left) - sz) / 2, Result.Top + 4,
        Result.Left + ((Result.Right - Result.Left) - sz) / 2 + sz, Result.Top + sz + 4);
      tlpRight: Result := RectF(Result.Left + ((Result.Right - Result.Left) - sz) / 2, Result.Bottom - sz - 4,
        Result.Left + ((Result.Right - Result.Left) - sz) / 2 + sz, Result.Bottom - 4);
    end;
  end;
end;

function TTMSFNCTabSetTab.GetFixedRect(ARect: TRectF; ATab: TTMSFNCTabSetDisplayTab): TRectF;
var
  t: TTMSFNCCustomTabSet;
begin
  Result := EmptyRect;
  t := TabSet;
  if not Assigned(t) then
    Exit;

  Result := ARect;
  case TabSet.Layout.Position of
    tlpTop, tlpBottom:
    begin
      if ATab.First then
        Result := RectF(Int(Result.Left) + 0.5, Int(Result.Top) + 0.5, Int(Result.Right) + 0.5, Int(Result.Bottom) - 0.5)
      else if ATab.Last then
        Result := RectF(Int(Result.Left) - 0.5, Int(Result.Top) + 0.5, Int(Result.Right) - 0.5, Int(Result.Bottom) - 0.5)
      else
        Result := RectF(Int(Result.Left) - 0.5, Int(Result.Top) + 0.5, Int(Result.Right) + 0.5, Int(Result.Bottom) - 0.5);
    end;
    tlpLeft, tlpRight:
    begin
      if ATab.First then
        Result := RectF(Int(Result.Left) + 0.5, Int(Result.Top) + 0.5, Int(Result.Right) - 0.5, Int(Result.Bottom) + 0.5)
      else if ATab.Last then
        Result := RectF(Int(Result.Left) + 0.5, Int(Result.Top) - 0.5, Int(Result.Right) - 0.5, Int(Result.Bottom) - 0.5)
      else
        Result := RectF(Int(Result.Left) + 0.5, Int(Result.Top) - 0.5, Int(Result.Right) - 0.5, Int(Result.Bottom) + 0.5);
    end;
  end;
end;

function TTMSFNCTabSetTab.GetFriendlyName: String;
begin
  Result := GetStrippedHTMLText;
  if Result = '' then
    Result := 'Tab ' + IntToStr(Index);
end;

function TTMSFNCTabSetTab.GetInsertShapePath(
  ARect: TRectF): TTMSFNCGraphicsPath;
var
  r: TRectF;
  rnd: Single;
begin
  r := ARect;
  rnd := TabSet.TabAppearance.ShapeRounding;
  Result := TTMSFNCGraphicsPath.Create;
  Result.AddArc(PointF(r.Left + rnd, r.Top + rnd), PointF(rnd, rnd), 180, 90);
  Result.AddArc(PointF(r.Right - rnd, r.Top + rnd), PointF(rnd, rnd), -90, 90);
  Result.AddArc(PointF(r.Right - rnd, r.Bottom - rnd), PointF(rnd, rnd), 0, 90);
  Result.AddArc(PointF(r.Left + rnd, r.Bottom - rnd), PointF(rnd, rnd), -270, 90);
  Result.ClosePath;
end;

function TTMSFNCTabSetTab.GetProgressRect(ARect: TRectF): TRectF;
var
  sz: Single;
begin
  case FTabSet.Layout.Position of
    tlpLeft: Result := RectF(ARect.Left, ARect.Bottom, ARect.Right, ARect.Bottom);
    tlpTop, tlpBottom: Result := RectF(ARect.Left, ARect.Top, ARect.Left, ARect.Bottom);
    tlpRight: Result := RectF(ARect.Left, ARect.Top, ARect.Right, ARect.Top);
  end;

  case ProgressKind of
    tpkCircular:
    begin
      sz := TabSet.TabAppearance.ProgressCircularSize + 4;
      case FTabSet.Layout.Position of
        tlpTop, tlpBottom: Result := RectF(Result.Left + 4, Result.Top + ((Result.Bottom - Result.Top) - sz) / 2,
          Result.Left + sz + 4, Result.Top + ((Result.Bottom - Result.Top) - sz) / 2 + sz);
        tlpLeft: Result := RectF(Result.Left + ((Result.Right - Result.Left) - sz) / 2, Result.Bottom - sz - 4,
          Result.Left + ((Result.Right - Result.Left) - sz) / 2 + sz, Result.Bottom - 4);
        tlpRight: Result := RectF(Result.Left + ((Result.Right - Result.Left) - sz) / 2, Result.Top + 4,
          Result.Left + ((Result.Right - Result.Left) - sz) / 2 + sz, Result.Top + sz + 4);
      end;
    end;
  end;
end;

function TTMSFNCTabSetTab.GetShape: TTMSFNCTabSetTabShape;
begin
  if UseDefaultAppearance then
    Result := TabSet.TabAppearance.Shape
  else
    Result := Shape;
end;

function TTMSFNCTabSetTab.GetShapePath(ARect: TRectF): TTMSFNCGraphicsPath;
var
  shz: Single;
  r: TRectF;
  sh: TTMSFNCTabSetTabShape;
  rnd, an: Single;
begin
  r := ARect;
  sh := GetShape;
  shz := TabSet.TabAppearance.ShapeSlope;
  if (shz = 0) and (sh in [tsPyramidLeft, tsPyramidRight, tsPyramid]) then
    sh := tsRectangle;

  rnd := TabSet.TabAppearance.ShapeRounding;

  an := 0;
  if shz > 0 then
  begin
    case TabSet.Layout.Position of
      tlpTop, tlpBottom: an := RadToDeg(ArcSin((r.Bottom - r.Top) / Sqrt(Power((r.Bottom - r.Top), 2) + Power(shz, 2))));
      tlpLeft, tlpRight: an := RadToDeg(ArcSin((r.Right - r.Left) / Sqrt(Power((r.Right - r.Left), 2) + Power(shz, 2))));
    end;
  end;

  case TabSet.Layout.Position of
    tlpTop:
    begin
      Result := TTMSFNCGraphicsPath.Create;
      Result.MoveTo(PointF(r.Left, r.Bottom));
      case sh of
        tsRectangle, tsPyramidRight, tsRoundRight: Result.LineTo(PointF(r.Left, r.Top));
        tsPyramidLeft, tsPyramid:
        begin
          Result.AddArc(PointF(r.Left, r.Bottom - rnd), PointF(rnd, rnd), -270, -an);
          Result.AddArc(PointF(r.Left + shz + rnd, r.Top + rnd), PointF(rnd, rnd), -an - 90, an);
        end;
        tsRoundLeft, tsRound:
        begin
          Result.LineTo(PointF(r.Left, r.Top + rnd));
          Result.AddArc(PointF(r.Left + rnd, r.Top + rnd), PointF(rnd, rnd), 180, 90);
        end;
      end;

      case sh of
        tsRectangle, tsPyramidLeft, tsRoundLeft: Result.LineTo(PointF(r.Right, r.Top));
        tsPyramidRight, tsPyramid:
        begin
          Result.AddArc(PointF(r.Right - shz - rnd, r.Top + rnd), PointF(rnd, rnd), 270, an);
          Result.AddArc(PointF(r.Right, r.Bottom - rnd), PointF(rnd, rnd), an + 90, -an);
        end;
        tsRoundRight, tsRound:
        begin
          Result.AddArc(PointF(r.Right - rnd, r.Top + rnd), PointF(rnd, rnd), 270, 90);
          Result.LineTo(PointF(r.Right, r.Top + rnd));
        end;
      end;

      Result.LineTo(PointF(r.Right, r.Bottom));
      Result.LineTo(PointF(r.Left, r.Bottom));
      Result.ClosePath;
    end;
    tlpBottom:
    begin
      Result := TTMSFNCGraphicsPath.Create;
      Result.MoveTo(PointF(r.Left, r.Top));
      case sh of
        tsRectangle, tsPyramidRight, tsRoundRight: Result.LineTo(PointF(r.Left, r.Bottom));
        tsPyramidLeft, tsPyramid:
        begin
          Result.AddArc(PointF(r.Left, r.Top + rnd), PointF(rnd, rnd), 270, an);
          Result.AddArc(PointF(r.Left + shz + rnd, r.Bottom - rnd), PointF(rnd, rnd), an - 270, -an);
        end;
        tsRoundLeft, tsRound:
        begin
          Result.LineTo(PointF(r.Left, r.Bottom - rnd));
          Result.AddArc(PointF(r.Left + rnd, r.Bottom - rnd), PointF(rnd, rnd), -180, -90);
        end;
      end;

      case sh of
        tsRectangle, tsPyramidLeft, tsRoundLeft: Result.LineTo(PointF(r.Right, r.Bottom));
        tsPyramidRight, tsPyramid:
        begin
          Result.AddArc(PointF(r.Right - shz - rnd, r.Bottom - rnd), PointF(rnd, rnd), -270, -an);
          Result.AddArc(PointF(r.Right, r.Top + rnd), PointF(rnd, rnd), -an + 270, an);
        end;
        tsRoundRight, tsRound:
        begin
          Result.AddArc(PointF(r.Right - rnd, r.Bottom - rnd), PointF(rnd, rnd), -270, -90);
          Result.LineTo(PointF(r.Right, r.Bottom - rnd));
        end;
      end;

      Result.LineTo(PointF(r.Right, r.Top));
      Result.LineTo(PointF(r.Left, r.Top));
      Result.ClosePath;
    end;
    tlpLeft:
    begin
      Result := TTMSFNCGraphicsPath.Create;
      Result.MoveTo(PointF(r.Right, r.Bottom));
      case sh of
        tsRectangle, tsPyramidRight, tsRoundRight: Result.LineTo(PointF(r.Left, r.Bottom));
        tsPyramidLeft, tsPyramid:
        begin
          Result.AddArc(PointF(r.Right - rnd, r.Bottom), PointF(rnd, rnd), 0, -an);
          Result.AddArc(PointF(r.Left + rnd, r.Bottom - shz - rnd), PointF(rnd, rnd), -an + 180, an);
        end;
        tsRoundLeft, tsRound:
        begin
          Result.LineTo(PointF(r.Left + rnd, r.Bottom));
          Result.AddArc(PointF(r.Left + rnd, r.Bottom - rnd), PointF(rnd, rnd), 90, 90);
        end;
      end;

      case sh of
        tsRectangle, tsPyramidLeft, tsRoundLeft: Result.LineTo(PointF(r.Left, r.Top));
        tsPyramidRight, tsPyramid:
        begin
          Result.AddArc(PointF(r.Left + rnd, r.Top + shz + rnd), PointF(rnd, rnd), 180, an);
          Result.AddArc(PointF(r.Right - rnd, r.Top), PointF(rnd, rnd), an, -an);
        end;
        tsRoundRight, tsRound:
        begin
          Result.AddArc(PointF(r.Left + rnd, r.Top + rnd), PointF(rnd, rnd), 180, 90);
          Result.LineTo(PointF(r.Left + rnd, r.Top));
        end;
      end;

      Result.LineTo(PointF(r.Right, r.Top));
      Result.LineTo(PointF(r.Right, r.Bottom));
      Result.ClosePath;
    end;
    tlpRight:
    begin
      Result := TTMSFNCGraphicsPath.Create;
      Result.MoveTo(PointF(r.Left, r.Top));
      case sh of
        tsRectangle, tsPyramidRight, tsRoundRight: Result.LineTo(PointF(r.Right, r.Top));
        tsPyramidLeft, tsPyramid:
        begin
          Result.AddArc(PointF(r.Left + rnd, r.Top), PointF(rnd, rnd), -180, -an);
          Result.AddArc(PointF(r.Right - rnd, r.Top + shz + rnd), PointF(rnd, rnd), -an, an);
        end;
        tsRoundLeft, tsRound:
        begin
          Result.LineTo(PointF(r.Right - rnd, r.Top));
          Result.AddArc(PointF(r.Right - rnd, r.Top + rnd), PointF(rnd, rnd), -90, 90);
        end;
      end;

      case sh of
        tsRectangle, tsPyramidLeft, tsRoundLeft: Result.LineTo(PointF(r.Right, r.Bottom));
        tsPyramidRight, tsPyramid:
        begin
          Result.AddArc(PointF(r.Right - rnd, r.Bottom - shz - rnd), PointF(rnd, rnd), 0, an);
          Result.AddArc(PointF(r.Left + rnd, r.Bottom), PointF(rnd, rnd), -180 + an, -an);
        end;
        tsRoundRight, tsRound:
        begin
          Result.AddArc(PointF(r.Right - rnd, r.Bottom - rnd), PointF(rnd, rnd), 0, 90);
          Result.LineTo(PointF(r.Right - rnd, r.Bottom));
        end;
      end;

      Result.LineTo(PointF(r.Left, r.Bottom));
      Result.LineTo(PointF(r.Left, r.Top));
      Result.ClosePath;
    end;
    else
      Result := nil;
  end;
end;

function TTMSFNCTabSetTab.GetStrippedHTMLText: String;
var
  str: string;
begin
  str := Text;
  if IsHTML then
    Result := TTMSFNCUtils.HTMLStrip(str)
  else
    Result := str;
end;

function TTMSFNCTabSetTab.GetTextRect(ARect: TRectF): TRectF;
var
  bmpr, cr: TRectF;
  r: TRectF;
begin
  r := ARect;
  bmpr := GetBitmapRect(ARect);
  cr := GetCloseButtonRect(ARect);
  case TabSet.Layout.Position of
    tlpTop, tlpBottom:
    begin
      if not StretchText then
        Result := RectF(bmpr.Right + 2, r.Top + 2, cr.Left - 4, r.Bottom - 2)
      else
        Result := RectF(r.Left + 2, r.Top + 2, r.Right - 2, r.Bottom - 2);
    end;
    tlpLeft:
    begin
      if not StretchText then
        Result := RectF(r.Left + 2, cr.Bottom + 4, r.Right - 2, bmpr.Top - 2)
      else
        Result := RectF(r.Left + 2, r.Top + 2, r.Right - 2, r.Bottom - 2);
    end;
    tlpRight:
    begin
      if not StretchText then
        Result := RectF(r.Left + 2, bmpr.Bottom + 2, r.Right - 2, cr.Top - 4)
      else
        Result := RectF(r.Left + 2, r.Top + 2, r.Right - 2, r.Bottom - 2);
    end;
  end;
end;

function TTMSFNCTabSetTab.GetAutoSize: Single;
var
  g: TTMSFNCGraphics;
  b: TTMSFNCBitmap;
  t: TTMSFNCCustomTabSet;
  sz: TSizeF;
  paintScale: Single;
begin
  Result := 0;
  paintScale := 1;
  t := TabSet;
  if Assigned(t) then
  begin
    g := TTMSFNCGraphics.CreateBitmapCanvas(1, 1, t.NativeCanvas);
    if not t.NativeCanvas then
      g.BeginScene;
    try
      b := GetBitmap;
      if (Text <> '') and (TextVisible) then
      begin
        g.SetFont(t.TabAppearance.Font);
        sz := g.CalculateTextSize(Text, RectF(0, 0, 10000, 10000), True, True);
        Result := Result + sz.cx + 6;
        if ((t.TabAppearance.Trimming <> gttNone) and UseDefaultAppearance) or ((Trimming <> gttNone) and not UseDefaultAppearance) then
        begin
          Result := Result + 2;
        end;
      end;

      if Assigned(b) and BitmapVisible then
        Result := Result + BitmapSize + 4;

      if ProgressKind = tpkCircular then
        Result := Result + t.TabAppearance.ProgressCircularSize + 8;

      if (t.Options.CloseMode = tcmTab) and CloseButton then
        Result := Result + t.TabAppearance.CloseSize + 4;

    finally
      if not t.NativeCanvas then
        g.EndScene;
      g.Free;
    end;
    paintScale := t.PaintScaleFactor;
  end;

  Result := Max(30 * paintScale, Result);
end;

function TTMSFNCTabSetTab.IsBitmapSizeStored: Boolean;
begin
  Result := BitmapSize <> 24;
end;

function TTMSFNCTabSetTab.IsHTML: Boolean;
begin
  Result := (Pos('</', Text) > 0) or (Pos('/>', Text)  > 0) or (Pos('<BR>', UpperCase(Text)) > 0);
end;

function TTMSFNCTabSetTab.IsProgressMaxStored: Boolean;
begin
  Result := ProgressMax <> 100;
end;

function TTMSFNCTabSetTab.IsProgressStored: Boolean;
begin
  Result := Progress <> 0;
end;

function TTMSFNCTabSetTab.IsWidthStored: Boolean;
begin
  Result := Width <> -1;
end;

function TTMSFNCTabSetTab.TabSet: TTMSFNCCustomTabSet;
begin
  Result := FTabSet;
end;

procedure TTMSFNCTabSetTab.SetHint(const Value: string);
begin
  if FHint <> Value then
  begin
    FHint := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetBadge(const Value: string);
begin
  if FBadge <> Value then
  begin
    FBadge := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetBadgeColor(const Value: TTMSFNCGraphicsColor);
begin
  if FBadgeColor <> Value then
  begin
    FBadgeColor := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetBadgeTextColor(const Value: TTMSFNCGraphicsColor);
begin
  if FBadgeTextColor <> Value then
  begin
    FBadgeTextColor := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetDisabledBitmaps(const Value: TTMSFNCScaledBitmaps);
begin
  FDisabledBitmaps.Assign(Value);
end;

procedure TTMSFNCTabSetTab.SetBitmaps(const Value: TTMSFNCScaledBitmaps);
begin
  FBitmaps.Assign(Value);
end;

procedure TTMSFNCTabSetTab.SetBitmapSize(const Value: Single);
begin
  if FBitmapSize <> Value then
  begin
    FBitmapSize := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetBitmapVisible(const Value: Boolean);
begin
  if FBitmapVisible <> Value then
  begin
    FBitmapVisible := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetCloseButton(const Value: Boolean);
begin
  if FCloseButton <> Value then
  begin
    FCloseButton := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetColor(const Value: TTMSFNCGraphicsColor);
begin
  if FColor <> Value then
  begin
    FColor := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetDisabledColor(const Value: TTMSFNCGraphicsColor);
begin
  if FDisabledColor <> Value then
  begin
    FDisabledColor := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetDisabledTextColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FDisabledTextColor <> Value then
  begin
    FDisabledTextColor := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetDownColor(const Value: TTMSFNCGraphicsColor);
begin
  if FDownColor <> Value then
  begin
    FDownColor := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetDownTextColor(const Value: TTMSFNCGraphicsColor);
begin
  if FDownTextColor <> Value then
  begin
    FDownTextColor := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetEnabled(const Value: Boolean);
begin
  if FEnabled <> Value then
  begin
    FEnabled := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetHoverColor(const Value: TTMSFNCGraphicsColor);
begin
  if FHoverColor <> Value then
  begin
    FHoverColor := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetHoverTextColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FHoverTextColor <> Value then
  begin
    FHoverTextColor := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetProgress(const Value: Single);
begin
  if FProgress <> Value then
  begin
    FProgress := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetProgressColor(const Value: TTMSFNCGraphicsColor);
begin
  if FProgressColor <> Value then
  begin
    FProgressColor := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetProgressKind(
  const Value: TTMSFNCTabSetTabProgressKind);
begin
  if FProgressKind <> Value then
  begin
    FProgressKind := Value;
    if (FProgressMode = tpmMarquee) and (FProgressKind <> tpkNone) then
      FTabSet.StartProgressAnimationTimer;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetProgressMax(const Value: Single);
begin
  if FProgressMax <> Value then
  begin
    FProgressMax := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetProgressMode(
  const Value: TTMSFNCTabSetTabProgressMode);
begin
  if FProgressMode <> Value then
  begin
    FProgressMode := Value;
    if (FProgressMode = tpmMarquee) and (FProgressKind <> tpkNone) then
      FTabSet.StartProgressAnimationTimer;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetActiveColor(const Value: TTMSFNCGraphicsColor);
begin
  if FActiveColor <> Value then
  begin
    FActiveColor := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetActiveTextColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FActiveTextColor <> Value then
  begin
    FActiveTextColor := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetShape(const Value: TTMSFNCTabSetTabShape);
begin
  if FShape <> Value then
  begin
    FShape := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetStretchText(const Value: Boolean);
begin
  if FStretchText <> Value then
  begin
    FStretchText := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetText(const Value: String);
begin
  if FText <> Value then
  begin
    FText := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTextAlign <> Value then
  begin
    FTextAlign := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetTextColor(const Value: TTMSFNCGraphicsColor);
begin
  if FTextColor <> Value then
  begin
    FTextColor := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetTextVisible(const Value: Boolean);
begin
  if FTextVisible <> Value then
  begin
    FTextVisible := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetTrimming(
  const Value: TTMSFNCGraphicsTextTrimming);
begin
  if FTrimming <> Value then
  begin
    FTrimming := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetUseDefaultAppearance(const Value: Boolean);
begin
  if FUseDefaultAppearance <> Value then
  begin
    FUseDefaultAppearance := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetWidth(const Value: Single);
begin
  if FWidth <> Value then
  begin
    FWidth := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.SetWordWrapping(const Value: Boolean);
begin
  if FWordWrapping <> Value then
  begin
    FWordWrapping := Value;
    UpdateTab;
  end;
end;

procedure TTMSFNCTabSetTab.UpdateTab;
var
  l: TTMSFNCCustomTabSet;
begin
  l := TabSet;
  if Assigned(l) then
    l.UpdateTabs;
end;

{ TTMSFNCTabSetTabSize }

procedure TTMSFNCTabSetTabSize.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCTabSetTabSize then
  begin
    FMode := (Source as TTMSFNCTabSetTabSize).Mode;
    FWidth := (Source as TTMSFNCTabSetTabSize).Width;
    FHeight := (Source as TTMSFNCTabSetTabSize).Height;
    FMargins.Assign((Source as TTMSFNCTabSetTabSize).Margins);
    FSpacing := (Source as TTMSFNCTabSetTabSize).Spacing;
  end;
end;

procedure TTMSFNCTabSetTabSize.ChangeDPIScale(M, D: Integer);
begin
  Margins.Left := TTMSFNCUtils.MulDivSingle(Margins.Left, M, D);
  Margins.Right := TTMSFNCUtils.MulDivSingle(Margins.Right, M, D);
  Margins.Top := TTMSFNCUtils.MulDivSingle(Margins.Top, M, D);
  Margins.Bottom := TTMSFNCUtils.MulDivSingle(Margins.Bottom, M, D);
  Height := TTMSFNCUtils.MulDivSingle(Height, M, D);
  Spacing := TTMSFNCUtils.MulDivSingle(Spacing, M, D);
end;

constructor TTMSFNCTabSetTabSize.Create(ATabSet: TTMSFNCCustomTabSet);
begin
  FTabSet := ATabSet;
  FWidth := 100;
  FHeight := 22;
  FSpacing := 0;
  FMode := tsmAutoTabSize;
  FMargins := TTMSFNCMargins.Create;
  if Assigned(FTabSet) and (FTabSet.IsDesignTime) then
  begin
    FMargins.Left := 8;
    FMargins.Top := 8;
    FMargins.Right := 8;
    FMargins.Bottom := 8;
  end;
  FMargins.OnChange := @MarginsChanged;
end;

destructor TTMSFNCTabSetTabSize.Destroy;
begin
  FMargins.Free;
  inherited;
end;

function TTMSFNCTabSetTabSize.IsHeightStored: Boolean;
begin
  Result := Height <> 22;
end;

function TTMSFNCTabSetTabSize.IsSpacingStored: Boolean;
begin
  Result := Spacing <> 0;
end;

function TTMSFNCTabSetTabSize.IsWidthStored: Boolean;
begin
  Result := Width <> 100
end;

procedure TTMSFNCTabSetTabSize.MarginsChanged(Sender: TObject);
begin
  UpdateTabs;
end;

procedure TTMSFNCTabSetTabSize.SetHeight(const Value: Single);
begin
  if FHeight <> Value then
  begin
    FHeight := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetTabSize.SetMargins(const Value: TTMSFNCMargins);
begin
  FMargins.Assign(Value);
end;

procedure TTMSFNCTabSetTabSize.SetMode(const Value: TTMSFNCTabSetTabSizeMode);
begin
  if FMode <> Value then
  begin
    FMode := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetTabSize.SetSpacing(const Value: Single);
begin
  if FSpacing <> Value then
  begin
    FSpacing := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetTabSize.SetWidth(const Value: Single);
begin
  if FWidth <> Value then
  begin
    FWidth := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetTabSize.UpdateTabs;
begin
  if Assigned(OnChange) then
    OnChange(Self);

  if Assigned(FTabSet) then
    FTabSet.UpdateTabs;
end;

{ TTMSFNCTabSetTabAppearance }

procedure TTMSFNCTabSetTabAppearance.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCTabSetTabAppearance) then
  begin
    FWordWrapping := (Source as TTMSFNCTabSetTabAppearance).WordWrapping;
    FTrimming := (Source as TTMSFNCTabSetTabAppearance).Trimming;
    FShapeOverlap := (Source as TTMSFNCTabSetTabAppearance).ShapeOverlap;
    FTextAlign := (Source as TTMSFNCTabSetTabAppearance).TextAlign;
    FShapeSlope := (Source as TTMSFNCTabSetTabAppearance).ShapeSlope;
    FTextSpacing := (Source as TTMSFNCTabSetTabAppearance).TextSpacing;
    FShapeRounding := (Source as TTMSFNCTabSetTabAppearance).ShapeRounding;
    FFont.Assign((Source as TTMSFNCTabSetTabAppearance).Font);
    FTextColor := (Source as TTMSFNCTabSetTabAppearance).TextColor;
    FActiveTextColor := (Source as TTMSFNCTabSetTabAppearance).ActiveTextColor;
    FDisabledTextColor := (Source as TTMSFNCTabSetTabAppearance).DisabledTextColor;
    FDownTextColor := (Source as TTMSFNCTabSetTabAppearance).DownTextColor;
    FHoverTextColor := (Source as TTMSFNCTabSetTabAppearance).HoverTextColor;
    FFill.Assign((Source as TTMSFNCTabSetTabAppearance).Fill);
    FProgressFill.Assign((Source as TTMSFNCTabSetTabAppearance).ProgressFill);
    FProgressStroke.Assign((Source as TTMSFNCTabSetTabAppearance).ProgressStroke);
    FDownFill.Assign((Source as TTMSFNCTabSetTabAppearance).DownFill);
    FActiveFill.Assign((Source as TTMSFNCTabSetTabAppearance).ActiveFill);
    FHoverFill.Assign((Source as TTMSFNCTabSetTabAppearance).HoverFill);
    FDisabledFill.Assign((Source as TTMSFNCTabSetTabAppearance).DisabledFill);
    FStroke.Assign((Source as TTMSFNCTabSetTabAppearance).Stroke);
    FDownStroke.Assign((Source as TTMSFNCTabSetTabAppearance).DownStroke);
    FActiveStroke.Assign((Source as TTMSFNCTabSetTabAppearance).ActiveStroke);
    FHoverStroke.Assign((Source as TTMSFNCTabSetTabAppearance).HoverStroke);
    FDisabledStroke.Assign((Source as TTMSFNCTabSetTabAppearance).DisabledStroke);
    FShape := (Source as TTMSFNCTabSetTabAppearance).Shape;
    FShowFocus := (Source as TTMSFNCTabSetTabAppearance).ShowFocus;
    FFocusBorderColor := (Source as TTMSFNCTabSetTabAppearance).FocusBorderColor;
    FCloseSize := (Source as TTMSFNCTabSetTabAppearance).CloseSize;
    FInsertSize := (Source as TTMSFNCTabSetTabAppearance).InsertSize;
    FProgressCircularSize := (Source as TTMSFNCTabSetTabAppearance).ProgressCircularSize;
    FCloseFill.Assign((Source as TTMSFNCTabSetTabAppearance).CloseFill);
    FCloseStroke.Assign((Source as TTMSFNCTabSetTabAppearance).CloseStroke);
    FCloseHoverFill.Assign((Source as TTMSFNCTabSetTabAppearance).CloseHoverFill);
    FCloseHoverStroke.Assign((Source as TTMSFNCTabSetTabAppearance).CloseHoverStroke);
    FCloseDownFill.Assign((Source as TTMSFNCTabSetTabAppearance).CloseDownFill);
    FCloseDownStroke.Assign((Source as TTMSFNCTabSetTabAppearance).CloseDownStroke);
    FBadgeFont.Assign((Source as TTMSFNCTabSetTabAppearance).BadgeFont);
    FBadgeFill.Assign((Source as TTMSFNCTabSetTabAppearance).BadgeFill);
    FBadgeStroke.Assign((Source as TTMSFNCTabSetTabAppearance).BadgeStroke);
  end;
end;

procedure TTMSFNCTabSetTabAppearance.ChangeDPIScale(M, D: Integer);
begin
  ShapeRounding := TTMSFNCUtils.MulDivSingle(ShapeRounding, M, D);
  ShapeSlope := TTMSFNCUtils.MulDivSingle(ShapeSlope, M, D);
  ShapeOverlap := TTMSFNCUtils.MulDivSingle(ShapeOverlap, M, D);
  TextSpacing := TTMSFNCUtils.MulDivSingle(TextSpacing, M, D);
  Font.Height := TTMSFNCUtils.MulDivInt(FFont.Height, M, D);
  BadgeFont.Height := TTMSFNCUtils.MulDivInt(FBadgeFont.Height, M, D);

  CloseSize := TTMSFNCUtils.MulDivSingle(CloseSize, M, D);
  ProgressCircularSize := TTMSFNCUtils.MulDivSingle(ProgressCircularSize, M, D);
  InsertSize := TTMSFNCUtils.MulDivSingle(InsertSize, M, D);
end;

constructor TTMSFNCTabSetTabAppearance.Create(ATabSet: TTMSFNCCustomTabSet);
begin
  FTabSet := ATabSet;
  FWordWrapping := False;
  FTrimming := gttCharacter;
  FShapeOverlap := 12;
  FTextAlign := gtaCenter;
  FShapeSlope := 11;
  FTextSpacing := 0;
  FProgressCircularSize := 15;
  FCloseSize := 14;
  FInsertSize := 20;
  FShapeRounding  := 5;
  FTextColor := gcBlack;
  FBadgeFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcOrange);
  FBadgeFill.OnChanged := @FillChanged;
  FBadgeStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkred);
  FBadgeStroke.OnChanged := @StrokeChanged;
  FBadgeFont := TTMSFNCGraphicsFont.Create;
  if Assigned(FTabSet) and FTabSet.IsDesignTime then
  begin
    FBadgeFont.Color := gcDarkred;
    FBadgeFont.Style := [TFontStyle.fsBold];
  end;
  FBadgeFont.OnChanged := @FontChanged;

  FActiveTextColor := gcBlack;
  FDisabledTextColor := gcBlack;
  FDownTextColor := gcBlack;
  FShowFocus := True;
  FFocusBorderColor := gcBlack;
  FHoverTextColor := gcBlack;
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @FontChanged;
  FProgressFill := TTMSFNCGraphicsFill.Create(gfkSolid, TTMSFNCTabSetColorProgress);
  FProgressFill.OnChanged := @FillChanged;
  FProgressStroke := TTMSFNCGraphicsStroke.Create(gskSolid, TTMSFNCTabSetColorProgress);
  FProgressStroke.OnChanged := @StrokeChanged;
  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, TTMSFNCTabSetColor);
  FFill.OnChanged := @FillChanged;
  FDownFill := TTMSFNCGraphicsFill.Create(gfkSolid, TTMSFNCTabSetDownColor);
  FDownFill.OnChanged := @FillChanged;
  FActiveFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcWhite);
  FActiveFill.OnChanged := @FillChanged;
  FHoverFill := TTMSFNCGraphicsFill.Create(gfkSolid, TTMSFNCTabSetHoverColor);
  FHoverFill.OnChanged := @FillChanged;
  FDisabledFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcLightgray);
  FDisabledFill.OnChanged := @FillChanged;
  FStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkgray);
  FStroke.OnChanged := @StrokeChanged;
  FDownStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkgray);
  FDownStroke.OnChanged := @StrokeChanged;
  FActiveStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkgray);
  FActiveStroke.OnChanged := @StrokeChanged;
  FHoverStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkgray);
  FHoverStroke.OnChanged := @StrokeChanged;
  FDisabledStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkgray);
  FDisabledStroke.OnChanged := @StrokeChanged;
  FCloseFill := TTMSFNCGraphicsFill.Create(gfkNone, TTMSFNCTabSetColor);
  FCloseFill.OnChanged := @FillChanged;
  FCloseStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkgray);
  FCloseStroke.Width := 2;
  FCloseStroke.OnChanged := @StrokeChanged;
  FCloseHoverFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcRed);
  FCloseHoverFill.OnChanged := @FillChanged;
  FCloseHoverStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcWhite);
  FCloseHoverStroke.Width := 2;
  FCloseHoverStroke.OnChanged := @StrokeChanged;
  FCloseDownFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcCrimson);
  FCloseDownFill.OnChanged := @FillChanged;
  FCloseDownStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcWhite);
  FCloseDownStroke.Width := 2;
  FCloseDownStroke.OnChanged := @StrokeChanged;
  FShape := tsPyramid;
end;

destructor TTMSFNCTabSetTabAppearance.Destroy;
begin
  FBadgeFont.Free;
  FBadgeFill.Free;
  FBadgeStroke.Free;
  FFont.Free;
  FFill.Free;
  FProgressFill.Free;
  FProgressStroke.Free;
  FDownFill.Free;
  FHoverFill.Free;
  FDisabledFill.Free;
  FActiveFill.Free;
  FStroke.Free;
  FDownStroke.Free;
  FHoverStroke.Free;
  FDisabledStroke.Free;
  FActiveStroke.Free;
  FCloseHoverStroke.Free;
  FCloseHoverFill.Free;
  FCloseFill.Free;
  FCloseStroke.Free;
  FCloseDownFill.Free;
  FCloseDownStroke.Free;
  inherited;
end;

procedure TTMSFNCTabSetTabAppearance.FillChanged(Sender: TObject);
begin
  UpdateTabs;
end;

procedure TTMSFNCTabSetTabAppearance.FontChanged(Sender: TObject);
begin
  UpdateTabs;
end;

function TTMSFNCTabSetTabAppearance.IsCloseSizeStored: Boolean;
begin
  Result := CloseSize <> 14;
end;

function TTMSFNCTabSetTabAppearance.IsInsertSizeStored: Boolean;
begin
  Result := InsertSize <> 20;
end;

function TTMSFNCTabSetTabAppearance.IsProgressCircularSizeStored: Boolean;
begin
  Result := ProgressCircularSize <> 15;
end;

function TTMSFNCTabSetTabAppearance.IsShapeOverlapStored: Boolean;
begin
  Result := ShapeOverlap <> 12;
end;

function TTMSFNCTabSetTabAppearance.IsShapeRoundingStored: Boolean;
begin
  Result := ShapeRounding <> 5;
end;

function TTMSFNCTabSetTabAppearance.IsShapeSlopeStored: Boolean;
begin
  Result := ShapeSlope <> 11;
end;

procedure TTMSFNCTabSetTabAppearance.SetDisabledFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FDisabledFill.Assign(Value);
end;

procedure TTMSFNCTabSetTabAppearance.SetDisabledStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FDisabledStroke.Assign(Value);
end;

procedure TTMSFNCTabSetTabAppearance.SetDisabledTextColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FDisabledTextColor <> Value then
  begin
    FDisabledTextColor := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetTabAppearance.SetDownFill(const Value: TTMSFNCGraphicsFill);
begin
  FDownFill.Assign(Value);
end;

procedure TTMSFNCTabSetTabAppearance.SetDownStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FDownStroke.Assign(Value);
end;

procedure TTMSFNCTabSetTabAppearance.SetDownTextColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FDownTextColor <> Value then
  begin
    FDownTextColor := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetTabAppearance.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  FFill.Assign(Value);
end;

procedure TTMSFNCTabSetTabAppearance.SetFocusBorderColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FFocusBorderColor <> Value then
  begin
    FFocusBorderColor := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetTabAppearance.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  FFont.Assign(Value);
end;

procedure TTMSFNCTabSetTabAppearance.SetHoverFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FHoverFill.Assign(Value);
end;

procedure TTMSFNCTabSetTabAppearance.SetHoverStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FHoverStroke.Assign(Value);
end;

procedure TTMSFNCTabSetTabAppearance.SetHoverTextColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FHoverTextColor <> Value then
  begin
    FHoverTextColor := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetTabAppearance.SetInsertSize(
  const Value: Single);
begin
  if FInsertSize <> Value then
  begin
    FInsertSize := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetTabAppearance.SetProgressFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FProgressFill.Assign(Value);
end;

procedure TTMSFNCTabSetTabAppearance.SetProgressCircularSize(const Value: Single);
begin
  if FProgressCircularSize <> Value then
  begin
    FProgressCircularSize := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetTabAppearance.SetProgressStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FProgressStroke.Assign(Value);
end;

procedure TTMSFNCTabSetTabAppearance.SetActiveFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FActiveFill.Assign(Value);
end;

procedure TTMSFNCTabSetTabAppearance.SetActiveStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FActiveStroke.Assign(Value);
end;

procedure TTMSFNCTabSetTabAppearance.SetActiveTextColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FActiveTextColor <> Value then
  begin
    FActiveTextColor := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetTabAppearance.SetBadgeFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FBadgeFill.Assign(Value);
end;

procedure TTMSFNCTabSetTabAppearance.SetBadgeFont(
  const Value: TTMSFNCGraphicsFont);
begin
  FBadgeFont.Assign(Value);
end;

procedure TTMSFNCTabSetTabAppearance.SetBadgeStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FBadgeStroke.Assign(Value);
end;

procedure TTMSFNCTabSetTabAppearance.SetCloseDownFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FCloseDownFill.Assign(Value);
end;

procedure TTMSFNCTabSetTabAppearance.SetCloseDownStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FCloseDownStroke.Assign(Value);
end;

procedure TTMSFNCTabSetTabAppearance.SetCloseFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FCloseFill.Assign(Value);
end;

procedure TTMSFNCTabSetTabAppearance.SetCloseHoverFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FCloseHoverFill.Assign(Value);
end;

procedure TTMSFNCTabSetTabAppearance.SetCloseHoverStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FCloseHoverStroke.Assign(Value);
end;

procedure TTMSFNCTabSetTabAppearance.SetCloseSize(const Value: Single);
begin
  if FCloseSize <> Value then
  begin
    FCloseSize := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetTabAppearance.SetCloseStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FCloseStroke.Assign(Value);
end;

procedure TTMSFNCTabSetTabAppearance.SetShape(const Value: TTMSFNCTabSetTabShape);
begin
  if FShape <> Value then
  begin
    FShape := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetTabAppearance.SetShapeOverlap(const Value: Single);
begin
  if FShapeOverlap <> Value then
  begin
    FShapeOverlap := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetTabAppearance.SetShapeRounding(const Value: Single);
begin
  if FShapeRounding <> Value then
  begin
    FShapeRounding := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetTabAppearance.SetShapeSlope(const Value: Single);
begin
  if FShapeSlope <> Value then
  begin
    FShapeSlope := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetTabAppearance.SetShowFocus(const Value: Boolean);
begin
  if FShowFocus <> Value then
  begin
    FShowFocus := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetTabAppearance.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  FStroke.Assign(Value);
end;

procedure TTMSFNCTabSetTabAppearance.SetTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTextAlign <> Value then
  begin
    FTextAlign := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetTabAppearance.SetTextColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FTextColor <> Value then
  begin
    FTextColor := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetTabAppearance.SetTextSpacing(const Value: Single);
begin
  if FTextSpacing <> Value then
  begin
    FTextSpacing := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetTabAppearance.SetTrimming(
  const Value: TTMSFNCGraphicsTextTrimming);
begin
  if FTrimming <> Value then
  begin
    FTrimming := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetTabAppearance.SetWordWrapping(const Value: Boolean);
begin
  if FWordWrapping <> Value then
  begin
    FWordWrapping := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetTabAppearance.StrokeChanged(Sender: TObject);
begin
  UpdateTabs;
end;

procedure TTMSFNCTabSetTabAppearance.UpdateTabs;
begin
  if Assigned(FTabSet) then
    FTabSet.UpdateTabs;
end;

{ TTMSFNCTabSetLayout }

procedure TTMSFNCTabSetLayout.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCTabSetLayout) then
  begin
    FMultiline := (Source as TTMSFNCTabSetLayout).Multiline;
    FPosition := (Source as TTMSFNCTabSetLayout).Position;
  end;
end;

constructor TTMSFNCTabSetLayout.Create(ATabSet: TTMSFNCCustomTabSet);
begin
  FTabSet := ATabSet;
  FMultiline := tlmNone;
  FPosition := tlpTop;
end;

procedure TTMSFNCTabSetLayout.SetMultiline(const Value: TTMSFNCTabSetLayoutMultiline);
begin
  if FMultiline <> Value then
  begin
    FMultiline := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetLayout.SetPosition(
  const Value: TTMSFNCTabSetLayoutPosition);
begin
  if FPosition <> Value then
  begin
    FPosition := Value;
    if Assigned(FTabSet) and Assigned(FTabSet.FTabList) then
    begin
      case FPosition of
        tlpLeft: FTabSet.FTabListPopup.Placement := ppRight;
        tlpTop: FTabSet.FTabListPopup.Placement := ppBottom;
        tlpBottom: FTabSet.FTabListPopup.Placement := ppTop;
        tlpRight: FTabSet.FTabListPopup.Placement := ppLeft;
      end;
    end;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetLayout.UpdateTabs;
begin
  if Assigned(OnChange) then
    OnChange(Self);

  if Assigned(FTabSet) then
    FTabSet.UpdateTabs;
end;

{ TTMSFNCTabSetInteraction }

procedure TTMSFNCTabSetInteraction.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCTabSetInteraction then
  begin
    FSelectTabOnFocus := (Source as TTMSFNCTabSetInteraction).SelectTabOnFocus;
    FSelectTabOnInsert := (Source as TTMSFNCTabSetInteraction).SelectTabOnInsert;
    FSelectTabOnScroll := (Source as TTMSFNCTabSetInteraction).SelectTabOnScroll;
    FEditing := (Source as TTMSFNCTabSetInteraction).Editing;
    FCloseTabWithKeyboard := (Source as TTMSFNCTabSetInteraction).CloseTabWithKeyboard;
    FInsertTabWithKeyboard := (Source as TTMSFNCTabSetInteraction).InsertTabWithKeyboard;
    FAutoOpenURL := (Source as TTMSFNCTabSetInteraction).AutoOpenURL;
    FReorder := (Source as TTMSFNCTabSetInteraction).Reorder;
  end;
end;

constructor TTMSFNCTabSetInteraction.Create(ATabSet: TTMSFNCCustomTabSet);
begin
  FTabSet := ATabSet;
  FSelectTabOnFocus := True;
  FSelectTabOnInsert := True;
  FSelectTabOnScroll := True;
  FEditing := False;
  FInsertTabWithKeyboard := True;
  FCloseTabWithKeyboard := True;
  FAutoOpenURL := True;
  FReorder := False;
end;

procedure TTMSFNCTabSetInteraction.UpdateTabs;
begin
  if Assigned(FTabSet) then
    FTabSet.UpdateTabs;
end;

{$IFDEF LCLLIB}
class operator TTMSFNCTabSetDisplayTab.=(z1, z2: TTMSFNCTabSetDisplayTab)b: boolean;
begin
  Result := z1.Index = z2.Index;
end;
{$ENDIF}

{ TTMSFNCTabSetOptions }

procedure TTMSFNCTabSetOptions.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCTabSetOptions then
  begin
    FCloseMode := (Source as TTMSFNCTabSetOptions).CloseMode;
    FInsertMode := (Source as TTMSFNCTabSetOptions).InsertMode;
    FTabListButton := (Source as TTMSFNCTabSetOptions).TabListButton;
    FCloseAction := (Source as TTMSFNCTabSetOptions).CloseAction;
  end;
end;

constructor TTMSFNCTabSetOptions.Create(ATabSet: TTMSFNCCustomTabSet);
begin
  FTabSet := ATabSet;
  FCloseMode := tcmNone;
  FInsertMode := timNone;
  FTabListButton := False;
  FCloseAction := ttcaHide;
end;

procedure TTMSFNCTabSetOptions.SetCloseMode(
  const Value: TTMSFNCTabSetCloseMode);
begin
  if FCloseMode <> Value then
  begin
    FCloseMode := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetOptions.SetInsertMode(const Value: TTMSFNCTabSetInsertMode);
begin
  if FInsertMode <> Value then
  begin
    FInsertMode := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetOptions.SetTabListButton(const Value: Boolean);
begin
  if FTabListButton <> Value then
  begin
    FTabListButton := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetOptions.UpdateTabs;
begin
  if Assigned(FTabSet) then
    FTabSet.UpdateTabs;
end;

{ TTMSFNCTabSetButtonAppearance }

procedure TTMSFNCTabSetButtonAppearance.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCTabSetButtonAppearance then
  begin
    FSize := (Source as TTMSFNCTabSetButtonAppearance).Size;
    FRounding := (Source as TTMSFNCTabSetButtonAppearance).Rounding;
    FFill.Assign((Source as TTMSFNCTabSetButtonAppearance).Fill);
    FDownFill.Assign((Source as TTMSFNCTabSetButtonAppearance).DownFill);
    FHoverFill.Assign((Source as TTMSFNCTabSetButtonAppearance).HoverFill);
    FDisabledFill.Assign((Source as TTMSFNCTabSetButtonAppearance).DisabledFill);
    FStroke.Assign((Source as TTMSFNCTabSetButtonAppearance).Stroke);
    FDownStroke.Assign((Source as TTMSFNCTabSetButtonAppearance).DownStroke);
    FHoverStroke.Assign((Source as TTMSFNCTabSetButtonAppearance).HoverStroke);
    FDisabledStroke.Assign((Source as TTMSFNCTabSetButtonAppearance).DisabledStroke);
    FTabListIcon.Assign((Source as TTMSFNCTabSetButtonAppearance).TabListIcon);
    FCloseIcon.Assign((Source as TTMSFNCTabSetButtonAppearance).CloseIcon);
    FInsertIcon.Assign((Source as TTMSFNCTabSetButtonAppearance).InsertIcon);
    FScrollNextIcon.Assign((Source as TTMSFNCTabSetButtonAppearance).ScrollNextIcon);
    FScrollPreviousIcon.Assign((Source as TTMSFNCTabSetButtonAppearance).ScrollPreviousIcon);
  end;
end;

procedure TTMSFNCTabSetButtonAppearance.BitmapChanged(Sender: TObject);
begin
  if Assigned(FTabSet) and Assigned(FTabSet.FInsertTab) then
  begin
    FTabSet.FInsertTab.Bitmaps.Clear;
    FTabSet.FInsertTab.Bitmaps.AddBitmap(InsertIcon);
    FTabSet.FInsertTab.DisabledBitmaps.Clear;
    FTabSet.FInsertTab.DisabledBitmaps.AddBitmap(InsertIcon);
  end;

  UpdateTabs;
end;

constructor TTMSFNCTabSetButtonAppearance.Create(ATabSet: TTMSFNCCustomTabSet);
begin
  FTabSet := ATabSet;
  FSize := 20;
  FRounding := 5;
  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, TTMSFNCTabSetColor);
  FFill.OnChanged := @FillChanged;
  FDownFill := TTMSFNCGraphicsFill.Create(gfkSolid, TTMSFNCTabSetDownColor);
  FDownFill.OnChanged := @FillChanged;
  FHoverFill := TTMSFNCGraphicsFill.Create(gfkSolid, TTMSFNCTabSetHoverColor);
  FHoverFill.OnChanged := @FillChanged;
  FDisabledFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcLightgray);
  FDisabledFill.OnChanged := @FillChanged;
  FStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkgray);
  FStroke.OnChanged := @StrokeChanged;
  FDownStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkgray);
  FDownStroke.OnChanged := @StrokeChanged;
  FHoverStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkgray);
  FHoverStroke.OnChanged := @StrokeChanged;
  FDisabledStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkgray);
  FDisabledStroke.OnChanged := @StrokeChanged;
  FTabListIcon := TTMSFNCBitmap(TTMSFNCBitmap.CreateFromResource(TMSFNCTABSETLISTSVG, HInstance));
  FTabListIcon.OnChange := @BitmapChanged;
  FCloseIcon := TTMSFNCBitmap(TTMSFNCBitmap.CreateFromResource(TMSFNCTABSETCLOSESVG, HInstance));
  FCloseIcon.OnChange := @BitmapChanged;
  FInsertIcon := TTMSFNCBitmap(TTMSFNCBitmap.CreateFromResource(TMSFNCTABSETADDSVG, HInstance));
  FInsertIcon.OnChange := @BitmapChanged;
  FScrollNextIcon := TTMSFNCBitmap(TTMSFNCBitmap.CreateFromResource(TMSFNCTABSETNEXTSVG, HInstance));
  FScrollNextIcon.OnChange := @BitmapChanged;
  FScrollPreviousIcon := TTMSFNCBitmap(TTMSFNCBitmap.CreateFromResource(TMSFNCTABSETPREVIOUSSVG, HInstance));
  FScrollPreviousIcon.OnChange := @BitmapChanged;
end;

destructor TTMSFNCTabSetButtonAppearance.Destroy;
begin
  FTabListIcon.Free;
  FCloseIcon.Free;
  FInsertIcon.Free;
  FScrollPreviousIcon.Free;
  FScrollNextIcon.Free;
  FFill.Free;
  FStroke.Free;
  FHoverFill.Free;
  FHoverStroke.Free;
  FDownFill.Free;
  FDownStroke.Free;
  FDisabledFill.Free;
  FDisabledStroke.Free;
  inherited;
end;

procedure TTMSFNCTabSetButtonAppearance.FillChanged(Sender: TObject);
begin
  UpdateTabs;
end;

function TTMSFNCTabSetButtonAppearance.IsRoundingStored: Boolean;
begin
  Result := Rounding <> 5;
end;

function TTMSFNCTabSetButtonAppearance.IsSizedStored: Boolean;
begin
  Result := Size <> 20;
end;

procedure TTMSFNCTabSetButtonAppearance.SetCloseIcon(
  const Value: TTMSFNCBitmap);
begin
  FCloseIcon.Assign(Value);
end;

procedure TTMSFNCTabSetButtonAppearance.SetDisabledFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FDisabledFill.Assign(Value);
end;

procedure TTMSFNCTabSetButtonAppearance.SetDisabledStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FDisabledStroke.Assign(Value);
end;

procedure TTMSFNCTabSetButtonAppearance.SetDownFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FDownFill.Assign(Value);
end;

procedure TTMSFNCTabSetButtonAppearance.SetDownStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FDownStroke.Assign(Value);
end;

procedure TTMSFNCTabSetButtonAppearance.SetFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FFill.Assign(Value);
end;

procedure TTMSFNCTabSetButtonAppearance.SetHoverFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FHoverFill.Assign(Value);
end;

procedure TTMSFNCTabSetButtonAppearance.SetHoverStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FHoverStroke.Assign(Value);
end;

procedure TTMSFNCTabSetButtonAppearance.SetInsertIcon(
  const Value: TTMSFNCBitmap);
begin
  FInsertIcon.Assign(Value);
end;

procedure TTMSFNCTabSetButtonAppearance.SetRounding(const Value: Single);
begin
  FRounding := Value;
end;

procedure TTMSFNCTabSetButtonAppearance.SetScrollNextIcon(
  const Value: TTMSFNCBitmap);
begin
  FScrollNextIcon.Assign(Value);
end;

procedure TTMSFNCTabSetButtonAppearance.SetScrollPreviousIcon(
  const Value: TTMSFNCBitmap);
begin
  FScrollPreviousIcon.Assign(Value);
end;

procedure TTMSFNCTabSetButtonAppearance.SetSize(const Value: Single);
begin
  if FSize <> Value then
  begin
    FSize := Value;
    UpdateTabs;
  end;
end;

procedure TTMSFNCTabSetButtonAppearance.SetStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FStroke.Assign(Value);
end;

procedure TTMSFNCTabSetButtonAppearance.SetTabListIcon(
  const Value: TTMSFNCBitmap);
begin
  FTabListIcon.Assign(Value);
end;

procedure TTMSFNCTabSetButtonAppearance.StrokeChanged(Sender: TObject);
begin
  UpdateTabs;
end;

procedure TTMSFNCTabSetButtonAppearance.UpdateTabs;
begin
  if Assigned(FTabSet) then
    FTabSet.UpdateTabs;
end;

procedure TTMSFNCTabSetEdit.DoExit;
begin
  inherited;
  if Assigned(FTabSet) and FTabSet.FInplaceEditorActive then
    FTabSet.CloseInplaceEditor(False);
end;

constructor TTMSFNCTabSetEdit.Create(AOwner: TComponent);
begin
  inherited;
  if AOwner is TTMSFNCCustomTabSet then
    FTabSet := AOwner as TTMSFNCCustomTabSet;
end;

{$IFDEF CMNWEBLIB}
procedure TTMSFNCTabSetEdit.KeyDown(var Key: Word; Shift: TShiftState);
begin
  inherited;
  if Assigned(FTabSet) and FTabSet.FInplaceEditorActive then
  begin
    case Key of
      KEY_ESCAPE:
      begin
        FTabSet.CloseInplaceEditor(True);
        FTabSet.FInplaceEditorClosed := True;
        Key := 0;
      end;
      KEY_F2, KEY_RETURN:
      begin
        FTabSet.CloseInplaceEditor(False);
        FTabSet.FInplaceEditorClosed := True;
        Key := 0;
      end;
    end;
  end;
end;
{$ENDIF}

{$IFDEF FMXLIB}
function TTMSFNCTabSetEdit.GetDefaultStyleLookupName: string;
begin
  Result := 'editstyle';
end;
{$ENDIF}

{ TTMSFNCTabSet }

constructor TTMSFNCTabSet.Create(AOwner: TComponent);
begin
  inherited;
  {$IFDEF CMNLIB}
  {$IFDEF MSWINDOWS}
  NativeCanvas := True;
  Transparent := True;
  {$ENDIF}
  {$ENDIF}
  Width := 225;
  Height := 30;
  if IsDesignTime then
    InitSample;
end;

procedure TTMSFNCTabSet.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClasses([TTMSFNCTabSet, TTMSFNCTabSetTab]);
end;

{$IFDEF WEBLIB}
function TTMSFNCTabSetDisplayList.GetItem(Index: Integer): TTMSFNCTabSetDisplayTab;
begin
  Result := TTMSFNCTabSetDisplayTab(inherited Items[Index]);
end;

procedure TTMSFNCTabSetDisplayList.SetItem(Index: Integer; const Value: TTMSFNCTabSetDisplayTab);
var
  v: TTMSFNCTabSetDisplayTab;
begin
  v := Value;
  inherited Items[Index] := v;
end;

function TTMSFNCTabSetInvisibleTabList.GetItem(Index: Integer): TTMSFNCTabSetTab;
begin
  Result := TTMSFNCTabSetTab(inherited Items[Index]);
end;

procedure TTMSFNCTabSetInvisibleTabList.SetItem(Index: Integer; const Value: TTMSFNCTabSetTab);
begin
  inherited Items[Index] := Value;
end;
{$ENDIF}

{$IFDEF WEBLIB}
initialization
begin
  TTMSFNCBitmap.CreateFromResource(TMSFNCTABSETLIST);
  TTMSFNCBitmap.CreateFromResource(TMSFNCTABSETCLOSE);
  TTMSFNCBitmap.CreateFromResource(TMSFNCTABSETPREVIOUS);
  TTMSFNCBitmap.CreateFromResource(TMSFNCTABSETNEXT);
  TTMSFNCBitmap.CreateFromResource(TMSFNCTABSETADD);
end;
{$ENDIF}

end.
